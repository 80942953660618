import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import "simplebar/src/simplebar.css";
import {
  BatteryChangeSettingResult,
  BatteryManualCommand,
  BatteryManualStatus,
  ICameraInfo,
  Webviewer,
} from "@thingsw/pitta-modules";
import { IconButton, SettingsIcon } from "@thingsw/pitta-design-system";
import BatteryChargingStatus from "../battery/BatteryChargingStatus";
import BatteryChargingHistory from "../battery/BatteryChargingHistory";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  batteryManualEnd,
  loadBatteryHistory,
  loadBatteryManual,
  resetBatteryChangeSettingResult,
  resetBatteryHistory,
  resetBatteryInfo,
} from "../../features/Battery/slice";
import { RootState } from "../../features/store";
import BatteryTempHistory from "../battery/BatteryTempHistory";
import { openToast } from "../../features/Toast/slice";
import BatteryInuseModal from "../modals/BatteryInuseModal";
import BatteryUnableConnectModal from "../modals/BatteryUnableConnectModal";
import BatteryConnectionLostModal from "../modals/BatteryConnectionLostModal";
import BatteryConnectionFailPanel from "../battery/BatteryConnectionFailPanel";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: (props: any) => props.colors.primary["0"],
    borderRadius: 12,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    userSelect: "none",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
    ...(theme.direction === "rtl" && {
      "& > div > div": {
        scrollbarWidth: "none",
        paddingRight: 17,
      },
    }),
  },
  rootApp: {
    boxShadow: "unset",
    borderRadius: 0,
  },
  rootOpen: {
    width: 375,
    height: "100%",
  },
  rootClose: {
    width: 0,
  },
  rootMobile: {
    width: "100%",
  },
  header: {
    display: "flex",
    minHeight: 56,
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    justifyContent: "space-between",
    backgroundColor: (props: any) => props.colors.primary["0"],
    padding: "0 12px",
  },
  listPane: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

    transition: theme.transitions.create("transform"),
    backgroundColor: (props: any) => props.colors.primary["6"],
  },
  rootPanel: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 375,
    },
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-375px)",
    },
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(375px)",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    backgroundColor: (props: any) => props.colors.primary["5"],
  },
  disConnectModal: {
    width: "350px",
  },
  disConnectModalButton: {
    width: "100%",
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  circularLoading: {
    color: (props: any) => props.colors.primary["8"],
  },
}));

export type PANEL_MODE = "battery" | "list" | "setting";

interface CameraListPanelProps {
  open: boolean;
  mobile?: boolean;
  className?: string;
  setPanelState: (mode: PANEL_MODE) => void;
  currentCam: ICameraInfo | undefined;
  app?: boolean;
}

export const BatteryStatusPanel = (props: CameraListPanelProps) => {
  const { t } = useTranslation();

  const { open, className, mobile, setPanelState, currentCam, app } = props;
  const [isCelcius, setIsCelcius] = useState(true);

  const dispatch = useDispatch();
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const {
    batteryInfo,
    batterySettings,
    batteryDayHistory,
    batteryWeekHistory,
    currentCam: loadedCam,
  } = useSelector((state: RootState) => state.battery);
  const batteryDayHistoryInterval = React.useRef<any>(null);
  const batteryWeekHistoryInterval = React.useRef<any>(null);
  const classes = useStyles({ ...props, colors });

  // 배터리 메인 화면에서 새로고침 시 상태 저장
  useEffect(() => {
    if (window && batteryInfo) {
      window.localStorage.setItem(
        "pitta-webviewer:battery-status",
        JSON.stringify(batteryInfo)
      );
      window.localStorage.setItem(
        "pitta-webviewer:battery-currentCam",
        JSON.stringify(currentCam || loadedCam)
      );
    }

    return () => {
      window.localStorage.removeItem("pitta-webviewer:battery-status");
      window.localStorage.removeItem("pitta-webviewer:battery-currentCam");
    };
  }, [batteryInfo, currentCam, loadedCam]);

  // 배터리 연결 상태 local storage에 저장
  // 페이지 이탈 시 배터리 연결 종료 여부 확인용
  useEffect(() => {
    if (window && currentCam) {
      window.localStorage.setItem(
        "pitta-webviewer:battery-connect-info",
        JSON.stringify({
          ssid: currentCam.battery.ssid,
          psn: currentCam.psn,
        })
      );
    }
  }, [currentCam]);

  useEffect(() => {
    if (!currentCam && !loadedCam) return;

    batteryDayHistoryInterval.current = setInterval(() => {
      if (batteryDayHistory.items === undefined) {
        dispatch(
          loadBatteryHistory({ camera: currentCam || loadedCam!, period: 1 })
        );
      }
    }, 1500);

    return () => {
      clearInterval(batteryDayHistoryInterval.current);
    };
  }, [batteryDayHistory.items, currentCam, dispatch, loadedCam]);

  useEffect(() => {
    if (!currentCam && !loadedCam) return;

    batteryWeekHistoryInterval.current = setInterval(() => {
      if (batteryWeekHistory.items === undefined) {
        dispatch(
          loadBatteryHistory({ camera: currentCam || loadedCam!, period: 7 })
        );
      }
    }, 1500);

    return () => {
      clearInterval(batteryWeekHistoryInterval.current);
    };
  }, [batteryWeekHistory.items, currentCam, dispatch, loadedCam]);

  useEffect(() => {
    switch (batterySettings.changeSettingResult) {
      case BatteryChangeSettingResult.SUCCESS:
        dispatch(
          openToast({ message: t("Setting changed"), notification: true })
        );
        dispatch(resetBatteryChangeSettingResult());
        break;
      case BatteryChangeSettingResult.FAIL:
        dispatch(
          openToast({
            message: t("Setting changed failed"),
            notification: true,
          })
        );
        dispatch(resetBatteryChangeSettingResult());
        break;

      default:
        break;
    }
  }, [batterySettings.changeSettingResult, dispatch, t]);

  const handleCloseBatteryStatusPanel = (isError: boolean) => {
    if (!currentCam && !loadedCam) return;
    dispatch(resetBatteryInfo());
    dispatch(resetBatteryHistory());

    if (!isError) {
      // 배터리 연결 종료
      window.localStorage.removeItem("pitta-webviewer:battery-connect-info");
      dispatch(batteryManualEnd(currentCam || loadedCam!));
    }

    setPanelState("list");
  };

  const handleCloseBatteryInuseModal = () => {
    window.localStorage.removeItem("pitta-webviewer:battery-connect-info");

    setPanelState("list");
    if (!currentCam && !loadedCam) return;
    dispatch(resetBatteryInfo());
    dispatch(resetBatteryHistory());
  };

  const handleManualBatteryConnect = () => {
    if (!currentCam && !loadedCam) return;

    dispatch(resetBatteryInfo());

    dispatch(
      loadBatteryManual({
        camera: currentCam || loadedCam!,
        command: BatteryManualCommand.START,
      })
    );
  };

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.rootOpen]: open,
          [classes.rootClose]: !open,
          [classes.rootMobile]: mobile || app,
          [classes.rootApp]: app,
        },
        className
      )}
    >
      {batteryInfo.isConnectFail && (
        <BatteryConnectionFailPanel
          title={t("Battery connection failed")}
          onClick={() => handleCloseBatteryStatusPanel(true)}
        />
      )}

      {batteryInfo.isPowerOff && (
        <BatteryConnectionFailPanel
          title={t("Connection lost")}
          onClick={() => handleCloseBatteryStatusPanel(true)}
        />
      )}
      <BatteryInuseModal
        open={batteryInfo.manualStatus === BatteryManualStatus.IN_USE}
        onClickPositive={handleCloseBatteryInuseModal}
      />
      <BatteryUnableConnectModal
        open={
          batteryInfo.isNotStarted &&
          batteryInfo.manualStatus !== BatteryManualStatus.SUCCESS
        }
        onClickPositive={handleManualBatteryConnect}
        onClickNegative={() => handleCloseBatteryStatusPanel(true)}
      />
      <BatteryConnectionLostModal
        open={
          batteryInfo.isNotStarted &&
          batteryInfo.manualStatus === BatteryManualStatus.SUCCESS
        }
        onClickPositive={() => handleCloseBatteryStatusPanel(true)}
      />
      <Scrollbars style={{ height: "100%", width: "100%" }} autoHide>
        <div className={classes.rootPanel}>
          <div className={classes.listPane}>
            <div className={classes.header}>
              <IconButton onClick={() => handleCloseBatteryStatusPanel(false)}>
                <ArrowBackIosIcon htmlColor={colors.primary["1"]} />
              </IconButton>
              <span>
                <IconButton
                  disabled={
                    batteryInfo.manualStatus !== BatteryManualStatus.SUCCESS
                  }
                  onClick={() => setPanelState("setting")}
                >
                  <SettingsIcon
                    htmlColor={
                      // color === "dark"
                      //   ? batteryInfo.manualStatus !==
                      //     BatteryManualStatus.SUCCESS
                      //     ? LightColors.primary["2"]
                      //     : DarkColors.primary["1"]
                      //   : batteryInfo.manualStatus !==
                      //     BatteryManualStatus.SUCCESS
                      //   ? DarkColors.primary["2"]
                      //   : LightColors.primary["1"]
                      batteryInfo.manualStatus !== BatteryManualStatus.SUCCESS
                        ? colors.primary["2"]
                        : colors.primary["1"]
                    }
                  />
                </IconButton>
              </span>
            </div>

            <div className={classes.body}>
              <BatteryChargingStatus
                isCelcius={isCelcius}
                setIsCelcius={setIsCelcius}
                currentCam={currentCam}
              />
              <BatteryChargingHistory />
              <BatteryTempHistory
                isCelcius={isCelcius}
                setIsCelcius={setIsCelcius}
              />
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};
