import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Fonts,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import SimpleBarReact from "simplebar-react";
import _ from "lodash";

import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import { VOD } from "../../features/VOD/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";
import { THEME } from "../../features/Theme/slice";

export const FormControlLabel = withStyles((theme: Theme) => ({
  label: {
    ...Fonts.Default.Body,
    color: LightColors.primary["1"],
    marginLeft: 2,
    marginRight: 2,
  },
  root: {
    marginLeft: -6,
    marginRight: -6,
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}))(MuiFormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    // width: 343,
    border: "none",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      position: "absolute",
      right: 0,
      top: 0,
      margin: "112px 103px",
      width: 375,
      height: 716,
    },
  },
  modalTitle: {
    padding: "24px 27px 25px",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "36px 28px 36px 28px",
      minHeight: 22,
    },
  },
  modalContent: {
    // overflow: "hidden",
    padding: 0,
    border: "none",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  modalCloseIcon: {
    top: 23,
    right: 20,
  },
  divider: {
    height: 2,
    margin: "0 17px 0 11px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: "0 13px 0 11px",
    },
  },

  option: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 12px",
    marginBottom: 8,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  selectAllOption: {
    marginBottom: 16,
  },
  optionCheckedDiv: {
    backgroundColor: LightColors.primary["10"],
    borderRadius: 30,
  },
  icon: {
    fontSize: 19,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 1,
    },
  },

  checkboxRoot: {
    color: LightColors.primary["4"],
    padding: 0,
    marginRight: 2,
    "&:hover": {
      color: LightColors.primary["7"],
      backgroundColor: "transparent",
    },
  },
  checkboxChecked: {
    color: `${LightColors.primary["1"]} !important`,
    "&:hover": {
      color: `${LightColors.primary["7"]} !important`,
      backgroundColor: "transparent !important",
    },
  },

  scrollbarStyle: {
    height: "100%",
    flex: 1,
    padding: "0 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: "calc(100vh - 320px)",
      overflowX: "hidden",
    },
  },

  modalHeadingText: {
    fontSize: "16px !important",
    fontWeight: 500,
    marginLeft: 4,
    marginTop: 2,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginLeft: 0,
      paddingLeft: 12,
    },
  },
  headerBtn: {
    top: 36,
    right: 28,
  },
  btnContainer: {
    padding: "24px 15px 24px",
    justifyContent: "center",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8px 16px 24px",
    },
  },
  modalBtn: {
    minWidth: "167.5px",
    [theme.breakpoints.down(Webviewer.smallMobile)]: {
      minWidth: "50%",
    },
  },
  modalLeftBtn: {
    marginRight: 8,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.color === "dark" ? "#636367" : "#FFFFFF",
    borderColor: (props: any) => props.colors.primary["4"],
    "&:hover.Mui-disabled": {
      backgroundColor: (props: any) => props.color === "dark" ? "#636367" : "#FFFFFF",
    },
  },
}));

export interface SharedEventFilterModalProps {
  open: boolean;
  onClose: () => void;
  onFilterHashtagName: (tagName: string[]) => void;
  filterHashtag: string[];
}

export const SharedEventFilterModal = ({
  open,
  onClose,
  onFilterHashtagName,
  filterHashtag,
}: SharedEventFilterModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ color, colors });
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { hashtags } = useSelector((state: RootState) => state[VOD]);

  // 해시태그이름과 "None"(해시태그가 없는 경우 일단 None으로)이 있는 배열
  const hashtagsNameArr = useMemo(() => {
    return _.concat(
      _.map(hashtags, (tag) => tag.name),
      ["None"]
    );
  }, [hashtags]);

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    hashtagsNameArr
  );

  useEffect(() => {
    if (open) {
      setSelectedFilters(filterHashtag);
    }
  }, [filterHashtag, open]);

  const handleEventCheck = useCallback(
    (tagName: string[]) => (e: any, checked: boolean) => {
      if (checked) {
        setSelectedFilters(
          _.chain(selectedFilters).concat(tagName).uniq().value()
        );
      } else {
        setSelectedFilters(
          _.filter(selectedFilters, (tag) => !_.includes(tagName, tag))
        );
      }
    },
    [selectedFilters]
  );

  return (
    <Modal
      open={open}
      heading={t("Filter")}
      headingIcon={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      headerBtn={
        <div>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.checkboxRoot,
                }}
                checked={selectedFilters.length > 0}
                indeterminate={
                  selectedFilters.length > 0 &&
                  selectedFilters.length < hashtagsNameArr.length
                }
                color="primary"
                onChange={(_e, checked) => {
                  if (!checked) {
                    setSelectedFilters([]);
                  } else {
                    setSelectedFilters(hashtagsNameArr);
                  }
                }}
                disableRipple
              />
            }
            label=""
          />
        </div>
      }
      headerBtnClassName={classes.headerBtn}
      fullWidthBtn={true}
      LButtonClassName={clsx(classes.modalBtn, classes.modalLeftBtn)}
      LButtonDisabled={selectedFilters.length === hashtagsNameArr.length}
      RButtonClassName={classes.modalBtn}
      className={classes.modal}
      titleClassName={classes.modalTitle}
      contentClassName={classes.modalContent}
      closeStyle={classes.modalCloseIcon}
      headingTextClassName={classes.modalHeadingText}
      RButton={t("OK")}
      LButton={t("Clear")}
      onClickPositive={() => {
        onFilterHashtagName(selectedFilters);
      }}
      onClickNegative={() => {
        setSelectedFilters(hashtagsNameArr);
      }}
      actionClassName={classes.btnContainer}
      fullSize={mobile}
      content={
        <>
          <SimpleBarReact className={classes.scrollbarStyle}>
            <div className={clsx(classes.option)}>
              <Typography category="Default" variant={"Body"}>
                {t("No Hashtags")}
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    classes={{
                      root: classes.checkboxRoot,
                    }}
                    checked={_.includes(selectedFilters, "None")}
                    color="primary"
                    onChange={handleEventCheck(["None"])}
                    disableRipple
                  />
                }
                label=""
              />
            </div>
            {_.chain(hashtags)
              .sortBy((tag) => tag.order)
              .map((tag) => (
                <div key={tag.id} className={clsx(classes.option)}>
                  <Typography category="Default" variant={"Body"}>
                    {t(tag.name)}
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                        }}
                        checked={_.includes(selectedFilters, tag.name)}
                        color="primary"
                        onChange={handleEventCheck([tag.name])}
                        disableRipple
                      />
                    }
                    label=""
                  />
                </div>
              ))
              .value()}
          </SimpleBarReact>
        </>
      }
    />
  );
};
