import React, { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
  dayOffsetGridLinesPlugin,
  dayVerticalLinePlugin,
} from "../../utils/ChartPlugin";
import { getDayData } from "../../utils/BatteryHistoryChartData";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { THEME } from "../../features/Theme/slice";

const ChargingHistoryDayChart = () => {
  const { batteryDayHistory } = useSelector(
    (state: RootState) => state.battery
  );
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const [isLoading, setIsLoading] = React.useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (batteryDayHistory.items) setIsLoading(false);
  }, [batteryDayHistory]);

  const { dataset, labels, dateLabels } = useMemo(
    () =>
      getDayData(
        batteryDayHistory.items || [],
        (history) => history.batteryAverage
      ),
    [batteryDayHistory.items]
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress size={48} thickness={6} color="primary" />
      </div>
    );
  }

  const options = {
    maintainAspectRatio: false,
    animations: false,
    scales: {
      y: {
        beginAtZero: true, // Y축의 시작을 0부터
        position: "right", // Y축을 오른쪽에 표시
        max: 100,
        min: 0,
        grid: {
          drawBorder: false, // 축 경계에 그려지는 선 숨김
          color: "rgba(233, 233, 234, 0.4)",
        },
        ticks: {
          stepSize: 25, // Y축 눈금 간격을 25으로 설정
          callback: function (value: any) {
            // 표시할 눈금에 대한 포맷을 정의
            if (value === 25 || value === 75) return "";

            if (value === 50) return "  50%";

            if (value === 0) return "      0";
            return value + "%";
          },
        },
      },
      x: {
        position: "bottom",
        grid: {
          drawOnChartArea: false, // X축의 그리드 라인 그림
          drawBorder: false, // 축 경계에 그려지는 선 숨김
          display: false, // 이 축의 그리드 라인을 숨김
        },
        ticks: {
          maxTicksLimit: 24, // X축에 표시할 최대 눈금 수
          maxRotation: 0, // 레이블의 최대 기울기 각도
          minRotation: 0, // 레이블의 최소 기울기 각도
          color: colors.primary["0"],
          callback: function (value: any) {
            return labels[value].toString();
          },
        },
      },
      x2: {
        type: "category",
        position: "bottom", // 날짜 라벨이 표시될 위치
        labels: dateLabels, // 여기에 날짜 라벨을 설정
        grid: {
          drawOnChartArea: false, // 이 축의 그리드 라인을 차트 영역에 그리지 않음
          display: false, // 이 축의 그리드 라인을 숨김
          drawBorder: false, // 축 경계에 그려지는 선 숨김
        },
        ticks: {
          autoSkip: false,
          align: "start",
          color: colors.primary["1"],
          maxRotation: 0, // 레이블의 최대 기울기 각도
          minRotation: 0, // 레이블의 최소 기울기 각도
        },
      },
    },
    plugins: {
      legend: {
        display: false, // 범례 숨김
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        position: "top",
        align: "start",
        text: t("Battery Level"), // 차트 제목
        font: {
          size: 12,
          weight: "bold",
        },
        color: colors.primary["1"],
        padding: {
          bottom: 20,
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: t("Battery Level"),
        data: dataset,
        backgroundColor: "#6FDC8C",
        hoverBackgroundColor: "#6FDC8C",
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={options}
      plugins={[dayVerticalLinePlugin, dayOffsetGridLinesPlugin(colors)]}
      type="bar"
    />
  );
};

export default ChargingHistoryDayChart;
