import {
  ClickAwayListener,
  FormControlLabel,
  Grow,
  makeStyles,
  Paper,
  Popper,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Button,
  CameraInfoRev,
  CheckBox,
  DatePicker,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AutoSizer, Index, List, ListRowRenderer } from "react-virtualized";
//@ts-ignore
import PopperJs from "popper.js";

import {
  clearGPSDriveData,
  clearGPSTrackingData,
  GPS,
  IDriveInfo,
  loadGPSDriveData,
  setCameraInfo,
  setCandiDrives,
  setFirstFitBounds,
  setSelectedDates,
  setSelectedDrives,
  setShowedDates,
  setShowTracks,
  setPickerType,
  setUpdateMapBounds,
  setSelectedTrack,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import { renderListHelper } from "../../utils/List";
import { CameraListPopper } from "../CameraListPopper";
import { EmptyItems } from "../EmptyItems";

import SearchIcon from "@material-ui/icons/Search";
import { CAMERA } from "../../features/Camera/slice";
import { NoResults } from "../NoResults";

import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { LightColors, PSN650, Webviewer } from "@thingsw/pitta-modules";
import React from "react";
import { detect } from "detect-browser";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  panel: {
    transform: "translateX(100%)",
    transition: "transform 0.3s ease-in-out",
    position: "absolute",
    top: 48,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
  },
  panelOpen: {
    transform: "translateX(100%)",
    zIndex: 1000,
    // opacity: 1,
    // visibility: "visible",
    // animation: `$fadeIn 0.3s`,
    animation: `$transformAnimation 0.3s ease-in forwards`,
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  panelClose: {
    // transform: "translateX(0)",
    // zIndex: 0,
    // animation: `$delayedTransformAnimation 0.1s ease-out 0.3s forwards`,
  },
  "@keyframes transformAnimation": {
    to: {
      transform: "translateX(0)",
    },
  },
  "@keyframes delayedTransformAnimation": {
    to: {
      transform: "translateX(100%)",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      visibility: "hidden",
    },
    "1%": {
      opacity: 0,
      visibility: "visible",
    },
    "100%": {
      opacity: 1,
      visibility: "visible",
    },
  },
  listDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-320px)",
    },
  },
  dateDiv: {    
    backgroundColor: (props: any) => props.colors.primary["0"],
    padding: theme.spacing(3, 2, 2, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "& input": {
      color: (props: any) => props.colors.primary["1"],
    },
  },
  routeListDiv: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 69px - 85px)",
  },
  emptyDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  routeDateTextDiv: {
    padding: theme.spacing(0.75, 2),
  },
  routesListDiv: {
    height: "calc(100% - 54px)",
    width: "100%",
  },
  btnDiv: {
    display: "flex",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  modalContentWrap: {
    padding: theme.spacing(2.5, 3, 3.25),
    display: "flex",
    flexDirection: "column",
  },
  camModalTitle: {
    padding: "0px 44px 0 24px",
    display: "flex",
    alignItems: "center",
    minHeight: "59px",
  },
  searchInputDiv: {
    width: "-webkit-fill-available",
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(0, 0.75, 0, 1.875) }
      : { padding: theme.spacing(0, 1.875, 0, 0.75) }),
    display: "flex",
    alignItems: "center",
    height: 44,
    position: "fixed",
    top: 62,
  },
  camListtDiv: {
    marginTop: 65,
    overflowY: "auto",
    display: "flex",
    height: "calc(100% - 120px)",
    justifyContent: "center",
  },
  cameraListDiv: {
    width: "100%",
    maxHeight: "50vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  datePickerPaper: {
    borderRadius: 12,
  },
  datePickerDiv: {
    background: LightColors.primary["0"],
    /* Light mode/#D4D4D5 ‧ 5 */

    border: `1px solid ${LightColors.primary["5"]}`,
    /* Shadow 1 ‧ Modals, menus */

    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 12,
  },
  dateTitleApp: {
    padding: "0 12px",
    height: 48,
    gap: 12,
  },
}));

interface GPSTrackingDatePanelProps {
  mode?: "camera" | "fleet";
  app?: boolean;
  onClose?: () => void;
  open?: boolean;
}

export const GPSTrackingDatePanel = ({
  mode,
  app,
  onClose,
  open,
}: GPSTrackingDatePanelProps) => {  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const themeState = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles(themeState);
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const dateListRef = useRef<List>(null);
  const popperRef = useRef<PopperJs>(null);
  const cameraAnchorRef = useRef<HTMLDivElement>(null);
  const dateAnchorRef = useRef<HTMLDivElement>(null);

  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const {
    dates,
    driveList,
    camera,
    type,
    loading,
    showTracks,
    selectedDates,
    showedDates,
    candiDrives,
    pickerType,
  } = useSelector((state: RootState) => state[GPS]);

  const [dateText, setDateText] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [cameraName, setCameraName] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openRoutes, setOpenRoutes] = useState(false);
  const [datePickerType, setDatePickerType] = useState<"range" | "multi">(
    "range"
  );
  const [fromDate, setFromDate] = useState<moment.Moment>();
  const [toDate, setToDate] = useState<moment.Moment>();
  const [showedTab, setShowedTab] = useState<"range" | "multi">();

  useEffect(() => {
    if (open) {
      setOpenRoutes(false);
    }
  }, [open]);
  
  useEffect(() => {
    const orientationHandler = (e: any) => {
      setOpenDate(false);
      setOpenCamera(false);
    };
    if ("onorientationchange" in window) {
      window.addEventListener("orientationchange", orientationHandler, false);
    }
    if (!mobile) {
      window.addEventListener("resize", orientationHandler, false);
    }
    return () => {
      if ("onorientationchange" in window) {
        window.removeEventListener("orientationchange", orientationHandler);
      }
      if (!mobile) {
        window.removeEventListener("resize", orientationHandler);
      }
    };
  }, [mobile]);

  useEffect(() => {
    console.log("GPSTrackingScreen", "camera", camera);
    if (camera) {
      setCameraName(camera.dev_name);
      dispatch(loadGPSDriveData(camera.psn));
    } else {
      // matnis - 84, 카메라 선택하지 않았는데 캠이름 남아있는 이슈 수정 (Leehj)
      setCameraName("");
      dispatch(clearGPSDriveData());
    }
  }, [dispatch, camera]);

  const datePickerMarkup = useMemo(() => {
    // console.log(
    //   "GPSTrackingDatePanel",
    //   "showedDates",
    //   showedDates,
    //   "selectedDates",
    //   selectedDates,
    //   "showedTab",
    //   showedTab,
    //   "datePickerType",
    //   datePickerType,
    //   pickerType
    // );
    const browserName = detect()?.name;
    let initialDays: Date[] = [];
    if (showedTab === datePickerType) {
      initialDays = _.map(showedDates, (d) => d.toDate());
    } else if (datePickerType === pickerType) {
      initialDays = _.map(showedDates, (d) => d.toDate());
    }

    return (
      <DatePicker
        variant={datePickerType}
        enabledDays={dates}
        initialDays={initialDays}
        onChangeVariant={(variant) => {
          setDatePickerType(variant);
          setShowedTab(undefined);
          dispatch(setShowedDates(selectedDates));

          // console.log("GPSTrackingDatePanel", "selectedDates", selectedDates);
        }}
        total={31}
        t={t}
        loading={loading && type === loadGPSDriveData.type}
        mobile={mobile}
        ios={browserName === "ios-webview" || browserName === "ios"}
        onResetClick={() => {
          const dr = _.first(driveList);
          if (dr) {
            const sdate = moment(dr.sdate.format("YYYYMMDD"), "YYYYMMDD").set(
              "hour",
              12
            );
            const edate = moment(dr.edate.format("YYYYMMDD"), "YYYYMMDD").set(
              "hour",
              12
            );

            let currentDate = moment(sdate); // 시작 날짜로 초기화
            let dates: moment.Moment[] = [];
            while (currentDate.isSameOrBefore(edate)) {
              dates.push(moment(currentDate));
              currentDate.add(1, "day"); // 다음 날짜로 이동
            }

            // dispatch(setSelectedDates(dates));
            dispatch(setShowedDates(dates));
            // dispatch(setSelectedDrives([dr]));
          }
        }}
        onClickOK={(item) => {
          setShowedTab(datePickerType);
          if (camera) {
            if (item.range) {
              const { from, to } = item.range;
              if (from && to) {
                const sdate = moment(from).utc(false).startOf("d");
                const edate = moment(to).utc(false).endOf("d");

                // setDateText(
                //   `${sdate.format("MMM DD, YYYY")}-${edate.format(
                //     "MMM DD, YYYY"
                //   )}`
                // );
                // setFromDate(sdate);
                // setToDate(edate);
                // setSelectedDates([]);

                // setSelectedDrives([]);

                let currentDate = moment(sdate); // 시작 날짜로 초기화
                let dates: moment.Moment[] = [];
                while (currentDate.isSameOrBefore(edate)) {
                  dates.push(moment(currentDate));
                  currentDate.add(1, "day"); // 다음 날짜로 이동
                }

                if (
                  !_.every(
                    _.zip(dates, showedDates).map(([a, b]) => {
                      return !!a?.isSame(b, "day");
                    })
                  )
                ) {
                  dispatch(setCandiDrives([]));
                }

                // dispatch(setSelectedDates(dates));
                dispatch(setShowedDates(dates));
                // if (!app) {
                //   dispatch(setShowedDates(dates));
                // }
              }
            } else if (item.dates && item.dates.length > 0) {
              // const ddates = _.map(item.dates, (d) =>
              //   moment(d).utc(false).startOf("d")
              // );
              const ddates = _.sortBy(
                _.map(item.dates, (d) => moment(d).utc(false).startOf("d")),
                (s) => moment(s)
              );
              // const first = _.first(ddates);
              // const last = _.last(ddates);

              // setDateText(
              //   `${first?.format("MMM DD, YYYY")}-${last?.format(
              //     "MMM DD, YYYY"
              //   )}`
              // );
              if (!_.isEqual(ddates, showedDates)) {
                dispatch(setCandiDrives([]));
              }
              // dispatch(setSelectedDates(ddates));
              dispatch(setShowedDates(ddates));
              // if (!app) {
              //   dispatch(setShowedDates(ddates));
              // }
              // setFromDate(undefined);
              // setToDate(undefined);
            }
            setSelectedDrives([]);
            // dispatch(setCandiDrives([]));
          }
          setOpenDate(false);
          if (app) {
            setOpenRoutes(true);
          }
        }}
        darkMode={themeState?.color === "dark"}
      />
    );
  }, [
    app,
    camera,
    datePickerType,
    dates,
    dispatch,
    driveList,
    loading,
    mobile,
    pickerType,
    selectedDates,
    showedDates,
    showedTab,
    t,
    type,
    themeState,
  ]);

  useEffect(() => {
    if (showedDates.length > 0) {
      const first = _.first(showedDates);
      const last = _.last(showedDates);

      setDateText(
        `${first?.format("MMM DD, YYYY")}-${last?.format("MMM DD, YYYY")}`
      );
    } else {
      setDateText("");
    }
  }, [showedDates]);

  const list = useMemo(() => {
    // console.log(
    //   "GPSTrackingDatePanel",
    //   "fromDate",
    //   fromDate,
    //   "toDate",
    //   toDate,
    //   "showedDates",
    //   showedDates,
    //   "driveList",
    //   driveList
    // );
    if (fromDate && toDate) {
      return _.chain(driveList)
        .filter(
          (d) =>
            // d.sdate.isSameOrAfter(fromDate) && d.edate.isSameOrBefore(toDate)
            (d.sdate.isSameOrAfter(fromDate) &&
              d.sdate.isSameOrBefore(toDate)) ||
            (d.edate.isSameOrAfter(fromDate) && d.edate.isSameOrBefore(toDate))
        )
        .groupBy((d) => {
          return `${d.sdate.format("YYYYMMDD")}-${d.edate.format("YYYYMMDD")}`;
        })
        .map((value, key) => [key, ...value])
        .flattenDeep()
        .value();
    } else if (showedDates) {
      return _.chain(driveList)
        .filter((d) => {
          const filtered = _.filter(showedDates, (dd) => {
            const sdate = moment(dd).startOf("D");
            const edate = moment(dd).endOf("D");
            return (
              (d.sdate.isSameOrAfter(sdate) && d.sdate.isSameOrBefore(edate)) ||
              (d.edate.isSameOrAfter(sdate) && d.edate.isSameOrBefore(edate))
            );
          });
          // console.log("GPSTrackingDatePanel", "filtered", filtered);
          return filtered.length > 0;
        })
        .groupBy((d) => {
          return `${d.sdate.format("YYYYMMDD")}-${d.edate.format("YYYYMMDD")}`;
        })
        .map((value, key) => [key, ...value])
        .flattenDeep()
        .value();
    }
    return [];
  }, [driveList, fromDate, showedDates, toDate]);

  const filteredDriveList = useMemo(() => {
    return _.filter(list, (l) => typeof l !== "string") as IDriveInfo[];
  }, [list]);

  // useEffect(() => {
  //   console.log("GPSTrackingDatePanel", filteredDriveList, selectedDrives);
  //   dispatch(
  //     setCandiDrives(
  //       _.intersectionBy(filteredDriveList, selectedDrives, "drive_no")
  //     )
  //   );
  // }, [dispatch, filteredDriveList, selectedDrives]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      if (typeof list[indx] === "string") {
        //날짜 header
        const sdates = _.split(list[indx] as string, "-");
        const sdate = moment(sdates[0], "YYYYMMDD");
        const edate = moment(sdates[1], "YYYYMMDD");
        let dstring = "";
        if (sdate.isSame(edate)) {
          dstring = sdate.format("MMM DD, YYYY");
        } else {
          dstring = `${sdate.format("MMM DD, YYYY")} – ${edate.format(
            "MMM DD, YYYY"
          )}`;
        }
        return (
          <div
            key={`routes-list-date-${indx}`}
            style={props.style}
            className={classes.routeDateTextDiv}
            dir={theme.direction}
          >
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["2"]}
            >
              {dstring}
            </Typography>
          </div>
        );
      } else {
        // 실제 목록
        const item = list[indx] as IDriveInfo;

        // console.log(
        //   "GPSTrackingDatePanel",
        //   "candiDrives",
        //   candiDrives,
        //   "item",
        //   item
        // );
        return (
          <div
            key={`routes-list-drive-${item.drive_no}`}
            style={props.style}
            dir={theme.direction}
          >
            <FormControlLabel
              //  mantis - 9326, safari에서 두 줄로 나오는 이슈 수정위해 whiteSpace: "nowrap"추가(Leehj)
              style={{ margin: 0, padding: "3px 10px", whiteSpace: "nowrap" }}
              control={<CheckBox color="primary" />}
              // mantis - 13898, 체크 개수 오류 수정, include 에서 some 으로 변경(hongcs)
              checked={_.some(candiDrives, d => d.drive_no === item.drive_no)}
              onChange={(_e, checked) => {
                if (checked) {
                  dispatch(
                    setCandiDrives(
                      _.chain([...candiDrives, item])
                        .sortBy(["edate", "sdate"])
                        .reverse()
                        .value()
                    )
                  );
                } else {
                  dispatch(
                    setCandiDrives(
                      _.chain(candiDrives)
                        .filter((track) => track.drive_no !== item.drive_no)
                        .sortBy(["edate", "sdate"])
                        .reverse()
                        .value()
                    )
                  );
                }
              }}
              label={
                <Typography category="Default" variant="Body">
                  {`${item.sdate.format("HH:mm:ss")} – ${
                    item.present ? t("present") : item.edate.format("HH:mm:ss")
                  }`}
                </Typography>
              }
            />
          </div>
        );
      }
    },
    [list, classes.routeDateTextDiv, theme.direction, candiDrives, t, dispatch]
  );

  const rowHeight = useCallback(
    (indx: Index) => {
      return typeof list[indx.index] === "string" ? 33 : 36;
    },
    [list]
  );

  const renderList = useMemo(() => {
    return (
      <AutoSizer>
        {({ height, width }) =>
          renderListHelper(
            width,
            height,
            <List
              width={width}
              height={height}
              rowCount={list.length}
              rowRenderer={rowRenderer}
              rowHeight={rowHeight}
              overscanRowCount={10}
              ref={dateListRef}
              style={
                mobile
                  ? {}
                  : {
                      overflowY: "visible",
                      overflowX: "visible",
                    }
              }
            />,
            mobile,
            theme.direction,
            dateListRef
          )
        }
      </AutoSizer>
    );
  }, [list.length, mobile, rowHeight, rowRenderer, theme.direction]);

  const filteredCams = useMemo(
    () =>
      _.chain(cameraList?.deviceListInfo)
        .filter(
          (dev) =>
            dev.device.dev_name
              .toLowerCase()
              .indexOf(cameraName.toLowerCase()) > -1 ||
            dev.device.model.toLowerCase().indexOf(cameraName.toLowerCase()) >
              -1
        )
        .map((dev) => (
          <CameraInfoRev
            // mantis - 10517 ,클라우드 사용 불가한 카메라 ex)650s, login_date가 없는 경우 비활성화되도록 수정 (Leehj)
            disabled={
              _.includes(PSN650, dev.device.psn.substr(0, 4)) ||
              !dev.device.login_date
            }
            key={dev.device.psn}
            camera={dev.device}
            onClick={(cam) => {
              setOpenCamera(false);
              dispatch(setCameraInfo(cam));
              setToDate(undefined);
              setFromDate(undefined);
              setSelectedDates([]);
              dispatch(clearGPSDriveData());
            }}
            hover
            gpsMobile
          />
        ))
        .value(),
    [cameraList?.deviceListInfo, cameraName, dispatch]
  );

  const cameraListMarkup = useMemo(() => {
    return (
      <>
        {filteredCams.length > 0 ? (
          <div className={classes.cameraListDiv}>{filteredCams}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <NoResults />
          </div>
        )}
      </>
    );
  }, [classes.cameraListDiv, filteredCams]);

  const routeListMarkup = useMemo(() => {
    return (
      <>
        {mode === "fleet" && (
          <div className={classes.dateDiv}>
            <Input
              readOnly={mobile}
              variant="outlined"
              placeholder={t("Camera")}
              label={t("Camera")}
              rootRef={cameraAnchorRef}
              value={cameraName}
              onChange={(e) => {
                setDateText("");
                dispatch(clearGPSTrackingData());
                setCameraName(e.target.value);
              }}
              onClick={() => {
                setDateText("");
                dispatch(clearGPSTrackingData());
                setOpenCamera(true);
                setCameraName("");
                dispatch(setCameraInfo(undefined));
                setSelectedDates([]);
                setFromDate(undefined);
                setToDate(undefined);
              }}
            />
          </div>
        )}
        <div className={classes.dateDiv}>
          <Input
            inputmode="none"
            readOnly
            variant="outlined"
            placeholder={t("Dates")}
            label={t("Dates")}
            value={dateText}
            onClick={() => {
              if (app) {
                setOpenRoutes(false);
              } else {
                setOpenDate(true);
              }
            }}
            rootRef={dateAnchorRef}
          />
        </div>
        <div className={classes.routeListDiv}>
          {list.length === 0 && (
            <div className={classes.emptyDiv}>
              <EmptyItems variant="small" mode="routes" />
            </div>
          )}
          {list.length > 0 && (
            <>
              <div>
                <FormControlLabel
                  style={{ margin: 0, padding: "16px 10px 8px 10px" }}
                  control={
                    <CheckBox
                      indeterminate={
                        candiDrives.length > 0 &&
                        candiDrives.length !== filteredDriveList.length
                      }
                      checked={candiDrives.length === filteredDriveList.length}
                      onChange={(e, checked) => {
                        e.stopPropagation();
                        // e.preventDefault();
                        if (checked) {
                          dispatch(setCandiDrives(filteredDriveList));
                        } else {
                          dispatch(setCandiDrives([]));
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <Typography category="Default" variant="BodyBold">
                      {t("Routes")}
                    </Typography>
                  }
                />
              </div>
              <div className={classes.routesListDiv}>{renderList}</div>
            </>
          )}
        </div>
        <div className={classes.btnDiv}>
          <div>
            {candiDrives.length}/{filteredDriveList.length}
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={
              // _.isEqual(selectedDrives, candiDrives) ||
              candiDrives.length === 0
            }
            onClick={() => {
              if (!app) {
                dispatch(setShowTracks(true));
              }
              dispatch(setFirstFitBounds(true));

              dispatch(clearGPSTrackingData());
              dispatch(setSelectedTrack(undefined));
              dispatch(setSelectedDrives(candiDrives));

              // dispatch(setShowedDates(selectedDates));
              dispatch(setSelectedDates(showedDates));
              dispatch(setPickerType(datePickerType));
              // if (app) {
              //   setOpenRoutes(false);
              // }
              onClose?.();
              // setShowLoading(true);
              // setUpdateTrack(true);
              dispatch(setUpdateMapBounds(true));
            }}
          >
            {t("Show")}
          </Button>
        </div>
      </>
    );
  }, [
    app,
    cameraName,
    candiDrives,
    classes.btnDiv,
    classes.dateDiv,
    classes.emptyDiv,
    classes.routeListDiv,
    classes.routesListDiv,
    datePickerType,
    dateText,
    dispatch,
    filteredDriveList,
    list.length,
    mobile,
    mode,
    onClose,
    renderList,
    showedDates,
    t,
  ]);

  return (
    <div
      className={clsx(classes.listDiv, {
        [classes.listDivHide]: !app && showTracks,
      })}
    >
      {app && (
        <div
          style={{
            height: 48,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            {!openRoutes && (
              <IconButton
                onClick={() => {
                  // console.log("GPSTrackingDatePanel", selectedDates);
                  // dispatch(setShowedDates(selectedDrives));
                  dispatch(setShowedDates([...selectedDates]));
                  onClose?.();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            {openRoutes && (
              <IconButton
                onClick={() => {
                  setOpenRoutes(false);

                  // dispatch(setCandiDrives(selectedDrives));
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}

            <Typography category="Default" variant="BodyBold">
              {t(openRoutes ? "Routes" : "Dates")}
            </Typography>
          </div>
        </div>
      )}
      {app && (
        <div
          className={clsx(classes.panel, {
            [classes.panelOpen]: openRoutes,
            [classes.panelClose]: !openRoutes,
          })}
        >
          {routeListMarkup}
        </div>
      )}
      {app && (
        <div style={{ height: "calc(100% - 48px)" }}>{datePickerMarkup}</div>
      )}
      {!app && routeListMarkup}

      {!mobile && openCamera && (
        <CameraListPopper
          open={openCamera}
          anchorEl={cameraAnchorRef.current}
          onClickAway={() => setOpenCamera(false)}
          cameraName={cameraName}
          onClick={(cam) => {
            setOpenCamera(false);
            dispatch(setCameraInfo(cam));

            // setSelectedDrives([]);
            setToDate(undefined);
            setFromDate(undefined);
            dispatch(clearGPSDriveData());
          }}
        />
      )}

      {mobile && openCamera && (
        <Modal
          open={openCamera}
          fullSize
          noPadding
          mobile
          heading={t("Camera")}
          close
          noScroll
          contentClassName={classes.modalContentWrap}
          // mantis - 10517 , 모달 헤더 가운데 정렬 (Leehj)
          titleClassName={classes.camModalTitle}
          content={
            <>
              <div className={classes.searchInputDiv}>
                <Input
                  inputID="camerasSearch"
                  placeholder={t("Search cameras")}
                  startIcon={
                    <SearchIcon style={{ color: LightColors.primary["3"] }} />
                  }
                  value={cameraName}
                  onChange={(e) => {
                    setCameraName(e.target.value);
                    // setSelectedDrives([]);
                    // setToDate(undefined);
                    // setFromDate(undefined);
                  }}
                  onCloseSearch={() => {
                    setCameraName("");
                  }}
                  variant="standard"
                  search
                />
              </div>
              <div className={classes.camListtDiv}>{cameraListMarkup}</div>
            </>
          }
          onClose={() => setOpenCamera(false)}
        />
      )}

      {!mobile && openDate && (
        <Popper
          popperRef={popperRef}
          open={openDate}
          anchorEl={dateAnchorRef.current}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 2",
            },
            preventOverflow: {
              enabled: true,
              priority: ["right", "bottom", "top", "left"],
              boundariesElement: "viewport",
            },
            flip: {
              enabled: false,
            },
          }}
          placement="bottom-start"
          transition
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "center top",
              }}
            >
              <Paper className={classes.datePickerPaper}>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenDate(false);
                  }}
                >
                  <div className={classes.datePickerDiv}>
                    {datePickerMarkup}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {mobile && openDate && (
        <Modal
          open={openDate}
          fullSize
          noPadding
          mobile
          titleClassName={clsx({
            [classes.dateTitleApp]: app,
          })}
          headingIcon={
            <IconButton onClick={() => setOpenDate(false)}>
              <CloseIcon />
            </IconButton>
          }
          heading={t("Dates")}
          close
          noScroll
          content={datePickerMarkup}
          onClose={() => setOpenDate(false)}
        />
      )}
    </div>
  );
};
