import {
  Button,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import InfoIcon from "@material-ui/icons/Info";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Theme, makeStyles } from "@material-ui/core";
import BatteryStatusIcon from "./BatteryStatusIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 311,
    padding: "24px 16px",
  },
  modalButton: {
    marginTop: 16,
    height: 48,
  },
  batteryChargingInfo: {
    position: "absolute",
    width: 316,
    height: 316,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
  batteryTimeInfo: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoIcon: {
    color: (props: any) => props.colors.primary[2],
    "&:hover": {
      color: (props: any) => props.colors.primary[2],
    },
  },
}));

interface BatteryChargingInfoProps {
  isLoading: boolean;
  isLessThanOnePercent: boolean;
  isFullCharge: boolean;
  isCharging: boolean;
  remainingTime: string;
  isInputPowerCutOff: boolean;
  percent: number;
}

const BatteryChargingInfo = (props: BatteryChargingInfoProps) => {
  const {
    isLoading,
    isLessThanOnePercent,
    isCharging,
    isFullCharge,
    remainingTime,
    isInputPowerCutOff,
    percent,
  } = props;
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ ...props, colors });
  const { t } = useTranslation();

  const [isShowInfo, setIsShowInfo] = useState(false);

  const batteryStatus = () => {
    if (isLoading) {
      return (
        <>
          <div className={classes.batteryTimeInfo}>
            <Typography variant="Caption" htmlColor={colors.primary["1"]}>
              {t("Remaining time")}
            </Typography>
            <IconButton
              className={classes.infoIcon}
              onClick={() => setIsShowInfo(true)}
            >
              <InfoIcon />
            </IconButton>
          </div>
          <div>
            <Typography variant="BodyBold" htmlColor={colors.primary["1"]}>
              --h : --m
            </Typography>
          </div>
        </>
      );
    }

    if (isInputPowerCutOff) {
      return (
        <>
          <div className={classes.batteryTimeInfo}>
            <Typography variant="Caption" htmlColor={colors.primary["1"]}>
              {t("Remaining time")}
            </Typography>
            <IconButton
              className={classes.infoIcon}
              onClick={() => setIsShowInfo(true)}
            >
              <InfoIcon />
            </IconButton>
          </div>
          <div>
            <Typography variant="BodyBold" htmlColor={colors.primary["1"]}>
              {remainingTime}
            </Typography>
          </div>
        </>
      );
    }

    if (isCharging) {
      return (
        <>
          <div className={classes.batteryTimeInfo}>
            <Typography variant="Caption" htmlColor={colors.primary["1"]}>
              Now
            </Typography>
          </div>
          <div>
            <Typography variant="BodyBold" htmlColor={colors.primary["1"]}>
              Charging
            </Typography>
          </div>
        </>
      );
    }

    if (isLessThanOnePercent) {
      return (
        <Typography variant="H1" htmlColor={colors.secondary["11"]}>
          {percent}%
        </Typography>
      );
    }

    return (
      <>
        <div className={classes.batteryTimeInfo}>
          <Typography variant="Caption" htmlColor={colors.primary["1"]}>
            {t("Remaining time")}
          </Typography>
          <IconButton
            className={classes.infoIcon}
            onClick={() => setIsShowInfo(true)}
          >
            <InfoIcon />
          </IconButton>
        </div>
        <div>
          <Typography variant="BodyBold" htmlColor={colors.primary["1"]}>
            {remainingTime}
          </Typography>
        </div>
      </>
    );
  };

  const iconStatus = () => {
    if (isLoading) {
      return "loading";
    }

    if (isInputPowerCutOff) {
      return "nonCharging";
    }

    if (isLessThanOnePercent && !isCharging) {
      return "alert";
    }

    if (isCharging) {
      return "charging";
    }

    if (isFullCharge) {
      return "full";
    }

    return "link";
  };

  return (
    <div className={classes.batteryChargingInfo}>
      <div>
        <BatteryStatusIcon status={iconStatus()} />
      </div>
      {batteryStatus()}
      <Modal
        open={isShowInfo}
        onClose={() => setIsShowInfo(false)}
        className={classes.modal}
        noPadding
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <Typography variant="H6" htmlColor={colors.primary["1"]}>
              {t("About Remaining time")}
            </Typography>
            <Typography
              variant="Body"
              htmlColor={colors.primary["1"]}
              dangerouslySetInnerHTML={{
                __html: t("Remaining time estimate").replaceAll("\n", "<br/>"),
              }}
            />
            <Button
              className={classes.modalButton}
              color="primary"
              onClick={() => setIsShowInfo(false)}
            >
              OK
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default BatteryChargingInfo;
