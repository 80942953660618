import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LightColors, IVOD, IAccel, Webviewer } from "@thingsw/pitta-modules";
import ResizeObserver from "resize-observer-polyfill";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // border: `1px solid ${LightColors.primary["6"]}`,
    // padding: theme.spacing(2),
    overflow: "auto",
    paddingTop: "4px",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      margin: "0 16px",
    },
    position: "relative",
  },
  timeSelectorDiv: {
    width: "calc(100% - 80px)",
    height: "100%",
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: "78px",
  },
  sensorDiv: {
    height: "100%",
    minWidth: 539,
    position: "relative",
    // marginTop: theme.spacing(2),
  },
  carIcon: {
    position: "absolute",
    left: theme.spacing(1),
    marginBottom: 32,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  circularLoading: {
    color: LightColors.primary["0"],
  },
}));

interface GSensorPlayerProps {
  vod?: IVOD;
  accels: IAccel[];
  timestamp?: number;
  totalTimestamp?: number;
  loading?: boolean;
  onChangeTime: (timestamp: number) => void;
}

const defaultWidth = 539;

export const GSensorPlayer = ({
  vod,
  accels,
  timestamp,
  totalTimestamp,
  loading,
  onChangeTime,
}: GSensorPlayerProps) => {
  const classes = useStyles();
  const sensorDivRef = useRef<HTMLDivElement>(null);
  const selectDivRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [width, setWidth] = useState(defaultWidth);
  const totalT = Math.round(totalTimestamp ?? 1);
  const length = _.last(accels)?.timestamp ?? 1;
  const [time, setTime] = useState(vod?.time);

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  useEffect(() => {
    setTime(vod?.time);
  }, [vod]);

  useEffect(() => {
    if (sensorDivRef.current) {
      const element = sensorDivRef.current;
      //@ts-ignore
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          if (
            !mobile ||
            (mobile && entries[0].contentRect.width > defaultWidth)
          ) {
            setWidth(entries[0].contentRect.width);
          }
        }
      });
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    } else {
      setWidth(defaultWidth);
    }
  }, [mobile]);

  const handleTimeSelector = useCallback(
    (e) => {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left;
      if (selectDivRef.current) {
        const ratio = x / selectDivRef.current.clientWidth;
        onChangeTime(ratio * totalT);
      }
    },
    [onChangeTime, totalT]
  );

  return (
    <div className={classes.root}>
      <div className={classes.sensorDiv} ref={sensorDivRef}>
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 ${width} 232`}
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => console.log(e)}
        >
          <rect width={width} height="208" fill="#E9E9EA" fillOpacity="0.35" />
          <line opacity="0.15" y1="0.5" x2={width} y2="0.5" stroke="#68686E" />
          <line
            opacity="0.15"
            y1="26.375"
            x2={width}
            y2="26.375"
            stroke="#68686E"
          />
          <line
            opacity="0.15"
            y1="52.25"
            x2={width}
            y2="52.25"
            stroke="#68686E"
          />
          <line
            opacity="0.15"
            y1="78.125"
            x2={width}
            y2="78.125"
            stroke="#68686E"
          />
          <line y1="104" x2={width} y2="104" stroke="#68686E" />
          <line
            opacity="0.15"
            y1="129.875"
            x2={width}
            y2="129.875"
            stroke="#68686E"
          />
          <line
            opacity="0.15"
            y1="155.75"
            x2={width}
            y2="155.75"
            stroke="#68686E"
          />
          <line
            opacity="0.15"
            y1="181.625"
            x2={width}
            y2="181.625"
            stroke="#68686E"
          />
          <line
            opacity="0.15"
            y1="207.5"
            x2={width}
            y2="207.5"
            stroke="#68686E"
          />

          <polyline
            points={_.chain(accels)
              .filter((acc) => acc && Math.abs(acc.timestamp) <= 100)
              .map(
                (acc) =>
                  `${(acc.timestamp / length) * (width - 90) + 78},${
                    (144 * acc.x) / 500 + 144 / 2 + 30
                  }`
              )
              .join(" ")
              .value()}
            style={{ stroke: "#2EA8E5", strokeWidth: 2 }}
          />

          <polyline
            points={_.chain(accels)
              .filter((acc) => acc && acc.timestamp <= 100000)
              .map(
                (acc) =>
                  `${(acc.timestamp / length) * (width - 90) + 78},${
                    (144 * acc.y) / 500 + 144 / 2 + 30
                  }`
              )
              .join(" ")
              .value()}
            style={{ stroke: "#ED9600", strokeWidth: 2 }}
          />

          <polyline
            points={_.chain(accels)
              .filter((acc) => acc && acc.timestamp <= 100000)
              .map(
                (acc) =>
                  `${(acc.timestamp / length) * (width - 90) + 78},${
                    (144 * acc.z) / 500 + 144 / 2 + 30
                  }`
              )
              .join(" ")
              .value()}
            style={{ stroke: "#EA2583", strokeWidth: 2 }}
          />
          {accels.length > 0 && (
            <rect
              width={(width - 90) / totalT}
              height="206"
              x={Math.max(
                8,
                ((Math.round(timestamp ?? 0) - 1) / totalT) * (width - 90) + 88
              )}
              y="1"
              stroke="#D81A26"
              strokeWidth="2"
            />
          )}
          {time && totalT > 1 && (
            <>
              <text y="230" x={90} fill="#68686E" textAnchor="middle">
                {time.format("mm:ss")}
              </text>
              <text
                y="230"
                x={(1 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6), "s")
                  .format("mm:ss")}
              </text>
              <text
                y="230"
                x={(2 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6) * 2, "s")
                  .format("mm:ss")}
              </text>
              <text
                y="230"
                x={(3 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6) * 3, "s")
                  .format("mm:ss")}
              </text>
              <text
                y="230"
                x={(4 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6) * 4, "s")
                  .format("mm:ss")}
              </text>
              <text
                y="230"
                x={(5 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6) * 5, "s")
                  .format("mm:ss")}
              </text>
              <text
                y="230"
                x={(6 / 6) * (width - 90 - 24) + 88}
                fill="#68686E"
                textAnchor="middle"
              >
                {moment(time)
                  .add(_.round(totalT / 6) * 6, "s")
                  .format("mm:ss")}
              </text>
            </>
          )}
        </svg>
        <div className={classes.carIcon}>
          <svg
            width="62"
            height="47"
            viewBox="0 0 62 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M10.1897 3.18742L7.95312 3.18742L10.9353 0L13.9175 3.18742H11.6808V12.7896H13.9175L10.9353 15.9771L7.95312 12.7896H10.1897V3.18742Z"
                fill="#2EA8E5"
              />
              <path
                d="M54.3264 28.9336L52.9082 26.6668L57.8861 27.7404L56.69 32.7116L55.2718 30.4448L45.9722 36.3157L47.3904 38.5825L42.4126 37.5089L43.6087 32.5377L45.0268 34.8045L54.3264 28.9336Z"
                fill="#EA2583"
              />
              <path
                d="M2.98498 37.1974L1.9175 39.6505L7.80643e-05 34.9127L4.76412 33.1088L3.69664 35.5619L13.7607 39.9811L14.8282 37.528L16.7456 42.2658L11.9816 44.0697L13.049 41.6166L2.98498 37.1974Z"
                fill="#ED9600"
              />
              <path
                d="M1.80774 25.1302L1.76758 27.6345C1.76758 28.6681 2.41009 30.0196 3.37387 30.4172L21.0029 37.1749C21.7257 37.4929 22.5691 37.0159 23.2919 36.6582L59.7145 17.5377C60.5177 17.1004 60.9996 16.3054 60.9996 15.4308V13.0855C60.9996 12.2905 60.4374 11.6544 59.6744 11.4954L52.7272 10.1439L46.7036 6.76498C46.0611 6.40722 45.3784 6.16871 44.6556 6.00971C30.721 3.3861 22.9706 9.15008 22.9706 9.15008L17.0273 15.7488L2.93214 23.3016C2.24947 23.6594 1.80774 24.3749 1.80774 25.1302Z"
                fill="#7E7E83"
              />
              <path
                d="M32.8942 36.1769C34.7973 36.2157 36.8558 34.5437 37.3218 31.8218C37.7879 29.0611 36.5062 26.6114 34.5254 26.2614H33.1661C31.3018 26.2225 29.2045 27.8945 28.7384 30.6164C28.2724 33.3383 29.5541 35.8269 31.5348 36.1769H32.8942Z"
                fill="#68686E"
              />
              <path
                d="M53.8357 24.0569C55.675 24.0968 57.2281 22.6996 57.6368 20.4243C58.0046 18.1489 56.942 16.0731 55.2254 15.7937H53.9175C52.2826 15.7937 50.5252 17.1509 50.1165 19.4263C49.7077 21.7017 50.8113 23.7774 52.5279 24.0569H53.8357Z"
                fill="#68686E"
              />
              <path
                d="M4.23635 29.0164C4.99359 29.0164 5.60746 28.1531 5.60746 27.0882C5.60746 26.0232 4.99359 25.1599 4.23635 25.1599C3.4791 25.1599 2.86523 26.0232 2.86523 27.0882C2.86523 28.1531 3.4791 29.0164 4.23635 29.0164Z"
                fill="white"
              />
              <path
                d="M19.3192 34.525C20.2279 34.525 20.9645 33.4151 20.9645 32.0458C20.9645 30.6766 20.2279 29.5667 19.3192 29.5667C18.4105 29.5667 17.6738 30.6766 17.6738 32.0458C17.6738 33.4151 18.4105 34.525 19.3192 34.525Z"
                fill="white"
              />
              <path
                d="M24.3181 10.4727L20.0523 14.857C19.6867 15.2125 19.8898 15.805 20.3773 15.9235L33.2559 19.0834C33.6216 19.1624 33.9872 18.9254 34.0685 18.5699L35.206 12.8031C35.2873 12.4476 35.0435 12.0921 34.6779 12.0526L24.8869 10.3147C24.6838 10.2357 24.44 10.3147 24.3181 10.4727Z"
                fill="white"
              />
              <path
                d="M37.7938 12.5641L36.8895 17.489C36.7322 18.2277 37.5186 18.8023 38.1477 18.4329L49.5895 11.9074C50.1792 11.5791 50.1792 10.6762 49.5895 10.3478L46.1927 8.35577C45.9175 8.23264 45.6422 8.23264 45.367 8.35577L38.2263 11.9484C37.9904 12.0716 37.8331 12.3178 37.7938 12.5641Z"
                fill="white"
              />
              <path
                d="M15.2417 33.1521L6.59268 29.8199C6.26047 29.701 6.09436 29.3838 6.17741 29.0269L6.67573 27.2823C6.75878 26.9254 7.17404 26.7272 7.54778 26.8461L15.2417 29.5677C15.4908 29.647 15.6985 29.8453 15.6985 30.1228L16.1553 32.5177C16.2383 32.9539 15.74 33.3108 15.2417 33.1521Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="62" height="47" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {loading && (
          <div className={classes.loadingDiv}>
            <CircularProgress
              className={classes.circularLoading}
              size={48}
              thickness={5}
            />
          </div>
        )}
      </div>
      {!loading && accels.length > 0 && (
        <div
          ref={selectDivRef}
          className={classes.timeSelectorDiv}
          onClick={handleTimeSelector}
        />
      )}
    </div>
  );
};
