import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors, SemanticDarkColors } from "@thingsw/pitta-modules";
import clsx from "clsx";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSwipeable } from "react-swipeable";
import ResizeObserver from "resize-observer-polyfill";
import { RootState } from "../features/store";
import { THEME } from "../features/Theme/slice";
import { useSelector } from "react-redux";

const HANDLE_HEIGHT = 56;
const HANDLE_MARGIN_HEIGHT = 64;
export type DRAWER_MODE =
  | "open"
  | "close"
  | "half"
  | "camera"
  | "markup"
  | "eventmap";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    height: "calc(var(--vh, 1vh) * 100)",
    width: "100%",
    backgroundColor: "transparent",
    bottom: 0,
    zIndex: 98,
    transformOrigin: "bottom",
  },
  rootOpen: {
    backgroundColor: `${LightColors.primary["1"]}73`,
  },
  rootEventmap: {
    position: "absolute",
    height: (props: any) => `calc(100% - ${props.offset ?? 0}px)`,
    backgroundColor: "transparent",
  },
  rootTransition: {
    transition: theme.transitions.create(["transform", "height"]),
    userSelect: "none",
  },
  panel: {
    width: "100%",
    height: "100%",
    // backgroundColor: LightColors.primary["0"],
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1.5, 1.5, 0, 0),
    backgroundColor: (props: any) =>
      props.color === "dark" ? SemanticDarkColors.primary[1] : "#FFFFFF",
  },
  panelCamera: {
    height: 130,
  },
  panelHalf: {
    height: "calc(var(--vh, 1vh) * 30 + 64px)",
  },
  panelEventmap: {
    marginTop: 0,
    borderRadius: "16px 16px 0 0",
    height: "100%",
  },
  handleDiv: {
    position: "absolute",
    width: "60%",
    height: HANDLE_HEIGHT,
    display: "flex",
    padding: theme.spacing(0, 0, 0, 0),
    justifyContent: "center",
    zIndex: 99,
    margin: "0 20%",
  },
  handle: {
    marginTop: 8,
    width: theme.spacing(3),
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: LightColors.primary["5"],
  },
  handleEventmap: {
    width: 88,
    height: 6,
    borderRadius: 4,
    backgroundColor: (props: any) => props.colors.primary["6"],
  },

  eventmapHeight: {
    height: "auto",
    // height: "calc(var(--vh, 1vh) * 72)",
  },
}));

interface MobileDrawerProps {
  children: React.ReactElement;
  initMode?: DRAWER_MODE;
  mode: "camera" | "gps-tracking" | "geofence" | "eventmap";
  offset?: number;
  closeHeight?: number;
  disableHalf?: boolean;
  disableSwipe?: boolean;
  mobile?: boolean;
  onDrawerMode?: (mode: DRAWER_MODE) => void;
}

interface Props {
  onResize: (event: Event) => void;
}

//
// I used this as reference
// http://www.backalleycoder.com/2013/03/18/cross-browser-event-based-element-resize-detection/
//

const ElementResizeListener: React.FC<Props> = ({ onResize }) => {
  const rafRef = useRef(0);
  const objectRef: RefObject<HTMLObjectElement> = useRef(null);
  const onResizeRef = useRef(onResize);

  onResizeRef.current = onResize;

  const _onResize = useCallback((e: Event) => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(() => {
      onResizeRef.current(e);
    });
  }, []);

  const onLoad = useCallback(() => {
    const obj = objectRef.current;
    if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
      obj.contentDocument.defaultView.addEventListener("resize", _onResize);
    }
  }, [_onResize]);

  useEffect(() => {
    const obj = objectRef.current;
    return () => {
      if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
        obj.contentDocument.defaultView.removeEventListener(
          "resize",
          _onResize
        );
      }
    };
  }, [_onResize]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <object
      onLoad={onLoad}
      ref={objectRef}
      tabIndex={-1}
      type={"text/html"}
      data={"about:blank"}
      title={""}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        pointerEvents: "none",
        zIndex: -1,
        opacity: 0,
      }}
    />
  );
};

export default ElementResizeListener;

export const MobileDrawer = (props: MobileDrawerProps) => {
  const {
    mode,
    initMode,
    // offset,
    closeHeight,
    disableSwipe,
    // mobile,
    onDrawerMode,
  } = props;
  const { colors, color } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ ...props, colors, color });
  // const [open, setOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<DRAWER_MODE>("close");
  const handlerRef = useRef<HTMLDivElement>(null);
  const startPosYRef = useRef<number>(0);
  const rootRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const [curPosY, setCurPosY] = useState(0);
  const [onSwipe, setOnSwipe] = useState(false);
  const [onTransition, setOnTransition] = useState(false);
  const [onTap, setOnTap] = useState(false);
  const [rootHeight, setRootHeight] = useState(
    rootRef.current?.clientHeight ?? 100
  );
  const testRef = useRef(Math.random().toString());

  const handlers = useSwipeable({
    trackMouse: true,
    // trackTouch: true,
    onTap: (e) => {
      if (mode === "eventmap") return;
      const { event } = e;
      console.log("onTap", onTransition, e);
      if (onTransition) return;
      if (e.event.type !== "touchend") {
        event.stopPropagation();
        event.preventDefault();
      }

      setOnSwipe(false);
      setOnTransition(true);
      setOnTap(true);
      // if (drawerMode === "open") {
      //   if (mode === "eventmap") {
      //     setDrawerMode("eventmap");
      //   } else {
      //     setCurPosY(rootHeight - HANDLE_MARGIN_HEIGHT);
      //     setDrawerMode("close");
      //   }

      // } else if (drawerMode === "half") {

      // } else {
      //   setCurPosY(0);
      //   setDrawerMode("open");
      // }
      switch (drawerMode) {
        case "close":
          setCurPosY(0);
          setDrawerMode("open");
          break;
        case "open":
          if (mode === "gps-tracking") {
            setDrawerMode("close");
          } else {
            setDrawerMode("half");
          }
          break;
        case "half":
          setCurPosY(rootHeight - HANDLE_MARGIN_HEIGHT);
          setDrawerMode("close");
          break;

        default:
          setCurPosY(0);
          setDrawerMode("open");
          break;
      }
    },
    onSwipeStart: (e) => {
      startPosYRef.current = e.deltaY;
      // console.log("onSwipeStart", e);
    },
    onSwiped: (e) => {
      console.log("onSwiped");
      setOnSwipe(false);
    },
    onSwipedUp: (e) => {
      console.log("onSwipedUp");
      if (!onTap) {
        if (mode === "eventmap") {
          if (curPosY >= (rootHeight - (closeHeight ?? 0)) / 3) {
            setDrawerMode("half");
          } else {
            setDrawerMode("open");
          }
        } else {
          setDrawerMode("open");
        }

        setOnTransition(true);
      }
    },
    onSwipedDown: (e) => {
      console.log("onSwipedDown");
      if (!onTap) {
        // setOpen(false);
        if (mode === "gps-tracking") {
          setDrawerMode("close");
        } else if (mode === "eventmap") {
          if (curPosY <= (rootHeight - (closeHeight ?? 0)) / 3) {
            setDrawerMode("half");
          } else {
            setDrawerMode("close");
          }
        } else {
          if (curPosY >= rootHeight / 2) {
            setDrawerMode("close");
          } else {
            if (initMode === "markup") {
              setDrawerMode("markup");
            } else {
              setDrawerMode("half");
            }
          }
        }

        setOnTransition(true);
      }
    },
    onSwiping: (e) => {
      // console.log("onSwiping", onTap);
      if (!onTap) {
        setOnSwipe(true);
        const newPosY = e.initial[1] + e.deltaY;
        const diff =
          mode === "eventmap"
            ? e.deltaY - startPosYRef.current
            : Math.abs(curPosY - newPosY);
        startPosYRef.current = e.deltaY;
        // console.log("diff", e, diff);
        if (mode === "eventmap") {
          setCurPosY(
            //@ts-ignore
            Math.min(
              Math.max(curPosY + diff, 0),
              rootHeight - 140 - (closeHeight ?? 0)
            )
          );
        } else if (diff > 1) {
          setCurPosY(Math.min(newPosY, rootHeight - HANDLE_MARGIN_HEIGHT));
        }
      }
    },
  });

  useEffect(() => {
    if (initMode) {
      setDrawerMode(initMode);
    }
  }, [initMode]);

  useEffect(() => {
    const onResize = (ev: any) => {
      if (mode === "eventmap") {
        setCurPosY(rootHeight - 140 - (closeHeight ?? 0));
      } else {
        setCurPosY(rootHeight - HANDLE_MARGIN_HEIGHT);
      }
    };
    const onOpen = () => {
      setDrawerMode("open");
    };
    onResize({});
    window.addEventListener("resize", onResize);
    document.addEventListener("drawer:open", onOpen);
    return () => {
      window.removeEventListener("resize", onResize);
      document.removeEventListener("drawer:open", onOpen);
    };
  }, [closeHeight, mode, rootHeight]);

  useEffect(() => {
    if (handlerRef.current) {
      handlers.ref(handlerRef.current);
    }
  }, [handlers]);

  const updateCurPosY = useCallback(() => {
    if (drawerMode === "open") {
      if (mode === "eventmap") {
        setCurPosY(0);
      } else {
        setCurPosY(0);
      }
    } else if (drawerMode === "markup") {
      setCurPosY(childrenRef.current?.clientHeight ?? 0);
    } else if (drawerMode === "half") {
      if (mode === "eventmap") {
        setCurPosY((rootHeight - (closeHeight ?? 0)) / 3);
      } else {
        setCurPosY(rootHeight / 2);
      }
    } else {
      if (mode === "eventmap") {
        // console.log(
        //   "closeHeight",
        //   closeHeight,
        //   // curPosY,
        //   rootRef.current?.clientHeight,
        //   mode,
        //   rootHeight - 140 - (closeHeight ?? 0)
        // );
        setCurPosY(rootHeight - 140 - (closeHeight ?? 0));
      } else {
        setCurPosY(rootHeight - HANDLE_MARGIN_HEIGHT);
      }
    }
  }, [closeHeight, drawerMode, mode, rootHeight]);

  useEffect(() => {
    updateCurPosY();
  }, [updateCurPosY]);

  const transformStyle = useMemo(() => {
    if (onSwipe) {
      return {
        transform: `translateY(${curPosY}px)`,
        //@ts-ignore
        // transform: "translateY(calc(var(--vh, 1vh) * 100 - 64px))",
      };
    }
    if (mode === "eventmap") {
      return {
        transform: `translateY(${curPosY}px)`,
      };
    }
    switch (drawerMode) {
      case "markup":
        return {
          transform: `translateY(calc(var(--vh, 1vh) * 100 - ${
            childrenRef.current?.clientHeight ?? 0
          }px))`,
        };
      case "half":
        return {
          transform: "translateY(calc(var(--vh, 1vh) * 70 - 64px))",
        };
      case "camera":
        return {
          transform: "translateY(calc(var(--vh, 1vh) * 100 - 130px))",
        };
      case "close":
        return {
          transform: "translateY(calc(var(--vh, 1vh) * 100 - 64px))",
        };
      default:
        return {};
    }
  }, [curPosY, drawerMode, mode, onSwipe]);

  useEffect(() => {
    onDrawerMode?.(drawerMode);
  }, [drawerMode, onDrawerMode]);

  useEffect(() => {
    if (!rootRef.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      if (rootRef.current?.clientHeight) {
        console.log(
          "MobileDrawer",
          testRef.current,
          "resizeObserver",
          rootRef.current?.clientHeight
        );
        setRootHeight(rootRef.current.clientHeight);
      }
    });
    resizeObserver.observe(rootRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    if (!childrenRef.current) return; // wait for the elementRef to be available
    const childrenObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      if (childrenRef.current?.clientHeight) {
        console.log(
          "MobileDrawer",
          testRef.current,
          "childrenObserver",
          childrenRef.current?.clientHeight
        );
        setRootHeight(childrenRef.current.clientHeight);
      }
    });
    childrenObserver.observe(childrenRef.current);
    return () => childrenObserver.disconnect(); // clean up
  }, []);
  // rootRef.current?.addEventListener("resize", (e) => {
  //   console.log("rootRef resize", rootRef.current?.clientHeight);
  // });

  console.log(
    "MobileDrawer",
    testRef.current,
    "rootHeight",
    rootHeight,
    "childrenRef.current?.clientHeight",
    childrenRef.current?.clientHeight
  );

  return (
    <div
      className={clsx(classes.root, {
        // [classes.eventmapHeight]: drawerMode === "markup" && !onSwipe,
        [classes.rootOpen]: drawerMode === "open" && !onSwipe,
        [classes.rootEventmap]: mode === "eventmap",
        [classes.rootTransition]: !onSwipe,
      })}
      onTransitionEnd={() => {
        setOnTransition(false);
        setOnTap(false);
        console.log("onTransitionEnd");
      }}
      style={transformStyle}
      ref={rootRef}
    >
      <ElementResizeListener
        onResize={() => {
          updateCurPosY();
        }}
      />
      <div
        className={clsx(classes.panel, {
          [classes.panelHalf]:
            drawerMode === "half" && !onSwipe && !onTransition,
          [classes.panelCamera]:
            drawerMode === "camera" && !onSwipe && !onTransition,
          [classes.panelEventmap]:
            mode === "eventmap" && !onSwipe && !onTransition,
        })}
        onClickCapture={(e) => {
          // 모바일에서 drawer가 닫혀있는 경우, 하단을 클릭하면 자식 element에게 클릭 이벤트가 전달되는 이슈발생
          // swiping 중이거나 trasition중에는 클릭이벤트가 자식 element에게 전달되는 것을 방지
          // 6607
          if (onTransition || onSwipe) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <div className={classes.handleDiv} {...(disableSwipe ? {} : handlers)}>
          {!disableSwipe && (
            <div
              className={clsx(classes.handle, {
                [classes.handleEventmap]: mode === "eventmap",
              })}
            />
          )}
        </div>
        {/* <CameraListPanel open mobile {...props} /> */}
        {initMode === "markup" ? (
          <div ref={childrenRef}>{React.cloneElement(props.children)}</div>
        ) : (
          React.cloneElement(props.children)
        )}
      </div>
    </div>
  );
};
