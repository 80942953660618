import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "simplebar/src/simplebar.css";
import {
  BatteryChangeSettingResult,
  BatteryModel,
  ICameraInfo,
  Webviewer,
} from "@thingsw/pitta-modules";
import { IconButton, Switch, Typography } from "@thingsw/pitta-design-system";
import { CircularProgress, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { changeBatterySetting } from "../../features/Battery/slice";
import { PANEL_MODE } from "./BatteryStatusPanel";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: (props: any) => props.colors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      borderRadius: 12,
    },
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
  },
  rootApp: {
    boxShadow: "unset",
    borderRadius: 0,
  },
  rootOpen: {
    width: 375,
    height: "100%",
  },
  rootClose: {
    width: 0,
  },
  rootMobile: {
    width: "100%",
    height: "100vh",
  },
  header: {
    display: "flex",
    minHeight: 56,
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    justifyContent: "flex-start",
    backgroundColor: (props: any) => props.colors.primary["0"],
    padding: "0 12px",
    gap: 16,
  },
  listPane: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
    backgroundColor: (props: any) => props.colors.primary["6"],
  },
  rootPanel: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    transition: theme.transitions.create("transform"),
    position: "relative",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 375,
    },
  },
  body: {
    padding: "12px 16px",
  },
  beepInfoDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    borderRadius: 12,
    backgroundColor: (props: any) => props.colors.primary["0"],
    boxShadow:
      "0px 6px 40px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.06)",
    margin: "12px 0",
  },
  capacityInfoDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 12px",
    borderRadius: 12,
    backgroundColor: (props: any) => props.colors.primary["0"],
    boxShadow:
      "0px 6px 40px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.06)",
    margin: "12px 0",
  },
  nestedCapacityInfoDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 12px 20px 0",
  },
  nestedSpareBatteryInfoDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 0 6px 0",
  },
  infoDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 12px",
    borderRadius: 12,
    backgroundColor: (props: any) => props.colors.primary["0"],
    boxShadow:
      "0px 6px 40px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.06)",
    margin: "12px 0",
  },
  batteryNameDiv: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: "0 16px",
  },
  addButton: {
    width: 40,
    height: 36,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    border: (props: any) => `1px solid ${props.colors.primary["5"]} `,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
    cursor: "pointer",
    fontSize: 14,
    borderLeft: "none",
  },
  spareBatteryDisplay: {
    width: 60,
    height: 36,
    border: (props: any) => `1px solid ${props.colors.primary["5"]} `,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  removeButton: {
    width: 40,
    height: 36,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    border: (props: any) => `1px solid ${props.colors.primary["5"]} `,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
    cursor: "pointer",
    fontSize: 14,
    borderRight: "none",
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 60,
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },
  circularLoading: {
    color: (props: any) => props.colors.primary["8"],
  },
}));

interface BatterySettingPanelProps {
  open: boolean;
  mobile?: boolean;
  className?: string;
  setPanelState: (mode: PANEL_MODE) => void;
  currentCam?: ICameraInfo;
  app?: boolean;
}

const MAX_BATTERY_COUNT = 9;
const MIN_BATTERY_COUNT = 0;

export const BatterySettingPanel = (props: BatterySettingPanelProps) => {
  const { open, className, mobile, setPanelState, currentCam, app } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useSelector((root: RootState) => root[THEME]);
  const { batterySettings, batteryInfo, currentCam: loadedCam } = useSelector(
    (state: RootState) => state.battery
  );
  const [beep, setBeep] = React.useState<"on" | "off">(batterySettings.beep);
  const [spareBatteryCount, setSpareBatteryCount] = React.useState<number>(
    batterySettings.spareBatteryCount
  );

  const classes = useStyles({ ...props, colors });

  // 배터리 세팅 화면에서 새로고침 시 정보 저장
  useEffect(() => {
    if (window && batteryInfo) {
      window.localStorage.setItem(
        "pitta-webviewer:battery-setting",
        JSON.stringify(batterySettings)
      );
      window.localStorage.setItem(
        "pitta-webviewer:battery-status",
        JSON.stringify(batteryInfo)
      );
      window.localStorage.setItem(
        "pitta-webviewer:battery-currentCam",
        JSON.stringify(currentCam || loadedCam)
      );
    }

    return () => {
      window.localStorage.removeItem("pitta-webviewer:battery-setting");
      window.localStorage.removeItem("pitta-webviewer:battery-status");
      window.localStorage.removeItem("pitta-webviewer:battery-currentCam");
    };
  }, [batteryInfo, batterySettings, currentCam, loadedCam]);

  useEffect(() => {
    setBeep(batterySettings.beep);
    setSpareBatteryCount(batterySettings.spareBatteryCount);
  }, [batterySettings.beep, batterySettings.spareBatteryCount]);

  const handleChangeSetting = () => {
    if (
      (currentCam || loadedCam) &&
      batteryInfo.model === BatteryModel.B130X &&
      batterySettings.beep !== beep
    ) {
      dispatch(
        changeBatterySetting({
          camera: currentCam || loadedCam!,
          setting: {
            beep: beep,
          },
        })
      );
    } else if (
      (currentCam || loadedCam) &&
      batteryInfo.model === BatteryModel.B124X &&
      (batterySettings.beep !== beep ||
        batterySettings.spareBatteryCount !== spareBatteryCount)
    ) {
      dispatch(
        changeBatterySetting({
          camera: currentCam || loadedCam!,
          setting: {
            beep: beep,
            spareBatteryCount: spareBatteryCount,
            capacity: 6000 + 6600 * spareBatteryCount,
          },
        })
      );
    } else {
      setPanelState("battery");
    }
  };

  useEffect(() => {
    if (
      batterySettings.changeSettingResult !== BatteryChangeSettingResult.NONE &&
      batterySettings.loading === false
    ) {
      setPanelState("battery");
    }
  }, [
    batterySettings.changeSettingResult,
    batterySettings.loading,
    setPanelState,
  ]);

  return (
    <>
      <div
        className={clsx(
          classes.root,
          {
            [classes.rootOpen]: open,
            [classes.rootClose]: !open,
            [classes.rootMobile]: (mobile || app) && open,
            [classes.rootApp]: app,
          },
          className
        )}
      >
        <div className={classes.rootPanel}>
          <div className={classes.listPane}>
            <div className={classes.header}>
              <IconButton onClick={handleChangeSetting}>
                <ArrowBackIosIcon htmlColor={colors.primary["1"]} />
              </IconButton>

              <Typography variant="H6" htmlColor={colors.primary["1"]}>
                {t("Battery setting")}
              </Typography>
            </div>
            <div className={classes.body}>
              <div className={classes.beepInfoDiv}>
                <Typography variant="Body" htmlColor={colors.primary["1"]}>
                  {t("Beep")}
                </Typography>
                <Switch
                  checked={beep === "on" ? true : false}
                  onChange={(e) => setBeep(e.target.checked ? "on" : "off")}
                />
              </div>
              <div className={classes.divider}>
                <Divider />
              </div>

              {batteryInfo.model === BatteryModel.B124X && (
                <div>
                  <div className={classes.capacityInfoDiv}>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className={classes.nestedCapacityInfoDiv}>
                        <Typography
                          variant="Body"
                          htmlColor={colors.primary["1"]}
                        >
                          {t("Battery Capacity")}
                        </Typography>
                        <Typography
                          variant="Body"
                          htmlColor={colors.primary["1"]}
                        >
                          {6000 + 6600 * spareBatteryCount}
                          mA
                        </Typography>
                      </div>

                      <Divider />
                      <div className={classes.nestedSpareBatteryInfoDiv}>
                        <Typography
                          variant="Body"
                          htmlColor={colors.primary["1"]}
                        >
                          {t("Expansion Battery")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className={classes.removeButton}
                            disabled={spareBatteryCount <= MIN_BATTERY_COUNT}
                            onClick={() =>
                              setSpareBatteryCount((prev) => prev - 1)
                            }
                          >
                            <RemoveIcon
                              fontSize="inherit"
                              htmlColor={
                                spareBatteryCount <= MIN_BATTERY_COUNT
                                  ? colors.primary["5"]
                                  : colors.primary["1"]
                              }
                            />
                          </button>
                          <div className={classes.spareBatteryDisplay}>
                            {spareBatteryCount}
                          </div>
                          <button
                            className={classes.addButton}
                            disabled={spareBatteryCount >= MAX_BATTERY_COUNT}
                            onClick={() =>
                              setSpareBatteryCount((prev) => prev + 1)
                            }
                          >
                            <AddIcon
                              fontSize="inherit"
                              htmlColor={
                                spareBatteryCount >= MAX_BATTERY_COUNT
                                  ? colors.primary["5"]
                                  : colors.primary["1"]
                              }
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                </div>
              )}
              <div className={classes.infoDiv}>
                <div className={classes.batteryNameDiv}>
                  <Typography variant="Small" htmlColor={colors.primary["1"]}>
                    {t("Battery Name")}
                  </Typography>
                  <Typography variant="Caption" htmlColor={colors.primary["2"]}>
                    {currentCam?.battery.ssid || loadedCam?.battery.ssid}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {batterySettings.loading && (
            <div className={classes.loadingDiv}>
              <CircularProgress
                className={classes.circularLoading}
                size={48}
                thickness={6}
              />
              <Typography variant="Body" htmlColor={colors.primary["1"]}>
                {t("Saving")}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
