import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import {
  BlackvueLogoOutlined,
  IconButton,
  Menu,
  MobileMenu,
  PlayerNextFrame,
  PlayerPreviousFrame,
  Tooltip,
  Typography,
  WebMenuItem,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { detect } from "detect-browser";

import videojs from "video.js";

// Styles
import "video.js/dist/video-js.css";

import { RootState } from "../../features/store";
import { loadUsageInfo, USER } from "../../features/User/slice";
import { loadUrgentVODToken, VOD } from "../../features/VOD/slice";

import {
  exitFullscreen,
  isFullscreen,
  requestFullscreen,
} from "../../utils/GoogleMap";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FlipIcon from "@material-ui/icons/Flip";
import SettingsIcon from "@material-ui/icons/Settings";
import Crop54Icon from "@material-ui/icons/Crop54";
import Crop75Icon from "@material-ui/icons/Crop75";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PauseIcon from "@material-ui/icons/Pause";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";

import Blackvuelogo from "../../lottiefiles/Blackvuelogo.json";
import { ProgressSlider } from "../ProgressSlider";
import { VolumeSlider } from "./VolumeSlider";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { pushArray } from "../../utils/Accel";
import { setError } from "../../features/Error/slice";
import { getDirectionString } from "../../utils/VOD";
import { setFullscreen, THEME } from "../../features/Theme/slice";
import { parseNMEA } from "../../utils/VOD";
import { getFirmwareConfig } from "../../utils/Firmware";
import {
  API_GATEWAY_URI,
  changeLandscape,
  changePortrait,
  getLbURI,
  getS3URI,
  IAccel,
  ICameraInfo,
  IDrow,
  IGPSLocation,
  IVOD,
  IVODToken,
  jwtAxiosInst,
  LightColors,
  RESULT_CODE,
  useScreenOrientation,
  VideoQualityType,
  Webviewer,
} from "@thingsw/pitta-modules";
import Lottie from "lottie-react";
import { isBrowserCheck } from "../../utils/isBrowserCheck";
import ResizeObserver from "resize-observer-polyfill";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  disabled: {
    opacity: 0.25,
  },
  menuDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    padding: theme.spacing(1.5, 1),
    "& svg": {
      fill: LightColors.primary["0"],
    },
    borderBottom: `1px solid ${LightColors.primary["0"]}`,
    minHeight: 49,
  },

  mapboxMenuDiv: {
    minHeight: 29,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 49,
    },
  },

  dirDiv: {
    display: "flex",
    alignItems: "center",
    // width: 62,
    marginRight: 5.5,
    cursor: "pointer",
  },
  uncheckedMenu: {
    paddingLeft: 44,
  },
  videoDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  },
  videoWideDiv: {
    paddingTop: 181,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop:
        "min(max(350px, calc(100vh - 108px - 188px - 24px - 24px - 49px - 16px)), 480px)",
    },
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: LightColors.primary["1"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoScale: {
    transform: "scale(1.2,1)",
  },
  gpsTrackingVideoScale: {
    transform: "scale(1.23,1)",
  },
  videoScaleFull: {
    transform: "scale(1,0.833)",
  },
  modalFullScreen: {
    paddingTop: "min(55.25%, 506px)",
  },
  gpsTrackingModalFullScreen: {
    paddingTop: "min(57.25%, 506px)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: "min(55.25%, 506px)",
    },
  },
  videoFullScreen: {
    paddingTop: "calc((var(--vh, 1vh) * 100) - 49px)",

    // paddingTop: "100vh",
    backgroundColor: "black",
  },
  appVideoFullScreen: {
    paddingTop: "100vh",
    backgroundColor: "black",
  },
  // mantis - 7444, safari에서 비디오모달에서 모달 실행시 컨트롤바 잘리는 이슈 수정(Leehj)
  videoSafariFullScreen: {
    paddingTop: "calc((var(--vh, 1vh) * 100))",
  },

  videoHFlip: {
    "-webkit-transform": "scale(-1, 1)",
    transform: "scale(-1, 1)",
  },
  videoVFlip: {
    "-webkit-transform": "scale(1, -1)",
    transform: "scale(1, -1)",
  },
  videoFlip: {
    "-webkit-transform": "scale(-1, -1)",
    transform: "scale(-1, -1)",
  },
  ctrlContDiv: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    padding: theme.spacing(0, 1),
    zIndex: 1,
    // mantis - 11582, 하단 컨트롤러가 잘 보이지않아 background css 추가 (Leehj)
    background: "rgba(19, 19, 28, 0.25)",
  },
  ctrlDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  blackvueIcon: {
    width: 160,
    height: 29,
    fill: LightColors.primary["0"],
  },
  ctrlIcon: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    color: LightColors.primary["0"],
    fill: LightColors.primary["0"],
    "&>svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
    "&.Mui-disabled": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
    "&:hover,&:active,&:focus": {
      color: LightColors.primary["0"],
    },
  },
  gpsTackingCtrlIcon: {
    marginRight: "0px !important",
  },
  disabledCtrlIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    "&>svg": {
      color: `${LightColors.primary["0"]}40`,
      fill: `${LightColors.primary["0"]}40`,
    },
    display: "flex",
  },
  overlayDiv: {
    // mantis - 11582, 하단 워터마크색깔 위,아래 동일하게 하기 위해 background css 제거 (Leehj)
    // background:
    //   "linear-gradient(180deg, rgba(19, 19, 28, 0) 66.22%, rgba(19, 19, 28, 0.75) 95.5%)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  playerMenuDiv: {
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["0"],
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",

    "& svg": {
      color: LightColors.primary["0"],
      fill: LightColors.primary["0"],
    },
  },
  webMenuDiv: {
    "&:hover": {
      backgroundColor: LightColors.primary["2"],
    },
  },
  volumeCtrlDiv: {
    width: 52,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  circularLoading: {
    color: LightColors.primary["0"],
  },
  videoTitleDiv: {
    // maxWidth: 189,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
    color: (props: any) => props.colors.primary["1"],
  },
  mobilePlayCtrlDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${LightColors.primary["1"]}73`,
  },
  mobilePlayCtrl: {
    display: "flex",
    minWidth: 216,
    justifyContent: "space-between",
    alignItems: "center",
  },
  mapboxMobilePlayCtrl: {
    minWidth: "auto",
  },
  rootFullscreen: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1200,
    height: "calc(var(--vh, 1vh) * 100)",
  },
  webGpsVideoDirTextDiv: {
    display: "flex",
    alignItems: "center",
  },
  dirText: {
    fontSize: 11,
    letterSpacing: "-0.5px",
    cursor: "pointer",
    lineHeight: "0.75rem !important",
    padding: "4px 0",
  },
  gpsVideoDir: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 8,
    right: 8,
    backgroundColor: LightColors.primary["0"],
    padding: "0 16px",
    maxWidth: 173,
    borderRadius: 20,
    zIndex: 999,
  },
  webGpsVideoDir: {
    top: 16,
    right: 16,
  },
}));

interface VideoPlayerProps {
  camera?: ICameraInfo;
  vod?: IVOD;
  quality: VideoQualityType;
  mode: number; // 0: playback, 1: cloud, 2: live event upload
  fullHeight?: boolean;
  onResize?: (width: number, height: number) => void;
  disablePrev?: boolean;
  disableNext?: boolean;
  theater?: boolean;
  noTheater?: boolean;
  singleVideo?: boolean;
  screenMode?: "wide" | "default";
  requestPause?: any;
  showQuality?: boolean;
  disableQuality?: boolean;
  disableUndefined?: boolean;
  updatedTimestamp?: number;
  onPrev?: () => void;
  onNext?: () => void;
  onTheater?: () => void;
  onUpdateAccel?: (accels: IAccel[], totalTimestamp: number) => void;
  onUpdateGPS?: (locations: IGPSLocation[]) => void;
  onUpdateDrows?: (drowsData: IDrow[], gpsTime: string | undefined) => void;
  onUpdateTime?: (timestamp: number) => void;
  onRequestFront?: (fname: string) => void;
  onRequestRear?: (fname: string) => void;
  onRequestInterior?: (fname: string) => void;
  onRequestOption?: (fname: string) => void;
  onChangeQuality?: (qual: VideoQualityType) => void;
  onLoading?: (load: boolean) => void;
  onError?: () => void;
  onUpdateGPSRecordingInfo?: (on: boolean) => void;
  playback?: boolean;
  notification?: boolean;
  showDrows?: boolean;
  rearDisabled?: boolean;
  interiorDisabled?: boolean;
  optionDisabled?: boolean;
  mapbox?: boolean;
  gpsTracking?: boolean;
  app?: boolean;
  onFullscreen?: (fullscreen: boolean) => void;
}

export const VideoPlayer = ({
  mode,
  camera,
  vod,
  disablePrev,
  disableNext,
  theater,
  noTheater,
  singleVideo,
  quality,
  screenMode,
  fullHeight,
  requestPause,
  showQuality,
  disableQuality,
  disableUndefined,
  updatedTimestamp,
  onResize,
  onPrev,
  onNext,
  onTheater,
  onUpdateAccel,
  onUpdateGPS,
  onUpdateDrows,
  onUpdateTime,
  onRequestFront,
  onRequestRear,
  onRequestInterior,
  onRequestOption,
  onChangeQuality,
  onLoading,
  onError,
  onUpdateGPSRecordingInfo,
  playback,
  showDrows,
  rearDisabled,
  interiorDisabled,
  optionDisabled,
  mapbox,
  gpsTracking,
  app,
  onFullscreen,
}: VideoPlayerProps) => {
  const { t } = useTranslation();
  const themeState = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles(themeState);
  const theme = useTheme();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const changeRef = useRef<any>(null);

  const ref = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const qualityAnchorRef = useRef<HTMLButtonElement>(null);
  const flipAnchorRef = useRef<HTMLButtonElement>(null);
  const speedAnchorRef = useRef<HTMLButtonElement>(null);
  const volumeAnchorRef = useRef<HTMLButtonElement>(null);
  const metaAbortRef = useRef<AbortController>();
  const prevLink = useRef<string>();

  const prevFile = useRef<string>();
  const axiosCancelTokenRef = useRef<CancelTokenSource>();

  const email = useSelector((state: RootState) => state[USER].email);
  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);
  const tokenType = useSelector((state: RootState) => state[USER].tokenType);
  const low_vod_token = useSelector((state: RootState) => {
    if (vod && vod.lowFilename !== undefined) {
      return state[VOD].tokens[vod.lowFilename];
    }
  });
  const vod_token = useSelector((state: RootState) => {
    if (vod && vod.filename !== undefined) {
      return state[VOD].tokens[vod.filename];
    }
  });

  const [openMenu, setOpenMenu] = useState(false);
  const [openQualityMenu, setOpenQualityMenu] = useState(false);
  const [openFlipMenu, setOpenFlipMenu] = useState(false);
  const [openVolumeMenu, setOpenVolumeMenu] = useState(false);
  const [openPlaySpeedMenu, setOpenPlaySpeedMenu] = useState(false);
  const [hFlip, setHFlip] = useState(false);
  const [vFlip, setVFlip] = useState(false);
  const [paused, setPaused] = useState(true);
  const [rootFullscreen, setRootFullscreen] = useState(false);
  const [fullscreen, setfullscreen] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentPlayTime, setCurrentPlayTime] = useState(0);
  const [player, setPlayer] = useState<videojs.Player>();
  const [videoRatio /*, setVideoRatio*/] = useState<string>("56.25%");
  const [playSpeed, setPlaySpeed] = useState(1);
  const [duration, setDuration] = useState<moment.Duration>(
    moment.duration(0, "s")
  );
  const [current, setCurrent] = useState<moment.Duration>(
    moment.duration(0, "s")
  );
  const [loading, setLoading] = useState(false);
  const [hideCtrl, setHideCtrl] = useState(false);
  const [forceViewCtrl, setForceViewCtrl] = useState(false);
  const [preVolume, setPreVolume] = useState(volume);
  const [iosBrowser, setIosBrowser] = useState(false);
  const [fps /*, setFps*/] = useState(10);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [srcUrl, setSrcUrl] = useState<string>();
  const [playError, setPlayError] = useState(false);
  const [mediaError, setMediaError] = useState(false);
  const [tryToPlay, setTryToPlay] = useState(false);

  const orientation = useScreenOrientation("VideoPlayer");

  const prevModeRef = useRef<object>();

  const CLOUD_NATIVE3 = useMemo(() => {
    console.log("CloudNative3", "firmwareConfig-1", camera);
    if (!camera) return undefined;
    console.log("CloudNative3", "firmwareConfig-2", getFirmwareConfig(camera));
    return !!getFirmwareConfig(camera).CloudNative3;
  }, [camera]);

  // const CLOUD_NATIVE3 = useMemo(() => {
  //   if (firmwareConfig === undefined) {
  //     return undefined;
  //   }
  //   return !!firmwareConfig?.CloudNative3;
  // }, [firmwareConfig]);

  // useEffect(() => {
  //   if (tryToPlay && mode !== prevModeRef.current) {
  //     setLoading(false);
  //   }
  //   prevModeRef.current = mode;
  // }, [loading, mode, player, tryToPlay]);

  useEffect(() => {
    if (vod_token === undefined && camera && vod) {
      dispatch(
        loadUrgentVODToken({
          psn: camera.psn,
          filename: CLOUD_NATIVE3 ? vod.filename : vod.lowFilename,
          cloudNative: CLOUD_NATIVE3,
        })
      );
    }
  }, [CLOUD_NATIVE3, camera, dispatch, vod, vod_token]);

  useEffect(() => {
    const browser = detect();
    setIosBrowser(
      browser?.name === "ios" ||
        browser?.name === "crios" ||
        browser?.name === "ios-webview"
    );
  }, []);

  useEffect(() => {
    // console.log(
    //   "CloudNative3",
    //   "requestPause",
    //   requestPause,
    //   "tryToPlay",
    //   tryToPlay,
    //   "prevModeRef.current",
    //   prevModeRef.current
    // );
    if (requestPause) {
      prevModeRef.current = requestPause;
    }

    if (prevModeRef.current && player && !tryToPlay) {
      setTimeout(() => {
        player.pause();
      }, 200);

      prevModeRef.current = undefined;
      // axiosCancelTokenRef.current?.cancel();
      // axiosCancelTokenRef.current = undefined;
    }
  }, [requestPause, player, tryToPlay]);

  useEffect(() => {
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        //@ts-ignore
        !document.webkitIsFullScreen &&
        //@ts-ignore
        !document.mozFullScreen &&
        //@ts-ignore
        !document.msFullscreenElement
      ) {
        ///fire your event
        setfullscreen(false);
        setOpenTooltip(false);
      }
    }
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  useEffect(() => {
    if (player && updatedTimestamp) {
      player.currentTime(updatedTimestamp);
    }
  }, [updatedTimestamp, player]);

  useEffect(() => {
    if (!vod) {
      setDuration(moment.duration(0, "s"));
      setCurrent(moment.duration(0, "s"));
      setCurrentPlayTime(0);
    }

    metaAbortRef.current?.abort();
    metaAbortRef.current = undefined;
    setPlaySpeed(1);
  }, [vod]);

  useEffect(() => {
    if (paused) {
      setForceViewCtrl(true);
    } else {
      if (!openPlaySpeedMenu && !openFlipMenu && !openQualityMenu) {
        setForceViewCtrl(false);
      }
    }
  }, [openFlipMenu, openPlaySpeedMenu, openQualityMenu, paused]);

  useEffect(() => {
    if (!loading) {
      if (!paused && !hideCtrl && !forceViewCtrl) {
        const timerId = setTimeout(() => setHideCtrl(true), 2000);
        return () => {
          clearTimeout(timerId);
        };
      }
    } else {
      setHideCtrl(false);
    }
  }, [loading, paused, hideCtrl, forceViewCtrl]);

  const getLink = useCallback(
    async (camera: ICameraInfo, vod: IVOD) => {
      console.log("getLink", changeRef.current === vod);
      changeRef.current = vod;
      if (camera && vod && email && loginInfo) {
        const { user_token } = loginInfo;
        const { lb_server_name, lb_http_port, psn } = camera;
        const { filename, rid, lowFilename } = vod;
        console.log("getLink", filename, lowFilename, low_vod_token);
        let fname = filename;
        if (quality === "low" && lowFilename) {
          fname = lowFilename;
        }
        if (mode === 0 && fname) {
          if (quality === "low" && low_vod_token) {
            return [
              fname,
              `${getLbURI(
                lb_server_name,
                lb_http_port
              )}/proc/vod_file?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&vod_token=${
                low_vod_token.vod_token
              }&tokenType=web`,
            ];
          } else {
            dispatch(
              loadUrgentVODToken({
                psn,
                filename: fname,
              })
            );
          }
        } else if (mode === 1) {
          metaAbortRef.current = new AbortController();
          const resp = await fetch(
            `${API_GATEWAY_URI}/BCS/userS3PresignedUrl.php?email=${email}&user_token=${user_token}&psn=${psn}&filename=${fname}&tokenType=web`,
            {
              headers: {
                Authorization: `Bearer ${loginInfo?.jsonwebtoken}`,
              },
              signal: metaAbortRef.current?.signal,
            }
          );

          const data = await resp.json();
          const { resultcode, response } = data as {
            resultcode: RESULT_CODE;
            response: {
              presignedURL: string;
            };
          };
          if (resultcode === "BC_ERR_OK") {
            return [fname, getS3URI(response.presignedURL)];
          }
        } else if (mode === 2 && rid) {
          try {
            metaAbortRef.current = new AbortController();
            const resp = await fetch(
              `${API_GATEWAY_URI}/BCS/evtLrGetFileUrl.php?email=${email}&user_token=${user_token}&psn=${psn}&rid=${rid}&token_type=web`,
              {
                headers: {
                  Authorization: `Bearer ${loginInfo?.jsonwebtoken}`,
                },
                signal: metaAbortRef.current?.signal,
              }
            );

            const data = await resp.json();
            const { fileInfo } = data as {
              fileInfo: { fileName: string; rid: string; url: string };
            };
            return [fileInfo.fileName, getS3URI(fileInfo.url)];
          } catch (e) {
            setLoading(false);
            onError?.();
          }
        }
      }
      return [];
    },
    [low_vod_token, email, loginInfo, quality, mode, dispatch, onError]
  );

  // useEffect(() => {
  //   onLoading?.(loading);
  // }, [onLoading, loading]);

  useEffect(() => {
    if (ref.current) {
      videojs(ref.current, {
        bigPlayButton: false,
        controls: false,
        // fluid: true,
        autoplay: true,
        //@ts-ignore
        errorDisplay: false,
      }).ready(function () {
        const self = this;
        setPlayer(this);
        this.on("error", () => {
          const error = self.error();
          console.log("this", "error", self.error());
          if (error) {
            if (error.code === 4) {
              setPlayError(true);
              setLoading(false);
            }
            if (error.code === 3) {
              setMediaError(true);
              setLoading(false);
            }
          }
        });
      });
    }
  }, [dispatch, ref]);

  useEffect(() => {
    player?.playbackRate(playSpeed);
  }, [player, playSpeed]);

  useEffect(() => {
    player?.volume(volume / 100);
  }, [player, volume]);

  useEffect(() => {
    player?.currentTime(currentPlayTime);
  }, [player, currentPlayTime]);

  useEffect(() => {
    if (player) {
      player.on("waiting", () => {
        console.log("CloudNative3", "mode", "waiting");
      });
      player.on("play", (e) => {
        setPaused(false);
      });
      player.on("pause", (e) => {
        setPaused(true);
      });
      player.on("durationchange", (e) => {
        console.log("durationchange", player.duration());
        let dur = moment.duration(_.floor(player.duration(), 0), "s");
        setDuration(dur);
      });
      player.on("timeupdate", (e) => {
        if (vod) {
          const time = player.currentTime();
          onUpdateTime?.(time);
          // console.log("timeupdate", time, vod);
          setCurrent(moment.duration(_.floor(time, 0), "s"));
        }
      });
      player.on("loadeddata", (e) => {
        player.play();
        setLoading(false);
        dispatch(loadUsageInfo());
      });
      return () => {
        player.off([
          "waiting",
          "play",
          "pause",
          "durationchange",
          "timeupdate",
          "loadeddata",
        ]);
      };
    }
  }, [dispatch, onUpdateTime, player, quality, vod]);

  useEffect(() => {
    if (CLOUD_NATIVE3 && mode === 0) {
      const getVODLink = async (
        camera: ICameraInfo,
        vod: IVOD,
        email: string,
        token: string,
        vodToken: IVODToken
      ) => {
        try {
          setTryToPlay(true);
          setPlayError(false);
          setMediaError(false);
          setLoading(true);
          onLoading?.(true);
          setPaused(true);
          // mantis - 8826, filename이 바뀌면 flip설정 초기화 적용(Leehj,mckim)
          setVFlip(false);
          setHFlip(false);
          player?.pause();

          console.log("CloudNative3", "VOD", "vodToken", vodToken);
          console.log("CloudNative3", "send", "/IoT/devicecommand", vod, {
            command: "PlaySDVideo",
            email,
            user_token: token,
            tokenType: tokenType,
            psn: camera.psn,
            param1: vod.filename,
            param2: vodToken.vod_token,
            param3: "s",
            filesize: vod.subsize,
          });

          const CancelToken = axios.CancelToken;

          const source = CancelToken.source();
          axiosCancelTokenRef.current = source;
          const resp3 = await jwtAxiosInst.post(
            `/IoT/devicecommand`,
            {
              command: "PlaySDVideo",
              email,
              user_token: token,
              tokenType: "web",
              psn: camera.psn,
              param1: vod.filename,
              param2: vodToken.vod_token,
              param3: "s",
              filesize: vod.subsize,
            },
            { cancelToken: source.token }
          );
          const { data } = resp3;
          axiosCancelTokenRef.current = undefined;
          // 10156 : 파일 없는 경우 대시캠에서 busy리턴 이때 예외처리
          // mantis - 11692, 파일이 없어서 resultcode === "BC_ERR_INVALID_PARAMETER"인 경우도 에러메세지 출력 (Leehj)
          if (
            data.status === "busy" ||
            data.resultcode === "BC_ERR_INVALID_PARAMETER"
          ) {
            setPlayError(true);
            setLoading(false);
            return;
          }
          console.log(
            "CloudNative3",
            "VOD",
            "PlaySDVideo",
            data,
            `${data.streamURL}?filesize=${data.filesize}`
          );

          axiosCancelTokenRef.current = source;
          const resp4 = await jwtAxiosInst.post(
            `/IoT/devicecommand`,
            {
              command: "Metadata",
              email,
              user_token: token,
              tokenType: "web",
              psn: camera.psn,
              param1: vod.filename,
              param2: ["g", "s"],
            },
            { cancelToken: source.token }
          );
          axiosCancelTokenRef.current = undefined;

          console.log("CloudNative3", "VOD", "Metadata", resp4.data);

          if (player && data?.streamURL && data?.filesize) {
            const link = `${data.streamURL}?filesize=${data.filesize}`;

            setSrcUrl(link);
            player.src({
              src: link,
              type: "video/mp4",
            });
            player.load();
            prevFile.current = vod.filename;

            const { gps, sensor, gpsoption } = resp4.data;
            onUpdateGPSRecordingInfo?.(gpsoption !== "off");
            if (gps) {
              const nmea = atob(gps);
              const locations = parseNMEA(nmea, playback, camera);
              console.log("CloudNative3", "GPS", "locations", locations);
              onUpdateGPS?.(locations);
            }
            if (sensor) {
              const maxGSensorCount = 60;
              const accels: IAccel[] = [];
              let accels2: IAccel[] = [];
              const prevX: number[] = [];
              const prevY: number[] = [];
              const prevZ: number[] = [];
              const data = Buffer.from(atob(sensor), "ascii");
              for (let i = 0; i < data.length; ) {
                const timestamp = Math.floor(data.readUInt32BE(i) / 100) / 10;

                // console.log("CloudNative", "timestamp", timestamp);

                if (timestamp > maxGSensorCount) {
                  break;
                }
                i += 4;
                const x = Math.max(Math.min(data.readInt16BE(i), 250), -250);
                i += 2;
                const y = Math.max(Math.min(data.readInt16BE(i), 250), -250);
                i += 2;
                const z = Math.max(Math.min(data.readInt16BE(i), 250), -250);
                i += 2;

                const doc = { timestamp, x: x, y: y, z: z };
                accels2.push(doc);
              }
              const avgX = _.sumBy(accels2, (acc) => acc.x) / accels2.length;
              const avgY = _.sumBy(accels2, (acc) => acc.y) / accels2.length;
              const avgZ = _.sumBy(accels2, (acc) => acc.z) / accels2.length;

              accels2 = _.map(accels2, (acc) => ({
                timestamp: acc.timestamp,
                x: acc.x - avgX,
                y: acc.y - avgY,
                z: acc.z - avgZ,
              }));

              for (let acc of accels2) {
                const indx = Math.floor(acc.timestamp);
                const { x, y, z } = acc;
                if (accels[indx]) {
                  accels[indx] = {
                    timestamp: indx,
                    x:
                      Math.abs(accels[indx].x) < Math.abs(x)
                        ? x
                        : accels[indx].x,
                    y:
                      Math.abs(accels[indx].y) < Math.abs(y)
                        ? y
                        : accels[indx].y,
                    z:
                      Math.abs(accels[indx].z) < Math.abs(z)
                        ? z
                        : accels[indx].z,
                  };
                } else {
                  accels[indx] = {
                    timestamp: indx,
                    x,
                    y,
                    z,
                  };
                }
              }

              for (let acc of accels2) {
                const indx = Math.floor(acc.timestamp);
                const { x, y, z } = acc;
                const newX = x - _.sum(prevX) / prevX.length;
                const newY = y - _.sum(prevY) / prevY.length;
                const newZ = z - _.sum(prevZ) / prevZ.length;

                if (accels[indx]) {
                  accels[indx] = {
                    timestamp: indx,
                    x:
                      Math.abs(accels[indx].x) < Math.abs(newX)
                        ? newX
                        : accels[indx].x,
                    y:
                      Math.abs(accels[indx].y) < Math.abs(newY)
                        ? newY
                        : accels[indx].y,
                    z:
                      Math.abs(accels[indx].z) < Math.abs(newZ)
                        ? newZ
                        : accels[indx].z,
                  };
                } else {
                  accels[indx] = {
                    timestamp: indx,
                    x,
                    y,
                    z,
                  };
                }

                pushArray(prevX, x);
                pushArray(prevY, y);
                pushArray(prevZ, z);
              }

              if (
                accels.length > 0 &&
                _.filter(accels2, (acc) => acc.timestamp === 0).length <= 1
              ) {
                onUpdateAccel?.(accels, _.last(accels2)?.timestamp ?? 0);
              }

              console.log("CloudNative3", "GSensor", "data", data);
              console.log(
                "CloudNative3",
                "GSensor",
                "accels",
                accels,
                accels2,
                _.filter(accels2, (acc) => acc.timestamp === 0).length
              );
            }
          }
          // setLoading(false);
        } catch {
          setLoading(false);
        } finally {
          onLoading?.(false);

          setTryToPlay(false);
          // setLoading(false);
        }
      };
      // console.log(
      //   "aaaaa",
      //   "getVODLink",
      //   "camera",
      //   camera,
      //   "vod",
      //   vod,
      //   "loginInfo",
      //   loginInfo,
      //   "email",
      //   email,
      //   "vod_token",
      //   vod_token,
      //   "player",
      //   player
      // );
      if (
        camera &&
        vod &&
        loginInfo &&
        email &&
        vod_token &&
        player &&
        vod.filename !== prevFile.current
      ) {
        getVODLink(camera, vod, email, loginInfo.user_token, vod_token);
        prevFile.current = vod.filename;
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    email,
    loginInfo,
    mode,
    onLoading,
    onUpdateAccel,
    onUpdateGPS,
    onUpdateGPSRecordingInfo,
    playback,
    player,
    requestPause,
    tokenType,
    vod,
    vod_token,
  ]);

  useEffect(() => {
    if (!CLOUD_NATIVE3 || mode !== 0) {
      const playVOD = async () => {
        // console.log("getLink", "playVOD", vod?.filename, prevFile.current);
        if (camera && vod && vod.filename !== prevFile.current) {
          setPlayError(false);
          setMediaError(false);
          setLoading(true);
          onLoading?.(true);
          setPaused(true);

          // mantis - 8826, filename이 바뀌면 flip설정 초기화 적용(Leehj,mckim)
          setVFlip(false);
          setHFlip(false);
          player?.pause();
          const [filename, link] = await getLink(camera, vod);
          if (player && link) {
            setSrcUrl(link);
            player.src({
              src: link,
              type: "video/mp4",
            });
            player.load();
            prevFile.current = filename;
          }
          // Lottie 사라지는 문제로 주석처리
          //  else {
          //   console.log(
          //     "VideoPlayer",
          //     "getLink",
          //     "setLoading(false)",
          //     player,
          //     link
          //   );
          //   setLoading(false);
          // }
        }
      };
      playVOD();
    }
  }, [CLOUD_NATIVE3, camera, getLink, mode, onLoading, player, vod]);

  useEffect(() => {
    if (videoRef.current) {
      const element = videoRef.current;
      //@ts-ignore
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          onResize?.(
            entries[0].contentRect.width,
            entries[0].contentRect.height
          );
        }
      });
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
        // element.removeEventListener("resize", handler);
      };
    }
  }, [onResize, videoRef]);

  useEffect(() => {
    if (!CLOUD_NATIVE3 || mode !== 0) {
      const cancel = new AbortController();

      let link = srcUrl;
      if (process.env.NODE_ENV === "development") {
        link = link?.replace(
          "https://s3-ap-southeast-1.amazonaws.com",
          "/s3-prod"
        );
        link = link?.replace(
          "https://s3-us-west-1.amazonaws.com",
          "/s3-prod-us-west"
        );
      }

      const getVOD = async () => {
        if (!vod) return;
        if (link && prevLink.current !== link) {
          console.log("getVOD", link);
          prevLink.current = link;
          try {
            const maxGSensorCount = 60;
            const decoder = new TextDecoder();
            const resp = await fetch(link, {
              signal: cancel.signal,
            });
            if (resp.status === 404) {
              setLoading(false);
              setPlayError(true);
              return;
            }
            const reader = resp.body?.getReader();
            // console.log("reader", reader);
            if (reader) {
              let freeProcessed = false;
              let drowProcessed = false;
              let accProcessed = false;
              let gpsProcessed = false;
              let stsdProcessed = false;
              let stream: ReadableStreamReadResult<Uint8Array>;
              // let fileStart = 0;
              let locations: IGPSLocation[] = [];
              const accels: IAccel[] = [];
              let accels2: IAccel[] = [];
              let drowsData: IDrow[] = [];
              let gpsTime: string | undefined;
              const prevX: number[] = [];
              const prevY: number[] = [];
              const prevZ: number[] = [];
              const gpsBuffer: Buffer[] = [];
              const freeBuffer: Buffer[] = [];
              let needToAppend = false;
              let chunk: Buffer[] = [];
              const stsdBuffer: Buffer[] = [];
              let drowChunk: Buffer[] = [];
              do {
                stream = await reader.read();
                if (stream.value?.buffer) {
                  const buffer = Buffer.from(
                    stream.value.buffer as ArrayBuffer
                  );

                  if (!freeProcessed) {
                    const freeStartIdx = decoder.decode(buffer).indexOf("free");
                    if (freeStartIdx > 0) {
                      freeProcessed = true;
                      freeBuffer.push(buffer);
                    }
                  }

                  if (!gpsProcessed) {
                    const gpsStartIdx = decoder.decode(buffer).indexOf("gps ");
                    if (gpsStartIdx > 0) {
                      gpsProcessed = true;
                      gpsBuffer.push(buffer);
                    }
                  } else if (!accProcessed) {
                    gpsBuffer.push(buffer);
                  }

                  // 코덱정보가 저장된 stsd박스 찾아서 버퍼에 저장 (8357)
                  // if (!stsdProcessed) {
                  //   const stsdStartIndx = decoder
                  //     .decode(buffer)
                  //     .indexOf("stsd");
                  //   if (stsdStartIndx >= 0) {
                  //     stsdBuffer.push(buffer);
                  //     stsdProcessed = true;
                  //   }
                  // }

                  if (!accProcessed) {
                    if (needToAppend) {
                      chunk.push(Buffer.from(buffer));
                    } else {
                      chunk = [Buffer.from(buffer)];
                    }
                    const data = Buffer.concat(chunk);

                    let startIdx = decoder.decode(data).indexOf("3gf ");

                    if (startIdx > 0) {
                      accProcessed = true;
                      let slicedData = data.slice(startIdx);
                      // QA 9081, 9112, 9113, 9114: startIdx 오류 수정
                      if (slicedData[0] !== 51 && slicedData[1] !== 103) {
                        const reStartIdx = slicedData.indexOf(51);
                        startIdx = startIdx + reStartIdx;
                      }

                      for (let i = startIdx + 4; i < startIdx + 5800; ) {
                        if (data.length <= i + 4 + 2 + 2 + 2) {
                          needToAppend = true;
                          accProcessed = false;
                          accels2 = [];
                          break;
                        }
                        const timestamp =
                          Math.floor(data.readUInt32BE(i) / 100) / 10;

                        if (timestamp > maxGSensorCount) {
                          break;
                        }
                        i += 4;

                        const x = Math.max(
                          Math.min(data.readInt16BE(i), 250),
                          -250
                        );
                        i += 2;
                        const y = Math.max(
                          Math.min(data.readInt16BE(i), 250),
                          -250
                        );
                        i += 2;
                        const z = Math.max(
                          Math.min(data.readInt16BE(i), 250),
                          -250
                        );
                        i += 2;

                        const doc = { timestamp, x: x, y: y, z: z };
                        accels2.push(doc);
                      }

                      const avgX =
                        _.sumBy(accels2, (acc) => acc.x) / accels2.length;
                      const avgY =
                        _.sumBy(accels2, (acc) => acc.y) / accels2.length;
                      const avgZ =
                        _.sumBy(accels2, (acc) => acc.z) / accels2.length;

                      accels2 = _.map(accels2, (acc) => ({
                        timestamp: acc.timestamp,
                        x: acc.x - avgX,
                        y: acc.y - avgY,
                        z: acc.z - avgZ,
                      }));

                      for (let acc of accels2) {
                        const indx = Math.floor(acc.timestamp);
                        const { x, y, z } = acc;
                        if (accels[indx]) {
                          accels[indx] = {
                            timestamp: indx,
                            x:
                              Math.abs(accels[indx].x) < Math.abs(x)
                                ? x
                                : accels[indx].x,
                            y:
                              Math.abs(accels[indx].y) < Math.abs(y)
                                ? y
                                : accels[indx].y,
                            z:
                              Math.abs(accels[indx].z) < Math.abs(z)
                                ? z
                                : accels[indx].z,
                          };
                        } else {
                          accels[indx] = {
                            timestamp: indx,
                            x,
                            y,
                            z,
                          };
                        }
                      }

                      for (let acc of accels2) {
                        const indx = Math.floor(acc.timestamp);
                        const { x, y, z } = acc;
                        const newX = x - _.sum(prevX) / prevX.length;
                        const newY = y - _.sum(prevY) / prevY.length;
                        const newZ = z - _.sum(prevZ) / prevZ.length;

                        if (accels[indx]) {
                          accels[indx] = {
                            timestamp: indx,
                            x:
                              Math.abs(accels[indx].x) < Math.abs(newX)
                                ? newX
                                : accels[indx].x,
                            y:
                              Math.abs(accels[indx].y) < Math.abs(newY)
                                ? newY
                                : accels[indx].y,
                            z:
                              Math.abs(accels[indx].z) < Math.abs(newZ)
                                ? newZ
                                : accels[indx].z,
                          };
                        } else {
                          accels[indx] = {
                            timestamp: indx,
                            x,
                            y,
                            z,
                          };
                        }

                        pushArray(prevX, x);
                        pushArray(prevY, y);
                        pushArray(prevZ, z);
                      }
                    }
                  }

                  if (showDrows && !drowProcessed) {
                    if (needToAppend) {
                      drowChunk.push(Buffer.from(buffer));
                    } else {
                      drowChunk = [Buffer.from(buffer)];
                    }
                    const data = Buffer.concat(drowChunk);
                    let drowStartIdx = decoder.decode(data).indexOf("Drow");
                    let drowEndIdx = decoder.decode(data).indexOf("}]}");

                    if (drowStartIdx > 0) {
                      if (drowEndIdx < 0) {
                        drowEndIdx = decoder.decode(data).indexOf("]}");
                        if (drowEndIdx > 0) {
                          drowProcessed = true;
                        } else {
                          needToAppend = true;
                        }
                      } else {
                        const sliceD = decoder
                          .decode(data)
                          .slice(drowStartIdx + 4, drowEndIdx + 3);
                        const drowJason = JSON.parse(sliceD);
                        if (drowJason.data.length > 0) {
                          drowsData = drowJason.data;
                          gpsTime = drowJason.gpsTime;
                          drowProcessed = true;
                          // console.log("getDrows Success, Data Count:", drowJason.data.length);
                        } else {
                          // console.log("getDrows fail: founded data start but no data");
                        }
                      }
                    } else {
                      console.log(
                        "getDrows fail: not founded data start from VOD header."
                      );
                    }
                    onUpdateDrows?.(drowsData, gpsTime);
                  }
                }

                if (
                  gpsProcessed &&
                  accProcessed &&
                  // stsdProcessed &&
                  (!showDrows || (showDrows && drowProcessed)) &&
                  freeProcessed
                ) {
                  cancel.abort();
                  break;
                }

                // if (buffer) {
                //   //@ts-ignore
                //   buffer.fileStart = fileStart;
                //   mp4boxfile.appendBuffer(buffer);
                //   fileStart += buffer.byteLength;
                // }
              } while (!stream.done);

              // h265코덱이면 play정지 후, 에러 출력 (8305)
              if (stsdProcessed) {
                const isH265 =
                  decoder.decode(Buffer.concat(stsdBuffer)).indexOf("hvc1") > 0;
                if (isH265 && player) {
                  player.reset();
                  dispatch(setError("Unsupported video codec_"));
                  setLoading(false);
                  return;
                }
              }

              const data = Buffer.concat(gpsBuffer);
              const gpsStartIdx = decoder.decode(data).indexOf("gps ");
              const gpsData = data.slice(gpsStartIdx + 4);

              const nmea = new TextDecoder().decode(gpsData);

              const filename = vod.filename.split("_");
              // console.log(
              //   "locations",
              //   vod,
              //   moment(
              //     `${filename[0]}_${filename[1]}Z`,
              //     "YYYYMMDD_HHmmssZ"
              //   ).toISOString()
              // );

              locations = parseNMEA(
                nmea,
                playback,
                camera,
                moment(`${filename[0]}_${filename[1]}Z`, "YYYYMMDD_HHmmssZ")
              );

              // console.log("locations", locations);

              const freeBuf = Buffer.concat(freeBuffer);
              const videoInfoStartIndx = decoder.decode(freeBuf).indexOf("{");
              const videoInfoEndIndx = decoder.decode(freeBuf).indexOf("}") + 1;
              const freeData = freeBuf.slice(
                videoInfoStartIndx,
                videoInfoEndIndx
              );

              const videoInfoText = new TextDecoder().decode(freeData);
              try {
                const videoInfo = JSON.parse(videoInfoText);
                onUpdateGPSRecordingInfo?.(videoInfo.GPS === 1);
              } catch (err) {
                onUpdateGPSRecordingInfo?.(true);
              }

              console.log(
                "free",
                new TextDecoder().decode(freeData),
                JSON.parse(new TextDecoder().decode(freeData))
              );
              // console.log(
              //   "accels",
              //   accels,
              //   accels2,
              //   _.filter(accels2, (acc) => acc.timestamp === 0).length
              // );

              if (
                accels.length > 0 &&
                _.filter(accels2, (acc) => acc.timestamp === 0).length <= 1
              ) {
                onUpdateAccel?.(accels, _.last(accels2)?.timestamp ?? 0);
              }

              // console.log("locations", locations);
              onUpdateGPS?.(locations);
            }
          } catch (err) {
            console.log("fetch error", err.message);
          }
          onLoading?.(false);
        }
      };

      getVOD();
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    camera?.dev_name,
    dispatch,
    mode,
    onUpdateAccel,
    onUpdateGPS,
    onUpdateGPSRecordingInfo,
    playback,
    player,
    srcUrl,
    onUpdateDrows,
    vod,
    onLoading,
    showDrows,
  ]);

  const getTime = useCallback(() => {
    return `${current
      .minutes()
      .toString()
      .padStart(2, "0")}:${current
      .seconds()
      .toString()
      .padStart(2, "0")} / ${duration
      .minutes()
      .toString()
      .padStart(2, "0")}:${duration.seconds().toString().padStart(2, "0")}`;
  }, [current, duration]);

  const handleFullscreen = useCallback(
    (e?: { stopPropagation: () => void }) => {
      console.log("handleFullscreen", rootFullscreen, iosBrowser);
      e?.stopPropagation();
      if (iosBrowser) {
        if (rootFullscreen) {
          setRootFullscreen(false);
          setOpenTooltip(false);
          setfullscreen(false);
          onFullscreen?.(false);
          changePortrait();
        } else {
          setRootFullscreen(true);
          setOpenTooltip(false);
          setfullscreen(true);
          onFullscreen?.(true);
          changeLandscape();
        }
      } else {
        if (videoRef.current) {
          if (isFullscreen(videoRef.current)) {
            exitFullscreen(videoRef.current);
            dispatch(setFullscreen(false));
            setfullscreen(false);
            setOpenTooltip(false);
            changePortrait();
          } else {
            requestFullscreen(videoRef.current);
            dispatch(setFullscreen(true));
            setfullscreen(true);
            setOpenTooltip(true);
            changeLandscape();
          }
        }
      }
    },
    [dispatch, iosBrowser, onFullscreen, rootFullscreen]
  );

  useEffect(() => {
    if (app) {
      console.log("orientation", orientation, iosBrowser);
      const needToFullscreen = orientation === "landscape";
      if (iosBrowser) {
        if (needToFullscreen) {
          onFullscreen?.(true);
          setRootFullscreen(true);
          setOpenTooltip(false);
        } else {
          onFullscreen?.(false);
          setRootFullscreen(false);
          setOpenTooltip(false);
        }
      } else {
        if (videoRef.current) {
          if (!needToFullscreen) {
            exitFullscreen(videoRef.current);
            dispatch(setFullscreen(false));
            setfullscreen(false);
            setOpenTooltip(false);
          } else {
            requestFullscreen(videoRef.current);
            dispatch(setFullscreen(true));
            setfullscreen(true);
            setOpenTooltip(true);
          }
        }
      }
    }
  }, [app, dispatch, iosBrowser, onFullscreen, orientation]);

  useEffect(() => {
    setfullscreen(rootFullscreen);
  }, [rootFullscreen]);
  const renderCtrlIcon = useCallback(
    (icon: React.ReactNode, onClick?: () => void, disabled?: boolean) => {
      return disabled ? (
        <span className={classes.disabledCtrlIcon}>{icon}</span>
      ) : (
        <IconButton
          className={classes.ctrlIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
        >
          {icon}
        </IconButton>
      );
    },
    [classes.ctrlIcon, classes.disabledCtrlIcon]
  );

  const handlePrevFrame = useCallback(() => {
    player?.pause();
    const curr = player?.currentTime();
    if (curr) {
      player?.currentTime(curr - 1 / fps);
    }
  }, [fps, player]);

  const handlePlayPause = useCallback(() => {
    if (player?.paused()) {
      setHideCtrl(true);
      player?.play();
    } else {
      player?.pause();
    }
  }, [player]);

  const handleNextFrame = useCallback(() => {
    player?.pause();
    const curr = player?.currentTime();
    if (curr) {
      player?.currentTime(curr + 1 / fps);
    }
  }, [fps, player]);

  const handleScreenPlay = useCallback(() => {
    // 13115 이슈 때문에 주석처리
    // handlePlayPause();
    if (mobile && !paused && !forceViewCtrl) {
      setHideCtrl(false);
    }
  }, [forceViewCtrl, mobile, paused]);

  const videoPlayerMarkup = useMemo(() => {
    return (
      <div
        className={clsx(classes.videoDiv, {
          [classes.videoWideDiv]: screenMode === "wide",
          [classes.modalFullScreen]: fullHeight,
          // [classes.gpsTrackingModalFullScreen]: gpsTracking,
          [classes.videoFullScreen]: fullscreen,
          [classes.appVideoFullScreen]: fullscreen && app,

          // mantis - 7444, safari에서 비디오모달에서 모달 실행시 컨트롤바 잘리는 이슈 수정(Leehj)
          [classes.videoSafariFullScreen]:
            fullHeight && fullscreen && isBrowserCheck() === "Safari",
        })}
        style={
          screenMode !== "wide" && !fullscreen && !fullHeight
            ? { paddingTop: `min(480px, ${videoRatio})` }
            : undefined
        }
        onClick={handleScreenPlay}
        onMouseEnter={() => !paused && setHideCtrl(false)}
        onMouseMove={() => !paused && setHideCtrl(false)}
        onMouseLeave={() => !paused && !forceViewCtrl && setHideCtrl(true)}
      >
        <div
          className={clsx(classes.video, classes.videoScale, {
            [classes.gpsTrackingVideoScale]: !mobile && gpsTracking,
            [classes.videoScaleFull]: !app && fullscreen,
            [classes.videoHFlip]: !vFlip && hFlip,
            [classes.videoVFlip]: vFlip && !hFlip,
            [classes.videoFlip]: vFlip && hFlip,
          })}
        >
          <video
            style={{
              width: "100%",
              height: "100%",
            }}
            ref={ref}
            className="video-js"
            playsInline
            webkit-playsinline
          />
        </div>
        {(!camera || !vod) && (
          <div className={classes.video}>
            <BlackvueLogoOutlined className={classes.blackvueIcon} />
          </div>
        )}
        <div className={classes.overlayDiv} />
        {playError && (
          <div className={classes.video}>
            <ErrorOutlineIcon
              htmlColor={LightColors.primary["0"]}
              style={{ fontSize: 54, margin: "0 32px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                category="Default"
                variant="H2"
                htmlColor={LightColors.primary["0"]}
              >
                {t("Playback Failed")}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["0"]}
              >
                {t("The file has been overwritten or deleted")}
              </Typography>
            </div>
          </div>
        )}
        {mediaError && (
          <div className={classes.video}>
            <ErrorOutlineIcon
              htmlColor={LightColors.primary["0"]}
              style={{ fontSize: 54, margin: "0 32px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                category="Default"
                variant="H2"
                htmlColor={LightColors.primary["0"]}
              >
                {t("Playback Failed")}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["0"]}
              >
                {t("Unsupported video codec_")}
              </Typography>
            </div>
          </div>
        )}
        {loading && (
          <div className={classes.video}>
            <Lottie
              animationData={Blackvuelogo}
              loop={true}
              style={{ width: "100%" }}
            />
          </div>
        )}

        {/* gpsTracking  비디오 모달인 경우 방향 마크업 추가 */}
        {gpsTracking && (
          <div
            className={clsx(classes.gpsVideoDir, {
              [classes.webGpsVideoDir]: !mobile,
            })}
          >
            {vod?.hasFront &&
              _.compact([
                vod?.hasFront,
                vod?.hasRear,
                vod?.hasInterior,
                vod?.hasOption,
              ]).length >= 2 && (
                <div
                  className={classes.webGpsVideoDirTextDiv}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPlayError(false);
                    onRequestFront?.(vod.filename);
                  }}
                >
                  <Typography
                    category="Default"
                    variant={
                      vod.direction === "Front" ? "CaptionBold" : "Caption"
                    }
                    htmlColor={
                      vod.direction === "Front"
                        ? LightColors.primary["7"]
                        : LightColors.primary["1"]
                    }
                    className={classes.dirText}
                  >
                    {t("Front")}
                  </Typography>
                </div>
              )}
            {vod?.hasRear && (
              <div
                className={classes.webGpsVideoDirTextDiv}
                onClick={(e) => {
                  e.stopPropagation();
                  setPlayError(false);
                  onRequestRear?.(vod.filename);
                }}
              >
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ padding: "0 14px 0 13px" }}
                >
                  ·
                </Typography>
                <Typography
                  category="Default"
                  variant={vod.direction === "Rear" ? "CaptionBold" : "Caption"}
                  htmlColor={
                    vod.direction === "Rear"
                      ? LightColors.primary["7"]
                      : LightColors.primary["1"]
                  }
                  className={classes.dirText}
                >
                  {t("Rear")}
                </Typography>
              </div>
            )}
            {vod?.hasInterior && (
              <div
                className={classes.webGpsVideoDirTextDiv}
                onClick={(e) => {
                  e.stopPropagation();
                  setPlayError(false);
                  onRequestInterior?.(vod.filename);
                }}
              >
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ padding: "0 8px 0 14px" }}
                >
                  ·
                </Typography>
                <Typography
                  category="Default"
                  variant={
                    vod.direction === "Interior" ? "CaptionBold" : "Caption"
                  }
                  htmlColor={
                    vod.direction === "Interior"
                      ? LightColors.primary["7"]
                      : LightColors.primary["1"]
                  }
                  className={classes.dirText}
                >
                  {t("Interior")}
                </Typography>
              </div>
            )}

            {vod?.hasOption && (
              <div
                className={classes.webGpsVideoDirTextDiv}
                onClick={(e) => {
                  e.stopPropagation();
                  setPlayError(false);
                  onRequestOption?.(vod.filename);
                }}
              >
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ padding: "0 5px 0 10px" }}
                >
                  ·
                </Typography>
                <Typography
                  category="Default"
                  variant={
                    vod.direction === "Option" ? "CaptionBold" : "Caption"
                  }
                  htmlColor={
                    vod.direction === "Option"
                      ? LightColors.primary["7"]
                      : LightColors.primary["1"]
                  }
                  className={classes.dirText}
                >
                  {t("Option")}
                </Typography>
              </div>
            )}
          </div>
        )}

        {(!hideCtrl || forceViewCtrl) && (
          <>
            {(mobile || mapbox) && !loading && vod && (
              <div className={clsx(classes.mobilePlayCtrlDiv)}>
                <div
                  className={clsx(classes.mobilePlayCtrl, {
                    [classes.mapboxMobilePlayCtrl]: mapbox,
                  })}
                >
                  {!mapbox &&
                    renderCtrlIcon(
                      <PlayerPreviousFrame style={{ fontSize: "1.8125rem" }} />,
                      handlePrevFrame,
                      !vod
                    )}
                  {renderCtrlIcon(
                    paused ? (
                      <PlayArrowIcon style={{ fontSize: "3rem" }} />
                    ) : (
                      <PauseIcon style={{ fontSize: "3rem" }} />
                    ),
                    handlePlayPause,
                    !vod
                  )}
                  {!mapbox &&
                    renderCtrlIcon(
                      <PlayerNextFrame style={{ fontSize: "1.8125rem" }} />,
                      handleNextFrame,
                      !vod
                    )}
                </div>
              </div>
            )}
            <div
              className={classes.ctrlContDiv}
              onClick={(e) => e.stopPropagation()}
            >
              <ProgressSlider
                value={
                  0.5 +
                  (current.asSeconds() / Math.max(1, duration.asSeconds())) * 99
                }
                onMouseDown={() => {
                  setForceViewCtrl(true);
                }}
                onMouseUp={() => {
                  setForceViewCtrl(false);
                }}
                onChange={(_e, newVal) => {
                  const val = ((newVal as number) - 0.5) / 99;
                  setCurrentPlayTime(val * duration.asSeconds());
                }}
                aria-labelledby="continuous-slider"
              />

              <div className={classes.ctrlDiv}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!singleVideo && (
                    <Tooltip
                      disableTouchListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Previous")}
                        </Typography>
                      }
                      placement="top-start"
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: { enabled: false },
                          flip: { enabled: false },
                        },
                      }}
                    >
                      {renderCtrlIcon(
                        <SkipPreviousIcon />,
                        onPrev,
                        disablePrev || !vod
                      )}
                    </Tooltip>
                  )}

                  {!mobile && !mapbox && (
                    <>
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Previous frame")}
                          </Typography>
                        }
                        placement="top-start"
                        PopperProps={{
                          disablePortal: true,
                          modifiers: {
                            preventOverflow: { enabled: false },
                            flip: { enabled: false },
                          },
                        }}
                      >
                        {renderCtrlIcon(
                          <PlayerPreviousFrame />,
                          handlePrevFrame,
                          !vod
                        )}
                      </Tooltip>

                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {paused ? t("Play") : t("Pause")}
                          </Typography>
                        }
                        placement="top"
                        PopperProps={{
                          disablePortal: true,
                          modifiers: {
                            preventOverflow: { enabled: false },
                            flip: { enabled: false },
                          },
                        }}
                      >
                        {renderCtrlIcon(
                          paused ? <PlayArrowIcon /> : <PauseIcon />,
                          handlePlayPause,
                          !vod
                        )}
                      </Tooltip>

                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("Next frame")}
                          </Typography>
                        }
                        placement="top"
                        PopperProps={{
                          disablePortal: true,
                          modifiers: {
                            preventOverflow: { enabled: false },
                            flip: { enabled: false },
                          },
                        }}
                      >
                        {renderCtrlIcon(
                          <PlayerNextFrame />,
                          handleNextFrame,
                          !vod
                        )}
                      </Tooltip>
                    </>
                  )}

                  {!singleVideo && (
                    <Tooltip
                      disableTouchListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Next")}
                        </Typography>
                      }
                      placement="top"
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: { enabled: false },
                          flip: { enabled: false },
                        },
                      }}
                    >
                      {renderCtrlIcon(
                        <SkipNextIcon />,
                        onNext,
                        disableNext || !vod
                      )}
                    </Tooltip>
                  )}
                  <div
                    style={{ display: "flex" }}
                    onMouseEnter={() => setOpenVolumeMenu(true)}
                    onMouseLeave={() => setOpenVolumeMenu(false)}
                  >
                    {!mobile && !mapbox && volume === 0 ? (
                      <Tooltip
                        title={
                          <Typography category="Default" variant="Caption">
                            {t("UnMute")}
                          </Typography>
                        }
                        placement="top"
                        PopperProps={{
                          disablePortal: true,
                          modifiers: {
                            preventOverflow: { enabled: false },
                            flip: { enabled: false },
                          },
                        }}
                      >
                        <IconButton
                          className={classes.ctrlIcon}
                          ref={volumeAnchorRef}
                          onClick={(e) => {
                            e.stopPropagation();
                            setVolume(preVolume);
                          }}
                        >
                          <VolumeOffIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      !mobile &&
                      !mapbox && (
                        <Tooltip
                          title={
                            <Typography category="Default" variant="Caption">
                              {t("Mute")}
                            </Typography>
                          }
                          placement="top"
                          PopperProps={{
                            disablePortal: true,
                            modifiers: {
                              preventOverflow: { enabled: false },
                              flip: { enabled: false },
                            },
                          }}
                        >
                          <IconButton
                            className={classes.ctrlIcon}
                            ref={volumeAnchorRef}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPreVolume(volume);
                              setVolume(0);
                            }}
                          >
                            <VolumeUpIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                    {openVolumeMenu && (
                      <div className={classes.volumeCtrlDiv}>
                        <VolumeSlider
                          value={volume}
                          onChange={(e, newVal) => setVolume(newVal as number)}
                        />
                      </div>
                    )}
                  </div>

                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={LightColors.primary["0"]}
                  >
                    {getTime()}
                  </Typography>
                </div>
                {/*
                        mantis : 8469  
                        Resolution을 Div로 감싸 Icon들이 정렬할 수 있도록
                        display: 'flex'를 줌
                      */}
                <div style={{ display: "flex" }}>
                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Playback speed")}
                      </Typography>
                    }
                    placement="top"
                    PopperProps={{
                      disablePortal: true,
                      modifiers: {
                        preventOverflow: { enabled: false },
                        flip: { enabled: false },
                      },
                    }}
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={speedAnchorRef}
                      onClick={() => {
                        setForceViewCtrl(true);
                        setOpenPlaySpeedMenu((o) => !o);
                        setOpenFlipMenu(false);
                        setOpenQualityMenu(false);
                      }}
                    >
                      <Typography category="Default" variant="Small">
                        {playSpeed}x
                      </Typography>
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    title={
                      <Typography category="Default" variant="Caption">
                        {t("Flip")}
                      </Typography>
                    }
                    placement="top"
                    PopperProps={{
                      disablePortal: true,
                      modifiers: {
                        preventOverflow: { enabled: false },
                        flip: { enabled: false },
                      },
                    }}
                  >
                    <IconButton
                      className={classes.ctrlIcon}
                      ref={flipAnchorRef}
                      onClick={() => {
                        setForceViewCtrl(true);
                        setOpenFlipMenu((f) => !f);
                        setOpenPlaySpeedMenu(false);
                        setOpenQualityMenu(false);
                      }}
                    >
                      <FlipIcon />
                    </IconButton>
                  </Tooltip>
                  {showQuality && (
                    <Tooltip
                      disableTouchListener={mobile}
                      disableHoverListener={mobile}
                      title={
                        <Typography category="Default" variant="Caption">
                          {disableQuality
                            ? t("Original file play_")
                            : t("Resolution")}
                        </Typography>
                      }
                      placement="top-end"
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: { enabled: false },
                          flip: { enabled: false },
                          offset: { enabled: true, offset: "20, 0" },
                        },
                      }}
                    >
                      {/*
                        mantis : 8469  
                        IconButton이 disabled일 때 툴팁 기능이 정상 작동 하지 않아,
                        Div로 감싸 툴팁 기능을 활성화 함.
                      */}
                      <div style={{ padding: 0, marginRight: 8, width: 24 }}>
                        <IconButton
                          className={classes.ctrlIcon}
                          ref={qualityAnchorRef}
                          disabled={disableQuality || disableUndefined}
                          onClick={() => {
                            setForceViewCtrl(true);
                            setOpenQualityMenu((o) => !o);
                            setOpenFlipMenu(false);
                            setOpenPlaySpeedMenu(false);
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!mobile && !noTheater && (
                    <Tooltip
                      title={
                        <Typography category="Default" variant="Caption">
                          {t("Theater mode")}
                        </Typography>
                      }
                      placement="top-end"
                      PopperProps={{
                        disablePortal: true,
                        modifiers: {
                          preventOverflow: { enabled: false },
                          flip: { enabled: false },
                        },
                      }}
                    >
                      <IconButton
                        className={classes.ctrlIcon}
                        onClick={onTheater}
                      >
                        {theater ? <Crop75Icon /> : <Crop54Icon />}
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    disableTouchListener={mobile}
                    disableHoverListener={mobile}
                    open={openTooltip}
                    onOpen={() => setOpenTooltip(true)}
                    onClose={() => setOpenTooltip(false)}
                    title={
                      <Typography category="Default" variant="Caption">
                        {fullscreen ? t("Exit fullscreen") : t("Fullscreen")}
                      </Typography>
                    }
                    placement="top-end"
                    PopperProps={{
                      disablePortal: true,
                      modifiers: {
                        preventOverflow: { enabled: false },
                        flip: { enabled: false },
                      },
                    }}
                  >
                    <IconButton
                      className={clsx(classes.ctrlIcon, {
                        [classes.gpsTackingCtrlIcon]: gpsTracking,
                      })}
                      onClick={handleFullscreen}
                    >
                      {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }, [
    classes.videoDiv,
    classes.videoWideDiv,
    classes.modalFullScreen,
    classes.videoFullScreen,
    classes.appVideoFullScreen,
    classes.videoSafariFullScreen,
    classes.video,
    classes.videoScale,
    classes.gpsTrackingVideoScale,
    classes.videoScaleFull,
    classes.videoHFlip,
    classes.videoVFlip,
    classes.videoFlip,
    classes.blackvueIcon,
    classes.overlayDiv,
    classes.gpsVideoDir,
    classes.webGpsVideoDir,
    classes.webGpsVideoDirTextDiv,
    classes.dirText,
    classes.mobilePlayCtrlDiv,
    classes.mobilePlayCtrl,
    classes.mapboxMobilePlayCtrl,
    classes.ctrlContDiv,
    classes.ctrlDiv,
    classes.ctrlIcon,
    classes.volumeCtrlDiv,
    classes.gpsTackingCtrlIcon,
    screenMode,
    fullHeight,
    fullscreen,
    app,
    videoRatio,
    handleScreenPlay,
    mobile,
    gpsTracking,
    vFlip,
    hFlip,
    camera,
    vod,
    playError,
    t,
    mediaError,
    loading,
    hideCtrl,
    forceViewCtrl,
    mapbox,
    renderCtrlIcon,
    handlePrevFrame,
    paused,
    handlePlayPause,
    handleNextFrame,
    current,
    duration,
    singleVideo,
    onPrev,
    disablePrev,
    onNext,
    disableNext,
    volume,
    openVolumeMenu,
    getTime,
    playSpeed,
    showQuality,
    disableQuality,
    disableUndefined,
    noTheater,
    onTheater,
    theater,
    openTooltip,
    handleFullscreen,
    onRequestFront,
    onRequestRear,
    onRequestInterior,
    onRequestOption,
    preVolume,
  ]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootFullscreen]: iosBrowser && rootFullscreen,
      })}
      style={{ width: "100%" }}
      ref={videoRef}
    >
      {!gpsTracking && (
        <div
          className={clsx(classes.menuDiv, { [classes.mapboxMenuDiv]: mapbox })}
        >
          <Typography
            category="Default"
            variant={mobile ? "Small" : "Body"}
            className={clsx({ [classes.videoTitleDiv]: mobile })}
          >
            {vod &&
              `${vod.time.format("HH:mm:ss YYYY-MM-DD")} · ${
                vod.event
              } ${getDirectionString(vod.direction, camera?.model)}`}
          </Typography>
          {/* 7 BOX option카메라 관련 추가 (8467) */}
          {_.compact([
            vod?.hasFront,
            vod?.hasRear,
            vod?.hasInterior,
            vod?.hasOption,
          ]).length >= 2 && (
            <div
              className={clsx(classes.dirDiv, {
                [classes.disabled]: !vod,
              })}
              ref={menuAnchorRef}
              onClick={() => vod && setOpenMenu(true)}
            >
              <Typography category="Default" variant="BodyBold">
                {t(
                  vod
                    ? getDirectionString(vod.direction, camera?.model)
                    : "Front"
                )}
              </Typography>
              <ExpandMoreIcon fontSize="small" />
            </div>
          )}
        </div>
      )}

      {videoPlayerMarkup}
      {mobile && (
        <MobileMenu
          open={openQualityMenu}
          onClose={() => setOpenQualityMenu(false)}
          container={videoRef.current}
        >
          {vod?.hasLow && (
            <WebMenuItem
              endIcon={quality === "low" && <CheckIcon fontSize="small" />}
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("low");
              }}
            >
              {t("Low resolution")}
            </WebMenuItem>
          )}

          {vod?.hasOriginal && (
            <WebMenuItem
              endIcon={quality === "original" && <CheckIcon fontSize="small" />}
              className={clsx(classes.mobileMemuItem)}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("original");
              }}
            >
              {t("Original")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}
      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openQualityMenu}
          anchorEl={qualityAnchorRef.current}
          onClickAway={() => setOpenQualityMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          {vod?.hasLow && (
            <WebMenuItem
              startIcon={quality === "low" && <CheckIcon fontSize="small" />}
              className={clsx(classes.webMenuDiv, {
                [classes.uncheckedMenu]: quality !== "low",
              })}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("low");
              }}
            >
              {t("Low resolution")}
            </WebMenuItem>
          )}

          {vod?.hasOriginal && (
            <WebMenuItem
              startIcon={
                quality === "original" && <CheckIcon fontSize="small" />
              }
              className={clsx(classes.webMenuDiv, {
                [classes.uncheckedMenu]: quality !== "original",
              })}
              onClick={() => {
                setOpenQualityMenu(false);
                setForceViewCtrl(false);
                onChangeQuality?.("original");
              }}
            >
              {t("Original")}
            </WebMenuItem>
          )}
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openPlaySpeedMenu}
          onClose={() => setOpenPlaySpeedMenu(false)}
          container={videoRef.current}
        >
          <WebMenuItem
            endIcon={
              playSpeed === 0.5 && (
                <CheckIcon
                  fontSize="small"
                  htmlColor={themeState.colors.primary["1"]}
                />
              )
            }
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setForceViewCtrl(false);
              setPlaySpeed(0.5);
            }}
          >
            {t("0.5x")}
          </WebMenuItem>

          <WebMenuItem
            endIcon={
              playSpeed === 1 && (
                <CheckIcon
                  fontSize="small"
                  htmlColor={themeState.colors.primary["1"]}
                />
              )
            }
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setForceViewCtrl(false);
              setPlaySpeed(1);
            }}
          >
            {t("1x")}
          </WebMenuItem>
        </MobileMenu>
      )}

      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openPlaySpeedMenu}
          anchorEl={speedAnchorRef.current}
          onClickAway={() => setOpenPlaySpeedMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          <WebMenuItem
            startIcon={playSpeed === 0.5 && <CheckIcon fontSize="small" />}
            className={clsx(classes.webMenuDiv, {
              [classes.uncheckedMenu]: playSpeed !== 0.5,
            })}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setPlaySpeed(0.5);
            }}
          >
            {t("0.5x")}
          </WebMenuItem>

          <WebMenuItem
            startIcon={playSpeed === 1 && <CheckIcon fontSize="small" />}
            className={clsx(classes.webMenuDiv, {
              [classes.uncheckedMenu]: playSpeed !== 1,
            })}
            onClick={() => {
              setOpenPlaySpeedMenu(false);
              setPlaySpeed(1);
            }}
          >
            {t("1x")}
          </WebMenuItem>
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openFlipMenu}
          onClose={() => setOpenFlipMenu(false)}
          container={videoRef.current}
        >
          <WebMenuItem
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenFlipMenu(false);
              setHFlip((f) => !f);
            }}
          >
            {t("Flip horizontally")}
          </WebMenuItem>

          <WebMenuItem
            className={clsx(classes.mobileMemuItem)}
            onClick={() => {
              setOpenFlipMenu(false);
              setVFlip((f) => !f);
            }}
          >
            {t("Flip vertically")}
          </WebMenuItem>
        </MobileMenu>
      )}
      {!mobile && (
        <Menu
          style={{ zIndex: 2147483647 }}
          paperClasses={{ root: classes.playerMenuDiv }}
          open={openFlipMenu}
          anchorEl={flipAnchorRef.current}
          onClickAway={() => setOpenFlipMenu(false)}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 10px",
            },
          }}
          placement="top"
        >
          <WebMenuItem
            className={clsx(classes.webMenuDiv)}
            onClick={() => {
              setOpenFlipMenu(false);
              setForceViewCtrl(false);
              setHFlip((f) => !f);
            }}
          >
            {t("Flip horizontally")}
          </WebMenuItem>

          <WebMenuItem
            className={clsx(classes.webMenuDiv)}
            onClick={() => {
              setOpenFlipMenu(false);
              setForceViewCtrl(false);
              setVFlip((f) => !f);
            }}
          >
            {t("Flip vertically")}
          </WebMenuItem>
        </Menu>
      )}

      {mobile && (
        <MobileMenu
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          container={videoRef.current}
        >
          {vod?.hasFront &&
            _.compact([
              vod?.hasFront,
              vod?.hasRear,
              vod?.hasInterior,
              vod?.hasOption,
            ]).length >= 2 && (
              <WebMenuItem
                endIcon={
                  vod?.direction === "Front" && <CheckIcon fontSize="small" />
                }
                className={classes.mobileMemuItem}
                onClick={() => {
                  setOpenMenu(false);
                  onRequestFront?.(vod.filename);
                }}
              >
                {t("Front")}
              </WebMenuItem>
            )}
          {vod?.hasRear && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Rear" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestRear?.(vod.filename);
              }}
              // mantis - 11844,해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화 (Leehj)
              disabled={rearDisabled}
            >
              {t("Rear")}
            </WebMenuItem>
          )}
          {vod?.hasInterior && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Interior" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestInterior?.(vod.filename);
              }}
              // mantis - 11844,해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화 (Leehj)
              disabled={interiorDisabled}
            >
              {t("Interior")}
            </WebMenuItem>
          )}
          {/* 7 BOX option카메라 관련 추가 (8467) */}
          {vod?.hasOption && (
            <WebMenuItem
              endIcon={
                vod?.direction === "Option" && <CheckIcon fontSize="small" />
              }
              className={classes.mobileMemuItem}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestOption?.(vod.filename);
              }}
              disabled={optionDisabled}
            >
              {t("Option")}
            </WebMenuItem>
          )}
        </MobileMenu>
      )}

      {!mobile && (
        <Menu
          open={openMenu}
          anchorEl={menuAnchorRef.current}
          onClickAway={() => setOpenMenu(false)}
          modifiers={{
            preventOverflow: { enabled: false },
            offset: {
              enabled: true,
              offset: "0, 12px",
            },
          }}
          placement="bottom-end"
        >
          {vod?.hasFront &&
            _.compact([
              vod?.hasFront,
              vod?.hasRear,
              vod?.hasInterior,
              vod?.hasOption,
            ]).length >= 2 && (
              <WebMenuItem
                startIcon={
                  vod?.direction === "Front" && <CheckIcon fontSize="small" />
                }
                className={clsx({
                  [classes.uncheckedMenu]: vod?.direction !== "Front",
                })}
                onClick={() => {
                  setOpenMenu(false);
                  setPlayError(false);
                  setMediaError(false);
                  onRequestFront?.(vod.filename);
                }}
              >
                {t("Front")}
              </WebMenuItem>
            )}
          {vod?.hasRear && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Rear" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Rear",
              })}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestRear?.(vod.filename);
              }}
              // mantis - 11844,해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화 (Leehj)
              disabled={rearDisabled}
            >
              {t("Rear")}
            </WebMenuItem>
          )}
          {vod?.hasInterior && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Interior" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Interior",
              })}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestInterior?.(vod.filename);
              }}
              // mantis - 11844,해당 파일의 rear or Interior 영상을 찾아서 보여줌 / 없으면 비활성화 (Leehj)
              disabled={interiorDisabled}
            >
              {t("Interior")}
            </WebMenuItem>
          )}
          {/* 7 BOX option카메라 관련 추가 (8467) */}
          {vod?.hasOption && (
            <WebMenuItem
              startIcon={
                vod?.direction === "Option" && <CheckIcon fontSize="small" />
              }
              className={clsx({
                [classes.uncheckedMenu]: vod?.direction !== "Option",
              })}
              onClick={() => {
                setOpenMenu(false);
                setPlayError(false);
                setMediaError(false);
                onRequestOption?.(vod.filename);
              }}
              disabled={optionDisabled}
            >
              {t("Option")}
            </WebMenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};
