import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  Modal,
  LocationType,
  Typography,
} from "@thingsw/pitta-design-system";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import clsx from "clsx";

import { detect } from "detect-browser";
import {
  ICameraInfo,
  ITrackData,
  Webviewer,
  TabNameInfo1,
  IGPSLocation,
  IVOD,
  jwtAxiosInst,
  MODELS_2CH,
  MODELS_3CH,
  OPTION_CAMERA_MODELS,
  LightColors,
  getDirectionString,
  useWindowSize,
} from "@thingsw/pitta-modules";
import { IUXUILatestEvent } from "../../features/Event/slice";

import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CAMERA, loadFirmwareSettings } from "../../features/Camera/slice";
import {
  loadUrgentVODToken,
  VOD,
  loadVODList,
  successloadVODList,
} from "../../features/VOD/slice";
import { convertLowFilenameToOrignal } from "../../utils/VOD";
import { VideoPlayer } from "../cameras/VideoPlayer";
import { USER } from "../../features/User/slice";
import { MapboxLocationMapWrapper } from "../maps/MapboxLocationMapWrapper";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { THEME } from "../../features/Theme/slice";

interface VideoPlayerMapboxUXUIModalProps {
  open: boolean;
  mobile?: boolean;
  camera: ICameraInfo;
  mode: number; // 0: playback, 1: cloud, 2: live event upload, 3:auto
  data?: ITrackData;
  event?: IUXUILatestEvent;
  notification?: boolean;
  onClose?: VoidFunction;
  gpsTracking?: boolean;
  app?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 470,
      maxWidth: 593,
      borderRadius: 12,
      border: "unset",
    },
    color: (props: any) => props.colors.primary["1"],
  },
  container: {
    width: "100%",
    height: "fit-content",
    position: "relative",
    overflow: "auto",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  },
  rootFullscreen: {
    width: "100%",
    maxWidth: "unset",
    minWidth: "unset",
    borderRadius: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    boxShadow: "border-box",
    minHeight: 20,
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(2, 2, 1.5, 1.625) }
      : { padding: theme.spacing(2, 1.625, 1.5, 2) }),
    textOverflow: "ellipsis",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(2.375, 2, 2, 2) }
        : { padding: theme.spacing(2.375, 2, 2, 2) }),
    },
  },
  iconBtn: {
    padding: "0 4px",
    color: (props: any) => props.colors.primary["1"],
  },
  iconBtnFullscreen: {
    color: "white",
  },
  reportIcon: {
    marginRight: 7,
  },
  content: {
    padding: 0,
    height: "100%",
    overflow: "auto",
  },
  contentFullscreen: {
    padding: 0,
  },
  titleFullscreen: {
    padding: 0,
    height: "0px!important",
    minHeight: 0,
  },
  overflowHidden: {
    overflowY: "hidden",
  },
  hiddenScroll: {
    overflowY: "hidden",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflowY: "auto",
    },
  },
  paperScrollPaper: {
    maxHeight: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxHeight: 788,
    },
  },

  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(19, 19, 28, 0.45)",
  },

  mapDiv: {
    marginTop: 0,
    marginBottom: 0,
    flex: "1 1 auto",
    // height: "calc(100vh - 71vw)",
    width: "100%",
    minHeight: 300,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: 330,
    },
    // display: "flex",
    // position: "relative",
    // marginTop: theme.spacing(3),
  },
  gpsTrackingMapDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: 400,
    },
  },
  headerBtnStyle: {
    right: 10,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 13,
    },
  },
  gpsTrackingHeaderBtnStyle: {
    top: 13,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      top: 16,
    },
  },
  headerTitleStyle: {
    fontSize: "16px !important",
    display: "block",
  },
  videoTitleDiv: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  appHeaderBtnStyle: {
    left: 1,
  },
  appTitle: {
    padding: "12px 13px 12px 44px",
    minHeight: 24,
  },
  mapFullSizeTitle: {
    display: "none",
  },
}));

export const VideoPlayerMapboxUXUIModal = (
  props: VideoPlayerMapboxUXUIModalProps
) => {
  const {
    open,
    camera,
    mode,
    data,
    event,
    notification,
    onClose,
    mobile,
    gpsTracking,
    app,
  } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const cameraState = useSelector((state: RootState) => state[CAMERA]);
  const themeState = useSelector((state: RootState) => state[THEME]);

  const classes = useStyles(themeState);

  const { firmware, cameraList, firmwareConfig } = cameraState;

  const videoSettings = useMemo(() => {
    return _.find(firmware?.cloud_settings, (c) => c.section_name === "Tab1")
      ?.section_info as TabNameInfo1;
  }, [firmware?.cloud_settings]);

  const [cam, setCam] = useState<ICameraInfo>();
  const [locations, setLocations] = useState<IGPSLocation[]>([]);
  const [location, setLocation] = useState<IGPSLocation>();
  const [timestamp, setTimestamp] = useState<number>();
  const [playVOD, setPlayVOD] = useState<IVOD>();
  const [, /*error*/ setError] = useState(false);

  const [hiddenScroll, setHiddenScroll] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [isMapboxFullscreen, setIsMapboxFullscreen] = useState(false);

  const { vodList } = useSelector((state: RootState) => state[VOD]);

  const CLOUD_NATIVE3 = useMemo(() => {
    if (firmwareConfig === undefined) {
      return undefined;
    }
    return !!firmwareConfig?.CloudNative3;
  }, [firmwareConfig]);

  useEffect(() => {
    const requestCNVod = async (camera: ICameraInfo) => {
      const resp = await jwtAxiosInst.post(`/IoT/devicecommand`, {
        command: "VODList",
        email,
        user_token: loginInfo?.user_token,
        tokenType: tokenType,
        psn: camera?.psn,
      });
      if (resp.data?.["filelist"]) {
        dispatch(successloadVODList(resp.data["filelist"]));
      }
    };
    if (camera) {
      if (CLOUD_NATIVE3 === true) {
        requestCNVod(camera);
      } else if (CLOUD_NATIVE3 === false) {
        dispatch(
          loadVODList({
            lb_server_name: camera.lb_server_name,
            lb_http_port: camera.lb_http_port,
            psn: camera.psn,
          })
        );
      }
    }
  }, [
    CLOUD_NATIVE3,
    camera,
    dispatch,
    email,
    loginInfo?.user_token,
    tokenType,
  ]);

  useEffect(() => {
    if (windowSize.height > 849) {
      setHiddenScroll(true);
    } else {
      setHiddenScroll(false);
    }

    console.log("setHiddenScroll", windowSize.height);
  }, [windowSize.height]);

  useEffect(() => {
    const browserName = detect()?.name;
    if (open) {
      if (
        browserName === "ios-webview" &&
        //@ts-ignore
        webkit.messageHandlers.enableRotate
      ) {
        //@ts-ignore
        webkit.messageHandlers.enableRotate.postMessage({});
      }
      if (
        browserName === "chromium-webview" &&
        //@ts-ignore
        window.Webviewer?.enableRotate
      ) {
        //@ts-ignore
        window.Webviewer?.enableRotate?.();
      }
    } else {
      if (
        browserName === "ios-webview" &&
        //@ts-ignore
        webkit.messageHandlers.disableRotate
      ) {
        //@ts-ignore
        webkit.messageHandlers.disableRotate.postMessage({});
      }
      if (
        browserName === "chromium-webview" &&
        //@ts-ignore
        window.Webviewer?.disableRotate
      ) {
        //@ts-ignore
        window.Webviewer?.disableRotate?.();
      }
    }
  }, [open]);

  //@ts-ignore
  window.requestFullscreen = useCallback(() => {
    setFullscreen(true);
  }, []);

  //@ts-ignore
  window.exitFullscreen = useCallback(() => {
    setFullscreen(true);
  }, []);

  const headerBtnMarkup = useMemo(() => {
    if (app) {
      return (
        <>
          <IconButton className={classes.iconBtn} onClick={onClose}>
            <ArrowBackIos />
          </IconButton>
        </>
      );
    }
    return (
      <>
        <IconButton className={classes.iconBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </>
    );
  }, [app, classes.iconBtn, onClose]);

  const gpsTrackingHeaderTitle = useMemo(() => {
    if (gpsTracking) {
      return (
        playVOD &&
        `${playVOD.time.format("YYYY-MM-DD HH:mm:ss")} · ${
          playVOD.event
        } ${getDirectionString(playVOD.direction, camera?.model)}`
      );
    }
  }, [camera?.model, gpsTracking, playVOD]);

  //Blackvue7 Box 옵션 카메라 방향 추가
  const isOptionModel = useMemo(
    () => _.includes(OPTION_CAMERA_MODELS, camera?.model),
    [camera?.model]
  );

  const is2CH = useMemo(() => {
    return _.includes([...MODELS_2CH, ...MODELS_3CH], camera.model);
  }, [camera]);

  const is3CH = useMemo(() => {
    return _.includes(MODELS_3CH, camera.model);
  }, [camera]);

  const changeVOD = useCallback(
    (
      filename: string,
      direction: LocationType,
      track?: ITrackData,
      front?: boolean,
      rear?: boolean,
      third?: boolean
      // rid_front?: string,
      // rid_rear?: string,
      // rid_3?: string
    ) => {
      const timeStrings = filename.split("_");
      const time = moment(
        `${timeStrings[0]} ${timeStrings[1]}`,
        "YYYYMMDD hhmmss"
      );

      if (mode === 0) {
        dispatch(
          loadUrgentVODToken({
            psn: camera.psn,
            filename: filename,
          })
        );
      }

      let fname = track?.sub_front || filename;
      let rid = track?.rid_front;
      if (direction === "Rear") {
        fname = track?.sub_rear || filename;
        rid = track?.rid_rear;
      } else if (direction === "Interior" || direction === "Option") {
        fname = track?.sub_3 || filename;
        rid = track?.rid_3;
      }

      setPlayVOD({
        filename: convertLowFilenameToOrignal(fname),
        lowFilename: fname,
        rid,
        lowRid: rid,
        direction,
        event: "Event",
        time,
        hasLow: true,
        hasFront: !!(track?.rid_front || track?.sub_front) || front,
        hasRear: !!(track?.rid_rear || track?.sub_rear) || rear,
        hasInterior:
          !isOptionModel && (!!(track?.rid_3 || track?.sub_3) || third),
        hasOption: isOptionModel && (!!(track?.rid_3 || track?.sub_3) || third),
      });
    },
    [camera.psn, dispatch, isOptionModel, mode]
  );

  const getEventFile: (
    event: IUXUILatestEvent,
    dir?: LocationType
  ) => [string | undefined, LocationType] = useCallback(
    (event: IUXUILatestEvent, dir?: LocationType) => {
      const { event_file } = event;
      if (event_file) {
        const fnames = _.split(event_file, ".");
        const names = _.split(fnames[0], "_");
        let evt = _.last(names);
        let direction: LocationType = dir ?? "Front";
        if (!dir) {
          switch (evt?.[1]) {
            case "I":
              direction = "Interior";
              break;
            case "R":
              direction = "Rear";
              break;
            case "O":
              direction = "Option";
              break;
            default:
              direction = "Front";
              break;
          }
        } else if (evt) {
          switch (dir) {
            case "Front":
              evt = evt[0] + "F";
              break;
            case "Rear":
              evt = evt[0] + "R";
              break;
            case "Interior":
              evt = evt[0] + "I";
              break;
            case "Option":
              evt = evt[0] + "O";
              break;
          }
        }
        if (evt && evt.length === 2) {
          evt = evt + "S";
        }
        console.log("videoPlayer", "getEventFile", event, direction);
        const fname =
          _.join([..._.slice(names, 0, names.length - 1), evt], "_") +
          "." +
          fnames[1];
        return [fname, direction];
      }
      return [undefined, "Front"];
    },
    []
  );

  useEffect(() => {
    if (cam) {
      dispatch(loadFirmwareSettings(cam.psn));
    }
  }, [cam, dispatch]);

  useEffect(() => {
    if (event) {
      const [fname, direction] = getEventFile(event);

      if (fname && direction) {
        if (mode === 0) {
          // mode 0: 이벤트/푸시 알람
          changeVOD(fname, direction, undefined, true, is2CH, is3CH); // 13810 카메라 방향 선택 불가로 수정 (hongcs 2024.05.14)
        } else {
          changeVOD(fname, direction);
        }
      }
    } else if (data) {
      const {
        sub_front,
        sub_rear,
        sub_3,
        front_playable,
        rear_playable,
        sub3_playable,
      } = data;
      const isAI = (data.mtype >= 6 && data.mtype <= 12) || data.mtype === 18; // mtype 18 Seat Belt 추가 (hongcs 2024.07.19)
      if (isAI && sub_3) {
        if (isOptionModel) {
          changeVOD(sub_3, "Option", data);
        } else {
          changeVOD(sub_3, "Interior", data);
        }
      } else if (front_playable && sub_front) {
        changeVOD(sub_front, "Front", data);
      } else if (rear_playable && sub_rear) {
        changeVOD(sub_rear, "Rear", data);
      } else if (sub3_playable && sub_3) {
        changeVOD(sub_3, "Interior", data);
      }
    } else {
      setPlayVOD(undefined);
    }
  }, [
    changeVOD,
    camera,
    data,
    event,
    mode,
    getEventFile,
    isOptionModel,
    is2CH,
    is3CH,
  ]);

  useEffect(() => {
    if (cam && cam.latitude && cam.longitude) {
      setLocation({
        lat: parseFloat(cam.latitude),
        lng: parseFloat(cam.longitude),
        name: cam.dev_name,
        mode: "0",
      } as IGPSLocation);
    }
    // else {
    //   const cancel = new AbortController();
    //   dispatch(loadMyCemrasLocation({ cancel }));
    //   return () => {
    //     cancel.abort();
    //   };
    // }
  }, [cam, dispatch, is2CH, is3CH]);

  useEffect(() => {
    const cm = _.chain(cameraList?.deviceListInfo)
      .map((c) => c.device)
      .find((c) => c.psn === camera?.psn)
      .value();
    setCam((c) => {
      if (c?.psn !== cm?.psn) {
        return cm;
      } else if (c?.active !== cm?.active) {
        return cm;
      } else if (
        (!c?.latitude && cm?.latitude) ||
        (!c?.longitude && cm?.latitude)
      ) {
        return cm;
      }
      return c;
    });
  }, [camera, cameraList?.deviceListInfo]);

  useEffect(() => {
    if (timestamp) {
      const last = _.chain(locations)
        .filter((loc) => (loc.time ?? 0) <= timestamp)
        .last()
        .value();

      if (last) {
        setLocation(last);
      }
    }
  }, [timestamp, locations]);

  const playFrontVOD = useCallback(() => {
    if (data) {
      const { sub_front } = data;
      if (sub_front) {
        changeVOD(sub_front, "Front", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Front");
      if (fname && direction) {
        if (mode === 0) {
          changeVOD(fname, direction, undefined, true, is2CH, is3CH);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [changeVOD, data, event, getEventFile, mode, is2CH, is3CH]);

  const playRearVOD = useCallback(() => {
    if (data) {
      const { sub_rear } = data;
      if (sub_rear) {
        changeVOD(sub_rear, "Rear", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Rear");
      if (fname && direction) {
        if (mode === 0) {
          changeVOD(fname, direction, undefined, true, is2CH, is3CH);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [changeVOD, data, event, getEventFile, mode, is2CH, is3CH]);

  const playInteriorVOD = useCallback(() => {
    if (data) {
      // 재생 방향이 잘못 merge되어 수정 (8516)
      const { sub_3 } = data;
      if (sub_3) {
        changeVOD(sub_3, "Interior", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Interior");
      if (fname && direction) {
        if (mode === 0) {
          changeVOD(fname, direction, undefined, true, is2CH, is3CH);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [changeVOD, data, event, getEventFile, mode, is2CH, is3CH]);

  const playOptionVOD = useCallback(() => {
    if (data) {
      const { sub_3 } = data;
      if (sub_3) {
        changeVOD(sub_3, "Option", data);
      }
    } else if (event) {
      const [fname, direction] = getEventFile(event, "Option");
      if (fname && direction) {
        if (mode === 0) {
          changeVOD(fname, direction, undefined, true, is2CH, is3CH);
        } else {
          changeVOD(fname, direction);
        }
      }
    }
  }, [changeVOD, data, event, getEventFile, mode, is2CH, is3CH]);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const videoPlayer = useMemo(() => {
    let rmode = mode;
    if (mode === 3) {
      if (playVOD?.rid) {
        rmode = 2;
      } else {
        rmode = 1;
      }
    }

    console.log("vodList", vodList);
    const sameDateVod = _.filter(vodList, (item) =>
      _.includes(
        item.filename,
        playVOD?.filename.split("_").slice(0, 2).join("_")
      )
    );
    console.log("sameDateVod", sameDateVod);

    const isRearVod = _.filter(sameDateVod, (item) => item.rear);
    const isInteriorVod = _.filter(sameDateVod, (item) => item.interior);
    const isOptionVod = _.filter(sameDateVod, (item) => item.option);

    return (
      <VideoPlayer
        playback
        app={app}
        mode={rmode}
        quality="low"
        camera={cam}
        vod={playVOD}
        singleVideo
        noTheater
        fullHeight
        notification={notification}
        onUpdateGPS={setLocations}
        onUpdateTime={setTimestamp}
        onRequestFront={playFrontVOD}
        onRequestRear={playRearVOD}
        onRequestInterior={playInteriorVOD}
        onRequestOption={playOptionVOD}
        onError={handleError}
        onFullscreen={(b) => {
          setFullscreen(b);
        }}
        // mapbox 모달 변경 ui 적용하기 위함
        mapbox
        // gpstracking mapbox 모달 변경 ui 적용하기 위함
        rearDisabled={isRearVod.length === 0}
        interiorDisabled={isInteriorVod.length === 0}
        optionDisabled={isOptionVod.length === 0}
        gpsTracking={gpsTracking}
      />
    );
  }, [
    mode,
    app,
    cam,
    playVOD,
    notification,
    playFrontVOD,
    playRearVOD,
    playInteriorVOD,
    playOptionVOD,
    handleError,
    gpsTracking,
    vodList,
  ]);

  // console.log("fullscreen", isMapboxFullscreen, fullscreen);
  return (
    <>
      <Modal
        open={open}
        // container={container}
        onClose={onClose}
        fullScreen={true}
        scroll="paper"
        className={clsx(classes.root, {
          [classes.rootFullscreen]: fullscreen,
        })}
        titleClassName={clsx(classes.title, {
          [classes.appTitle]: app,
          [classes.mapFullSizeTitle]: isMapboxFullscreen || fullscreen,
        })}
        contentClassName={clsx(classes.content, {
          [classes.overflowHidden]: hiddenScroll && !mobile,
          [classes.hiddenScroll]: hiddenScroll && mobile,
        })}
        fullSize={mobile || app}
        paperScrollPaperClassName={classes.paperScrollPaper}
        headerBtnClassName={clsx(classes.headerBtnStyle, {
          [classes.gpsTrackingHeaderBtnStyle]: gpsTracking,
          [classes.appHeaderBtnStyle]: app,
        })}
        headingTextClassName={classes.headerTitleStyle}
        heading={gpsTracking ? gpsTrackingHeaderTitle : "  "}
        headerBtn={headerBtnMarkup}
        content={
          <div
            className={classes.container}
            onClick={(e) => e.stopPropagation()}
          >
            {videoPlayer}

            <div
              className={clsx(classes.mapDiv, {
                [classes.gpsTrackingMapDiv]: gpsTracking,
              })}
            >
              {videoSettings?.["UseGpsInfo"] === "1" && (
                <MapboxLocationMapWrapper
                  location={location}
                  publicIcon={false}
                  // noMarker={!playVOD}
                  playback
                  onClose={onClose}
                  setIsMapboxFullscreen={setIsMapboxFullscreen}
                />
              )}
              {playVOD && videoSettings?.["UseGpsInfo"] === "0" && (
                <div className={classes.loadingDiv}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["0"]}
                  >
                    {t("GPS Location recording off")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};
