import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Modal } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { setSelectedHashtags, VOD } from "../../features/VOD/slice";
import { HashtagButton } from "../events/HashtagButton";
import SimpleBarReact from "simplebar-react";
import { PortalProps } from "@material-ui/core";
import { THEME } from "../../features/Theme/slice";

interface HashtagModalProps {
  open: boolean;
  onClose: () => void;
  container?: PortalProps["container"];
}

//tab기준
const tablet = 660;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    [theme.breakpoints.up(tablet)]: {
      minWidth: 470,
      maxWidth: 590,
      borderRadius: 12,
      // paddingLeft: 56,
      // paddingRight: 56,
    },
  },
  title: {
    height: 56,
  },
  paperScrollPaper: {
    maxHeight: "100%",
    [theme.breakpoints.up(tablet)]: {
      maxHeight: "inherit",
    },
  },
  paperWidthSm: {
    height: "100%",
    [theme.breakpoints.up(tablet)]: {
      height: "auto",
      // height: "calc(100vh - 458px)",
    },
  },
  content: {
    padding: "24px 14px 50px 14px",
    [theme.breakpoints.up(tablet)]: {
      padding: "8px 28px 8px 35px",
    },
  },
  headerBtn: {
    left: 13,
  },
  scrollbarStyle: {
    height: "100%",
    flex: 1,
    [theme.breakpoints.up(tablet)]: {
      overflowX: "hidden",
      maxHeight: "calc(100vh - 250px)",
    },
  },
  buttonWrap: {
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.up(tablet)]: {
      paddingTop: 36,
    },
  },
  modalContent: {
    padding: "28px 0px 8px",
    [theme.breakpoints.up(tablet)]: {
      padding: "0px 0px 24px",
    },
  },
}));

export const HashtagModal = ({
  open,
  onClose,
  container,
}: HashtagModalProps) => {  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const themeState = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles(themeState);

  const { hashtags, selectedHashtags } = useSelector(
    (state: RootState) => state[VOD]
  );

  const [newHashtags, setNewHashtags] = useState(selectedHashtags);

  const handleChangeSelected = useCallback(
    (id: number) => {
      if (_.includes(newHashtags, id)) {
        setNewHashtags(_.difference(newHashtags, [id]));
      } else {
        setNewHashtags(_.union(newHashtags, [id]));
      }
    },
    [newHashtags]
  );

  const handleSave = useCallback(() => {
    dispatch(setSelectedHashtags(newHashtags));
    onClose();
  }, [dispatch, newHashtags, onClose]);

  const contentMarkup = useMemo(() => {
    return (
      <>
        <div className={classes.content}>
          {_.chain(hashtags)
            .sortBy((tag) => tag.order)
            .map((tag) => (
              <HashtagButton
                key={tag.id}
                hashtag={tag}
                onClick={() => handleChangeSelected(tag.id)}
                checked={_.includes(newHashtags, tag.id)}
                darkMode={themeState.color === "dark"}
              />
            ))
            .value()}
        </div>

        <div className={classes.buttonWrap}>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            style={{ width: 127 }}
          >
            {t("Save")}
          </Button>
        </div>
      </>
    );
  }, [
    classes.buttonWrap,
    classes.content,
    handleChangeSelected,
    handleSave,
    hashtags,
    newHashtags,
    t,
    themeState.color,
  ]);

  return (
    <Modal
      open={open}
      container={container}
      onClose={onClose}
      className={classes.root}
      titleClassName={classes.title}
      paperScrollPaperClassName={classes.paperScrollPaper}
      contentClassName={classes.modalContent}
      paperWidthSmClassName={classes.paperWidthSm}
      heading={" "}
      content={
        <SimpleBarReact className={classes.scrollbarStyle}>
          {contentMarkup}
        </SimpleBarReact>
      }
      // RButton={t("Save")}
      // onClickPositive={onClose}
      btnCenter
      close
    />
  );
};
