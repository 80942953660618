import { Theme, makeStyles } from "@material-ui/core";
import { Typography } from "@thingsw/pitta-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChargingHistoryDayChart from "./ChargingHistoryDayChart";
import ChargingHistoryWeekChart from "./ChargingHistoryWeekChart";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "24px 16px",
    backgroundColor: (props: any) => props.colors.primary["0"],
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  toggleBg: {
    borderRadius: 9,
    backgroundColor: (props: any) => props.colors.primary["6"],
    display: "flex",
    padding: 2,
    gap: 2,
    marginBottom: 12,
    "& > button": {
      width: "100%",
      minHeight: 30,
      height: 30,
      border: "none",
      cursor: "pointer",
      borderRadius: 9,
      color: (props: any) => props.colors.primary["1"],
    },
  },
  activated: {
    boxShadow:
      "0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12)",
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  inactivated: {
    backgroundColor: (props: any) => props.colors.primary["6"],
    boxShadow: "none",
  },
  chartDiv: {
    minHeight: 340,
    minWidth: 343,
    width: "100%",
    borderRadius: 12,
    border: (props: any) => `1px solid ${props.colors.primary["6"]}`,
    padding: "20px 10px",
  },
}));

const BatteryChargingHistory = (props: any) => {
  const { colors } = useSelector((root: RootState) => root[THEME]);
  const classes = useStyles({ ...props, colors });
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState<"day" | "week">("day");

  const toggleDisplayMode = (mode: "day" | "week") => {
    setDisplayMode(mode);
  };

  return (
    <div className={classes.root}>
      <Typography variant="H6" htmlColor={colors.primary["1"]}>
        {t("Capacity History")}
      </Typography>
      <Typography
        variant="Caption"
        htmlColor={colors.primary["2"]}
        style={{
          marginBottom: 16,
        }}
        dangerouslySetInnerHTML={{
          __html: t("The graph displays capacity").replaceAll("\n", "<br/>"),
        }}
      />
      <div className={classes.toggleBg}>
        <button
          className={clsx({
            [classes.activated]: displayMode === "day",
            [classes.inactivated]: displayMode === "week",
          })}
          onClick={() => toggleDisplayMode("day")}
        >
          <Typography variant="SmallBold" htmlColor={colors.primary["1"]}>
            {t("Last 24 Hours")}
          </Typography>
        </button>
        <button
          onClick={() => toggleDisplayMode("week")}
          className={clsx({
            [classes.activated]: displayMode === "week",
            [classes.inactivated]: displayMode === "day",
          })}
        >
          <Typography variant="SmallBold" htmlColor={colors.primary["1"]}>
            {t("Last 7 Days")}
          </Typography>
        </button>
      </div>
      <div className={classes.chartDiv}>
        {displayMode === "day" && <ChargingHistoryDayChart />}
        {displayMode === "week" && <ChargingHistoryWeekChart />}
      </div>
    </div>
  );
};

export default BatteryChargingHistory;
