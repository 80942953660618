import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  WebMenuItem,
  MobileMenu,
  Menu,
  ZoomPanel,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import { detect } from "detect-browser";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import CheckIcon from "@material-ui/icons/Check";

import { useTranslation } from "react-i18next";

import * as turf from "@turf/turf";
import { RootState } from "../../features/store";
import {
  clearUndoList,
  GEOFENCE,
  popRedoList,
  setCurrentGeofence,
  setDrawingGeometry,
  setShowDetail,
  setUndoList,
} from "../../features/Geofence/slice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  CircleMode,
  DEFAULT_LOCATION,
  DEFAULT_MAP_STYLE,
  DARKMODE_MAP_STYLE,
  DEFAULT_ZOOM,
  DirectMode,
  DrawRectangle,
  GEOFENCE_LINE_LAYER_NAME,
  GEOFENCE_POLYGON_LAYER_NAME,
  initGeofenceSourcesAndLayers,
  LightColors,
  NO_INT_MAP_STYLE,
  SATELLITE_MAP_STYLE,
  updateGeofence,
  Webviewer,
} from "@thingsw/pitta-modules";
import { TopRightControl } from "@thingsw/pitta-liveview-module";
import React from "react";

import "mapbox-gl/dist/mapbox-gl.css";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  DragCircleMode,
  SimpleSelectMode,
  //@ts-ignore
} from "mapbox-gl-draw-circle";
import { setLoadingLocation, THEME } from "../../features/Theme/slice";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  map: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
  },
  tnpDiv: {
    ...(theme.direction === "rtl" ? { paddingRight: 44 } : { paddingLeft: 44 }),
  },
  appIcon: {
    fontSize: 15,
    color: LightColors.primary["1"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  textTransform: {
    textTransform: "none",
  },
  mobileMemuItem: {
    padding: theme.spacing(1.5, 2),
  },
}));

interface MapboxGeofenceMapProps {
  mode?: "view" | "add";
}

export const MapboxGeofenceMap = ({ mode }: MapboxGeofenceMapProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const firstFitBounds = useRef(true);

  const rootDivRef = useRef<HTMLDivElement>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const drawingGeoemtriesRef = useRef<turf.helpers.Feature<any, any>[]>([]);

  const themeState = useSelector((state: RootState) => state[THEME]);
  const { loadingLocation, color, colors } = themeState;

  const {
    type,
    geofences,
    drawingMode,
    fillColor,
    fillOpacity,
    undoList,
    currentGeofence,
    drawingGeometry,
    redrawing,
  } = useSelector((state: RootState) => state[GEOFENCE]);

  const [mapMode, setMapMode] = useState<"map" | "satellite">("map");
  const [drawInst, setDrawInst] = useState<MapboxDraw | null>(null);
  const [openLayer, setOpenLayer] = useState(false);
  const [mapInst, setMapInst] = useState<mapboxgl.Map>();

  const MAP_STYLE = useMemo(() => {
    const browser = detect();
    let style = DEFAULT_MAP_STYLE;
    if (browser?.name === "chrome" || browser?.name === "chromium-webview") {
      const vstring = browser?.version ?? "0.0";
      const version = parseInt(vstring.split(".")[0]);
      if (version <= 76) {
        style = NO_INT_MAP_STYLE;
      }
    }
    if (color === "dark") {
      style = DARKMODE_MAP_STYLE;
    }

    return style;
  }, [color]);

  useEffect(() => {
    if (!mapInst) return;
    if (!drawingGeometry) return;
    if (mode === "add") return;

    const bounds = turf.bbox(drawingGeometry) as [
      number,
      number,
      number,
      number
    ];

    map.current.fitBounds(bounds, {
      padding: mobile
        ? { top: 50, left: 50, right: 50, bottom: 50 }
        : { top: 200, left: 500, right: 200, bottom: 200 },
      duration: 0,
      animate: false,
    });
  }, [drawingGeometry, mapInst, mobile, mode]);

  useEffect(() => {
    if (drawingGeometry) {
      firstFitBounds.current = true;
    }
  }, [drawingGeometry]);

  useEffect(() => {
    drawingGeoemtriesRef.current = [...undoList];
    if (undoList.length === 1) {
      dispatch(setDrawingGeometry(undoList[0]));
    }
  }, [dispatch, undoList]);

  useEffect(() => {
    if (type === clearUndoList.type) {
      drawingGeoemtriesRef.current = [];
    } else if (type === popRedoList.type) {
      drawingGeoemtriesRef.current = [...undoList];
    }
  }, [type, undoList]);

  useEffect(() => {
    if (!map.current) return;
    if (!drawInst) return;
    if (!map.current.hasControl(drawInst)) return;
    const geom = _.last(undoList);
    console.log("MapboxGeofenceMap", "last", geom);
    if (geom) {
      const featureId = geom.id as string;
      if (featureId) {
        drawInst.delete(featureId);
      }
      drawInst.add(geom);
      drawInst.changeMode("simple_select", {
        featureIds: [geom.id],
      });
    }
  }, [drawInst, undoList]);

  useEffect(() => {
    if (!map.current) return;
    if (!drawInst) return;
    if (!map.current.hasControl(drawInst)) return;

    const changeStyle = () => {
      const layers = map.current?.getStyle().layers;
      const fillLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-polygon-fill"))
        .map((l) => l.id)
        .value();
      for (let layerId of fillLayerIds) {
        map.current?.setPaintProperty(layerId, "fill-color", fillColor);
        map.current?.setPaintProperty(layerId, "fill-opacity", fillOpacity);
      }
      const strokeLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-polygon-stroke"))
        .map((l) => l.id)
        .value();
      for (let layerId of strokeLayerIds) {
        map.current?.setPaintProperty(layerId, "line-color", fillColor);
      }
      const lineLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-line"))
        .map((l) => l.id)
        .value();
      for (let layerId of lineLayerIds) {
        map.current?.setPaintProperty(layerId, "line-color", fillColor);
        map.current?.setPaintProperty(layerId, "line-dasharray", undefined);
      }
      const vertexLayerIds = _.chain(layers)
        .filter(
          (l) =>
            l.id.startsWith("gl-draw-polygon-and-line-vertex-inactive") ||
            l.id.startsWith("gl-draw-point-active")
        )
        .map((l) => l.id)
        .value();
      for (let layerId of vertexLayerIds) {
        map.current?.setPaintProperty(layerId, "circle-color", fillColor);
      }
    };

    changeStyle();
  }, [drawInst, fillColor, fillOpacity]);

  // useEffect(() => {
  //   if (!drawInst) return;
  //   if (drawingGeometry === undefined) {
  //     // drawInst.deleteAll().set(new turf.helpers.featureCollection([]));
  //     drawInst.trash();
  //   }
  // }, [drawInst, drawingGeometry]);

  useEffect(() => {
    if (!mapInst) return;
    // console.log(
    //   "MapboxGeofenceMap",
    //   "mode",
    //   mode,
    //   "currentGeofence",
    //   currentGeofence,
    //   "redrawing",
    //   redrawing,
    //   map.current
    // );
    if (mode === "add" || currentGeofence) {
      let defaultMode = "draw_polygon";
      if (drawingMode === "polygon") {
        defaultMode = "draw_polygon";
      }
      if (drawingMode === "polyline") {
        defaultMode = "draw_line_string";
      }
      if (drawingMode === "circle") {
        defaultMode = "draw_circle";
      }
      if (drawingMode === "rectangle") {
        defaultMode = "draw_rectangle";
      }

      const draw = new MapboxDraw({
        displayControlsDefault: false, // 폴리곤 그리기 모드 숨김
        userProperties: true,
        keybindings: false,
        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          drag_circle: DragCircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
          draw_rectangle: DrawRectangle,
        },
        controls: {
          polygon: false, // 폴리곤 그리기 모드만 표시
        },
        defaultMode,
      });

      console.log("MapboxGeofenceMap", "draw", draw);

      map.current.addControl(draw);
      setDrawInst(draw);

      return () => {
        setDrawInst(null);
        map.current?.removeControl(draw);
      };
    }
  }, [mode, drawingMode, currentGeofence, redrawing, mapInst]);

  useEffect(() => {
    console.log("MapboxGeofenceMap", "update listener", drawInst);
    if (!map.current) return;
    if (!drawInst) return;
    const updateGeofence1 = (e: any) => {
      const geometry = e.features[0];
      if (e.type === "draw.create" || e.type === "draw.update") {
        const last = _.last(drawingGeoemtriesRef.current);
        console.log(
          "MapboxGeofenceMap",
          "last",
          last,
          "geometry",
          geometry,
          _.isEqual(last, geometry)
        );
        if (!last) {
          drawingGeoemtriesRef.current.push(geometry);
        } else if (!_.isEqual(last, geometry)) {
          drawingGeoemtriesRef.current.push(geometry);
        }
        dispatch(setUndoList([...drawingGeoemtriesRef.current]));
      }
      console.log("MapboxGeofenceMap", e.type, geometry);
    };

    const changeStyle = () => {
      if (!map.current) return;
      const layers = map.current.getStyle().layers;
      const fillLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-polygon-fill"))
        .map((l) => l.id)
        .value();
      for (let layerId of fillLayerIds) {
        map.current.setPaintProperty(layerId, "fill-color", fillColor);
        map.current.setPaintProperty(layerId, "fill-opacity", fillOpacity);
      }
      const strokeLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-polygon-stroke"))
        .map((l) => l.id)
        .value();
      for (let layerId of strokeLayerIds) {
        map.current.setPaintProperty(layerId, "line-color", fillColor);
      }
      const lineLayerIds = _.chain(layers)
        .filter((l) => l.id.startsWith("gl-draw-line"))
        .map((l) => l.id)
        .value();
      for (let layerId of lineLayerIds) {
        map.current.setPaintProperty(layerId, "line-color", fillColor);
        map.current.setPaintProperty(layerId, "line-dasharray", undefined);
      }
      const vertexLayerIds = _.chain(layers)
        .filter(
          (l) =>
            l.id.startsWith("gl-draw-polygon-and-line-vertex-inactive") ||
            l.id.startsWith("gl-draw-point-active")
        )
        .map((l) => l.id)
        .value();
      for (let layerId of vertexLayerIds) {
        map.current.setPaintProperty(layerId, "circle-color", fillColor);
      }
    };

    map.current.on("draw.create", updateGeofence1);
    map.current.on("draw.update", updateGeofence1);
    map.current.on("draw.delete", updateGeofence1);
    map.current.on("styledata", changeStyle);
    return () => {
      map.current?.off("draw:create", updateGeofence1);
      map.current?.off("draw:update", updateGeofence1);
      map.current?.off("draw:delete", updateGeofence1);
      map.current?.off("styledata", changeStyle);
    };
  }, [dispatch, drawInst, fillColor, fillOpacity]);

  useEffect(() => {
    if (!mapInst) return;
    if (geofences.length === 0) return;
    if (drawingGeometry) return;

    updateGeofence(
      mapInst,
      geofences,
      mode,
      currentGeofence,
      !firstFitBounds.current,
      mobile
    );
    firstFitBounds.current = false;
  }, [currentGeofence, drawingGeometry, geofences, mapInst, mobile, mode]);

  useEffect(() => {
    if (!mapContainer.current) return;

    console.log("MapboxGeofenceMap", "initmap", map.current);
    // initialize map only once
    if (map.current === null) {
      let options: mapboxgl.MapboxOptions = {
        container: mapContainer.current,
        style: MAP_STYLE,
        pitchWithRotate: false,
        dragRotate: false,
        touchPitch: false,
        center: DEFAULT_LOCATION,
        zoom: DEFAULT_ZOOM,
      };

      console.log("MapboxGeofenceMap", "initmap - 2", options);
      map.current = new mapboxgl.Map(options);
    }

    if (map.current !== null) {
      const onLoad = async () => {
        console.log("MapboxGeofenceMap", "onLoad");
        if (!map.current) return;
        setMapInst(map.current);
        map.current?.resize();

        initGeofenceSourcesAndLayers(map.current);

        // Icons.forEach((icon) => {
        //   if (!icon.string) {
        //     let customIcon = new Image(icon.width, icon.height);
        //     customIcon.onload = () => {
        //       console.log("MapboxGPSTrakingMap", "icon onload", icon.name);
        //       map.current?.addImage(icon.name, customIcon);
        //     };
        //     customIcon.src = icon.src;
        //   }

        //   if (icon.string) {
        //     const canvas = document.createElement("canvas");
        //     canvas.width = icon.width;
        //     canvas.height = icon.height;
        //     const ctx = canvas.getContext("2d");
        //     const DOMURL = window.URL || window.webkitURL || window;

        //     const svg = new Blob([icon.src], {
        //       type: "image/svg+xml;charset=utf-8",
        //     });
        //     const url = DOMURL.createObjectURL(svg);
        //     const img = new Image();
        //     img.onload = function () {
        //       ctx?.drawImage(img, 0, 0, icon.width, icon.height);
        //       // DOMURL.revokeObjectURL(url);

        //       map.current?.addImage(icon.name, img);
        //     };

        //     img.src = url;
        //   }
        // });
      };

      const onIdle = () => {};

      const eventMouseEnter = () => {
        const canvas = map.current?.getCanvas();
        if (canvas) canvas.style.cursor = "pointer";
      };
      const eventMouseLeave = () => {
        const canvas = map.current?.getCanvas();
        if (canvas) canvas.style.cursor = "";
      };

      const onClick = (e: any) => {
        const geometry = e.features[0];
        const properties = geometry.properties;
        let polygon: any = undefined;
        let rectangle: any = undefined;
        let circle: any = undefined;
        let polyline: any = undefined;

        //polygon
        if (properties.fenceType === 1) {
          polygon = JSON.parse(properties.polygon);
        }
        //rectangle
        if (properties.fenceType === 2) {
          rectangle = JSON.parse(properties.rectangle);
        }
        //circle
        if (properties.fenceType === 3) {
          circle = JSON.parse(properties.circle);
        }
        //polyline
        if (properties.fenceType === 4) {
          polyline = JSON.parse(properties.polyline);
        }

        const currentGeofence = {
          ...properties,
          alarm: properties.alarm ? JSON.parse(properties.alarm) : "[]",
          polygon,
          rectangle,
          circle,
          polyline,
          liveEventUpload: JSON.parse(properties.liveEventUpload ?? "[]"),
          psn: JSON.parse(properties.psn) ?? "[]",
          newPsn: JSON.parse(properties.newPsn) ?? "[]",
          setShape: JSON.parse(properties.setShape) ?? "",
          newRecording: JSON.parse(properties.newRecording) ?? undefined,
          speed: properties.speed ? JSON.parse(properties.speed) : undefined,
        };
        console.log("currentGeofence", currentGeofence);

        dispatch(setCurrentGeofence(currentGeofence));
        dispatch(setShowDetail(true));
      };

      map.current?.on(
        "mouseenter",
        [GEOFENCE_POLYGON_LAYER_NAME, GEOFENCE_LINE_LAYER_NAME],
        eventMouseEnter
      );
      map.current?.on(
        "mouseleave",
        [GEOFENCE_POLYGON_LAYER_NAME, GEOFENCE_LINE_LAYER_NAME],
        eventMouseLeave
      );

      map.current.touchZoomRotate.disableRotation();
      map.current.on("load", onLoad);
      map.current.on("idle", onIdle);
      map.current.on(
        "click",
        [GEOFENCE_POLYGON_LAYER_NAME, GEOFENCE_LINE_LAYER_NAME],
        onClick
      );

      return () => {
        map.current?.off("load", onLoad);
        map.current?.off("idle", onIdle);

        map.current?.off(
          "mouseenter",
          GEOFENCE_POLYGON_LAYER_NAME,
          eventMouseEnter
        );
        map.current?.off(
          "mouseenter",
          GEOFENCE_LINE_LAYER_NAME,
          eventMouseEnter
        );
        map.current?.off(
          "mouseleave",
          GEOFENCE_POLYGON_LAYER_NAME,
          eventMouseLeave
        );
        map.current?.off(
          "mouseleave",
          GEOFENCE_LINE_LAYER_NAME,
          eventMouseLeave
        );
        map.current?.off("click", GEOFENCE_POLYGON_LAYER_NAME, onClick);
        map.current?.off("click", GEOFENCE_LINE_LAYER_NAME, onClick);
      };
    }
  }, [MAP_STYLE, dispatch]);

  const mapboxMarkup = useMemo(() => {
    return <div ref={mapContainer} className={classes.map} />;
  }, [classes.map]);

  const mapTopRightMenuMarkup = useMemo(() => {
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          round
          layerRef={anchorRef}
          onLayers={() => setOpenLayer((o) => !o)}
          t={t}
          themeState={themeState}
        />
        {!mobile && (
          <Menu
            open={openLayer}
            onClickAway={() => setOpenLayer(false)}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            modifiers={{
              offset: {
                enabled: true,
                offset: "0, 4px",
              },
              preventOverflow: {
                enabled: false,
              },
            }}
          >
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "satellite",
              })}
              startIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map.current?.setStyle(MAP_STYLE);
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={clsx({
                [classes.tnpDiv]: mapMode === "map",
              })}
              startIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map.current?.setStyle(SATELLITE_MAP_STYLE);
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </Menu>
        )}
        {mobile && (
          <MobileMenu
            open={openLayer}
            onClose={() => setOpenLayer(false)}
            container={anchorRef.current}
            classes={{ root: classes.textTransform }}
          >
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "map" && <CheckIcon className={classes.appIcon} />
              }
              onClick={() => {
                setMapMode("map");
                map.current?.setStyle(MAP_STYLE);
                setOpenLayer(false);
              }}
            >
              {t("Map")}
            </WebMenuItem>
            <WebMenuItem
              className={classes.mobileMemuItem}
              endIcon={
                mapMode === "satellite" && (
                  <CheckIcon className={classes.appIcon} />
                )
              }
              onClick={() => {
                setMapMode("satellite");
                map.current?.setStyle(SATELLITE_MAP_STYLE);
                setOpenLayer(false);
              }}
            >
              {t("Satellite")}
            </WebMenuItem>
          </MobileMenu>
        )}
      </div>
    );
  }, [
    classes.topControlPane,
    classes.tnpDiv,
    classes.appIcon,
    classes.textTransform,
    classes.mobileMemuItem,
    mobile,
    openLayer,
    mapMode,
    t,
    MAP_STYLE,
    themeState,
  ]);

  return (
    <div className={classes.root} ref={rootDivRef}>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      {mapboxMarkup}
      {mapTopRightMenuMarkup}
      <ZoomPanel
        onMyLocation={(position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.current?.setCenter(pos);
        }}
        onZoomIn={() => map.current?.zoomIn()}
        onZoomOut={() => map.current?.zoomOut()}
        hideLocation={true}
        loading={loadingLocation}
        t={t}
        onLoading={(v) => {
          dispatch(setLoadingLocation(v));
        }}
        colors={colors}
      />
    </div>
  );
};
