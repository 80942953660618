import React, { useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { DashcamEmptyIcon, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";
import { LightColors } from "@thingsw/pitta-modules";

interface EmptyCamerasProps {
  variant?: "default" | "small";
  mode?:
    | "camera"
    | "member"
    | "routes"
    | "geofences"
    | "notifications"
    | "geofence-alert"
    | "liveView"
    | "groupCam";
  noCamTextClassName?: string;
  darkMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ variant }: EmptyCamerasProps) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...(variant === "small"
      ? { margin: "auto 0px" }
      : { margin: theme.spacing(2, 0) }),
    flexGrow: 1,
  }),
  noItemDiv: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? {}
      : {
          marginTop: "18.75vh",
        },
  noCamText: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? {
          marginTop: theme.spacing(0.5),
        }
      : {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        },
  noItemIcon: ({ variant }: EmptyCamerasProps) =>
    variant === "small"
      ? {
          width: 140,
          height: 140,
        }
      : {
          width: 200,
          height: 200,
        },
  cameraText: {
    marginTop: 0,
  },
  textCenter: {
    textAlign: "center",
    padding: "0 16px",
  },
}));

export const EmptyItems = (props: EmptyCamerasProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { variant, mode, noCamTextClassName, darkMode } = props;

  const icon = useMemo(() => {
    if (mode === "camera" || mode === "groupCam") {
      return (
        <DashcamEmptyIcon
          // mantis - 10505, 아이콘 색상 검정->회색 수정 (Leehj)
          style={{ width: 60, height: 60, color: LightColors.primary["4"] }}
        />
      );
    }
    return (
      <ReactSVG
        src={darkMode ? "/images/noItem-uxui-dark.svg" : "/images/noItem-uxui.svg"}
        className={classes.noItemDiv}
        beforeInjection={(svg) => {
          svg.classList.add(..._.split(classes.noItemIcon, " "));
        }}
      />
    );
  }, [classes.noItemDiv, classes.noItemIcon, mode, darkMode]);

  return (
    <div className={classes.root}>
      {icon}
      {mode === "notifications" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={clsx(classes.noCamText, noCamTextClassName)}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No notifications yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Notifications will show_")}
          </Typography>
        </>
      )}
      {mode === "geofences" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No geofences yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Geofences will show_")}
          </Typography>
        </>
      )}
      {mode === "routes" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No routes to_")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("It will show_")}
          </Typography>
        </>
      )}
      {mode === "member" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No members yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Members will show_")}
          </Typography>
        </>
      )}
      {mode === "geofence-alert" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No geofences yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Geofences will show_")}
          </Typography>
        </>
      )}
      {(mode === "camera" || mode === undefined) && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={clsx(classes.noCamText, classes.cameraText)}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No cameras yet")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Cameras will show_")}
          </Typography>
        </>
      )}
      {mode === "liveView" && (
        <>
          <Typography
            category="Default"
            variant={variant === "small" ? "BodyBold" : "H6"}
            className={classes.noCamText}
            htmlColor={LightColors.primary["2"]}
          >
            {t("No cameras to_")}
          </Typography>
          <Typography
            category="Default"
            variant={variant === "small" ? "Small" : "Body"}
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("You have added_")}
          </Typography>
        </>
      )}
      {mode === "groupCam" && (
        <>
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary["2"]}
          >
            {t("No cameras yet")}
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            className={classes.textCenter}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Please assign cameras_")}
          </Typography>
        </>
      )}
    </div>
  );
};
