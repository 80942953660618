import React, { useState } from "react";
import { Modal, Typography, RadioButton } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormControl, RadioGroup, TextField } from "@material-ui/core";
import {
  Webviewer,
  LightColors,
  SemanticDarkColors,
} from "@thingsw/pitta-modules";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import clsx from "clsx";

interface ReportViedoModalProps {
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: (reason: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 15,
    // minHeight: 543,
    "&>.MuiDialogActions-root": {
      justifyContent: "center",
      [theme.breakpoints.up(Webviewer.mobile)]: {
        justifyContent: "flex-end",
      },
    },
    "&>.buttonPadding": {
      padding: "0px 23px 24px",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 437,
      minHeight: "auto",
    },
  },
  radioFormControl: {
    marginBottom: theme.spacing(0.625),
  },
  titleDiv: {
    minHeight: 26,
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(3.125, 1.875, 0, 1.625) }
      : { padding: theme.spacing(3.125, 1.625, 0, 1.875) }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(3.125, 2.875, 0, 1.625) }
        : { padding: theme.spacing(3.125, 1.625, 0, 2.875) }),
    },
    color: (props: any) => props.colors.primary["1"],
  },
  contentWrap: {
    padding: theme.spacing(2, 3, 2.25, 2.375),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(2),
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
  },
  radioBtn: {
    ...(theme.direction === "rtl" ? { marginLeft: 3 } : { marginRight: 3 }),
  },
  focused: {
    "& > fieldset.MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px!important",
      borderColor: `${LightColors.primary["7"]}!important`,
    },
  },
  textArea: {
    minWidth: 212,
    [theme.breakpoints.down(Webviewer.smallMobile)]: {
      minWidth: 90,
    },

    "&>.MuiOutlinedInput-multiline": {
      padding: theme.spacing(1.5, 2.125, 2.375, 2.125),
    },
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(4.5) }
      : { marginLeft: theme.spacing(4.5) }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 361,
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(4.25) }
        : { marginLeft: theme.spacing(4.25) }),
    },
  },
  textRoot: {
    color: (props: any) => props.colors.primary["1"],
  },
  textOutline: {
    borderColor: (props: any) => props.colors.primary["3"],
  },
  infoText: {
    margin: theme.spacing(4.625, 0.75, 1.25, 0.75),
  },
  modalBtnDiv: {
    padding: "24px 16px 24px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0px 24px 24px",
      justifyContent: "flex-end!important",
    },
    [theme.breakpoints.down(325)]: {
      padding: "24px 8px 24px",
    },
  },
  closeStyle: {
    right: "13px",
    top: "19px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: "21px",
    },
  },
  btnStyle: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      minWidth: "151.5px",
    },
    [theme.breakpoints.down(Webviewer.smallMobile)]: {
      minWidth: "50%",
    },
  },
  btnRStyle: {
    backgroundColor: (props: any) =>
      props.color === "dark" ? SemanticDarkColors.primary[0] : "#FFFFFF",
    borderColor: (props: any) => props.colors.primary["4"],
    color: (props: any) => props.colors.primary["1"],
  },
}));

export const ReportVideoModal = ({
  open,
  loading,
  onClose,
  onClickNegative,
  onClickPositive,
}: ReportViedoModalProps) => {
  const { t } = useTranslation();
  const { color, colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ color, colors });
  const [reportType, setReportType] = useState<string>();
  const [etcText, setEtcText] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEtcText(value);
  };

  return (
    <Modal
      className={classes.root}
      // mantis - 10957, se에서 ui깨지는 이슈 수정 및 모바일 피그마 디자인 적용(Leehj)
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentWrap}
      actionClassName={classes.modalBtnDiv}
      closeStyle={classes.closeStyle}
      fullWidthBtn={true}
      LButtonClassName={clsx(classes.btnStyle, classes.btnRStyle)}
      RButtonClassName={clsx(classes.btnStyle)}
      open={open}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => {
        if (reportType === undefined) {
          return;
        }
        if (reportType === "Other reason") {
          onClickPositive?.(etcText);
        } else {
          onClickPositive?.(reportType);
        }
      }}
      heading={t("Report video")}
      close
      content={
        <div className={classes.contentDiv}>
          <FormControl className={classes.radioFormControl}>
            <RadioGroup
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              {/* 
              mantis : 9429 (SH)
              신고 모달 텍스트 수정
              */}
              <RadioButton
                className={classes.radioBtn}
                value="Graphic violence"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Graphic violence")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
              <RadioButton
                className={classes.radioBtn}
                value="Harassment or bullying"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Harassment or bullying")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
              <RadioButton
                className={classes.radioBtn}
                value="Spam or advertisement"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Spam or advertisement")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
              <RadioButton
                className={classes.radioBtn}
                value="Sexually explicit content"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Sexually explicit content")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
              {/* mantis - 10592, Privacy 항목 추가 */}
              <RadioButton
                className={classes.radioBtn}
                value="Privacy"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Privacy")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
              <RadioButton
                className={classes.radioBtn}
                value="Other reason"
                label={
                  <>
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["1"]}
                    >
                      {t("Other reason")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={colors.primary["2"]}
                    ></Typography>
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
          <TextField
            className={classes.textArea}
            id="outlined-textarea"
            placeholder={t("Reason for reporting")}
            multiline
            variant="outlined"
            rows={2}
            // mantis ID : 9351 (SH)
            // Report Radio Etc 일 떄만 TextField 가능하도록 수정
            disabled={reportType !== "Other reason"}
            value={etcText}
            onChange={handleChange}
            name="etcText"
            InputProps={{
              classes: {
                root: classes.textRoot,
                focused: classes.focused,
                notchedOutline: classes.textOutline,
              },
            }}
          />
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Report")}
      RButtonDisabled={reportType === undefined}
      loading={loading}
    />
  );
};
