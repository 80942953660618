import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { detect } from "detect-browser";
import {
  Fab,
  IconButton,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  clearGPSTrackingData,
  GPS,
  loadGeofenceAlert,
  loadGPSDriveData,
  loadGPSTrackingData,
  setCameraInfo,
  setCandiDrives,
  setFirstFitBounds,
  setSelectedDates,
  setSelectedDrive,
  setSelectedDrives,
  setSelectedTrack,
  setShowedDates,
  setShowTracks,
  setUpdateMapBounds,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";
import { loadCameras } from "../../features/Camera/slice";
import { USER } from "../../features/User/slice";
import { doDownload, getCloudLink, getEventLink } from "../../utils/VOD";
import { MobileDrawer } from "../MobileDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PERMISSION } from "../../features/Permission/slice";
import { useHistory } from "react-router-dom";
import { ScreenDefaultProps } from "../../hoc/withViewerTemplate";
import { MapboxGPSTrackingMap } from "../maps/MapboxGPSTrackingMap";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { GPSTrackingListPanel } from "./GPSTrackingListPanel";
import {
  ITrackData,
  LightColors,
  RESULT_CODE,
  Webviewer,
} from "@thingsw/pitta-modules";
import { GPSTrackingDatePanel } from "./GPSTrackingDatePanel";
import { VideoPlayerMapboxUXUIModal } from "../uxui/VideoPlayerMapboxUXUIModal";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  mapDiv: {
    height: "calc(100% - 56px)",
    position: "relative",
    backgroundColor: LightColors.primary["6"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "100%",
    },
  },
  loadingDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: `${LightColors.primary["1"]}5A`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularLoading: {
    color: LightColors.primary["7"],
  },
  modalTitle: {
    minHeight: 16,
  },
  listPanel: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "calc(100% - 8px)",
    margin: theme.spacing(1, 0, 0),
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2),
      height: "calc(100% - 108px)",
      ...(theme.direction === "rtl" ? { left: "unset" } : { right: "unset" }),

      boxShadow: "unset",
    },
    display: "flex",
  },
  routesPanel: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: LightColors.primary["0"],
    borderRadius: 12,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
  },
  routesPanelOpen: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  routesPanelClose: {
    width: 0,
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  backArrow: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  panelBodyDiv: {
    flex: 1,
    display: "flex",
    position: "relative",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  fabUXUI: {
    borderRadius: 12,
    border: `1px solid ${LightColors.primary["6"]}`,
    boxShadow: "none",
    marginTop: 20,
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  listDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-320px)",
    },
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(320px)",
    },
  },
  // 사파리 17.3 버전에서 datePanel 잔상 문제 수정 (13164)
  hideDateText: {
    zIndex: -100,
  },
}));

interface GPSTrackingPanelProps {
  mode?: "camera" | "fleet";
}

export const GPSTrackingPanel = ({
  mode,
  plan,
}: GPSTrackingPanelProps & ScreenDefaultProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as Theme;

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { permission } = useSelector((state: RootState) => state[PERMISSION]);
  const {
    type,
    loading,
    camera,
    selectedDrives,
    tracks,
    driveList,
    showTracks,
  } = useSelector((state: RootState) => state[GPS]);

  const { email, loginInfo, userProfile } = useSelector(
    (state: RootState) => state[USER]
  );

  const needToSetDrive = useRef(true);

  const browser = detect();

  // 사파리 17.3 버전에서 datePanel 잔상 문제 수정 (13164)
  let isSafari173 = false;
  if (browser && browser.name === "safari" && browser.version === "17.3.0") {
    isSafari173 = true;
  }

  const [openNotSupport, setOpenNotSupport] = useState(false);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [playTrack, setPlayTrack] = useState<ITrackData>();
  const [openPanel, setOpenPanel] = useState(true);

  useEffect(() => {
    if (mobile) {
      const body = document.getElementById("body-container");
      body?.setAttribute("style", "overflow:hidden;");
      return () => {
        body?.removeAttribute("style");
      };
    }
  }, [mobile]);

  useEffect(() => {
    if (!permission.gpsTrackingPerm) {
      history.goBack();
    }
  }, [history, permission.gpsTrackingPerm]);

  useEffect(() => {
    dispatch(loadCameras());
    // mantis - 84, 날짜를 지정하지 않았는데 showTracks이 true인 경우가 존재하여 렌더링하면 false로 (Leehj)
    dispatch(setShowTracks(false));
  }, [dispatch]);

  useEffect(() => {
    if (showTracks && camera) {
      dispatch(
        loadGeofenceAlert({
          psn: camera?.psn,
          drive_no_list: _.map(selectedDrives, (t) => t.drive_no),
        })
      );
    }
  }, [dispatch, camera, selectedDrives, showTracks, userProfile?.userType]);

  useEffect(() => {
    return () => {
      dispatch(setCameraInfo(undefined));
      dispatch(clearGPSTrackingData());
      dispatch(setSelectedDrives([]));
      dispatch(setSelectedDrive(undefined));
      dispatch(setSelectedTrack(undefined));
      // mantis - 84, 두번째 gps tracking 진입시 이전에 선택한 날짜가 남아있던 이슈. 렌더링시 빈배열로 set처리 (Leehj)
      dispatch(setCandiDrives([]));
      dispatch(setSelectedDates([]));
      dispatch(setShowedDates([]));
      dispatch(setUpdateMapBounds(true));
      dispatch(setFirstFitBounds(true));
      dispatch(setShowTracks(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      mode !== "fleet" &&
      camera &&
      driveList.length > 0 &&
      needToSetDrive.current
    ) {
      const dr = _.first(driveList);
      if (dr) {
        dispatch(setShowTracks(true));
        dispatch(setSelectedDrives([dr]));
        const selectedTrack = _.find(
          tracks,
          (d) => d.drive_no === selectedDrives[0].drive_no
        );
        dispatch(setSelectedDrive(selectedTrack));
        dispatch(setCandiDrives([dr]));
        const sdate = moment(dr.sdate.format("YYYYMMDD"), "YYYYMMDD")
          .set("hour", 12)
          .utc(false);
        const edate = moment(dr.edate.format("YYYYMMDD"), "YYYYMMDD")
          .set("hour", 12)
          .utc(false);

        console.log("sdate", sdate);
        let currentDate = moment(sdate); // 시작 날짜로 초기화
        let dates: moment.Moment[] = [];
        while (currentDate.isSameOrBefore(edate)) {
          dates.push(moment(currentDate));
          currentDate.add(1, "day"); // 다음 날짜로 이동
        }

        dispatch(setSelectedDates(dates));
        dispatch(setShowedDates(dates));
      }

      needToSetDrive.current = false;
    }
  }, [
    camera,
    dispatch,
    driveList,
    driveList.length,
    mode,
    selectedDrives,
    tracks,
  ]);

  // useEffect(() => {
  //   if (showTracks && needToSetDrive.current) {
  //     const selectedTrack = _.find(
  //       tracks,
  //       (d) => d.drive_no === selectedDrives[0].drive_no
  //     );
  //     dispatch(setSelectedDrive(selectedTrack));
  //     needToSetDrive.current = false;
  //   }
  // }, [dispatch, selectedDrives, showTracks, tracks]);

  const handleDownload = useCallback(
    async (data: ITrackData) => {
      console.log("onDownload", camera, loginInfo, email, data);
      if (camera && loginInfo && email) {
        const { user_token: userToken } = loginInfo;
        const { psn } = camera;
        let resp: any | undefined = undefined;
        let respCloud: any | undefined = undefined;
        // 8582 - 다운로드 순서 수정
        if (data?.rid_front) {
          resp = await getEventLink(email, userToken, psn, data.rid_front);
        } else if (data?.sub_front) {
          respCloud = await getCloudLink(email, userToken, psn, data.sub_front);
        } else if (data?.rid_rear) {
          resp = await getEventLink(email, userToken, psn, data.rid_rear);
        } else if (data?.sub_rear) {
          respCloud = await getCloudLink(email, userToken, psn, data.sub_rear);
        } else if (data?.rid_3) {
          resp = await getEventLink(email, userToken, psn, data.rid_3);
        } else if (data?.sub_3) {
          respCloud = await getCloudLink(email, userToken, psn, data.sub_3);
        }
        if (resp) {
          doDownload(resp.data["fileInfo"]["url"]);
        }
        if (respCloud) {
          const {
            resultcode,
            response: { presignedURL },
          } = respCloud.data as {
            resultcode: RESULT_CODE;
            response: { presignedURL: string };
          };
          if (resultcode === "BC_ERR_OK") {
            doDownload(presignedURL);
          }
        }
      }
    },
    [camera, email, loginInfo]
  );

  const handlePlay = useCallback(
    (data: ITrackData) => {
      if (plan === "Fleet plan") {
        setOpenPlayerModal(true);
        setPlayTrack(data);
      } else {
        setOpenNotSupport(true);
      }
    },
    [plan]
  );

  const listPanelMarkup = useMemo(() => {
    return (
      //
      <div className={classes.listPanel}>
        <div
          className={clsx(classes.routesPanel, {
            [classes.routesPanelOpen]: openPanel,
            [classes.routesPanelClose]: !openPanel,
          })}
        >
          <div className={classes.titleDiv}>
            {showTracks && (
              <IconButton
                className={classes.backArrow}
                onClick={() => {
                  // setOpenDrives([]);
                  dispatch(setShowTracks(false));
                  dispatch(setSelectedDrives([]));
                  // setUpdateTrack(false);
                  // onCloseDetail?.();
                  dispatch(clearGPSTrackingData());
                  dispatch(setSelectedDrive(undefined));
                  dispatch(setSelectedTrack(undefined));
                  // dispatch(setUpdateMapBounds(true));
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography category="Default" variant="H6">
              {t("Routes")}
            </Typography>
          </div>
          <div className={classes.panelBodyDiv}>
            <div
              className={clsx(classes.listDiv, {
                [classes.listDivHide]: showTracks,
                // 사파리 17.3 버전에서 datePanel 잔상 문제 수정 (13164)
                [classes.hideDateText]: showTracks && isSafari173,
              })}
            >
              <GPSTrackingDatePanel
               mode={mode} />
            </div>

            <div
              className={clsx(classes.listDiv, {
                [classes.detailDivHide]: !showTracks,
              })}
            >
              <GPSTrackingListPanel
                onChangePlayTrack={handlePlay}
                onDownload={handleDownload}
              />
            </div>
          </div>
        </div>

        {!mobile && (
          <Fab
            size="small"
            variant="rounded"
            className={clsx(classes.fabUXUI, {
              [classes.fabMargin]: openPanel,
            })}
            onClick={() => setOpenPanel((o) => !o)}
          >
            {(theme.direction === "rtl") !== openPanel ? (
              <ArrowLeftIcon />
            ) : (
              <ArrowRightIcon />
            )}
          </Fab>
        )}
      </div>
    );
  }, [
    classes.listPanel,
    classes.routesPanel,
    classes.routesPanelOpen,
    classes.routesPanelClose,
    classes.titleDiv,
    classes.backArrow,
    classes.panelBodyDiv,
    classes.listDiv,
    classes.listDivHide,
    classes.hideDateText,
    classes.detailDivHide,
    classes.fabUXUI,
    classes.fabMargin,
    openPanel,
    showTracks,
    t,
    isSafari173,
    mode,
    handlePlay,
    handleDownload,
    mobile,
    theme.direction,
    dispatch,
  ]);

  const gpsTrackingMapMarkup = useMemo(() => {
    return (
      <MapboxGPSTrackingMap
        psn={camera?.psn}
        onDownload={handleDownload}
        onPlay={handlePlay}
      />
    );
  }, [camera?.psn, handleDownload, handlePlay]);

  return (
    <div className={classes.mapDiv}>
      {gpsTrackingMapMarkup}
      {!mobile && listPanelMarkup}
      {mobile && (
        <MobileDrawer mode="gps-tracking">{listPanelMarkup}</MobileDrawer>
      )}

      {camera && openPlayerModal && (
        <VideoPlayerMapboxUXUIModal
          // TODO:: 플래그 구현
          gpsTracking
          mobile={mobile}
          mode={3}
          open={openPlayerModal}
          camera={camera}
          data={playTrack}
          onClose={() => {
            setPlayTrack(undefined);
            setOpenPlayerModal(false);
          }}
        />
      )}
      {openNotSupport && (
        <Modal
          open={openNotSupport}
          close
          heading=" "
          titleClassName={classes.modalTitle}
          onClose={() => setOpenNotSupport(false)}
          RButton={t("OK")}
          onClickPositive={() => setOpenNotSupport(false)}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("This feature is only_vom"),
              }}
            />
          }
        />
      )}
      {loading &&
        (type === loadGPSDriveData.type || type === loadGPSTrackingData.type) &&
        !showTracks && (
          <div className={classes.loadingDiv}>
            <CircularProgress
              className={classes.circularLoading}
              size={64}
              thickness={5}
            />
          </div>
        )}
    </div>
  );
};
