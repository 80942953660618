import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  Paper,
  Popper,
  RadioGroup,
  Theme,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Typography,
  Menu,
  CheckBox,
  Modal as TWModal,
  RadioButton,
  IconButton,
  Fab,
  Tooltip,
  theme as TWTheme,
  ZoomPanel,
} from "@thingsw/pitta-design-system";
import { Helmet } from "react-helmet";
import {
  exitFullscreen,
  isFullscreen,
  requestFullscreen,
} from "../../utils/GoogleMap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { AxiosError } from "axios";
import * as turf from "@turf/turf";
import Icons from "../../icons/EventmapIcon";
import clsx from "clsx";

import CloseIcon from "@material-ui/icons/Close";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ErrorIcon from "@material-ui/icons/Error";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { openToast } from "../../features/Toast/slice";
import { ReportVideoModal } from "../modals/ReportVideoModal";
import { MobileDrawer } from "../MobileDrawer";
import { SharedEventListItem } from "../events/SharedEventListItem";
import { PAYMENT } from "../../features/Payment/slice";
import { setLoadingLocation, THEME } from "../../features/Theme/slice";
import { ReactSVG } from "react-svg";
import { eventToText } from "../../utils/VOD";
import { AutoSizer, List, ListRowRenderer } from "react-virtualized";
import { renderListHelper } from "../../utils/List";
import { useQuery } from "../../hooks/useQuery";
import { detect } from "detect-browser";
import { EventVideoModal } from "../modals/EventVideoModal";
import ReactDOMServer from "react-dom/server";
import { loadHashtags, VOD } from "../../features/VOD/slice";

import "mapbox-gl/dist/mapbox-gl.css";
import { showGNB } from "../../utils/App";
import { useWindowSize } from "../../hooks";
import Scrollbars from "react-custom-scrollbars";

import FilterListIcon from "@material-ui/icons/FilterList";
import { SharedEventFilterModal } from "../uxui/SharedEventFilterModal";
import moment from "moment";
import { eventmapAxiosInst, sleep } from "../../utils";

//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {
  Webviewer,
  LightColors,
  DarkColors,
  SharedEvent,
  EventVideo,
  EVENTMAP_REV_TILE_URI,
  PermissionProps,
  sendMessageBack,
  SemanticDarkColors,
} from "@thingsw/pitta-modules";
import { TopRightControl } from "@thingsw/pitta-liveview-module";
import ResizeObserver from "resize-observer-polyfill";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

const DEFAULT_MAP_STYLE =
  "mapbox://styles/pittasoft-mapbox/cl63bx9a8000k15ql4r829si5?optimize=true";
// 영어외 폰트 렌더링 제대로 안되는 브라우져용 스타일
const NO_INT_MAP_STYLE =
  "mapbox://styles/pittasoft-mapbox/cl9pecumk000415s0iuot2ben?optimize=true";
const SATELLITE_MAP_STYLE = "mapbox://styles/mapbox/satellite-v9?optimize=true";
export const DARKMODE_MAP_STYLE =
  "mapbox://styles/pittasoft-mapbox/clfc7nlwg000d01qc66totbki";

interface EventType {
  impact: boolean;
  reckless: boolean;
  manual: boolean;
  normal: boolean;
  parking: boolean;
}

const EVENT_START_END_SOURCE_NAME = "event-start-end-source";
const EVENT_START_END_LAYER_NAME = "event-start-end-layer";
const EVENT_START_END_LAYER = (
  singleEvent?: boolean
): mapboxgl.CircleLayer => ({
  id: EVENT_START_END_LAYER_NAME,
  source: EVENT_START_END_SOURCE_NAME,
  type: "circle",
  minzoom: singleEvent ? 0 : 15,
  paint: {
    "circle-color": "#52B7FF",
    "circle-radius": 6,
    "circle-stroke-color": "#0079D1",
    "circle-stroke-width": 2,
  },
});

const EVENT_LINE_SOURCE_NAME = "event-line-source";
const EVENT_LINE_LAYER_NAME = "event-line-layer";

const EVENT_LINE_LAYER = (singleEvent?: boolean): mapboxgl.LineLayer => ({
  id: EVENT_LINE_LAYER_NAME,
  type: "line",
  source: EVENT_LINE_SOURCE_NAME,
  minzoom: singleEvent ? 0 : 15,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 6,
    "line-opacity": 0.8,
    "line-color": "#0094FF",
  },
});

const genDotImageProperty = (
  hoverId: number,
  clickId: number,
  singleEvnet: boolean
) => {
  return [
    "match",
    ["id"],
    clickId,
    "location-active",
    hoverId === clickId ? -1 : hoverId,
    "location-hover",
    singleEvnet ? "location-active" : "location",
  ] as mapboxgl.Expression;
};

const EVENT_SOURCE_NAME = "event-source";
const EVENT_DOT_LAYER_NAME = "event-dot-layer";

const EVENT_DOT_LAYER: mapboxgl.SymbolLayer = {
  id: EVENT_DOT_LAYER_NAME,
  source: EVENT_SOURCE_NAME,
  type: "symbol",
  // minzoom: 15,
  paint: {
    "icon-opacity-transition": {
      duration: 0,
    },
  },
  layout: {
    "icon-image": genDotImageProperty(-1, -2, false),
    "icon-rotate": ["get", "ang"],
    "icon-anchor": "bottom",
    "icon-offset": [0, 12],

    "icon-allow-overlap": true,
    "text-allow-overlap": true,
  },
};

const TILE_SOURCE_NAME = "event-tile-source";
const TILE_LAYER_LV1_NAME = "event-tile-layer-lv1";
const TILE_LAYER_LV2_NAME = "event-tile-layer-lv2";
const TILE_LAYER_LV3_NAME = "event-tile-layer-lv3";
const TILE_LAYER_LV4_NAME = "event-tile-layer-lv4";
const TILE_LAYER_LV5_NAME = "event-tile-layer-lv5";
const TILE_LAYER_HOVER_NAME = "event-tile-hover-layer";

const lineWidth: mapboxgl.Expression = [
  "step",
  ["zoom"],
  // zoom 0+
  3,
  // zoom 14+
  14,
  4,
  17,
  5,
  19,
  8,
];

const TILE_HOVER_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_HOVER_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 10,
    "line-color": "transparent",
  },
};

const TILE_LV1_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_LV1_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
    "line-sort-key": ["get", "color"],
  },
  paint: {
    "line-width": lineWidth,
    "line-color": "#FFCF25",
  },

  filter: ["==", "color", "1"],
};

const TILE_LV2_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_LV2_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
    "line-sort-key": ["get", "color"],
  },
  paint: {
    "line-width": lineWidth,
    "line-color": "#FF9900",
  },
  filter: ["==", "color", "2"],
};

const TILE_LV3_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_LV3_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
    "line-sort-key": ["get", "color"],
  },
  paint: {
    "line-width": lineWidth,
    "line-color": "#FF6B00",
  },
  filter: ["==", "color", "3"],
};

const TILE_LV4_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_LV4_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
    "line-sort-key": ["get", "color"],
  },
  paint: {
    "line-width": lineWidth,
    "line-color": "#F70000",
  },
  filter: ["==", "color", "4"],
};

const TILE_LV5_LAYER: mapboxgl.LineLayer = {
  id: TILE_LAYER_LV5_NAME,
  type: "line",
  source: TILE_SOURCE_NAME,

  "source-layer": "default",
  layout: {
    "line-join": "round",
    "line-cap": "round",
    "line-sort-key": ["get", "color"],
  },
  paint: {
    "line-width": lineWidth,
    "line-color": "#BB0000",
    // "line-color": "red",
  },
  filter: ["==", "color", "5"],
};

const DEFAULT_ZOOM = process.env.NODE_ENV === "development" ? 15 : 4;
const DEFAULT_LOCATION: mapboxgl.LngLatLike =
  process.env.NODE_ENV === "development"
    ? [127.1105433, 37.4032191]
    : [-101.008655, 39.123098];

const NO_GPS_LOCATIONS: mapboxgl.LngLatLike[] = [
  [-119.074156, 34.794922], // 캘리포니아
  [151.246647, -32.895083], // 시드니
  [-0.077812, 51.694016], // 영국
];

const NO_GPS_ZOOMS: number[] = [4.5, 5.5, 7];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  map: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  topControlPane: {
    position: "absolute",
    top: 0,
    ...(theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    padding: theme.spacing(2, 2, 2, 1),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    zIndex: 80,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    background: "#121216",
    border: "1px solid #505054",
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 4,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "auto",
    },
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 12px 6px 15px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "8px 8px 6px 9px",
    },
  },
  icon: {
    width: 48,
    height: 48,
    fill: "white",
    cursor: "pointer",
    "&:hover": {},
  },
  dateDiv: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1) }
      : { marginLeft: theme.spacing(1) }),
  },
  videoWrap: {
    position: "relative",
    height: "83%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      height: "auto",
      display: "block",
      padding: "0px 8px 8px 8px",
      alignItems: "unset",
    },
  },
  progressThumb: {
    display: "none",
  },
  controlDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  close: {
    width: 30,
    height: 30,
    padding: 0,
    color: LightColors.primary["0"],
    zIndex: 1010,
    ...(theme.direction === "rtl" ? { marginRight: 20 } : { marginLeft: 20 }),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
    },
  },
  formControlLabel: {
    marginLeft: 10,
    marginRight: 18,
    minHeight: 36,
    // mantis - 10200, 언어 한국어 일 때 video type 항목이 세로로 정렬되지 않는 이슈 수정(Leehj)
    width: "100%",
  },
  mobileFormLabel: {
    margin: theme.spacing(0, -0.75, 1.25),
  },
  menuText: {
    whiteSpace: "nowrap",
  },
  eventColorPanel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  eventColorBox: {
    width: 12,
    height: 12,
    borderRadius: 2,
    margin: 2,
  },
  eventColorIndicator: {
    display: "flex",
    "&:last-child": {
      ...(theme.direction === "rtl" ? { marginRight: 2 } : { marginLeft: 2 }),
    },
    "&:first-child": {
      ...(theme.direction === "rtl" ? { marginLeft: 2 } : { marginRight: 2 }),
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      "&:last-child": {
        ...(theme.direction === "rtl" ? { marginRight: 6 } : { marginLeft: 6 }),
      },
      "&:first-child": {
        ...(theme.direction === "rtl" ? { marginLeft: 6 } : { marginRight: 6 }),
      },
    },
  },
  eventNumberTitle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 8,
    },
  },
  eventNumberPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    ...(theme.direction === "rtl" ? { left: 46 } : { right: 46 }),
    margin: theme.spacing(2, 0.75, 4),
    padding: "4px 8px",
    backgroundColor: LightColors.primary["0"],
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 8,

    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(4) - 2,
      padding: "12px 12px 12px 14px",
      minWidth: 174,
      minHeight: 74,
    },
    zIndex: 80,
  },
  noHover: {
    "&:hover": {
      color: (props: any) => props.colors.primary["1"],
      backgroundColor: (props: any) => props.colors.primary["0"],
      "& svg path": {
        fill: (props: any) => props.colors.primary["1"],
      },
    },
  },
  noFocus: {
    "&:focus": {
      color: (props: any) => props.colors.primary["1"],
      backgroundColor: (props: any) => props.colors.primary["0"],
      "& svg path": {
        fill: (props: any) => props.colors.primary["1"],
      },
    },
  },
  sharedButton: {
    minWidth: 161,
    width: "fit-content",
    padding: theme.spacing(1.25, 2),
    borderRadius: 12,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    "& span span": {
      fontWeight: "400!important",
    },
  },
  sharedListPanel: {
    borderRadius: 12,
    position: "absolute",
    top: 0,
    backgroundColor: LightColors.primary["0"],
    margin: theme.spacing(2, 0),
    ...(theme.direction === "rtl" ? { left: 64 } : { right: 64 }),

    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl" ? { left: 104 } : { right: 104 }),
    },
    zIndex: 80,
  },
  eventListDiv: {
    // 웹뷰 내 공유 영상 사이즈 통일 375x716
    height: 716,
    // overflow: "auto",
    overflow: "inherit",
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    borderRadius: 8,
  },
  sharedModal: {
    borderColor: (props: any) => props.colors.primary["0"],
  },
  sharedModalContent: {
    padding: 0,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    borderColor: (props: any) => props.colors.primary["0"],
  },
  mobileLayerModalContent: {
    borderBottom: "none",
  },
  sharedModalContentWrap: {
    width: "-webkit-fill-available",
  },
  removeModalbg: {
    margin: 16,
    backgroundColor: (props: any) => props.colors.primary["0"],
    color: (props: any) => props.colors.primary["1"],
  },
  mapboxPopup: {
    zIndex: 90,
  },
  popupDiv: {
    backgroundColor: LightColors.primary["1"],
    color: DarkColors.primary["1"],
    minWidth: 170,
    padding: 12,
    borderRadius: 4,
  },
  eventPopup: {
    padding: 8,
  },
  popupPic: {
    width: 56,
    height: 56,
    marginRight: 4,
  },
  picDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  popupFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  popupTotal: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 11,
  },

  mobileMarkupContent: {
    display: "flex",
    justifyContent: "space-between",
  },

  mobileMarkupTotal: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
  mobileInfoThumbDiv: {
    position: "relative",
  },
  mobileInfoNumberDiv: {
    position: "absolute",
    top: 12,
    ...(theme.direction === "rtl" ? { left: 12 } : { right: 12 }),
    backgroundColor: LightColors.primary["1"],
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mobilePlayCtrlDiv: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  eventInfo: {
    position: "absolute",
    bottom: 30,
    left: 10,
    maxWidth: 600,
    backgroundColor: "white",
    wordBreak: "break-word",
  },
  bodyBold: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: "left",
    lineHeight: 1.5,
  },
  playerDiv: {
    position: "relative",
    width: 316,
    height: 178,
    marginTop: 8,
    cursor: "pointer",
    "&:hover > *": {
      visibility: "unset",
    },
  },
  playBtnDiv: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#13131C73",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // visibility: "hidden",
    "& > *": {
      fill: "#EEEEF3",
      "&:hover": {
        fill: "#0095E0",
      },
    },
  },
  playBtn: {
    fill: "#EEEEF3",
    "&:hover": {
      fill: "#0095E0",
    },
  },
  lineImgThumb: {
    borderRadius: 2,
    border: `1px solid ${LightColors.primary["0"]}`,
    position: "relative",
  },
  lineThumbDiv: {
    display: "flex",
  },
  video: {
    width: "100vw",
    height: "56.3vw",
    objectFit: "fill",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 490,
      height: 276,
    },
  },
  noSharedModalContentWrap: {
    width: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: (props: any) => props.colors.primary["0"],
    "& svg path:nth-child(1)": {
      fill: (props: any) => (props.color === "dark" ? "#636367" : undefined),
    },
    "& svg path:nth-child(2)": {
      fill: (props: any) => (props.color === "dark" ? "#d0d0d5" : undefined),
    },
  },
  iosModalContent: {
    paddingTop: 32,
  },
  modalBottom: {
    padding: theme.spacing(0.5, 2, 1.625, 3),
  },
  modalWrap: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: 235 }
        : { marginLeft: 235 }),
    },
  },
  eventmapModalContent: {
    padding: theme.spacing(3),
  },
  eventThumb: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundSize: "100%",
    border: "3.5px solid #FFFFFF",
    borderRadius: "45%",
  },
  myEventListDialogTitle: {
    padding: "13px 13px 10px 15px",
  },
  myEventListDialogTitleMobile: {
    display: "flex",
    alignItems: "center",
    padding: "7px 13px 2px 15px",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    "& > span": {
      fontWeight: "400!important",
    },
  },
  layerMobileModalHeaderBtn: {
    right: 15,
    top: 16,
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  mobileSharedVideosModalTitle: {
    minHeight: "auto",
    padding: "14px 10px 10px",
    display: "flex",
    alignItems: "center",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "17.5px 15px",
    },
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  filterWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    padding: "17.5px 16px",
    borderBottom: `1px solid ${LightColors.primary["6"]}!important`,
  },
  sharedListTitle: {
    marginTop: 2,
    "& span": {
      fontWeight: "400!important",
    },
  },
  sharedModalHeaderBtn: {
    top: 13,
    right: 11,
    "& svg": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  sharedModalHeaderText: {
    ...(theme.direction === "rtl" ? { paddingRight: 13 } : { paddingLeft: 13 }),
  },
  removeModalTitle: {
    padding: "24px 16px 0px",
    minHeight: 40,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 20px 2px",
      minHeight: 45,
    },
  },
  removeModalClose: {
    top: 13,
    right: 13,
  },
  removeModalContent: {
    padding: "0px 16px 29px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0px 24px 16px",
    },
    "& span": {
      color: (props: any) => `${props.colors.primary["1"]} !important`,
    },
  },
  removeModalBtnDiv: {
    padding: "0px 15px 24px",
    // mantis 10496 모바일 일 때 버튼 가운데로 이동(Leehj)
    justifyContent: "center",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "0px 25px 24px",
      justifyContent: "flex-end",
    },
  },
  removeBtn: {
    minWidth: 150,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minWidth: 139.5,
    },
    [theme.breakpoints.down(Webviewer.smallMobile)]: {
      minWidth: "50%",
    },
  },
  removeLeftBtn: {
    marginRight: 11,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: 16,
    },
  },
  layerMobileModalBtnDiv: {
    padding: "9px 24px 24px",
    justifyContent: "center",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  layerMobileModalLBtn: {
    minWidth: 167.5,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) =>
      props.color === "dark" ? SemanticDarkColors.primary[1] : "#FFFFFF",
    borderColor: (props: any) => props.colors.primary["4"],
  },
  layerMobileModalRBtn: {
    minWidth: 167.5,
  },
  desktopLayerMenuDiv: {
    minWidth: 176,
    borderRadius: 12,
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2);",
  },
  desktopLayerMenuPadding: {
    padding: "8px 0 0",
  },
  radioBtnLabel: {
    "& .MuiFormControlLabel-label": {
      color: (props: any) => props.colors.primary["1"],
    },
  },
}));

type StatProperty = {
  layer: string;
  total: number;
  color: string;
  impact: number;
  impactColor?: string;
  reckless: number;
  recklessColor?: string;
  manual: number;
  manualColor?: string;
  segmentId: number;
  drivingImpact?: number;
  overspeed?: number;
  braking?: number;
  acceleration?: number;
  cornering?: number;
  events?: string;
};

interface EventmapProps {
  singleEvent?: boolean;
  event?: GeoJSON.Feature;
  explore?: boolean;
  initBounds?: mapboxgl.LngLatBoundsLike;
  name?: string;
  initCenter?: mapboxgl.LngLatLike;
  initZoom?: number;
  initMapType?: "map" | "satellite";
}

export const EventMap = ({
  singleEvent,
  event,
  shareToEventmapPerm,
  explore,
  initBounds,
  name,
  initCenter,
  initZoom,
  initMapType,
}: EventmapProps & Partial<PermissionProps>) => {
  const [mapInfo, setMapInfo] = useState<{ zoom: number; bounds?: number[] }>({
    zoom: DEFAULT_ZOOM,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const query = useQuery();
  const windowSize = useWindowSize();

  const scrollBarRef = useRef<Scrollbars>(null);
  const eventListRef = useRef<List>(null);
  const lineClickedRef = useRef(false);
  const markersRef = useRef<
    (mapboxgl.Marker & {
      id: any;
    })[]
  >([]);
  const prevClickEventIdRef = useRef<number>(-2);
  const eventPopupRef = useRef<mapboxgl.Popup | null>(null);
  const popupRef = useRef<mapboxgl.Popup | null>(null);
  const eventListAnchorRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapTypeRef = useRef<"map" | "satellite">(initMapType ?? "map");
  const styleChangedRef = useRef(false);
  const map = useRef<mapboxgl.Map | null>(null);
  const { hashtags } = useSelector((state: RootState) => state[VOD]);
  const { userProfile, email, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const themeState = useSelector((state: RootState) => state[THEME]);
  const { direction, fontFamily, loadingLocation, color, colors } = themeState;
  const classes = useStyles({ color, colors });

  const [eventType, setEventType] = useState<EventType>({
    impact: true,
    reckless: true,
    manual: true,
    normal: true,
    parking: true,
  });
  const [eventPreType, setEventPreType] = useState<EventType>({
    impact: true,
    reckless: true,
    manual: true,
    normal: true,
    parking: true,
  });
  const eventTypeRef = useRef<EventType>(eventType);
  const [mapType, setMapType] = useState<"map" | "satellite">("map");
  const [mapPreType, setMapPreType] = useState<"map" | "satellite">("map");
  // 이벤트 영상 재생 모달
  const [open, setOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [openLayer, setOpenLayer] = useState(false);
  // shared events 삭제 모달 flag
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [statList, setStatList] = useState<StatProperty[]>([]);
  const [clickedLngLat, setClickedLngLat] = useState<mapboxgl.LngLatLike>();
  const [focusedLngLat, setFocusedLngLat] = useState<mapboxgl.LngLatLike>();
  // 삭제중
  const [removingEvent, setRemovingEvent] = useState(false);
  const [sharedVideosModal, setSharedVideosModal] = useState(false);
  const [events, setEvents] = useState<GeoJSON.Feature[]>([]);
  const [hoverEventId, setHoverEventId] = useState<number>(-1);
  const [clickEventId, setClickEventId] = useState<number>(-2);

  const [sharedEvents, setSharedEvents] = useState<SharedEvent[]>([]);
  const [myVideos, setMyVideos] = useState<EventVideo[]>([]);
  const [videoModalMode, setVideoModalMode] = useState<
    "map" | "my-videos" | "line"
  >("map");
  const [videoIndex, setVideoIndex] = useState(-1);
  const [lineVideoIndex, setLineVideIndex] = useState(-1);
  //삭제하거나 재생할 이벤트
  const [selectedEvent, setSelectedEvent] = useState<EventVideo>();
  const [loadingMyEvents, setLoadingMyEvents] = useState(false);

  const [openEventList, setOpenEventList] = useState(false);
  const [loadingReportModal, setLoadingReportModal] = useState(false);
  const [openReportVideoModal, setOpenReportVideoModal] = useState<boolean>(
    false
  );
  // 0: 권한 없음, 1: 권한 있음
  const [locPerm, setLocPerm] = useState<number>();
  const [openNoLocPermIOSModal, setOpenNoLocPermIOSModal] = useState(false);
  const [openNoLocPermModal, setOpenNoLocPermModal] = useState(false);
  const [openNoPermModal, setOpenNoPermModal] = useState(false);
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const [openTooltip, setOpenTooltip] = useState(false);
  const [openEventVideoModal, setOpenEventVideoModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [reportedVideoId, setReportedVideoId] = useState<number>();
  const [popupVertical, setPopupVertical] = useState<
    "top" | "bottom" | "center"
  >("center");
  const [popupHorizontal, setPopupHorizontal] = useState<
    "left" | "right" | "center"
  >("center");
  // my shared videos의 현재 스크롤 위치
  // const [myEventListScrollTop, setMyEventListScrollTop] = useState(0);
  const myEventListScrollTopRef = useRef(0);
  // video 모달 열었을 때, my shared videos의 현재 스크롤 위치
  const [scrollTopWhenOpen, seScrollTopWhenOpen] = useState(0);
  const [loadingThumb, setLoadingThumb] = useState(false);
  //PC에서 라인 팝업 열리는 flag
  const [openLinePopup, setOpenLinePopup] = useState(false);
  const [needToSendOpen, setNeedToSendOpen] = useState(false);

  useEffect(() => {
    // 10372 - 라인/이벤트 동시에 클릭되는 경우 openEventmap 한번만 발생하도록 수정 (mckim)
    if (needToSendOpen) {
      const browser = detect();
      const msg = {};
      if (browser?.name === "ios-webview") {
        //@ts-ignore
        webkit.messageHandlers.openEventmap.postMessage(msg);
      } else if (browser?.name === "chromium-webview") {
        //@ts-ignore
        window.Webviewer?.openEventmap(JSON.stringify(msg));
      }
      setNeedToSendOpen(false);
    }
  }, [needToSendOpen]);

  useEffect(() => {
    if (initMapType) {
      setMapType(initMapType);
    }
  }, [initMapType]);

  // 해시태그필터모달 open
  const [openEventFilter, setOpenEventFilter] = useState(false);
  // 해시태그이름과 "None"(해시태그가 없는 경우 일단 None으로)이 있는 배열
  const hashtagsNameArr = useMemo(() => {
    return _.concat(
      _.map(hashtags, (tag) => tag.name),
      ["None"]
    );
  }, [hashtags]);

  const [filterHashtag, setFilterHashtag] = useState<string[]>(hashtagsNameArr);

  useEffect(() => {
    if (!openEventVideoModal && !singleEvent && !explore) {
      setTimeout(() => {
        scrollBarRef.current?.scrollTop(scrollTopWhenOpen);
        // eventListRef.current?.Grid?.handleScrollEvent({
        //   scrollTop: scrollTopWhenOpen,
        //   scrollLeft: 0,
        // });
      }, 0);
    }
  }, [explore, openEventVideoModal, scrollTopWhenOpen, singleEvent]);

  // ios에서 영상 팝업 가로 -> 세로 모드 전환 후 팝업 닫으면 지도 하단 짤리는 문제 처리용 로직
  useEffect(() => {
    const ios = detect()?.name === "ios-webview";
    if (ios && windowSize) {
      setTimeout(() => {
        map.current?.resize();
      }, 350);
    }
  }, [windowSize]);

  //hash태그 목록 불러오기
  useEffect(() => {
    if (hashtags.length === 0) {
      dispatch(loadHashtags());
    }
  }, [dispatch, hashtags.length]);

  useEffect(() => {
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
        console.log("Eventmap", "resize", map.current);
        map.current?.resize();
      });
      resizeObserver.observe(document.body);
      return () => {
        resizeObserver.unobserve(document.body);
      };
    } else {
      const resizeListener = () => {
        map.current?.resize();
      };
      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      if (map.current) {
        console.log("Eventmap", "mapbox removed");
        map.current.remove();
        map.current = null;
      }
    };
  }, []);

  const sharedEventVideoHeaderMarkup = useMemo(() => {
    return (
      <div className={classes.filterWrap}>
        <div className={classes.sharedListTitle}>
          <Typography category="Default" variant="H6">
            {t("My Shared Videos")}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => setOpenEventFilter(true)}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FilterListIcon />
              {/* mantis - 10553, 필터변경시 오른쪽 상단 파란점 UI 추가 (Leehj) */}
              {filterHashtag.length !== hashtagsNameArr.length && (
                <div
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: LightColors.primary["7"],
                    position: "absolute",
                    top: -3,
                    right: -3,
                  }}
                />
              )}
            </div>
          </IconButton>
          <IconButton onClick={() => setOpenEventList(false)}>
            <CloseIcon style={{ marginLeft: 16 }} />
          </IconButton>
        </div>
      </div>
    );
  }, [
    classes.filterWrap,
    classes.sharedListTitle,
    filterHashtag.length,
    hashtagsNameArr.length,
    t,
  ]);

  const MAP_STYLE = useMemo(() => {
    const browser = detect();
    let style = DEFAULT_MAP_STYLE;
    if (browser?.name === "chrome" || browser?.name === "chromium-webview") {
      const vstring = browser?.version ?? "0.0";
      const version = parseInt(vstring.split(".")[0]);
      if (version <= 76) {
        style = NO_INT_MAP_STYLE;
      }
    }
    if (color === "dark") {
      style = DARKMODE_MAP_STYLE;
    }

    return style;
  }, [color]);

  useEffect(() => {
    console.log("Eventmap", "color", color);
  }, [color]);

  useEffect(() => {
    console.log("Eventmap", "update MAP_STYLE", MAP_STYLE, map.current);
    map.current?.setStyle(MAP_STYLE);
  }, [MAP_STYLE]);

  const clearRawLines = useCallback(() => {
    if (!map.current) return;
    const source = map.current.getSource(
      EVENT_LINE_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;
    const source2 = map.current.getSource(
      EVENT_START_END_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;

    if (source) {
      source.setData(turf.featureCollection([]));
    }
    if (source2) {
      source2.setData(turf.featureCollection([]));
    }
  }, []);
  useEffect(() => {
    if (!singleEvent) {
      //@ts-ignore
      window.backPressed = () => {
        console.log("EventMap", "backPressed", {
          openEventList,
          openEventVideoModal,
          sharedVideosModal,
          openLayer,
          openReportVideoModal,
          openNoLocPermIOSModal,
          openNoLocPermModal,
          clickEventId,
          "statList.length": statList.length,
        });
        if (openReportVideoModal) {
          return setOpenReportVideoModal(false);
        }
        if (openRemoveModal) {
          return setOpenRemoveModal(false);
        }
        if (openEventVideoModal) {
          // 영상 쟂생중에 backbutton 클릭되면 모달 닫음 10383 이슈 by mckim
          return setOpenEventVideoModal(false);
        }
        if (sharedVideosModal || openEventList) {
          setSharedVideosModal(false);
          showGNB();
          return setOpenEventList(false);
        }
        if (openLayer) {
          return setOpenLayer(false);
        }
        if (openNoLocPermIOSModal) {
          return setOpenNoLocPermIOSModal(false);
        }
        if (openNoLocPermModal) {
          return setOpenNoLocPermModal(false);
        }

        // 이벤트 클릭했을 떄 backbutton 클릭되면 그냥 돌아감 10383 이슈 by mckim
        // if (clickEventId !== -2) {
        //   setClickedLngLat(undefined);
        //   clearRawLines();
        //   setStatList([]);
        //   setClickEventId(-2);
        //   return;
        // }

        if (statList.length > 0) {
          return setStatList([]);
        }

        sendMessageBack();
      };
      return () => {
        //@ts-ignore
        window.backPressed = undefined;
      };
    }
  }, [
    clearRawLines,
    clickEventId,
    openEventList,
    openEventVideoModal,
    openLayer,
    openNoLocPermIOSModal,
    openNoLocPermModal,
    openRemoveModal,
    openReportVideoModal,
    sharedVideosModal,
    singleEvent,
    statList.length,
  ]);

  useEffect(() => {
    if (!map.current || !singleEvent) return;
    if (!event) {
      const source = map.current.getSource(
        EVENT_SOURCE_NAME
      ) as mapboxgl.GeoJSONSource;
      console.log("source", source);
      if (!source) return;
      setEvents([]);
      setClickEventId(-2);
      // 에러나서 임시로 조건추가
      if (source) {
        source.setData(turf.featureCollection([]));
      }
    }
  }, [event, singleEvent]);

  const initEvent = useCallback(() => {
    console.log(
      "Eventmap",
      "initEvent",
      "source",
      map.current,
      singleEvent,
      event
    );
    if (!map.current || !singleEvent || !event) return;

    const source = map.current.getSource(
      EVENT_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;
    console.log("source", source);
    if (!source) return;
    setEvents([event]);
    setClickEventId(event.id);
    // 에러나서 임시로 조건추가
    if (source) {
      source.setData(turf.featureCollection([event]));
    }
    if (event.properties && event.properties.linestring) {
      const line = turf.lineString(event.properties.linestring.coordinates);
      const bbox = turf.bbox(line);
      console.log("bbox", event.properties.linestring, line, bbox);
      map.current.fitBounds(
        [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ],
        { padding: { top: 20, bottom: 150, left: 20, right: 20 }, duration: 0 }
      );
    } else {
      map.current.jumpTo({
        center: event.geometry.coordinates,
        zoom: 16,
      });
    }
  }, [event, singleEvent]);

  useEffect(() => {
    if (!map.current) return;
    initEvent();
  }, [initEvent]);

  useEffect(() => {
    const loc_perm = query.get("loc_perm");
    if (loc_perm) {
      setLocPerm(parseInt(loc_perm));
    } else {
      setLocPerm(1);
    }
  }, [query]);

  const handleFailedLocation = useCallback(() => {
    const browser = detect();
    if (browser?.name === "ios-webview") {
      if (locPerm === 1) {
        setOpenNoLocPermIOSModal(true);
      } else {
        //@ts-ignore
        webkit.messageHandlers.alert.postMessage({
          reason: "NO_LOCATION_PERMISSION",
        });
      }
    } else if (browser?.name === "chromium-webview") {
      //@ts-ignore
      window.Webviewer?.alert(
        JSON.stringify({ reason: "NO_LOCATION_PERMISSION" })
      );
    } else {
      setOpenNoLocPermModal(true);
    }
  }, [locPerm]);

  // const changeEventFilter = useCallback((eventT: EventType) => {
  //   if (!map.current) return;
  //   console.log("Eventmap", "changeEventFilter", eventT, eventTypeRef.current);
  //   let colorName = "empty";
  //   if (eventT.impact && eventT.manual && eventT.reckless) {
  //     colorName = "color";
  //   } else if (eventT.impact && eventT.reckless) {
  //     colorName = "impactRecklessColor";
  //   } else if (eventT.impact && eventT.manual) {
  //     colorName = "impactManualColor";
  //   } else if (eventT.reckless && eventT.manual) {
  //     colorName = "recklessManualColor";
  //   } else if (eventT.impact) {
  //     colorName = "impactColor";
  //   } else if (eventT.reckless) {
  //     colorName = "recklessColor";
  //   } else if (eventT.manual) {
  //     colorName = "manualColor";
  //   }

  //   map.current.setFilter(TILE_LAYER_LV1_NAME, ["==", colorName, "1"]);
  //   map.current.setFilter(TILE_LAYER_LV2_NAME, ["==", colorName, "2"]);
  //   map.current.setFilter(TILE_LAYER_LV3_NAME, ["==", colorName, "3"]);
  //   map.current.setFilter(TILE_LAYER_LV4_NAME, ["==", colorName, "4"]);
  //   map.current.setFilter(TILE_LAYER_LV5_NAME, ["==", colorName, "5"]);
  // }, []);

  // useEffect(() => {
  //   changeEventFilter(eventType);
  // }, [changeEventFilter, eventType]);

  useEffect(() => {
    const getEventsForSegment = async (segmentId: number) => {
      setLoadingThumb(true);
      const [resp] = await Promise.all([
        eventmapAxiosInst.get(`/segments/${segmentId}/shared-events`),
        // 10387: api호출이 너무 빨리 끝나면 로딩이 보이지 않아서,
        // ui동작이 이상해 보여서, 최소 300ms간 로딩을 보여주기 위해서 추가 (mckim)
        sleep(300),
      ]);
      if (!resp) return;

      setSharedEvents(
        _.chain(resp.data)
          .map((event) => {
            return { ...event, id: event.videoId };
          })
          // .filter((e) => {
          //   let result = true;
          //   // if (!e.thumb1) {
          //   //   result = false;
          //   // }

          //   if (!eventType.impact && _.includes([2], e.type)) {
          //     result = false;
          //   }

          //   if (!eventType.reckless && _.includes([1, 3, 4, 5], e.type)) {
          //     result = false;
          //   }

          //   if (!eventType.manual && e.mode === "M") {
          //     result = false;
          //   }

          //   return result;
          // })
          .value()
      );
      setLoadingThumb(false);
    };
    const segmentId = _.first(statList)?.segmentId;
    if (segmentId) {
      getEventsForSegment(segmentId);
    }
  }, [statList, tokenType]);

  useEffect(() => {
    if (!map.current) return;
    console.log(
      "Eventmap",
      "EVENT_DOT_LAYER_NAME",
      EVENT_DOT_LAYER_NAME,
      hoverEventId,
      clickEventId
    );
    map.current.setLayoutProperty(
      EVENT_DOT_LAYER_NAME,
      "icon-image",
      genDotImageProperty(hoverEventId, clickEventId, !!singleEvent)
    );
  }, [clickEventId, hoverEventId, singleEvent]);

  const makeEventMarker = useCallback(
    (evt: any, clicked?: boolean) => {
      if (!map.current) return;
      const el = document.createElement("div");
      el.className = "marker";
      el.style.zIndex = "10";
      el.style.top = "-5px";
      el.style.left = "-25.5px";
      if (clicked) {
        el.style.zIndex = "70";
      }

      const contentDiv = document.createElement("div");
      contentDiv.style.display = "flex";
      // Event Map 아이콘 호버시 커서 모양 변경, 손모양 -> 손가락모양으로 변경(Leehj)
      contentDiv.style.cursor = "pointer";

      const thumbDiv = document.createElement("div");
      thumbDiv.style.position = "relative";
      thumbDiv.style.width = "50px";
      thumbDiv.style.height = "56px";
      thumbDiv.style.backgroundImage = `url('data:image/svg+xml;utf8,<svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.2441 48.9837C41.3137 46.5 49.5849 36.6122 49.5849 24.7925C49.5849 11.1 38.485 0 24.7925 0C11.0999 0 0 11.1 0 24.7925C0 36.7648 8.4861 46.7551 19.7716 49.0762C19.7817 49.094 19.7928 49.1116 19.8049 49.129L24.1267 55.5441C24.5362 56.152 25.43 56.152 25.8396 55.5441L30.1614 49.129C30.1936 49.0816 30.2209 49.0331 30.2441 48.9837Z" fill="white" stroke="${
        clicked ? "none" : "%23D4D4D5"
      }"/></svg>')`;
      if (clicked) {
        thumbDiv.style.filter = `drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.05)) drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08))`;
      }

      //thumbnail
      const thumb = document.createElement("div");

      thumb.style.display = "flex";
      thumb.style.alignItems = "center";
      thumb.style.justifyContent = "center";
      thumb.style.backgroundImage = `url(${evt.properties?.thumb1}), url(/images/eventmap-empty-thumb.png)`;
      thumb.style.width = `44px`;
      thumb.style.height = `44px`;
      thumb.style.backgroundSize = "200%";
      thumb.style.backgroundPosition = "center";
      thumb.style.backgroundRepeat = "no-repeat";
      thumb.style.borderRadius = "50%";
      thumb.style.position = "absolute";
      thumb.style.top = "3px";
      thumb.style.left = "3px";

      thumbDiv.appendChild(thumb);

      contentDiv.appendChild(thumbDiv);

      el.appendChild(contentDiv);

      if (clicked) {
        console.log("Eventmap", "clickedEvent", evt);
        contentDiv.style.filter = `drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.05)) drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08))`;

        //event details
        const details = document.createElement("div");
        details.style.height = "50px";
        details.style.marginLeft = "-25px";
        details.style.padding = "0 5px 0 27px";
        details.style.display = "flex";
        details.style.flexDirection = "column";
        details.style.justifyContent = "center";
        details.style.alignItems = "center";
        details.style.borderRadius = "0 6px 6px 0";
        details.style.backgroundColor = "#FFFFFF";

        const eventType = document.createElement("div");
        eventType.style.paddingBottom = "3px";
        eventType.style.paddingRight = "4px";
        const divider = document.createElement("div");
        divider.style.height = "1px";
        divider.style.width = "100%";
        divider.style.backgroundColor = LightColors.primary["6"];
        const eventHashtags = document.createElement("div");
        eventHashtags.style.minHeight = "16px";
        eventHashtags.style.paddingTop = "3px";

        eventType.innerHTML = ReactDOMServer.renderToString(
          <ThemeProvider
            theme={TWTheme({
              fontFamily,
              dir: direction,
              Colors: LightColors,
              breakpoints: {
                values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
              },
            })}
          >
            <Typography
              category="Default"
              variant="BodyBold"
              style={{ lineHeight: 1 }}
            >
              {t(eventToText(evt.properties.mode, evt.properties.type))}
            </Typography>
          </ThemeProvider>
        );
        const foundedHashtag = _.find(
          hashtags,
          (tag) => tag.id === evt.properties.hashtagId
        );

        console.log("foundedHashtag", foundedHashtag);
        if (foundedHashtag) {
          eventHashtags.innerHTML = ReactDOMServer.renderToString(
            <ThemeProvider
              theme={TWTheme({
                fontFamily,
                dir: direction,
                Colors: LightColors,
                breakpoints: {
                  values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
                },
              })}
            >
              <Typography
                category="Default"
                variant="Caption"
                style={{ display: "block" }}
              >
                {foundedHashtag.name}
              </Typography>
            </ThemeProvider>
          );
        }

        details.appendChild(eventType);
        details.appendChild(divider);
        details.appendChild(eventHashtags);
        contentDiv.appendChild(details);

        //play button
        const playBtn = document.createElement("div");
        // playBtn.style.display = "none";
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svg.setAttributeNS(null, "viewBox", "0 0 14 16");
        svg.setAttributeNS(null, "width", "14");
        svg.setAttributeNS(null, "height", "16");
        svg.setAttributeNS(null, "fill", "white");
        svg.innerHTML = `<path d="M12.9257 6.25073L3.06923 0.572777C1.72971 -0.186949 0.0703125 0.772703 0.0703125 2.31215V13.688C0.0703125 15.2275 1.72971 16.1872 3.06923 15.4274L12.9257 9.74947C14.2652 8.98974 14.2652 7.05044 12.9257 6.29071V6.25073Z" />`;
        svg.style.display = "block";

        playBtn.appendChild(svg);

        thumb.appendChild(playBtn);

        thumb.addEventListener("mouseenter", (e) => {
          svg.setAttributeNS(null, "fill", LightColors.primary["7"]);
        });
        thumb.addEventListener("mouseleave", (e) => {
          svg.setAttributeNS(null, "fill", "white");
        });
        thumb.addEventListener("click", (e) => {
          console.log("openEventVideoModal", "open");
          setVideoModalMode("map");
          setOpenEventVideoModal(true);
          // hideGNB();

          // const browser = detect();
          // if (browser?.name === "ios-webview") {
          //   //@ts-ignore
          //   return webkit.messageHandlers.test.postMessage({});
          // }
          // if (browser?.name === "chromium-webview") {
          //   //@ts-ignore
          //   window.Webviewer?.test();
          // }
        });
      }

      el.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setClickEventId(evt.properties.id);
        // const zoom = map.current?.getZoom() ?? 16;
        // if (zoom < 16) {
        //   map.current?.jumpTo({
        //     center: evt.geometry.coordinates,
        //     zoom: 16,
        //   });
        // }

        if (explore && clicked) {
          const browser = detect();
          const msg = { videoId: evt.properties.videoId };
          if (browser?.name === "ios-webview") {
            //@ts-ignore
            return webkit.messageHandlers.openEventmap.postMessage(msg);
          }
          if (browser?.name === "chromium-webview") {
            //@ts-ignore
            window.Webviewer?.openEventmap(JSON.stringify(msg));
          }
        }
      });
      el.addEventListener("mousemove", (e) => {
        setHovered(true);
      });
      el.addEventListener("mouseleave", (e) => {
        setHovered(false);
        setHoverEventId(-1);
      });

      // Add markers to the map.
      const marker = new mapboxgl.Marker(el, {
        // anchor: "bottom",
        anchor: "bottom-left",
        offset: [0, 4],
      })
        .setLngLat(evt.geometry.coordinates)
        .addTo(map.current);
      return Object.assign(marker, { id: evt.properties.id });
    },
    [direction, explore, fontFamily, hashtags, t]
  );

  useEffect(() => {
    if (singleEvent) return;
    const markerEvents = _.filter(events, (evt) => evt.properties?.hasVideo);
    //@ts-ignore
    const eventIds = _.map(markerEvents, (m) => m.properties?.id);
    const markerIds = _.map(markersRef.current, (m) => m.id);
    const exists = _.chain(eventIds)
      .intersection(markerIds)
      .without(clickEventId)
      .without(prevClickEventIdRef.current)
      .value();
    const added = _.chain(eventIds)
      .difference(markerIds)
      .without(clickEventId)
      .without(prevClickEventIdRef.current)
      .value();
    const deleted = _.chain(markerIds)
      .difference(eventIds)
      .without(clickEventId)
      .without(prevClickEventIdRef.current)
      .value();
    console.log("exists", exists, "added", added, "deleted", deleted);

    let updatedMarker: any | undefined = undefined;
    let prevMarker: any | undefined = undefined;
    if (
      prevClickEventIdRef.current >= 0 &&
      prevClickEventIdRef.current !== clickEventId
    ) {
      _.find(
        markersRef.current,
        (m) => m.id === prevClickEventIdRef.current
      )?.remove();
      const evt = _.find(
        markerEvents,
        (evt) => evt.properties?.id === prevClickEventIdRef.current
      );
      if (evt) {
        prevMarker = makeEventMarker(evt);
      }
    }
    if (clickEventId >= 0) {
      _.find(markersRef.current, (m) => m.id === clickEventId)?.remove();
      const evt = _.find(
        markerEvents,
        (evt) => evt.properties?.id === clickEventId
      );
      if (evt) {
        updatedMarker = makeEventMarker(evt, true);
      }
    }
    prevClickEventIdRef.current = clickEventId;

    _.chain(markersRef.current)
      .filter((m) => _.includes(deleted, m.id))
      .forEach((m) => m.remove())
      .value();

    const markers = _.chain(markerEvents)
      .filter((m) => _.includes(added, m.properties?.id))
      .map((m) => makeEventMarker(m))
      .compact()
      .value();
    markersRef.current = _.compact([
      ..._.filter(markersRef.current, (m) => _.includes(exists, m.id)),
      ...markers,
      updatedMarker,
      prevMarker,
    ]);
  }, [clickEventId, events, makeEventMarker, singleEvent]);

  const handleVideoTypeChange = useCallback(() => {
    if (!map.current || singleEvent) return;
    const source = map.current.getSource(
      EVENT_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;
    if (mapInfo.bounds) {
      const center = [
        //@ts-ignore
        (mapInfo.bounds[0] + mapInfo.bounds[2]) / 2,
        //@ts-ignore
        (mapInfo.bounds[1] + mapInfo.bounds[3]) / 2,
      ];
      console.log(
        "Eventmap",
        "updateMapInfo",
        source,
        mapInfo,
        JSON.stringify({ coordinates: center, type: "Point" })
      );
    }

    if (source) {
      const getEvents = async () => {
        if (mapInfo.bounds && map.current) {
          const eventTypes: string[] = [];
          if (eventType.impact) {
            eventTypes.push("impact");
          }
          if (eventType.reckless) {
            eventTypes.push("reckless");
          }
          if (eventType.manual) {
            eventTypes.push("manual");
          }
          if (eventType.normal) {
            eventTypes.push("normal");
          }
          if (eventType.parking) {
            eventTypes.push("parking");
          }
          const resp = await eventmapAxiosInst.get(
            `/events?bounds=${mapInfo.bounds.join(",")}&events=${JSON.stringify(
              eventTypes
            )}&zoom=${mapInfo.zoom}&mobile=${mobile}`
          );
          if (!resp) return;
          if (mapInfo.zoom <= 14) {
            const data = _.chain(resp.data)
              .sortBy(["properties.like", "properties.view"])
              .reverse()
              .slice(0, 15)
              .value() as any[];
            // console.log("event below 14", data);

            setEvents(data);
            source.setData(turf.featureCollection(data));
          } else {
            setEvents(resp.data);
            source.setData(turf.featureCollection(resp.data));
          }
        }
      };

      getEvents();
      // if (mapInfo.zoom < 16) {
      //   setClickedLngLat(undefined);
      //   setClickEventId(-2);
      //   clearRawLines();
      // }
    }
  }, [
    eventType.impact,
    eventType.manual,
    eventType.normal,
    eventType.parking,
    eventType.reckless,
    mapInfo,
    mobile,
    singleEvent,
  ]);

  useEffect(() => {
    // mantis - 10200, 모바일 일 때는 OK버튼 눌러야 필터 적용되도록 수정(Leehj)
    if (!(mobile && openLayer)) {
      handleVideoTypeChange();
    }
  }, [handleVideoTypeChange, mobile, openLayer]);

  useEffect(() => {
    if (events.length > 0) {
      const eventMouseMoveListener = (e: any) => {
        if (!map.current) return;
        if (e.features.length > 0) {
          const id = e.features[0].properties?.id;
          setHoverEventId(id);
          // Event Map 아이콘 호버시 커서 모양 변경, 손모양 -> 손가락모양으로 변경(Leehj)
          map.current.getCanvas().style.cursor = "pointer";
        }
      };

      const eventMouseLeaveListener = (e: any) => {
        if (!map.current) return;
        // Event Map 아이콘에서 떠나면 다시 이전 커서모양으로 변경, 손 -> 손바닥모양(Leehj)
        map.current.getCanvas().style.cursor = "";
        setHoverEventId(-1);
      };

      const eventClickListener = (e: any) => {
        if (!map.current) return;
        console.log(
          "Eventmap",
          "eventClickListener",
          e.features,
          e.features[0].geometry
        );
        const zoom = map.current.getZoom();
        if (e.features.length > 0) {
          const center = e.features[0].geometry.coordinates;
          if (zoom < 16 && !explore) {
            map.current.jumpTo({
              center,
              zoom: 16,
            });
          }

          const feature = e.features[0];
          const properties = feature.properties;
          const id = properties?.id;

          if (explore) {
            if (!properties.hasVideo) {
              setNeedToSendOpen(true);
            }
          }

          setClickEventId(id);
          setClickedLngLat(e.lngLat);
        }
      };

      if (map.current !== null) {
        // initialize map only once
        if (!mobile && !singleEvent) {
          map.current.on(
            "mousemove",
            EVENT_DOT_LAYER_NAME,
            eventMouseMoveListener
          );
          map.current.on(
            "mouseleave",
            EVENT_DOT_LAYER_NAME,
            eventMouseLeaveListener
          );
        }

        map.current.on("click", [EVENT_DOT_LAYER_NAME], eventClickListener);

        return () => {
          if (!mobile) {
            map.current?.off(
              "mousemove",
              EVENT_DOT_LAYER_NAME,
              eventMouseMoveListener
            );
            map.current?.off(
              "mouseleave",
              EVENT_DOT_LAYER_NAME,
              eventMouseLeaveListener
            );
          }

          map.current?.off("click", EVENT_DOT_LAYER_NAME, eventClickListener);
          // map.current?.off(
          //   "click",
          //   EVENT_SYMBOL_LAYER_NAME,
          //   eventClickListener
          // );
        };
      }
    }
  }, [hoverEventId, events.length, mobile, singleEvent, explore]);

  const initSources = useCallback(() => {
    if (!map.current) return;
    if (!map.current.getSource(TILE_SOURCE_NAME)) {
      map.current.addSource(TILE_SOURCE_NAME, {
        type: "vector",
        tiles: [`${EVENTMAP_REV_TILE_URI}/tiles/{z}/{x}/{y}.pbf`],
      });
    }
    if (!map.current.getSource(EVENT_LINE_SOURCE_NAME)) {
      map.current.addSource(EVENT_LINE_SOURCE_NAME, {
        type: "geojson",
        data: turf.featureCollection([]),
      });
    }

    if (!map.current.getSource(EVENT_SOURCE_NAME)) {
      map.current.addSource(EVENT_SOURCE_NAME, {
        type: "geojson",
        data: turf.featureCollection([]),
      });
    }

    if (!map.current.getSource(EVENT_START_END_SOURCE_NAME)) {
      map.current.addSource(EVENT_START_END_SOURCE_NAME, {
        type: "geojson",
        data: turf.featureCollection([]),
      });
    }
  }, []);

  const initLayers = useCallback(() => {
    if (!map.current) return;
    if (!singleEvent && !map.current.getLayer(TILE_LAYER_HOVER_NAME)) {
      map.current.addLayer(TILE_HOVER_LAYER);
    }
    if (!map.current.getLayer(TILE_LAYER_LV1_NAME)) {
      map.current.addLayer(TILE_LV1_LAYER);
    }
    if (!map.current.getLayer(TILE_LAYER_LV2_NAME)) {
      map.current.addLayer(TILE_LV2_LAYER);
    }
    if (!map.current.getLayer(TILE_LAYER_LV3_NAME)) {
      map.current.addLayer(TILE_LV3_LAYER);
    }
    if (!map.current.getLayer(TILE_LAYER_LV4_NAME)) {
      map.current.addLayer(TILE_LV4_LAYER);
    }
    if (!map.current.getLayer(TILE_LAYER_LV5_NAME)) {
      map.current.addLayer(TILE_LV5_LAYER);
    }

    // changeEventFilter(eventTypeRef.current);

    if (!map.current.getLayer(EVENT_LINE_LAYER_NAME)) {
      map.current.addLayer(EVENT_LINE_LAYER(singleEvent));
    }
    if (!map.current.getLayer(EVENT_START_END_LAYER_NAME)) {
      map.current.addLayer(EVENT_START_END_LAYER(singleEvent));
    }

    if (!map.current.getLayer(EVENT_DOT_LAYER_NAME)) {
      if (EVENT_DOT_LAYER.layout) {
        EVENT_DOT_LAYER.layout["icon-image"] = genDotImageProperty(
          -1,
          -2,
          !!singleEvent
        );
      }
      map.current.addLayer(EVENT_DOT_LAYER);
    }
    Icons.forEach((icon) => {
      let customIcon = new Image(icon.width, icon.height);
      customIcon.onload = () => map.current?.addImage(icon.name, customIcon);
      customIcon.src = icon.src;
    });
  }, [singleEvent]);

  const handleMapTypeChange = useCallback(() => {
    if (!map.current) return;
    if (color === "dark") {
      map.current.setStyle(DARKMODE_MAP_STYLE);
      styleChangedRef.current = true;
    } else if (mapType === "map") {
      map.current.setStyle(DEFAULT_MAP_STYLE);
      styleChangedRef.current = true;
      // initLayers();
    } else {
      map.current.setStyle(SATELLITE_MAP_STYLE);
      styleChangedRef.current = true;
      // initLayers();
    }
  }, [mapType, color]);

  useEffect(() => {
    if (!mobile) {
      handleMapTypeChange();
    }
  }, [handleMapTypeChange, mobile]);

  useEffect(() => {
    const listener = () => {
      markersRef.current = [];
      initSources();
      initLayers();
      initEvent();
      map.current?.resize();
    };

    const onIdle = () => {
      if (map.current) {
        if (styleChangedRef.current) {
          styleChangedRef.current = false;
          initSources();
          initLayers();
          setMapInfo((b) => {
            return { ...b };
          });
        } else {
          setMapInfo((b) => {
            //@ts-ignore
            const zoom = map.current.getZoom();
            //@ts-ignore
            const bounds = _.flattenDeep(map.current.getBounds().toArray());
            const newMapInfo = { zoom, bounds };

            console.log("currentZoom", zoom);

            if (_.isEqual(newMapInfo, b)) {
              return b;
            } else {
              return newMapInfo;
            }
          });
        }
      }
    };

    const lineMouseMoveListener = (e: any) => {
      if (!map.current) return;
      if (lineClickedRef.current) return;
      // setHoverEventId(-1);
      setOpenLinePopup(true);
      const padding = 150;
      const leftLimit = padding;
      const rightLimit = (mapContainer.current?.clientWidth ?? 0) - padding;
      const topLimit = padding;
      const bottomLimit = (mapContainer.current?.clientHeight ?? 0) - padding;
      const locX = e.point.x;
      const locY = e.point.y;

      if (leftLimit > locX) {
        setPopupHorizontal("left");
      } else if (rightLimit < locX) {
        setPopupHorizontal("right");
      } else {
        setPopupHorizontal("center");
      }

      if (topLimit > locY) {
        setPopupVertical("top");
      } else if (bottomLimit < locY) {
        setPopupVertical("bottom");
      } else {
        setPopupVertical("center");
      }

      console.log(
        "Eventmap",
        "lineMouseMoveListener",
        mapContainer.current?.clientWidth,
        mapContainer.current?.clientHeight,
        e
      );
      setStatList((list) => {
        const f = _.chain(e.features)
          .sortBy((f) => -f.properties.total)
          .first()
          .value();
        const newList = [
          { layer: f.layer.id, ...f.properties },
        ] as StatProperty[];
        // console.log(
        //   "Eventmap",
        //   "lineMouseMoveListener",
        //   "newList",
        //   newList,
        //   "list",
        //   list
        // );
        if (_.isEqual(newList, list)) {
          return list;
        } else {
          return newList;
        }
      });
      setFocusedLngLat(e.lngLat);
    };

    const lineMouseLeaveListener = (e: any) => {
      if (!map.current) return;
      if (lineClickedRef.current) return;
      // console.log("Eventmap", "lineMouseLeaveListener");
      // setStatList([]);
      setOpenLinePopup(false);
      setFocusedLngLat(undefined);
    };

    const lineClickListener = (e: any) => {
      console.log("Eventmap", "lineClickListener", e.features);
      if (!map.current) return;

      if (explore) {
        setNeedToSendOpen(true);
      }

      setOpenLinePopup(true);
      lineClickedRef.current = true;
      setStatList((list) => {
        const f = _.chain(e.features)
          .sortBy((f) => -f.properties.total)
          .first()
          .value();
        const newList = [
          { layer: f.layer.id, ...f.properties },
        ] as StatProperty[];
        if (_.isEqual(newList, list)) {
          return list;
        } else {
          setFocusedLngLat(e.lngLat);
          return newList;
        }
      });
    };

    const mapClickListener = (e: any) => {
      console.log("Eventmap", "mapClickListener");
      setClickEventId(-2);
      setHoverEventId(-1);
      setOpen(false);
      clearRawLines();
      setOpenLinePopup(false);
      setStatList([]);
      lineClickedRef.current = false;
    };

    if (!mapContainer.current) return;

    console.log("Eventmap", "initmap", map.current);
    if (map.current === null) {
      let options: mapboxgl.MapboxOptions = {
        container: mapContainer.current,
        style: MAP_STYLE,
        minZoom: 3,
        maxZoom: 20,
        pitchWithRotate: false,
        dragRotate: false,
        touchPitch: false,
      };
      if (initCenter && initZoom) {
        options.center = initCenter;
        options.zoom = initZoom;
      } else if (initBounds) {
        options.bounds = initBounds;
      } else {
        options.center = DEFAULT_LOCATION;
        options.zoom = DEFAULT_ZOOM;
      }
      console.log("Eventmap", "initmap - 2", options);
      map.current = new mapboxgl.Map(options);
    }

    if (map.current !== null) {
      // initialize map only once
      map.current.touchZoomRotate.disableRotation();

      map.current.on("load", listener);
      map.current.on("idle", onIdle);

      if (!mobile && !singleEvent) {
        map.current.on(
          "mousemove",
          TILE_LAYER_HOVER_NAME,
          lineMouseMoveListener
        );
        map.current.on(
          "mouseleave",
          TILE_LAYER_HOVER_NAME,
          lineMouseLeaveListener
        );
      }
      if (!singleEvent) {
        // 순서 바뀌면 안됨.
        map.current.on("click", mapClickListener);
        map.current.on("click", TILE_LAYER_HOVER_NAME, lineClickListener);
      }

      return () => {
        map.current?.off("load", listener);
        map.current?.off("idle", onIdle);

        if (!mobile && !singleEvent) {
          map.current?.off(
            "mousemove",
            TILE_LAYER_HOVER_NAME,
            lineMouseMoveListener
          );
          map.current?.off(
            "mouseleave",
            TILE_LAYER_HOVER_NAME,
            lineMouseLeaveListener
          );
        }
        if (!singleEvent) {
          map.current?.off("click", TILE_LAYER_HOVER_NAME, lineClickListener);
          map.current?.off("click", mapClickListener);
        }
      };
    }
  }, [
    MAP_STYLE,
    clearRawLines,
    event?.geometry?.coordinates,
    explore,
    initBounds,
    initCenter,
    initEvent,
    initLayers,
    initSources,
    initZoom,
    mobile,
    singleEvent,
  ]);

  useEffect(() => {
    const updateEventPopup = () => {
      if (!map.current) return;
      if (!clickedLngLat || clickEventId === -2 || open) return;

      const event = _.find(events, (e) => e.id === clickEventId);
      if (event && !event.properties?.hasVideo) {
        eventPopupRef.current = new mapboxgl.Popup({
          closeOnClick: false,
          // anchor: "bottom-left",
          offset: [0, -25],
          maxWidth: "332px",
        })
          .setLngLat(clickedLngLat)
          .addTo(map.current);
        const properties = event.properties;

        const eventPopupMarkup = document.createElement("div");
        eventPopupMarkup.className = clsx(classes.popupDiv, classes.eventPopup);
        const titleMarkup = document.createElement("div");
        titleMarkup.innerHTML = `<div class='${classes.bodyBold}'>${t(
          properties ? eventToText(properties.mode, properties.type) : "Event"
        )}</div>`;
        eventPopupMarkup.appendChild(titleMarkup);

        eventPopupRef.current.setDOMContent(eventPopupMarkup);
      }
    };

    if (eventPopupRef.current) {
      eventPopupRef.current.remove();
    }

    updateEventPopup();
  }, [
    classes.bodyBold,
    classes.eventPopup,
    classes.playBtn,
    classes.playBtnDiv,
    classes.playerDiv,
    classes.popupDiv,
    clickEventId,
    clickedLngLat,
    events,
    mobile,
    open,
    t,
  ]);

  useEffect(() => {
    const updatePopup = () => {
      // console.log("Eventmap", "updatePopup", hovered, hoverEventId);
      if (
        !map.current ||
        !focusedLngLat ||
        (hoverEventId !== -1 && lineClickedRef.current === false) ||
        clickEventId !== -2 ||
        mobile ||
        hovered
      )
        return;

      const total = _.sumBy(statList, (s) => {
        let total = s.impact + s.reckless + s.manual;
        // if (eventType.impact) {
        //   total += s.impact;
        // }
        // if (eventType.reckless) {
        //   total += s.reckless;
        // }
        // if (eventType.manual) {
        //   total += s.manual;
        // }
        return total;
      });
      if (total === 0) return;
      const driving_impact = _.sumBy(statList, (s) => s.drivingImpact ?? 0);
      const overspeed = _.sumBy(statList, (s) => s.overspeed ?? 0);
      const braking = _.sumBy(statList, (s) => s.braking ?? 0);
      const acceleration = _.sumBy(statList, (s) => s.acceleration ?? 0);
      const cornering = _.sumBy(statList, (s) => s.cornering ?? 0);
      const manual = _.sumBy(statList, (s) => s.manual);
      const parkingImpact = _.sumBy(statList, (s) => s.parkingImpact);
      let anchor: mapboxgl.Anchor = "bottom";
      let offset: mapboxgl.Offset = [0, -15];

      // console.log("Eventmap", "updatePopup", popupHorizontal, popupVertical);
      if (popupHorizontal === "left") {
        offset[0] = 20;
        anchor = "left";
      } else if (popupHorizontal === "right") {
        offset[0] = -20;
        anchor = "right";
      }
      if (popupVertical === "top") {
        offset[1] = 20;
        anchor = "top";
      }
      if (!popupRef.current) {
        popupRef.current = new mapboxgl.Popup({
          closeOnClick: false,
          anchor,
          offset,
          className: classes.mapboxPopup,
        })
          .setLngLat(focusedLngLat)
          .addTo(map.current);
      } else {
        popupRef.current.setLngLat(focusedLngLat);
      }

      let thumbnailMarkup: HTMLElement | null = null;
      // const filteredEvents = _.filter(sharedEvents, (e) => {
      //   let result = true;
      //   if (!e.thumb1) {
      //     result = false;
      //   }

      //   if (!eventType.impact && _.includes([2], e.type)) {
      //     result = false;
      //   }

      //   if (!eventType.reckless && _.includes([1, 3, 4, 5], e.type)) {
      //     result = false;
      //   }

      //   if (!eventType.manual && e.mode === "M") {
      //     result = false;
      //   }

      //   return result;
      // });

      const filteredEvents = sharedEvents;

      const length = filteredEvents.length;
      console.log("filtered", sharedEvents, filteredEvents);
      if (length > 0) {
        thumbnailMarkup = document.createElement("div");
        thumbnailMarkup.className = classes.lineThumbDiv;

        filteredEvents.slice(0, 2).forEach((e, index) => {
          if (thumbnailMarkup) {
            const divMarkup = document.createElement("div");
            divMarkup.className = classes.lineImgThumb;
            const imgMarkup = document.createElement("img");
            if (e.thumb1) {
              imgMarkup.src = e.thumb1;
            }

            imgMarkup.setAttribute(
              "style",
              "width:56px;height:56px;display:block;"
            );
            divMarkup.appendChild(imgMarkup);
            const btnDiv = document.createElement("div");
            btnDiv.className = classes.playBtnDiv;
            const btn = document.createElement("div");
            btn.innerHTML = `<svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" style="display:block;">
                    <g clip-path="url(#clip0_3570_8955)">
                    <path d="M8.89844 5.01562L19.8672 12L8.89844 18.9844V5.01562Z" />
                    </g>
                    <defs>
                    <clipPath id="clip0_3570_8955">
                    <rect width="24" height="24" fill="white" transform="translate(0.882812)"/>
                    </clipPath>
                    </defs>
                    </svg>`;
            btn.addEventListener("click", async () => {
              console.log(
                "Eventmap",
                "EventVideoModal",
                "click",
                "event",
                index
              );
              // setClickedEvent(e);
              // setClickEventId(e.id);
              // setOpenEventVideoModal(true);

              setClickEventId(e.eventId);
              setVideoModalMode("line");
              setLineVideIndex(index);
              setOpenEventVideoModal(true);
            });
            btnDiv.appendChild(btn);
            divMarkup.appendChild(btnDiv);
            thumbnailMarkup.appendChild(divMarkup);
          }
        });
      }

      const drivingImpactMarkup =
        // driving_impact > 0 && eventType.impact
        driving_impact > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Driving impact")}</div>
              <div>${driving_impact}</div>
            </div>`
          : "";

      const parkingImpactMarkup =
        // parkingImpact > 0 && eventType.impact
        parkingImpact > 0
          ? `<div class='${classes.popupFlex}'>
            <div>${t("Parking impact")}</div>
            <div>${parkingImpact}</div>
          </div>`
          : "";

      const overspeedMarkup =
        // overspeed > 0 && eventType.reckless
        overspeed > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Overspeed")}</div>
              <div>${overspeed}</div>
            </div>`
          : "";

      const brakingMarkup =
        // braking > 0 && eventType.reckless
        braking > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Hard braking")}</div>
              <div>${braking}</div>
            </div>`
          : "";

      const accelerationMarkup =
        // acceleration > 0 && eventType.reckless
        acceleration > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Acceleration")}</div>
              <div>${acceleration}</div>
            </div>`
          : "";

      const corneringMarkup =
        // cornering > 0 && eventType.reckless
        cornering > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Hard cornering")}</div>
              <div>${cornering}</div>
            </div>`
          : "";

      const manualMarkup =
        // manual > 0 && eventType.manual
        manual > 0
          ? `<div class='${classes.popupFlex}'>
              <div>${t("Manual")}</div>
              <div>${manual}</div>
            </div>`
          : "";

      let thumbMarkup = length > 0 ? document.createElement("div") : null;
      if (thumbMarkup) {
        thumbMarkup.className = classes.picDiv;

        if (loadingThumb) {
          thumbMarkup.innerHTML = ReactDOMServer.renderToString(
            <CircularProgress style={{ color: "white" }} />
          );
        } else {
          const textMarkup = document.createElement("div");
          textMarkup.innerText = length > 2 ? `+${length - 2}` : "";
          if (thumbnailMarkup) {
            thumbMarkup.appendChild(thumbnailMarkup);
          }

          thumbMarkup.appendChild(textMarkup);
        }
      }

      const linePopupMarkup = document.createElement("div");
      linePopupMarkup.className = classes.popupDiv;
      if (thumbMarkup) {
        linePopupMarkup.appendChild(thumbMarkup);
      }

      const lineStatMarkup = document.createElement("div");
      lineStatMarkup.innerHTML = `
      <div>
        ${drivingImpactMarkup}
        ${parkingImpactMarkup}
        ${overspeedMarkup}
        ${brakingMarkup}
        ${accelerationMarkup}
        ${corneringMarkup}
        ${manualMarkup}
        <div class='${classes.popupTotal}'>
          <div>${t("Total events")}</div>
          <div>${total}</div>
        </div>
      </div>`;

      linePopupMarkup.appendChild(lineStatMarkup);

      popupRef.current.setDOMContent(linePopupMarkup);
    };

    // console.log(
    //   "Eventmap",
    //   "before updatePopup",
    //   focusedLngLat,
    //   hoverEventId,
    //   statList.length,
    //   lineClickedRef.current
    // );
    if (
      !map.current ||
      !focusedLngLat ||
      (hoverEventId !== -1 && lineClickedRef.current === false) ||
      clickEventId !== -2 ||
      statList.length === 0 ||
      !openLinePopup
    ) {
      // console.log("Eventmap", "updatePopup", "remove", statList.length);
      popupRef.current?.remove();
      popupRef.current = null;
    } else {
      updatePopup();
    }
  }, [
    classes.picDiv,
    classes.popupDiv,
    classes.popupFlex,
    classes.popupPic,
    classes.popupTotal,
    hoverEventId,
    focusedLngLat,
    statList,
    t,
    clickEventId,
    mobile,
    classes.lineImgThumb,
    classes.playBtnDiv,
    classes.lineThumbDiv,
    sharedEvents,
    hovered,
    classes.mapboxPopup,
    popupHorizontal,
    popupVertical,
    loadingThumb,
    openLinePopup,
  ]);

  const getSharedEvents = useCallback(
    async (getFilterTag?: string[]) => {
      console.log("Eventmap", "call getSharedEvents");
      if (!userProfile || !subscriptionInfo) return;
      setLoadingMyEvents(true);
      let query = "";
      if (userProfile.userType === "SubMaster") {
        query = `?email=${subscriptionInfo.masterEmail}`;
      }
      try {
        const resp = await eventmapAxiosInst.get(`/shared-events${query}`);
        const videos = _.chain(resp.data)
          .map((event) => {
            return _.map(event.videos, (video) => {
              return {
                ...video,
                mode: event.mode,
                filename: event.filename,
                type: event.type,
                lat: event.lat,
                lon: event.lon,
                owner: true,
                time: event.time,
              };
            });
          })
          .flattenDeep()
          // 10164 수정
          .sortBy((video) => moment(video.createdAt).unix())
          // 표시 순서 - 최근 공유한 영상 순으로 적용(Leehj) 23.01.10
          .reverse()
          .value();

        if (getFilterTag) {
          // 해시태그가 존재하지 않는 공유 영상과 선택한 해시태그가 포함된(선택한 해시태그배열과 공유영상의 해시태그배열의 교집합이 존재할 때) 공유영상을 출력
          if (_.includes(getFilterTag, "None")) {
            setMyVideos(
              _.filter(videos, (e) => {
                if (
                  _.map(e.hashtags, (eventHashTag) => eventHashTag.name)
                    .length === 0 ||
                  _.intersection(
                    getFilterTag,
                    _.map(e.hashtags, (eventHashTag) => eventHashTag.name)
                  ).length > 0
                ) {
                  return e;
                }
              }) as EventVideo[]
            );
          } else {
            // 해시태그가 존재하는 공유 영상과 선택한 해시태그가 포함된(선택한 해시태그배열과 공유영상의 해시태그배열의 교집합이 존재할 때) 공유영상을 출력
            setMyVideos(
              _.filter(videos, (e) => {
                if (
                  _.map(e.hashtags, (eventHashTag) => eventHashTag.name)
                    .length > 0 &&
                  _.intersection(
                    getFilterTag,
                    _.map(e.hashtags, (eventHashTag) => eventHashTag.name)
                  ).length > 0
                ) {
                  return e;
                }
              }) as EventVideo[]
            );
          }
        } else {
          // 처음에 필터 적용없이 이벤트 공유영상 리스트
          setMyVideos(videos);
        }
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response?.status === 403) {
          setOpenNoPermModal(true);
          setOpenEventList(false);
          showGNB();
          setSharedVideosModal(false);
        }
      } finally {
        setLoadingMyEvents(false);
      }
    },
    [subscriptionInfo, userProfile]
  );

  const mapboxMarkup = useMemo(() => {
    return <div ref={mapContainer} className={classes.map} />;
  }, [classes.map]);

  const handleFullscreen = useCallback(() => {
    const element: HTMLDivElement | null = rootDivRef.current;
    if (element) {
      if (isFullscreen(element)) {
        exitFullscreen(element);
        setFullscreen(false);
      } else {
        requestFullscreen(element);
        setFullscreen(true);
      }
    }
  }, []);

  const handleEventTypeLayer = useCallback(() => {
    console.log("handleEventTypeLayer");
    setOpenLayer((o) => !o);
    // mobile layerModal 닫기버튼을 선택시 변경사항이 저장되지 않은 상태로 리스트로 다시 돌아간다.(Leehj)
    if (mobile) {
      setMapPreType(mapType);
      setEventPreType(eventType);
    }
  }, [eventType, mapType, mobile]);

  const handleRemoveEvent = useCallback(async (remove: EventVideo) => {
    setSelectedEvent(remove);
    setOpenRemoveModal(true);
  }, []);

  const removeEvent = useCallback(async () => {
    if (selectedEvent) {
      let dir: string | undefined = undefined;
      if (selectedEvent.dir === 1) {
        dir = "video1";
      } else if (selectedEvent.dir === 2) {
        dir = "video2";
      } else if (selectedEvent.dir === 3) {
        dir = "video3";
      }
      console.log("Eventmap", "removeEvent", selectedEvent);
      setRemovingEvent(true);
      await eventmapAxiosInst.delete(
        `/shared-events/${selectedEvent.eventId}/${dir}`
      );
      console.log("Eventmap", "call getSharedEvents - 111");
      await getSharedEvents(filterHashtag);
      setRemovingEvent(false);
      setOpenRemoveModal(false);
    }
  }, [filterHashtag, getSharedEvents, selectedEvent]);

  // console.log("myVideos", myVideos);
  const rowRenderer: ListRowRenderer = useCallback(
    ({ index, key, style }) => {
      const event = myVideos[index];

      return (
        <div key={key} style={style}>
          <SharedEventListItem
            event={event}
            onRemove={handleRemoveEvent}
            onPlay={() => {
              // 마케팅팀 요청으로 모바일에서 내 영상 목록 열려있도록 유지
              // if (mobile) {
              //   setSharedVideosModal(false);
              // }
              seScrollTopWhenOpen(myEventListScrollTopRef.current);
              setClickEventId(event.eventId);
              setOpenEventVideoModal(true);
              setVideoModalMode("my-videos");
              setVideoIndex(index);
            }}
          />
        </div>
      );
    },
    [handleRemoveEvent, myVideos]
  );

  const btnMarkup = useMemo(() => {
    if (userProfile?.userType === "User") return;
    if (singleEvent || explore) return;
    return (
      // mantis - 9421,9416 admin이면서 shareToEventmapPerm권한 없는 경우 No permission 툴팁추가 (Leehj)
      <Tooltip
        PopperProps={{ disablePortal: true }}
        open={openTooltip}
        onOpen={() => {
          if (!shareToEventmapPerm && userProfile?.userType === "SubMaster") {
            setOpenTooltip(true);
          }
        }}
        onClose={() => setOpenTooltip(false)}
        disableTouchListener={mobile}
        title={t("No permission") ?? "No permission"}
      >
        <div>
          <Fab
            // onClick={onDashcamPublic}
            // mantis - 9421,9416 admin이면서 shareToEventmapPerm권한 없는 경우 비활성화 처리 (Leehj)
            disabled={
              !shareToEventmapPerm && userProfile?.userType === "SubMaster"
            }
            ref={eventListAnchorRef}
            size="small"
            variant="rounded"
            className={clsx(classes.sharedButton, classes.noFocus, {
              [classes.noHover]: mobile,
            })}
            onClick={() => {
              console.log("Eventmap", "fab clicked");
              if (!sharedVideosModal && !openEventList) {
                getSharedEvents();
                //  처음에 해시태크필터 모달 열었을 때 전체 선택이 풀려있는 이슈로 아래 코드 추가(Leehj)
                setFilterHashtag(hashtagsNameArr);
              }

              if (mobile) {
                setSharedVideosModal((o) => {
                  // if (!o) {
                  //   hideGNB();
                  // } else {
                  //   showGNB();
                  // }
                  return !o;
                });
              }
              setOpenEventList((o) => !o);
            }}
          >
            <Typography
              category="Default"
              variant="Button"
              style={{ textTransform: "none" }}
            >
              {t("My Shared Videos")}
            </Typography>
          </Fab>
        </div>
      </Tooltip>
    );
  }, [
    classes.noFocus,
    classes.noHover,
    classes.sharedButton,
    explore,
    getSharedEvents,
    hashtagsNameArr,
    mobile,
    openEventList,
    openTooltip,
    shareToEventmapPerm,
    sharedVideosModal,
    singleEvent,
    t,
    userProfile?.userType,
  ]);

  const myEventListMarkup = useMemo(() => {
    if (userProfile?.userType === "User") return;
    if (singleEvent || explore) return;
    console.log(
      "Eventmap",
      "openEventList",
      openEventList,
      loadingMyEvents,
      myVideos.length
    );
    let bodyMarkup = <></>;
    if (loadingMyEvents) {
      bodyMarkup = (
        <div
          className={classes.noSharedModalContentWrap}
          style={mobile ? {} : { width: 340, height: "calc(60vh + 56px)" }}
        >
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    } else if (myVideos.length > 0) {
      bodyMarkup = (
        <div
          className={clsx({
            [classes.sharedModalContentWrap]: mobile,
          })}
          style={
            !mobile
              ? {
                  // 웹뷰 내 공유 영상 사이즈 통일 375x716
                  width: 375,
                  // height: "calc(60vh + 56px)",
                  // maxHeight: "calc(60vh + 56px)",
                  height: 660,
                  maxHeight: 660,
                }
              : {}
          }
        >
          {!mobile && sharedEventVideoHeaderMarkup}

          <AutoSizer>
            {({ height, width }) =>
              renderListHelper(
                width,
                height,
                <List
                  width={width}
                  height={height}
                  rowCount={myVideos.length}
                  rowHeight={110}
                  rowRenderer={rowRenderer}
                  ref={eventListRef}
                  onScroll={(e) => {
                    myEventListScrollTopRef.current = e.scrollTop;
                  }}
                  style={
                    mobile
                      ? {}
                      : {
                          overflowY: "visible",
                          overflowX: "visible",
                        }
                  }
                />,
                mobile,
                theme.direction,
                eventListRef,
                scrollBarRef
              )
            }
          </AutoSizer>
        </div>
      );
    } else {
      // mantis ID : 9338(SH)
      // 공유된 영상이 없을 때 문구 출력
      bodyMarkup = (
        <>
          {!mobile && sharedEventVideoHeaderMarkup}
          <div
            className={classes.noSharedModalContentWrap}
            style={mobile ? {} : { width: 340, height: "calc(60vh + 56px)" }}
          >
            <div style={{ marginBottom: 32 }}>
              <ReactSVG src="/images/eventmap-videocam.svg" />
            </div>
            <div style={{ textAlign: "center" }}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["3"]}
                dangerouslySetInnerHTML={{
                  __html: t(`There is no_`)?.replace(
                    // eslint-disable-next-line no-control-regex
                    new RegExp("\n", "g"),
                    "<br/>"
                  ),
                }}
              ></Typography>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {!mobile && (
          <Popper
            open={openEventList}
            anchorEl={eventListAnchorRef.current}
            modifiers={{
              offset: {
                enabled: true,
                offset: "0, 4",
              },
            }}
            style={{
              zIndex: 1000,
            }}
            placement={"bottom-end"}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "center top",
                }}
              >
                <Paper className={classes.eventListDiv} elevation={0}>
                  <ClickAwayListener
                    onClickAway={() => {
                      console.log("onClickAway", openRemoveModal, open);
                      if (!openRemoveModal && !open && !openEventVideoModal) {
                        if (openEventList) {
                          setOpenEventList(false);
                        }
                      }
                    }}
                  >
                    {bodyMarkup}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}

        {mobile && (
          <TWModal
            open={sharedVideosModal}
            mobile
            onClose={() => {
              setOpenEventList(false);
              setSharedVideosModal(false);
              showGNB();
            }}
            headingIcon={
              <IconButton
                onClick={() => {
                  setOpenEventList(false);
                  setSharedVideosModal(false);
                  showGNB();
                }}
              >
                <CloseIcon />
              </IconButton>
            }
            heading={t("My Shared Videos")}
            content={bodyMarkup}
            fullSize={mobile && true}
            className={classes.sharedModal}
            contentClassName={classes.sharedModalContent}
            titleClassName={clsx(
              classes.myEventListDialogTitle,
              classes.mobileSharedVideosModalTitle
            )}
            headingTextClassName={classes.sharedModalHeaderText}
            headerBtnClassName={classes.sharedModalHeaderBtn}
            headerBtn={
              <div>
                <IconButton onClick={() => setOpenEventFilter(true)}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FilterListIcon />
                    {/* mantis - 10598, 모바일 필터변경시 오른쪽 상단 파란점 UI 추가 (Leehj) */}
                    {filterHashtag.length !== hashtagsNameArr.length && (
                      <div
                        style={{
                          width: 6,
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: LightColors.primary["7"],
                          position: "absolute",
                          top: -3,
                          right: -3,
                        }}
                      />
                    )}
                  </div>
                </IconButton>
              </div>
            }
          />
        )}
      </>
    );
  }, [
    classes.eventListDiv,
    classes.mobileSharedVideosModalTitle,
    classes.myEventListDialogTitle,
    classes.noSharedModalContentWrap,
    classes.sharedModal,
    classes.sharedModalContent,
    classes.sharedModalContentWrap,
    classes.sharedModalHeaderBtn,
    classes.sharedModalHeaderText,
    explore,
    filterHashtag.length,
    hashtagsNameArr.length,
    loadingMyEvents,
    mobile,
    myVideos.length,
    open,
    openEventList,
    openEventVideoModal,
    openRemoveModal,
    rowRenderer,
    sharedEventVideoHeaderMarkup,
    sharedVideosModal,
    singleEvent,
    t,
    theme.direction,
    userProfile?.userType,
  ]);

  const desktopLayerMenu = useMemo(() => {
    return (
      <Menu
        open={openLayer}
        mouseEvent="onClick"
        onClickAway={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpenLayer(false);
        }}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        paperClasses={{ root: classes.desktopLayerMenuDiv }}
        classes={{ root: classes.desktopLayerMenuPadding }}
        modifiers={{
          offset: {
            enabled: true,
            offset: "0, 4px",
          },
        }}
      >
        <div style={{ padding: "8px 0" }}>
          <div>
            <Typography
              category="Default"
              variant="Caption"
              style={{ padding: "0 16px" }}
            >
              {t("Map style")}
            </Typography>
            <RadioGroup
              name="map_type"
              value={mapType}
              onChange={(e) => {
                setMapType(e.target.value as any);
                mapTypeRef.current = e.target.value as any;
                setOpenLayer(false);
              }}
              className={classes.formControlLabel}
              style={{ marginTop: 8 }}
            >
              <RadioButton
                value="map"
                label={t("Map")}
                labelStyle={{ marginBottom: 7 }}
              />
              <RadioButton
                value="satellite"
                label={t("Satellite")}
                // mantis : 8550 (SH)
                // 일본어 선택 시 event type이 줄바뀜 되는 이슈로 인해 minWidth 추가
                labelStyle={{ minWidth: 98 }}
              />
            </RadioGroup>
          </div>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: LightColors.primary["6"],
              margin: "8px 0 17px",
            }}
          ></div>
          <div>
            <Typography
              category="Default"
              variant="Caption"
              style={{ padding: "0 16px" }}
            >
              {t("Video type")}
            </Typography>

            <FormControlLabel
              className={classes.formControlLabel}
              style={{ marginTop: 8 }}
              control={
                <CheckBox
                  color="primary"
                  checked={eventType.normal}
                  onChange={(e, checked) => {
                    setEventType((t) => {
                      eventTypeRef.current = { ...t, normal: checked };
                      return { ...t, normal: checked };
                    });
                  }}
                />
              }
              label={
                <Typography
                  category="Default"
                  variant="Body"
                  className={classes.menuText}
                >
                  {t("Normal")}
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <CheckBox
                  color="primary"
                  checked={eventType.reckless && eventType.impact}
                  onChange={(e, checked) => {
                    setEventType((t) => {
                      // impact & reckless가 event로 통합됨
                      eventTypeRef.current = {
                        ...t,
                        impact: checked,
                        reckless: checked,
                      };
                      return { ...t, impact: checked, reckless: checked };
                    });
                  }}
                />
              }
              label={
                <Typography
                  category="Default"
                  variant="Body"
                  className={classes.menuText}
                >
                  {t("Event")}
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <CheckBox
                  color="primary"
                  checked={eventType.parking}
                  onChange={(e, checked) => {
                    setEventType((t) => {
                      eventTypeRef.current = { ...t, parking: checked };
                      return { ...t, parking: checked };
                    });
                  }}
                />
              }
              label={
                <Typography
                  category="Default"
                  variant="Body"
                  className={classes.menuText}
                >
                  {t("Parking")}
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <CheckBox
                  color="primary"
                  checked={eventType.manual}
                  onChange={(e, checked) => {
                    setEventType((t) => {
                      eventTypeRef.current = { ...t, manual: checked };
                      return { ...t, manual: checked };
                    });
                  }}
                />
              }
              label={
                <Typography
                  category="Default"
                  variant="Body"
                  className={classes.menuText}
                >
                  {t("Manual")}
                </Typography>
              }
            />
          </div>
        </div>
      </Menu>
    );
  }, [
    classes.desktopLayerMenuDiv,
    classes.desktopLayerMenuPadding,
    classes.formControlLabel,
    classes.menuText,
    eventType.impact,
    eventType.manual,
    eventType.normal,
    eventType.parking,
    eventType.reckless,
    mapType,
    openLayer,
    t,
  ]);

  const mobileLayerMenu = useMemo(() => {
    return (
      <TWModal
        open={openLayer}
        mobile
        // onClose={() => {
        //   setOpenLayer(false);
        //   setMapType(mapPreType);
        // }}
        heading={t("Layers")}
        content={
          <div style={{ padding: 16, width: "100%" }}>
            <div>
              <Typography category="Default" variant="Small">
                {t("Map style")}
              </Typography>
              <RadioGroup
                name="map_type"
                value={mapType}
                onChange={(e) => {
                  setMapType(e.target.value as any);
                  mapTypeRef.current = e.target.value as any;
                }}
                style={{ marginTop: 13, marginLeft: -6, marginRight: -6 }}
              >
                <RadioButton
                  value="map"
                  label={t("Map")}
                  labelStyle={{ marginBottom: 10 }}
                  labelClassName={classes.radioBtnLabel}
                />
                <RadioButton
                  value="satellite"
                  label={t("Satellite")}
                  labelClassName={classes.radioBtnLabel}
                />
              </RadioGroup>
            </div>
            <div
              style={{
                height: 1,
                width: "100%",
                backgroundColor: LightColors.primary["6"],
                margin: "12px 0 16px",
              }}
            ></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                category="Default"
                variant="Small"
                style={{ marginBottom: 13 }}
              >
                {t("Video type")}
              </Typography>

              <FormControlLabel
                className={classes.mobileFormLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={eventType.normal}
                    onChange={(e, checked) => {
                      setEventType((t) => {
                        eventTypeRef.current = { ...t, normal: checked };
                        return { ...t, normal: checked };
                      });
                    }}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.menuText}
                  >
                    {t("Normal")}
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.mobileFormLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={eventType.reckless && eventType.impact}
                    onChange={(e, checked) => {
                      setEventType((t) => {
                        // impact & reckless가 event로 통합됨
                        eventTypeRef.current = {
                          ...t,
                          impact: checked,
                          reckless: checked,
                        };
                        return { ...t, impact: checked, reckless: checked };
                      });
                    }}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.menuText}
                  >
                    {t("Event")}
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.mobileFormLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={eventType.parking}
                    onChange={(e, checked) => {
                      setEventType((t) => {
                        eventTypeRef.current = { ...t, parking: checked };
                        return { ...t, parking: checked };
                      });
                    }}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.menuText}
                  >
                    {t("Parking")}
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.mobileFormLabel}
                control={
                  <CheckBox
                    color="primary"
                    checked={eventType.manual}
                    onChange={(e, checked) => {
                      setEventType((t) => {
                        eventTypeRef.current = { ...t, manual: checked };
                        return { ...t, manual: checked };
                      });
                    }}
                  />
                }
                label={
                  <Typography
                    category="Default"
                    variant="Body"
                    className={classes.menuText}
                  >
                    {t("Manual")}
                  </Typography>
                }
              />
            </div>
          </div>
        }
        headerBtn={
          <div>
            <IconButton
              onClick={() => {
                setOpenLayer(false);
                // 닫기버튼을 선택시 변경사항이 저장되지 않은 상태로 리스트로 다시 돌아간다.(Leehj)
                setMapType(mapPreType);
                setEventType(eventPreType);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        }
        close
        LButton={t("Clear")}
        RButton={t("OK")}
        onClickPositive={() => {
          handleMapTypeChange();
          // mantis - 10200, 모바일 일 때는 OK버튼 눌러야 필터 적용되도록 수정(Leehj)
          handleVideoTypeChange();
          setOpenLayer(false);
        }}
        onClickNegative={() => {
          setEventType({
            normal: true,
            impact: true,
            reckless: true,
            parking: true,
            manual: true,
          });
          // mantis - 10388, Event Map Layers> Clear 선택 동작시 Map style 디폴트(map)로 변경
          setMapType("map");
        }}
        fullSize={mobile && true}
        headerBtnClassName={classes.layerMobileModalHeaderBtn}
        className={classes.sharedModal}
        contentClassName={clsx(
          classes.sharedModalContent,
          classes.mobileLayerModalContent
        )}
        titleClassName={clsx(
          classes.myEventListDialogTitle,
          classes.myEventListDialogTitleMobile
        )}
        actionClassName={classes.layerMobileModalBtnDiv}
        LButtonClassName={clsx(classes.layerMobileModalLBtn)}
        RButtonClassName={classes.layerMobileModalRBtn}
      />
    );
  }, [
    classes.layerMobileModalBtnDiv,
    classes.layerMobileModalHeaderBtn,
    classes.layerMobileModalLBtn,
    classes.layerMobileModalRBtn,
    classes.menuText,
    classes.mobileFormLabel,
    classes.mobileLayerModalContent,
    classes.myEventListDialogTitle,
    classes.myEventListDialogTitleMobile,
    classes.sharedModal,
    classes.sharedModalContent,
    classes.radioBtnLabel,
    eventPreType,
    eventType.impact,
    eventType.manual,
    eventType.normal,
    eventType.parking,
    eventType.reckless,
    handleMapTypeChange,
    handleVideoTypeChange,
    mapPreType,
    mapType,
    mobile,
    openLayer,
    t,
  ]);

  const topRightMenuMarkup = useMemo(() => {
    if (singleEvent || explore) return;
    return (
      <div className={classes.topControlPane}>
        <TopRightControl
          // ref={anchorRef}
          t={t}
          round
          onFullscreen={mobile ? undefined : handleFullscreen}
          onEventLayers={handleEventTypeLayer}
          fullscreen={fullscreen}
          eventRef={anchorRef}
          themeState={themeState}
        />

        {!mobile && desktopLayerMenu}
        {mobile && mobileLayerMenu}
      </div>
    );
  }, [
    classes.topControlPane,
    desktopLayerMenu,
    explore,
    fullscreen,
    handleEventTypeLayer,
    handleFullscreen,
    mobile,
    mobileLayerMenu,
    singleEvent,
    t,
    themeState,
  ]);

  const handleReporting = useCallback(
    async (reason: string) => {
      if (userProfile && reportedVideoId) {
        setLoadingReportModal(true);

        try {
          await eventmapAxiosInst.delete(
            `/event-videos/${reportedVideoId}/report`,
            {
              data: {
                // mantis 9350 - 모바일에서 이유작성에 줄바꿈시 에러나는 이슈 수정 (Leehj)
                reason: _.replace(reason, /\n/g, "\\n"),
                reporter: email,
                reporterName: `${userProfile.firstName} ${userProfile.lastName}`,
              },
            }
          );
          setOpenReportVideoModal(false);

          dispatch(
            openToast({
              message: "The video has been reported_",
            })
          );
        } catch (e) {
          console.error(e);

          dispatch(
            openToast({
              message: "Internal server error_",
            })
          );
        } finally {
          setLoadingReportModal(false);

          if (openEventVideoModal) {
            const browserName = detect()?.name;
            if (
              browserName === "ios-webview" &&
              //@ts-ignore
              webkit.messageHandlers.enableRotate
            ) {
              //@ts-ignore
              webkit.messageHandlers.enableRotate.postMessage({});
            }
            if (
              browserName === "chromium-webview" &&
              //@ts-ignore
              window.Webviewer?.enableRotate
            ) {
              //@ts-ignore
              window.Webviewer?.enableRotate?.();
            }
          }
        }
      }
    },
    [dispatch, email, openEventVideoModal, reportedVideoId, userProfile]
  );

  const handleMyLocation = useCallback(
    (position: GeolocationPosition) => {
      dispatch(setLoadingLocation(false));
      map.current?.jumpTo({
        center: {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        },
        // 10595 - 내위치 기본 줌레벨 변경
        zoom: 6,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (navigator.geolocation && locPerm === 1 && !singleEvent && !explore) {
      dispatch(setLoadingLocation(true));
      const timer = setTimeout(() => {
        dispatch(setLoadingLocation(false));
      }, 5500);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timer);
          handleMyLocation(position);
        },
        () => {
          dispatch(setLoadingLocation(false));
          // handleFailedLocation();
          if (map.current) {
            const indx = _.random(0, NO_GPS_LOCATIONS.length - 1);
            map.current.jumpTo({
              center: NO_GPS_LOCATIONS[indx],
              zoom: NO_GPS_ZOOMS[indx],
            });
          }
        },
        { timeout: 5000 }
      );
      //10625 ios 위치 권한 없는 경우 랜덤 위치 보여주도록 수정 (mckim)
    } else if (locPerm === 0) {
      if (map.current) {
        const indx = _.random(0, NO_GPS_LOCATIONS.length - 1);
        map.current.jumpTo({
          center: NO_GPS_LOCATIONS[indx],
          zoom: NO_GPS_ZOOMS[indx],
        });
      }
    }
  }, [
    dispatch,
    explore,
    handleFailedLocation,
    handleMyLocation,
    locPerm,
    singleEvent,
  ]);

  const handleZoomIn = useCallback(() => {
    const zoom = map.current?.getZoom();
    if (zoom) {
      map.current?.jumpTo({ zoom: zoom + 1 });
    }
  }, []);

  const handleZoomOut = useCallback(() => {
    const zoom = map.current?.getZoom();
    if (zoom) {
      map.current?.jumpTo({ zoom: zoom - 1 });
    }
  }, []);

  useEffect(() => {
    if (!map.current) return;
    const source = map.current.getSource(
      EVENT_LINE_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;
    const source2 = map.current.getSource(
      EVENT_START_END_SOURCE_NAME
    ) as mapboxgl.GeoJSONSource;
    source?.setData(turf.featureCollection([]));
    source2?.setData(turf.featureCollection([]));

    if (clickEventId === -2) return;

    const event = _.find(events, (e) => e.id === clickEventId);
    if (!event) return;

    const linestring = event.properties?.linestring;
    if (linestring && source) {
      source.setData(
        turf.featureCollection([
          {
            type: "Feature",
            id: event.id,
            properties: {},
            geometry: linestring,
          },
        ])
      );
      if (source2) {
        const first = _.first(linestring.coordinates) as number[];
        const last = _.last(linestring.coordinates) as number[];
        if (first && last) {
          source2.setData(
            turf.featureCollection([turf.point(first), turf.point(last)])
          );
        }
      }
    }
  }, [clickEventId, events]);

  // UXUI에서 GNB 닫거나 열도록 요청

  useEffect(() => {
    if (mobile) {
      // console.log("Eventmap", "hideGNB", statList.length === 0);
      if (singleEvent || explore) return;
      // if (statList.length === 0) {
      //   showGNB();
      // } else {
      //   hideGNB();
      // }
    }
  }, [explore, mobile, singleEvent, statList.length]);

  const mobileLineDetailMarkup = useMemo(() => {
    // console.log(
    //   "Eventmap",
    //   "mobileLineDetailMarkup",
    //   statList.length,
    //   clickEventId
    // );
    if (singleEvent || explore) return;
    if (!mobile || statList.length === 0 || clickEventId !== -2) return;

    const total = _.sumBy(statList, (s) => {
      // 기획 변경으로 라인데이터는 필터와 상관없이 동작하도록 수정됨 - 10398 by mckim
      let total = s.impact + s.reckless + s.manual;
      // if (eventType.impact) {
      //   total += s.impact;
      // }
      // if (eventType.reckless) {
      //   total += s.reckless;
      // }
      // if (eventType.manual) {
      //   total += s.manual;
      // }
      return total;
    });
    if (total === 0) return;
    const driving_impact = _.sumBy(statList, (s) => s.drivingImpact ?? 0);
    const overspeed = _.sumBy(statList, (s) => s.overspeed ?? 0);
    const braking = _.sumBy(statList, (s) => s.braking ?? 0);
    const acceleration = _.sumBy(statList, (s) => s.acceleration ?? 0);
    const cornering = _.sumBy(statList, (s) => s.cornering ?? 0);
    const manual = _.sumBy(statList, (s) => s.manual);
    const parkingImpact = _.sumBy(statList, (s) => s.parkingImpact);

    let thumbMarkup = undefined;

    const length = sharedEvents.length;
    if (loadingThumb) {
      thumbMarkup = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "calc(100vw - 64px)",
            height: "calc((100vw - 64px)*0.563)",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (length > 0) {
      thumbMarkup = (
        <div className={classes.mobileInfoThumbDiv}>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={sharedEvents[0].thumb1}
            style={{
              width: "calc(100vw - 64px)",
              height: "calc((100vw - 64px)*0.563)",
            }}
          />
          <div className={classes.mobileInfoNumberDiv}>
            {sharedEvents.length > 1 && (
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["0"]}
                style={{ padding: "6px 12px" }}
              >
                +{sharedEvents.length - 1}
              </Typography>
            )}
          </div>
          <div className={classes.mobilePlayCtrlDiv}>
            <IconButton
              onClick={() => {
                console.log(
                  "Eventmap",
                  "mobileLineDetailMarkup",
                  "click",
                  sharedEvents,
                  sharedEvents[0].id
                );
                setClickEventId(sharedEvents[0].eventId);
                setOpenEventVideoModal(true);
              }}
            >
              <PlayCircleFilledIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
      );
    }

    return (
      <MobileDrawer
        //key={`${loadingThumb}`}
        initMode="markup"
        mode="camera"
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ padding: "18.5px 15px 15.5px", flex: 1 }}>
              <Typography category="Default" variant="H6">
                {t("Event information")}
              </Typography>
            </div>
            <IconButton
              style={{ padding: "12px 16px 13px 13px" }}
              onClick={() => setStatList([])}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ padding: "0 32px 24px" }}>
            {thumbMarkup}

            <div style={{ marginTop: 8 }}>
              {/* 기획 변경으로 라인데이터는 필터와 상관없이 동작하도록 수정됨 - 10398 by mckim */}
              {driving_impact > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Driving impact")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {driving_impact}
                  </Typography>
                </div>
              )}
              {/* parking impact 누락됨 10405 by mckim */}
              {parkingImpact > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Parking impact")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {parkingImpact}
                  </Typography>
                </div>
              )}
              {overspeed > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Overspeed")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {overspeed}
                  </Typography>
                </div>
              )}
              {braking > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Hard braking")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {braking}
                  </Typography>
                </div>
              )}
              {acceleration > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Acceleration")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {acceleration}
                  </Typography>
                </div>
              )}
              {cornering > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Sharp turn")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {cornering}
                  </Typography>
                </div>
              )}
              {manual > 0 && (
                <div className={classes.mobileMarkupContent}>
                  <Typography category="Default" variant="Body">
                    {t("Manual")}
                  </Typography>
                  <Typography category="Default" variant="BodyBold">
                    {manual}
                  </Typography>
                </div>
              )}
              <div className={classes.mobileMarkupTotal}>
                <Typography category="Default" variant="H6">
                  {t("Total events")}
                </Typography>
                <Typography category="Default" variant="H6">
                  {total}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </MobileDrawer>
    );
  }, [
    classes.icon,
    classes.mobileInfoNumberDiv,
    classes.mobileInfoThumbDiv,
    classes.mobileMarkupContent,
    classes.mobileMarkupTotal,
    classes.mobilePlayCtrlDiv,
    clickEventId,
    explore,
    loadingThumb,
    mobile,
    sharedEvents,
    singleEvent,
    statList,
    t,
  ]);

  const eventDetailModal = useMemo(() => {
    if (singleEvent || explore) return;

    const eventTypes: string[] = [];
    if (eventType.impact) {
      eventTypes.push("impact");
    }
    if (eventType.reckless) {
      eventTypes.push("reckless");
    }
    if (eventType.manual) {
      eventTypes.push("manual");
    }
    if (eventType.normal) {
      eventTypes.push("normal");
    }
    if (eventType.parking) {
      eventTypes.push("parking");
    }
    return (
      <EventVideoModal
        mobile={mobile}
        mapType={mapType}
        eventId={clickEventId}
        open={openEventVideoModal}
        zoom={mapInfo.zoom}
        shareToEventmapPerm={shareToEventmapPerm}
        onClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setClickEventId(-2);
          setOpenEventVideoModal(false);
          if (!sharedVideosModal && statList.length === 0) {
            showGNB();
          }
        }}
        onOpenReportVideoModal={(videoId) => {
          setReportedVideoId(videoId);
          setOpenReportVideoModal(true);
        }}
        // container={rootDivRef.current}
        myEventVideos={myVideos}
        mode={videoModalMode}
        myVideoIndex={videoIndex}
        lineIndex={lineVideoIndex}
        lineVideos={sharedEvents}
        eventTypes={eventTypes}
      />
    );
  }, [
    clickEventId,
    eventType.impact,
    eventType.manual,
    eventType.normal,
    eventType.parking,
    eventType.reckless,
    explore,
    lineVideoIndex,
    mapInfo.zoom,
    mapType,
    mobile,
    myVideos,
    openEventVideoModal,
    shareToEventmapPerm,
    sharedEvents,
    sharedVideosModal,
    singleEvent,
    statList.length,
    videoIndex,
    videoModalMode,
  ]);

  const eventColorPanelMarkup = useMemo(() => {
    if (singleEvent || explore) return;
    return (
      <div className={classes.eventNumberPanel}>
        <div className={classes.eventNumberTitle}>
          <Typography category="Default" variant={mobile ? "Small" : "Body"}>
            {t("Number of events_2")}
          </Typography>
          {/* uxui 피그마 디자인 반영으로 주석처리(Leehj) */}
          {process.env.REACT_APP_ENV === "qa" ? (
            <>
              <br />
              <Typography category="Default" variant="Body">
                Zoom: {_.floor(mapInfo.zoom, 4)}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.eventColorPanel}>
          <div className={classes.eventColorIndicator}>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["2"]}
            >
              {t("Low")}
            </Typography>
          </div>
          <div
            className={classes.eventColorBox}
            style={{ background: "#FFCF25" }}
          />
          <div
            className={classes.eventColorBox}
            style={{ background: "#FF9900" }}
          />
          <div
            className={classes.eventColorBox}
            style={{ background: "#FF6B00" }}
          />
          <div
            className={classes.eventColorBox}
            style={{ background: "#F80000" }}
          />
          <div
            className={classes.eventColorBox}
            style={{ background: "#BB0000" }}
          />
          <div className={classes.eventColorIndicator}>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["2"]}
            >
              {t("High_2")}
            </Typography>
          </div>
        </div>
      </div>
    );
  }, [
    classes.eventColorBox,
    classes.eventColorIndicator,
    classes.eventColorPanel,
    classes.eventNumberPanel,
    classes.eventNumberTitle,
    explore,
    mapInfo.zoom,
    mobile,
    singleEvent,
    t,
  ]);

  return (
    <div className={classes.root} ref={rootDivRef}>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      {mapboxMarkup}
      {topRightMenuMarkup}
      {/* {shareToEventmapPerm && myEventListMarkup} */}
      {/* mantis - 9416 driver가 아닌경우 버튼 노출되도록 수정 (Leehj) */}

      <div className={classes.sharedListPanel}>
        {btnMarkup}
        {myEventListMarkup}
      </div>

      {mobileLineDetailMarkup}

      {eventColorPanelMarkup}
      {!singleEvent && !explore && (
        <ZoomPanel
          eventMap
          onMyLocation={handleMyLocation}
          onFailedLocation={handleFailedLocation}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          locPerm={locPerm}
          loading={loadingLocation}
          onLoading={(v) => {
            dispatch(setLoadingLocation(v));
          }}
          t={t}
          colors={colors}
        />
      )}

      {openReportVideoModal && (
        <ReportVideoModal
          open={openReportVideoModal}
          loading={loadingReportModal}
          onClose={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenReportVideoModal(false);

            if (openEventVideoModal) {
              const browserName = detect()?.name;
              if (
                browserName === "ios-webview" &&
                //@ts-ignore
                webkit.messageHandlers.enableRotate
              ) {
                //@ts-ignore
                webkit.messageHandlers.enableRotate.postMessage({});
              }
              if (
                browserName === "chromium-webview" &&
                //@ts-ignore
                window.Webviewer?.enableRotate
              ) {
                //@ts-ignore
                window.Webviewer?.enableRotate?.();
              }
            }
          }}
          onClickNegative={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenReportVideoModal(false);
            if (openEventVideoModal) {
              const browserName = detect()?.name;
              if (
                browserName === "ios-webview" &&
                //@ts-ignore
                webkit.messageHandlers.enableRotate
              ) {
                //@ts-ignore
                webkit.messageHandlers.enableRotate.postMessage({});
              }
              if (
                browserName === "chromium-webview" &&
                //@ts-ignore
                window.Webviewer?.enableRotate
              ) {
                //@ts-ignore
                window.Webviewer?.enableRotate?.();
              }
            }
          }}
          onClickPositive={handleReporting}
        />
      )}
      {openRemoveModal && (
        <TWModal
          open={openRemoveModal}
          heading={t("Remove shared video")}
          content={t("Are you sure you want_")}
          RButton={t("Remove")}
          LButton={t("Cancel")}
          Secondary
          disablePortal
          close
          zIndex={1301}
          // disablePortal
          onClose={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenRemoveModal(false);
          }}
          onClickNegative={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenRemoveModal(false);
          }}
          onClickPositive={(e) => {
            e.stopPropagation();
            e.preventDefault();
            removeEvent();
          }}
          loading={removingEvent}
          className={classes.removeModalbg}
          titleClassName={classes.removeModalTitle}
          contentClassName={classes.removeModalContent}
          actionClassName={classes.removeModalBtnDiv}
          closeStyle={classes.removeModalClose}
          fullWidthBtn={true}
          LButtonClassName={clsx(classes.removeLeftBtn, classes.removeBtn)}
          RButtonClassName={classes.removeBtn}
        />
      )}
      {!singleEvent && (
        <TWModal
          open={openNoLocPermModal}
          headingIcon={<ErrorIcon htmlColor={LightColors.secondary["11"]} />}
          heading={t("Location access permission required")}
          content={
            <>
              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <img
                  src="/images/loc-perm-required.png"
                  alt="location permissin is required"
                  width={346}
                />
              </div>
            </>
          }
          close
          onClose={() => setOpenNoLocPermModal(false)}
          onClickPositive={() => setOpenNoLocPermModal(false)}
          RButton={t("OK")}
        />
      )}
      {!singleEvent && (
        <TWModal
          open={openNoLocPermIOSModal}
          heading={t("Want to center the map on your location?")}
          content={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography category="Default" variant="Body">
                {t("To let the app access your location_")}
              </Typography>
              <img
                src="/images/ios-loc-perm.png"
                style={{ width: 270, paddingTop: 16, paddingBottom: 16 }}
                alt="No mic permission"
              />
            </div>
          }
          close
          onClose={() => setOpenNoLocPermIOSModal(false)}
          onClickPositive={() => setOpenNoLocPermIOSModal(false)}
          contentClassName={classes.iosModalContent}
          RButton={t("OK")}
        />
      )}

      {!singleEvent && (
        <TWModal
          open={openNoPermModal}
          close
          onClose={() => setOpenNoPermModal(false)}
          onClickPositive={() => setOpenNoPermModal(false)}
          heading={" "}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("You do not have permission_"),
              }}
            />
          }
          RButton={t("OK")}
          actionClassName={classes.modalBottom}
          contentClassName={classes.eventmapModalContent}
          className={classes.modalWrap}
        />
      )}
      {eventDetailModal}

      {/* 공유이벤트 해시태그 필터 모달 */}
      <SharedEventFilterModal
        open={openEventFilter}
        onClose={() => {
          setOpenEventFilter(false);
        }}
        filterHashtag={filterHashtag}
        onFilterHashtagName={(filterTag) => {
          setFilterHashtag(filterTag);
          setOpenEventFilter(false);
          getSharedEvents(filterTag);
        }}
      />
    </div>
  );
};
