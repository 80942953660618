import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import React, { Dispatch, forwardRef, SetStateAction, useMemo } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// import { useTranslation } from "react-i18next";
import { Card, useMediaQuery } from "@material-ui/core";
import {
  DashcamEventHandler,
  Typography,
  Tooltip,
  IconButton,
  Avatars,
  Button,
} from "@thingsw/pitta-design-system";

import NotificationsIcon from "@material-ui/icons/Notifications";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  getCloudConnectivityIcon,
  getCloudConnectivityText,
} from "../../utils/Camera";
import clsx from "clsx";
import {
  ICameraInfo,
  LightColors,
  OLD_MODELS,
  ENABLED_CLOUD_OLD_MODELS,
} from "@thingsw/pitta-modules";
import { BatteryStatusButton } from "../battery/BatteryStatusButton";
import useAbleBatteryCloud from "../../hooks/useAbleBatteryCloud";
import _ from "lodash";
import { ReactSVG } from "react-svg";

export interface CameraInfoProps {
  camera: ICameraInfo;

  onMore?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    psn: string
  ) => void;
  onClick?: DashcamEventHandler;
  onNotification?: DashcamEventHandler;
  onUpgrade: () => void;
  onRemove: ((camera: ICameraInfo) => void) | null;
  newFw?: boolean;
  disabled?: boolean;

  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  connectivityStyle?: React.CSSProperties;
  setPanelState: Dispatch<SetStateAction<"battery" | "list" | "setting">>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: CameraInfoProps) => ({
    minHeight: "fit-content",
    cursor: "pointer",
    margin: "4px 16px",
    ...(props.disabled
      ? {
          cursor: "auto",
          opacity: 0.35,
          "&:hover div": {
            backgroundColor: LightColors.primary["0"],
          },
          "&:active div": {
            backgroundColor: LightColors.primary["0"],
          },
          "&:hover": {
            // color: LightColors.primary["3"],
          },
        }
      : {}),
    position: "relative",
    borderRadius: 16,
    boxShadow: `-2px 2px 12px -4px rgb(0 0 0 / 12%), 0px 2px 16px -4px rgb(0 0 0 / 16%)`,
  }),
  profile: {
    padding: "16px 16px 12px",
    marginInlineEnd: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
  },
  camName: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
    justifyContent: "center",
  },
  icon: {
    display: "block",
  },
  lteIcon: {
    display: "block",
    fontSize: 18,
  },
  iconSamll: {
    fontSize: 18,
  },
  iconActive: {
    fill: LightColors.primary["7"],
  },
  iconInactive: {
    fill: LightColors.primary["3"],
  },
  iconDiv: {
    minWidth: 24,
    // marginRight: (props) => (props.more ? 5 : 13),
    display: "flex",
    justifyContent: "center",
    ...(theme.direction === "rtl" ? { marginLeft: 13 } : { marginRight: 13 }),
  },
  iconDivMore: {
    ...(theme.direction === "rtl" ? { marginLeft: 5 } : { marginRight: 5 }),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  marginB5: {
    marginBottom: 5,
  },
  marginB4: {
    marginBottom: 4,
  },
  infoDiv: {
    flexGrow: 1,
  },
  moreBtn: {
    padding: 0,
    color: LightColors.primary["4"],
    ...(theme.direction === "rtl" && { transform: "scaleX(-1)" }),
  },
  event: {
    fill: LightColors.secondary["11"],
  },
  status: {
    padding: "12px 21px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  noti: {
    display: "flex",
    alignItems: "center",
  },
  BatteryStatusButton: {
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
  divider: {
    margin: "0 10px",
    height: 1,
    background: "rgba(233, 233, 234, 0.45)",
    borderRadius: 1,
  },
  invalid: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(4px)",
    borderRadius: 16,
    padding: "14px 10px 22px 15px",
    // mantis - 10956, 모바일에서도 x 버튼 나오도록 수정(Leehj)
    [theme.breakpoints.down(325)]: {
      padding: "13px 10px 22px 10px",
    },
  },
  btn: {
    borderRadius: 10,
    minWidth: 157,
    fontSize: 14,
    minHeight: 29,
    // mantis - 10956, 모바일에서도 x 버튼 나오도록 수정(Leehj)
    [theme.breakpoints.down(325)]: {
      minWidth: 131.5,
      maxHeight: 29,
    },
  },
  upgradeBtn: {
    background: "rgba(255, 255, 255, 0.65)",
    border: "1px solid #E9E9EA",
    backdropFilter: "blur(4px)",
    color: LightColors.primary["7"],
    // mantis - 10956, 모바일에서도 x 버튼 나오도록 수정(Leehj)
    [theme.breakpoints.down(325)]: {
      marginRight: 4,
    },
  },
  removeBtn: {
    background: "rgba(143, 143, 143, 0.55)",
    border: "1px solid #BEBEC1",
    backdropFilter: "blur(4px)",
    color: LightColors.primary["0"],
    // mantis - 10956, 모바일에서도 x 버튼 나오도록 수정(Leehj)
    [theme.breakpoints.down(325)]: {
      whiteSpace: "nowrap",
    },
  },
  storeDiv: {
    display: "flex",
    width: "100%",
    // justifyContent: "space-evenly",
    justifyContent: "center",
    marginTop: 32,
    [theme.breakpoints.down(361)]: {
      flexDirection: "column",
    },
  },
  store: {
    display: "flex",
    flexDirection: "column",
    minHeight: 152,
    justifyContent: "space-between",
    alignItems: "center",
  },
  appStore: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(5) }
      : { marginRight: theme.spacing(5) }),
    [theme.breakpoints.down(361)]: {
      margin: 0,
      marginBottom: 32,
    },
  },
  qr: {
    width: 80,
    height: 80,
  },
  connectTextDiv: {
    marginLeft: 8,
  },
}));

export const CameraInfo = forwardRef<HTMLButtonElement, CameraInfoProps>(
  (props, ref) => {
    const {
      camera,
      onMore,
      onClick,
      onUpgrade,
      onRemove,
      onNotification,
      disabled,
      style,
      newFw,
      setPanelState,
    } = props;
    const classes = useStyles(props);
    const theme = useTheme();
    const { t } = useTranslation();
    const mobile = useMediaQuery(theme.breakpoints.down(956));
    const semobile = useMediaQuery(theme.breakpoints.down(325));
    const isAbleBatteryCloud = useAbleBatteryCloud(camera);
    const isNoneCloudModel = _.includes(
      _.filter(OLD_MODELS, (d) => !ENABLED_CLOUD_OLD_MODELS.includes(d)),
      camera?.model
    );

    // Cloud registered but not connected yet인 경우 connection step문구 미출력 이슈 처리(Leehj) 23.01.11
    // camera.login_date가 없는 경우 return false
    const loggined = useMemo(() => {
      if (!camera.login_date) {
        return false;
      } else {
        return !moment(camera.login_date).isBefore(moment.utc(0));
      }
    }, [camera.login_date]);

    const notiText = useMemo(() => {
      if (camera.new_notification > 99) {
        return "99+";
      } else if (camera.new_notification > 0) {
        return `${camera.new_notification}`;
      }
      return "";
    }, [camera.new_notification]);

    const statusMarkup = useMemo(() => {
      if (camera.reg_category === "wifi" || !loggined) {
        if (isAbleBatteryCloud) {
          const step = camera.reg_category === "wifi" ? 1 : 2;
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginRight: 6,
                }}
              >
                <Typography
                  category="Default"
                  variant="Caption"
                  htmlColor={LightColors.primary["2"]}
                  style={{ lineHeight: 1 }}
                >
                  {t("Cloud connection")}
                </Typography>
                <Typography
                  category="Default"
                  variant="CaptionBold"
                  htmlColor={LightColors.primary["7"]}
                >
                  {t("Step N/N", { a: step, b: 2 })}
                </Typography>
              </div>

              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: 3,
                  backgroundColor: LightColors.secondary["11"],
                }}
              />

              <IconButton ref={ref} className={classes.moreBtn}>
                <KeyboardArrowRightIcon htmlColor={LightColors.primary["3"]} />
              </IconButton>
            </>
          );
        } else {
          const step = camera.reg_category === "wifi" ? 1 : 2;
          return (
            <>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
                style={{ lineHeight: 1 }}
              >
                {t("Cloud connection steps")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["7"]}
                style={{ lineHeight: 1, marginLeft: 6, marginRight: 6 }}
              >
                {t("n of n", { a: step, b: 2 })}
              </Typography>

              <div
                style={{
                  width: 4,
                  height: 4,
                  borderRadius: 2,
                  backgroundColor: LightColors.secondary["11"],
                  marginInlineEnd: 6,
                }}
              />

              <IconButton ref={ref} className={classes.moreBtn}>
                <KeyboardArrowRightIcon htmlColor={LightColors.primary["4"]} />
              </IconButton>
            </>
          );
        }
      }

      return (
        <>
          <div
            style={{ position: "relative" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onNotification?.(camera);
              // setOpenNotification(true);
            }}
          >
            <NotificationsIcon
              style={{ display: "block" }}
              htmlColor={LightColors.primary["4"]}
            />

            {camera.new_notification > 0 && (
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: 3,
                  backgroundColor: LightColors.secondary["11"],
                  position: "absolute",
                  top: -3,
                  right: -3,
                }}
              />
            )}
          </div>
          <Typography category="Default" variant="Caption">
            {notiText}
          </Typography>
        </>
      );
    }, [
      camera,
      classes.moreBtn,
      isAbleBatteryCloud,
      loggined,
      notiText,
      onNotification,
      ref,
      t,
    ]);

    return (
      <>
        <Card
          style={style}
          className={classes.root}
          onClick={() => {
            if (!disabled) {
              onClick?.(camera);
            }
          }}
        >
          <div className={classes.profile}>
            <div className={classes.avatar}>
              {!isNoneCloudModel ? (
                <Avatars
                  name={camera.dev_name}
                  imgSrc={`${camera.profile_img}`}
                  style={{ width: 44, height: 44 }}
                />
              ) : (
                <ReactSVG
                  src={"/images/noCloudCamera.svg"}
                  style={{ width: 44, height: 44 }}
                />
              )}
              <div className={classes.camName}>
                <Typography
                  category="Default"
                  variant="SmallBold"
                  htmlColor={LightColors.primary["1"]}
                  style={{
                    wordBreak: "break-all",
                    whiteSpace: "nowrap",
                    marginBottom: 4,
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {camera.dev_name}
                </Typography>
                <Typography
                  category="Default"
                  variant={"Caption"}
                  htmlColor={LightColors.primary["2"]}
                  style={{ fontSize: 10 }}
                >
                  {camera.model === "590X2" ? "DR590X-2CH" : camera.model}
                </Typography>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <Tooltip
                disableTouchListener={mobile}
                placement="bottom"
                title={"More"}
              >
                <IconButton
                  ref={ref}
                  className={classes.moreBtn}
                  onClick={(e) => onMore?.(e, camera.psn)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>

              {(newFw || camera?.require_time_sync) && (
                <div
                  style={{
                    width: 6,
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: LightColors.secondary["11"],
                    position: "absolute",
                    top: -3,
                    right: -3,
                  }}
                />
              )}
            </div>
          </div>
          {!isNoneCloudModel && (
            <>
              <div className={classes.divider} />
              <div className={classes.status}>
                <div className={classes.noti}>
                  {getCloudConnectivityIcon(camera)}

                  {isAbleBatteryCloud && (
                    <div className={clsx([classes.BatteryStatusButton])}>
                      <BatteryStatusButton
                        camera={camera}
                        setPanelState={setPanelState}
                        onClick={onClick}
                      />
                    </div>
                  )}

                  {!isAbleBatteryCloud && (
                    <div className={classes.connectTextDiv}>
                      <Typography variant="Caption">
                        {t(getCloudConnectivityText(camera))}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className={classes.noti}>{statusMarkup}</div>
              </div>

              {camera.valid === "invalid" && camera.reg_category !== "wifi" && (
                <div className={classes.invalid}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: semobile ? 2 : 9,
                    }}
                  >
                    <CloudOffIcon
                      htmlColor={LightColors.primary["0"]}
                      style={{ fontSize: 32, margin: "0 12px 0 16px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("Your previous plan is expired.")}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="SmallBold"
                        htmlColor={LightColors.primary["0"]}
                      >
                        {t("Upgrade the plan to restore the camera")} (
                        {camera.dev_name})
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className={clsx(classes.btn, classes.upgradeBtn)}
                      onClick={(e) => {
                        e.stopPropagation();
                        onUpgrade();
                      }}
                    >
                      {t("Upgrade")}
                    </Button>

                    <Button
                      className={clsx(classes.btn, classes.removeBtn)}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove?.(camera);
                      }}
                    >
                      {t("Remove from cloud")}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
);
