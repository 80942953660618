import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  GeofenceAlert,
  RouteHeader,
  Tab,
  Tabs,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import _ from "lodash";
import { Index } from "react-virtualized";
import EventItem from "@thingsw/pitta-design-system/dist/components/EventItem";
import { useTranslation } from "react-i18next";

import DateRangeIcon from "@material-ui/icons/DateRange";
import NavigationIcon from "@material-ui/icons/Navigation";

import CloseIcon from "@material-ui/icons/Close";

import { Scrollbars } from "react-custom-scrollbars";
import { AutoSizer } from "react-virtualized";
import {
  GPS,
  IDriveInfo,
  ITrackInfo,
  setSelectedDrive,
  setSelectedTrack,
  setUpdateMapBounds,
} from "../../features/GPS/slice";
import { EmptyItems } from "../EmptyItems";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { IconButton, useMediaQuery } from "@material-ui/core";
import {
  EventAbbrToEventFull,
  ITrackData,
  LightColors,
  MCODE_TO_TEXT,
  Webviewer,
} from "@thingsw/pitta-modules";
import { detect } from "detect-browser";
import { THEME } from "../../features/Theme/slice";

const useStyles = makeStyles((theme: Theme) => ({
  listPanel: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "calc(100% - 8px)",
    margin: theme.spacing(1, 0, 0),
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2),
      height: "calc(100% - 32px)",
      ...(theme.direction === "rtl" ? { left: "unset" } : { right: "unset" }),

      boxShadow: "unset",
    },
    display: "flex",
  },
  routesPanel: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create(["width", "height"]),
    backgroundColor: LightColors.primary["0"],
    borderRadius: 12,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08);",
  },
  routesPanelOpen: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  routesPanelClose: {
    width: 0,
  },
  titleDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  backArrow: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  panelBodyDiv: {
    flex: 1,
    display: "flex",
    position: "relative",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 320,
    },
  },
  listDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    transition: theme.transitions.create("transform"),
  },
  listDivHide: {
    transform: "translateX(-100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(-320px)",
    },
  },
  dateDiv: {
    padding: theme.spacing(3, 2, 2, 2),
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
  },
  routeListDiv: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 69px - 85px)",
  },
  emptyDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  routesListDiv: {
    height: "calc(100% - 54px)",
    width: "100%",
  },
  routeDateTextDiv: {
    padding: theme.spacing(0.75, 2),
  },
  btnDiv: {
    display: "flex",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  fabMargin: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.5) }
      : { marginLeft: theme.spacing(0.5) }),
  },
  detailDivHide: {
    transform: "translateX(100vw)",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      transform: "translateX(320px)",
    },
  },
  detailHeaderDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    "&:not(:first-child)": {
      paddingTop: 0,
    },
  },
  detailHeaderIcon: {
    color: LightColors.primary["3"],
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  detailTabDiv: {
    borderBottom: (props: any) => `1px solid ${props.colors.primary["5"]}`,
    // minHeight: 36,
    maxHeight: 36,
  },
  // 탭을 상단에 고정시키기 위한 css (Leehj)
  appDetailTabDiv: {
    position: "fixed",
    top: 48,
    left: 0,
    width: "100%",
    zIndex: 999,
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  logListWarpper: {
    position: "relative",
    flex: 1,
    overflowY: "auto",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  scrollableDiv: {
    position: "absolute",
    top: 0,
    bottom: -96,
    left: 0,
    right: 0,
    display: "flex",
    paddingTop: 96,
    flexDirection: "column",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      bottom: 0,
      overflow: "hidden",
    },
  },
  appScrollableDiv: {
    paddingTop: 48,
    position: "unset",
    height: "calc(100% - 48px)",
  },
  logDiv: {
    position: "relative",
    display: "flex",
    flex: 1,
  },
  geofenceAlertDiv: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    "&:last-child": {
      borderBottom: undefined,
    },
  },
  noScrollDiv: {
    overflow: "hidden!important",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      overflow: "auto!important",
    },
  },
  flex1: {
    flex: 1,
  },
  fabUXUI: {
    borderRadius: 12,
    border: `1px solid ${LightColors.primary["6"]}`,
    boxShadow: "none",
    marginTop: 20,
  },
  noHover: {
    color: (props: any) => props.colors.primary["1"],
    "&:hover": {
      fill: LightColors.primary["1"],
      color: (props: any) => props.colors.primary["1"] + "!important",
    },
  },
  active: {
    fill: LightColors.primary["7"] + "!important",
    color: (props: any) => props.colors.primary["7"] + "!important",
  },
  trackList: {
    "& div": {
      color: (props: any) => props.colors.primary["1"] + "!important",
      backgroundColor: (props: any) => props.colors.primary["0"] + "!important",
      borderColor: (props: any) => props.colors.primary["5"] + "!important",
    },
  },
}));

interface GPSTrackingListPanelProps {
  onChangePlayTrack: (track: ITrackData) => void;
  onDownload: (data: ITrackData) => void;
  app?: boolean;
  onClose?: () => void;
}

export const GPSTrackingListPanel = ({
  onChangePlayTrack,
  onDownload,
  app,
  onClose,}: GPSTrackingListPanelProps) => {  
  const theme = useTheme() as Theme;
  const themeState = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles(themeState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const detailListRef = useRef<List>(null);
  const detailGeoListRef = useRef<List>(null);
  const detailScrollRef = useRef<Scrollbars>(null);

  const {
    tracks,
    geofenceAlerts,
    driveList,
    selectedDrive,
    selectedTrack,
    selectedDrives,
    showTracks,
    updateLoc,
    updateMapBounds,
  } = useSelector((state: RootState) => state[GPS]);

  const { userSettings } = useSelector((state: RootState) => state[USER]);

  const [tabValue, setTabValue] = useState(0);

  const [logListDiv, setLogListDiv] = useState<HTMLDivElement | null>(null);
  const [openDrives, setOpenDrives] = useState<boolean[]>([]);
  const [visibleRow, setVisibleRow] = useState<number>();
  const [showLoading, setShowLoading] = useState(true);
  const [, /*canScrollList*/ setCanScrollList] = useState(false);
  const [tabDivRef, setTabDivRef] = useState<HTMLDivElement | null>(null);
  const [calculatedLogHeight, setCalculatedLogHeight] = useState<number[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [needToUpdateScroll, setNeedToUpdateScroll] = useState(false);

  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // useEffect(() => {
    // dispatch(loadUserSettings());
    // mantis - 84, 카메라와 날짜 선택, routes선택 후 show버튼 클릭 시 무한 로딩 이슈 해결을 위해 UpdateMapBounds ture (Leehj)
    // dispatch(setUpdateMapBounds(true));
  // }, [dispatch]);

  useEffect(() => {
    if (tracks.length > 0 && openDrives.length > 0) {
      const cnt = _.chain(_.zip(selectedDrives, openDrives))
        .map((dr) => {
          if (dr[1]) {
            const track = _.find(
              tracks,
              (tr) => tr.drive_no === dr[0]?.drive_no
            );
            const len = _.chain(track?.data)
              .map((d) => {
                if (d.pevents) {
                  return 1 + d.pevents.length;
                }
                return 1;
              })
              .sum()
              .value();
            return [1, len];
          } else {
            return 1;
          }
        })
        .flattenDeep()
        .sum()
        .value();
      setRowCount(cnt);
      const heights = _.range(0, cnt).map((indx) => {
        const counts = _.chain(_.zip(selectedDrives, openDrives))
          .map((dr) => {
            if (dr[1]) {
              const track = _.find(
                tracks,
                (tr) => tr.drive_no === dr[0]?.drive_no
              );
              const len = _.chain(track?.data)
                .map((d) => {
                  if (d.pevents) {
                    return 1 + d.pevents.length;
                  }
                  return 1;
                })
                .sum()
                .value();
              return [1, len];
            } else {
              return 1;
            }
          })
          .flattenDeep()
          .value();
        let isRoute = true;
        let driveIndx = 0;
        let prevSum = 0;
        let prevPIndx = -1;
        let pIndex = -1;
        let first = false;
        let last = false;
        let data: ITrackData | undefined = undefined;
        for (let i = 1; i <= counts.length; i++) {
          const c = _.chain(counts).slice(0, i).sum().value();
          const drive = selectedDrives[driveIndx];
          if (indx < c) {
            if (!isRoute) {
              first = indx - prevSum === 0;
              last = c - indx === 1;
              const track = _.find(
                tracks,
                (tr) => tr.drive_no === drive.drive_no
              );

              const totalCnt = indx - prevSum;
              let cnt = 0;
              let j = 0;
              for (; j < (track?.data?.length ?? 0); j++) {
                cnt += 1;

                if (cnt > totalCnt) break;
                cnt += track?.data?.[j]?.pevents?.length ?? 0;

                if (cnt > totalCnt) {
                  j++;
                  break;
                }
              }
              prevPIndx = _.chain(track?.data)
                .slice(0, j)
                .findLastIndex((d) => (d.pevents?.length ?? 0) > 0)
                .value();

              let vv = 0;
              if (prevPIndx > -1) {
                vv = _.chain(track?.data)
                  .slice(0, prevPIndx)
                  .map((d) => {
                    return 1 + (d.pevents?.length ?? 0);
                  })
                  .sum()
                  .value();
              }
              data = track?.data[j];

              if (prevPIndx > -1) {
                pIndex = indx - vv - prevSum - 1;
                if (
                  track?.data[prevPIndx]?.pevents &&
                  (track?.data[prevPIndx]?.pevents?.length ?? 0) > pIndex
                ) {
                  data = track.data[prevPIndx].pevents?.[pIndex];
                }
              }
            }
            break;
          } else {
            if (!isRoute) {
              driveIndx++;
              isRoute = true;
            } else if (openDrives[driveIndx]) {
              isRoute = false;
              prevSum = c;
            } else {
              driveIndx++;
            }
          }
        }
        // console.log("logRowHeight", indx, isRoute, data);
        if (isRoute) {
          return 60;
        } else {
          let height = app ? 102 : 90;
          if (
            data &&
            (data.mode === "E" ||
              (data.mode === "P" && data.pevents && data.pevents.length > 0))
          ) {
            if (data.mp4_playable) {
              height = app ? 116 : 115;
            } else {
              height = app ? 116 : 112;
            }
          }
          if (data && (data.mode === "M")) {
            height = 94;
          }
          if (first || last) {
            return height + 8;
          } else {
            return height;
          }
        }
      });
      setCalculatedLogHeight(heights);

      setShowLoading(false);
    }
  }, [tracks, openDrives, selectedDrives, app]);

  useEffect(() => {
    const element = tabDivRef;
    const handleScroll = (e: Event) => {
      if (element?.scrollTop === 96) {
        setCanScrollList(true);
      } else if (element?.scrollTop === 0) {
        setCanScrollList(false);
      }
    };
    element?.addEventListener("scroll", handleScroll);
    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [tabDivRef]);

  useEffect(() => {
    if (tracks.length === 0) {
      setShowLoading(true);
    }
  }, [tracks]);

  useEffect(() => {
    if (selectedDrive && selectedTrack && updateLoc === "map") {
      const indx = _.findIndex(
        selectedDrives,
        (tr) => tr.drive_no === selectedDrive.drive_no
      );
      // console.log(
      //   "GPSTrackingListPanel",
      //   "indx",
      //   indx,
      //   "openDrives",
      //   openDrives,
      //   "selectedDrives",
      //   selectedDrives,
      //   "selectedDrive",
      //   selectedDrive,
      //   "selectedTrack",
      //   selectedTrack
      // );
      if (indx > -1) {
        setOpenDrives((o) => {
          if (o[indx]) {
            return o;
          }
          o[indx] = true;
          return [...o];
        });
        setNeedToUpdateScroll(true);
      }

      let row = 0;
      for (let i = 0; i < indx; i++) {
        row++;
        if (openDrives[i]) {
          const dr = selectedDrives[i];
          row +=
            _.find(tracks, (tr) => tr.drive_no === dr.drive_no)?.data.length ??
            0;
        }
      }
      row++;
      const track = _.find(
        tracks,
        (tr) =>
          // Cannot read properties of undefined (reading 'drive_no') 에러로 'selectedDrives[indx] &&' 추가 (Leehj)
          selectedDrives[indx] && tr.drive_no === selectedDrives[indx].drive_no
      );
      if (track) {
        for (let data of track.data) {
          if (data.sid === selectedTrack.sid) {
            break;
          }
          row++;
        }
      }
      const scrollPos = _.chain(_.range(0, row))
        .map((ind) => calculatedLogHeight[ind])
        .sum()
        .value();
      // console.log("GPSTrackingListPanel", "scrollPos", scrollPos);
      setVisibleRow(scrollPos);
    }
  }, [
    calculatedLogHeight,
    openDrives,
    selectedDrive,
    selectedDrives,
    selectedTrack,
    selectedTrack?.sid,
    tracks,
    updateLoc,
  ]);

  useEffect(() => {
    // console.log(
    //   "GPSTrackingListPanel",
    //   "detailScrollRef.current",
    //   detailScrollRef.current,
    //   "detailListRef.current",
    //   detailListRef.current,
    //   "visibleRow",
    //   visibleRow
    // );
    if (visibleRow) {
      if (app && detailListRef.current) {
        detailListRef.current.scrollToPosition(visibleRow);
      }
      if (!app && detailScrollRef.current) {
        detailScrollRef.current.scrollTop(visibleRow);
      }
    }
  }, [app, visibleRow]);

  //여기 수정해야함..
  useEffect(() => {
    console.log("MapboxGPSTrakingMap", "updateMapBounds", updateMapBounds);
    if (tracks.length > 0 && updateMapBounds) {
      setOpenDrives(_.map(tracks, (t, indx) => indx === 0));
      // dispatch(setSelectedDrive(tracks[0]));
      dispatch(setUpdateMapBounds(false));
    }
  }, [dispatch, tracks, updateMapBounds]);

  const filteredDriveList = useMemo(() => {
    // return _.filter(list, (l) => typeof l !== "string") as IDriveInfo[];
    return driveList;
  }, [driveList]);

  const logRowHeight = useCallback(
    (indx: Index) => {
      return calculatedLogHeight[indx.index];
    },
    [calculatedLogHeight]
  );

  const logRowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      const counts = _.chain(_.zip(selectedDrives, openDrives))
        .map((dr) => {
          if (dr[1]) {
            const track = _.find(
              tracks,
              (tr) => tr.drive_no === dr[0]?.drive_no
            );
            const len = _.chain(track?.data)
              .map((d) => {
                if (d.pevents) {
                  return 1 + d.pevents.length;
                }
                return 1;
              })
              .sum()
              .value();
            return [1, len];
          } else {
            return 1;
          }
        })
        .flattenDeep()
        .value();
      let route: ITrackInfo | undefined = undefined;
      let data: ITrackData | undefined = undefined;
      let isRoute = true;
      let driveIndx = 0;
      let prevSum = 0;
      let prevPIndx = -1;
      let pIndex = -1;
      let first = false;
      let last = false;
      let prevPEvent: ITrackData | undefined = undefined;
      let drive: IDriveInfo | undefined = undefined;
      // console.log("logRowRenderer", props, counts, tracks);
      // console.log("logRowRenderer");
      for (let i = 1; i <= counts.length; i++) {
        const c = _.chain(counts).slice(0, i).sum().value();
        drive = selectedDrives[driveIndx];
        // console.log("\t", indx, c, "/", i);
        if (indx < c) {
          // eslint-disable-next-line no-loop-func
          route = _.find(tracks, (tr) => tr.drive_no === drive?.drive_no);
          first = indx - prevSum === 0;
          last = c - indx === 1;
          // let trackIndx = 0;
          const totalCnt = indx - prevSum;
          let cnt = 0;
          let j = 0;
          for (; j < (route?.data?.length ?? 0); j++) {
            cnt += 1;

            if (cnt > totalCnt) break;
            cnt += route?.data?.[j]?.pevents?.length ?? 0;

            if (cnt > totalCnt) {
              j++;
              break;
            }
          }
          prevPIndx = _.chain(route?.data)
            .slice(0, j)
            .findLastIndex((d) => (d.pevents?.length ?? 0) > 0)
            .value();
          prevPEvent = _.chain(route?.data)
            .slice(0, j)
            .findLast((d) => (d.pevents?.length ?? 0) > 0)
            .value();

          let vv = 0;
          if (prevPIndx > -1) {
            vv = _.chain(route?.data)
              .slice(0, prevPIndx)
              .map((d) => {
                return 1 + (d.pevents?.length ?? 0);
              })
              .sum()
              .value();
          }

          data = route?.data[j];

          if (prevPIndx > -1) {
            pIndex = indx - vv - prevSum - 1;
            if (
              route?.data[prevPIndx]?.pevents &&
              (route?.data[prevPIndx]?.pevents?.length ?? 0) > pIndex
            ) {
              data = route.data[prevPIndx].pevents?.[pIndex];
            }
          }

          // data = route?.data[indx - prevSum];
          break;
        } else {
          if (!isRoute) {
            driveIndx++;
            isRoute = true;
          } else if (openDrives[driveIndx]) {
            isRoute = false;
            prevSum = c;
          } else {
            driveIndx++;
          }
        }
      }
      // console.log(
      //   "\tlogRowRenderer",
      //   `A${driveIndx}`,
      //   isRoute,
      //   "pIndex",
      //   pIndex,
      //   "prevPIndx",
      //   prevPIndx,
      //   "prevSum",
      //   prevSum,
      //   "prevPEvent",
      //   prevPEvent,
      //   "data",
      //   data
      // );

      if (isRoute && drive) {
        // const route = selectedTrack[indx];
        let dateText = "";
        // console.log("logRowRenderer", indx, route);
        if (drive.present) {
          if (app) {
            dateText = `${drive.sdate.format("YYYY-MM-DD h:mm:ss A")} ~ ${t(
              "present"
            )}`;
          } else {
            dateText = `${drive.sdate.format("MMM DD YYYY, HH:mm:ss")} - ${t(
              "present"
            )}`;
          }

          // dateText = `${drive.sdate.format("YYYY-MM-DD h:mm:ss A")} ~ ${t(
          //   "present"
          // )}`;
        } else if (drive.sdate.isSame(drive.edate, "day")) {
          if (app) {
            dateText = `${drive.sdate.format(
              "YYYY-MM-DD h:mm:ss A"
            )} ~ ${drive.edate.format("YYYY-MM-DD h:mm:ss A")}`;
          } else {
            dateText = `${drive.sdate.format(
              "MMM DD YYYY, HH:mm:ss"
            )} - ${drive.edate.format("HH:mm:ss")}`;
          }
          // dateText = `${drive.sdate.format(
          //   "YYYY-MM-DD h:mm:ss A"
          // )} ~ ${drive.edate.format("YYYY-MM-DD h:mm:ss A")}`;
        } else {
          // dateText = `${drive.sdate.format(
          //   "YYYY-MM-DD h:mm:ss A"
          // )} ~ ${drive.edate.format("YYYY-MM-DD h:mm:ss A")}`;
          if (app) {
            dateText = `${drive.sdate.format(
              "YYYY-MM-DD h:mm:ss A"
            )} ~ ${drive.edate.format("YYYY-MM-DD h:mm:ss A")}`;
          } else {
            dateText = `${drive.sdate.format(
              "YYYY-MM-DD HH:mm:ss"
            )} - ${drive.edate.format("YYYY-MM-DD HH:mm:ss")}`;
          }
        }

        return (
          <div
            key={props.key}
            style={{
              backgroundColor: LightColors.primary["0"],
              ...(!openDrives[driveIndx] && {
                borderBottom: `1px solid ${LightColors.primary["6"]}`,
              }),
              // app인경우 열려있는 route의 borderTop은 파란색으로 피그마 디자인 적용(Leehj)
              ...(openDrives[driveIndx] &&
                app && {
                  borderTop: `1px solid ${LightColors.primary["7"]}`,
                }),
              ...props.style,
            }}
          >
            <RouteHeader
              app={app}
              heading={`A${driveIndx}`}
              content={dateText}
              open={openDrives[driveIndx]}
              onClick={() => {
                openDrives[driveIndx] = !openDrives[driveIndx];
                if (_.compact(openDrives).length === 0) {
                  dispatch(setSelectedDrive(undefined));
                } else if (openDrives[driveIndx]) {
                  route && dispatch(setSelectedDrive(route));
                }
                setOpenDrives([...openDrives]);
                detailListRef.current?.recomputeRowHeights();
                // detailListRef.current?.forceUpdateGrid();
                forceUpdate();

                // console.log("RouteHeader", "clicked", openTracks);
              }}
            />
          </div>
        );
      }
      if (!isRoute && data) {
        let parkingEvent = false;
        let eventString = "";
        if (data.mode === "E") {
          if (
            prevPEvent?.mode === "P" &&
            prevPEvent.loc[0] === data.loc[0] &&
            prevPEvent.loc[1] === data.loc[1]
          ) {
            parkingEvent = true;
            if (data.mode === "E") {
              eventString = "Parking impact";
            }
          } else {
            eventString = MCODE_TO_TEXT[data.mtype];
          }
        } else if (
          data.mode === "P" &&
          data.pevents &&
          data.pevents.length > 0
        ) {
          eventString = `${t("Parking events")} · ${data.pevents.length}`;
        }
        const isAI = (data.mtype >= 6 && data.mtype <= 12) || data.mtype === 17;
        // 파일이 클라우드에 있는 경우 추가 (8483)
        // 8584 - playable 수정
        const isPlay =
          data.front_playable || data.rear_playable || data.sub3_playable;

        // console.log(
        //   "data",
        //   data,
        //   "time",
        //   data.vdate.format("YYYY-MM-DD h:mm:ss A")
        // );
        return (
          <div
            key={props.key}
            style={{
              backgroundColor: app
                ? LightColors.primary["0"]
                : "rgba(233, 233, 234, 0.35)",
              paddingLeft: parkingEvent ? 26 : app ? 8 : 16,
              paddingRight: app ? 8 : 16,
              ...props.style,
            }}
          >
            <div
              style={{
                paddingLeft: parkingEvent ? 4 : 0,
                ...(first && { paddingBottom: 2, paddingTop: 8 }),
                ...(last && {
                  paddingBottom: 8,
                  paddingTop: 2,
                  borderBottom: `1px solid ${LightColors.primary["6"]}`,
                }),
                ...(!first && !last && { paddingBottom: 2, paddingTop: 2 }),
                borderLeft: parkingEvent
                  ? `2px solid ${LightColors.primary["6"]}`
                  : undefined,
              }}
            >
              <EventItem
                mobile
                app={app}
                t={t}
                heading={
                  // app
                  //   ? data.vdate.format("YYYY-MM-DD h:mm:ss A")
                  //   : data.vdate.format("MMM DD YYYY, HH:mm:ss")
                  data.vdate.format("YYYY-MM-DD h:mm:ss A")
                }
                speed={
                  // Speed 이벤트 예외 처리
                  userSettings?.velocityUnit === "0"
                    ? `${(data.mode === "J" || data.mtype === 1)
                      ? Math.floor(data.speed * 1.852) 
                      : Math.floor(data.avg_speed * 1.852)} km/h`
                    : `${(data.mode === "J" || data.mtype === 1) 
                      ? Math.floor(data.speed * 1.150779)
                      : Math.floor(data.avg_speed * 1.150779)} MPH`
                }
                fileType={EventAbbrToEventFull[data.mode]}
                content={t(eventString)}
                content2={`${data.loc[1].toFixed(6)}, ${data.loc[0].toFixed(6)}`}
                contentAlt=""
                isAI={isAI}
                play={isPlay}
                download={isPlay}
                hoverable={!app}
                hover={data.sid === selectedTrack?.sid}
                onClick={() => {
                  const d = data as ITrackData;
                  route && dispatch(setSelectedDrive(route));
                  if (parkingEvent && prevPEvent) {
                    dispatch(
                      setSelectedTrack({
                        updateLoc: "panel",
                        track: {
                          ...prevPEvent,
                          //@ts-ignore
                          vdate: prevPEvent.vdate.unix(),
                        },
                      })
                    );
                    // onChangeInfoWindow({
                    //   drive_no: tracks[driveIndx].drive_no,
                    //   data: prevPEvent,
                    //   index: prevPIndx,
                    //   sid: prevPEvent.sid,
                    //   selectedSid: d.sid,
                    // });
                  } else {
                    dispatch(
                      setSelectedTrack({
                        updateLoc: "panel",
                        //@ts-ignore
                        track: { ...d, vdate: d.vdate.unix() },
                      })
                    );
                    // onChangeInfoWindow({
                    //   drive_no: tracks[driveIndx].drive_no,
                    //   data: d,
                    //   index: indx - prevSum,
                    //   sid: d.sid,
                    //   selectedSid: d.sid,
                    // });
                  }

                  // 13137 이슈 수정
                  if (app) {
                    onClose?.();
                  }
                }}
                onPlay={() => {
                  data && onChangePlayTrack(data);
                }}
                onDownload={() => {
                  if (data) {
                    onDownload(data);
                  }
                }}
              />
            </div>
          </div>
        );
      }
    },
    [
      selectedDrives,
      openDrives,
      tracks,
      app,
      t,
      forceUpdate,
      onClose,
      dispatch,
      userSettings?.velocityUnit,
      selectedTrack?.sid,
      onChangePlayTrack,
      onDownload,
    ]
  );

  const geofenceAlertRenderer: ListRowRenderer = useCallback(
    (props) => {
      const indx = props.index;
      let cnt = 0;
      let aIndx = 0;
      let speed = "";

      for (let alerts of geofenceAlerts) {
        const count = cnt + alerts.data.length;
        if (indx < count) {
          break;
        }
        cnt = count;
        aIndx++;
      }

      const data = geofenceAlerts[aIndx].data[indx - cnt];

      if (data.speed) {
        speed = userSettings?.velocityUnit === "0" ? `${data.speed}Km/h` : `${data.speed}MPH`;
      }

      return (
        <div
          className={classes.geofenceAlertDiv}
          key={props.key}
          style={{ ...props.style }}
        >
          <GeofenceAlert
            date={data.time.format("YYYY-MM-DD h:mm:ss A")}
            fenceName={data.fenceName}
            sendStatus={data.sendStatus}
            latLng={`${data.latLng.lat}, ${data.latLng.lng}`}
            speed={speed}
          />
        </div>
      );
    },
    [classes.geofenceAlertDiv, geofenceAlerts, userSettings?.velocityUnit]
  );

  const handleScroll = useCallback(
    (ref: React.RefObject<List>) => (e: any) => {
      if (ref.current) {
        const { scrollTop, scrollLeft } = e.target;
        const { Grid } = ref.current;
        Grid?.handleScrollEvent({ scrollTop, scrollLeft });
      }
    },
    []
  );

  const renderListHelper = useCallback(
    (
      width: number,
      height: number,
      elem: React.ReactElement,
      ref: React.RefObject<List>,
      scrollRef?: React.RefObject<Scrollbars>
    ) => {
      return mobile ? (
        elem
      ) : (
        <Scrollbars
          ref={scrollRef}
          onScroll={handleScroll(ref)}
          style={{ height, width }}
          autoHide
          renderView={(props) => (
            <div
              {...props}
              style={{
                marginLeft: theme.direction === "rtl" ? -17 : 0,
                marginRight: theme.direction === "rtl" ? 0 : -17,
                overflowY: "auto",
              }}
            />
          )}
        >
          {elem}
        </Scrollbars>
      );
    },
    [handleScroll, mobile, theme.direction]
  );

  const detailPaneMarkup = useMemo(() => {
    const geofenceLength = _.chain(geofenceAlerts)
      .map((g) => g.data)
      .flattenDeep()
      .value().length;
    const sdrive = _.chain(selectedDrives)
      .sortBy((s) => s.sdate.unix())
      .first()
      .value();
    const edrive = _.chain(selectedDrives)
      .sortBy((s) => s.edate.unix())
      .last()
      .value();

    const browserName = detect()?.name;
    const ios = browserName === "ios" || browserName === "ios-webview";

    let dateText = "";
    // console.log("logRowRenderer", indx, route);
    if (sdrive && edrive) {
      dateText = `${sdrive.sdate.format(
        "MMM DD, YYYY"
      )} - ${edrive.edate.format("MMM DD, YYYY")}`;
    }

    let tab0Markup = (
      <div style={{ width: "100%", display: "flex" }}>
        <EmptyItems 
          variant="small" 
          mode="routes" 
          darkMode={themeState?.color === "dark"}
        />
      </div>
    );

    // console.log(
    //   "detailPaneMarkup",
    //   showLoading,
    //   showTracks,
    //   rowCount,
    //   calculatedLogHeight
    // );

    if (showTracks) {
      if (showLoading) {
        tab0Markup = (
          <div className={classes.emptyDiv}>
            <CircularProgress thickness={4} />
          </div>
        );
      } else if (logListDiv && tracks.length > 0) {
        tab0Markup = (
          <AutoSizer>
            {({ height, width }) =>
              renderListHelper(
                width,
                height,
                <List
                  className={clsx(classes.trackList, {
                    // [classes.noScrollDiv]: !canScrollList,
                  })}
                  width={width}
                  height={height}
                  rowCount={rowCount}
                  rowRenderer={logRowRenderer}
                  rowHeight={logRowHeight}
                  // overscanRowCount={30}
                  // scrollToIndex={visibleRow}
                  ref={detailListRef}
                  onRowsRendered={(info) => {
                    // console.log(
                    //   "onRowsRendered",
                    //   info,
                    //   selectedDrive,
                    //   selectedTrack,
                    //   needToUpdateScroll
                    // );
                    if (selectedDrive && selectedTrack) {
                      if (needToUpdateScroll) {
                        const indx = _.findIndex(
                          selectedDrives,
                          (tr) => tr.drive_no === selectedDrive.drive_no
                        );
                        let row = 0;
                        for (let i = 0; i < indx; i++) {
                          row++;
                          if (openDrives[i]) {
                            const dr = selectedDrives[i];
                            row +=
                              _.find(
                                tracks,
                                (tr) => tr.drive_no === dr.drive_no
                              )?.data.length ?? 0;
                          }
                        }
                        row++;
                        const track = _.find(
                          tracks,
                          (tr) =>
                            // Cannot read properties of undefined (reading 'drive_no') 에러로 'selectedDrives[indx] &&' 추가 (Leehj)
                            selectedDrives[indx] &&
                            tr.drive_no === selectedDrives[indx].drive_no
                        );
                        if (track) {
                          for (let data of track.data) {
                            if (data.sid === selectedTrack.sid) {
                              break;
                            }
                            row++;
                          }
                        }
                        const scrollPos = _.chain(_.range(0, row))
                          .map((ind) => calculatedLogHeight[ind])
                          .sum()
                          .value();
                        setVisibleRow(scrollPos);
                        setNeedToUpdateScroll(false);
                      }
                    }
                  }}
                  onScroll={(p: any) => {
                    // 스크롤 후, 원래 시도했던 스크롤 (visibleRow)까지 스크롤이 정상적으로 이루어지지 않으면
                    // 재시도 (7177)
                    // console.log(
                    //   "onScroll",
                    //   visibleRow,
                    //   detailScrollRef.current?.getScrollTop() !== visibleRow
                    // );
                    if (
                      visibleRow &&
                      detailScrollRef.current?.getScrollTop() !== visibleRow
                    ) {
                      // mantis = 8569
                      // item = 클릭한 item의 높이 값
                      // GPS Tracking log의 height 값이
                      // item의 높이 보다 작으면 Scroll 이동
                      // item의 높이 보다 크면 setVisibleRow(undefined)
                      const item = detailScrollRef.current?.getScrollTop();
                      if (item) {
                        if (visibleRow - item > height) {
                          detailScrollRef.current?.scrollTop(visibleRow);
                        } else {
                          setVisibleRow(undefined);
                        }
                      }
                    } else {
                      setVisibleRow(undefined);
                    }
                  }}
                  style={
                    mobile
                      ? {
                      }
                      : {
                          overflowY: "visible",
                          overflowX: "visible",
                        }
                  }
                />,
                detailListRef,
                detailScrollRef
              )
            }
          </AutoSizer>
        );
      }
    }

    let tab1Markup = (
      <div style={{ width: "100%", display: "flex" }}>
        <EmptyItems 
          variant="small" 
          mode="geofence-alert"
          darkMode={themeState?.color === "dark"}
        />
      </div>
    );

    if (logListDiv && showTracks && geofenceLength > 0) {
      tab1Markup = (
        <AutoSizer>
          {({ height, width }) =>
            renderListHelper(
              width,
              height,
              <List
                className={clsx(classes.trackList, {
                  // [classes.noScrollDiv]: !canScrollList,
                })}
                width={width}
                height={height}
                rowCount={geofenceLength}
                rowRenderer={geofenceAlertRenderer}
                rowHeight={137}
                // overscanRowCount={10}
                ref={detailGeoListRef}
                style={
                  mobile
                    ? {}
                    : {
                        overflowY: "visible",
                        overflowX: "visible",
                      }
                }
              />,
              detailGeoListRef
            )
          }
        </AutoSizer>
      );
    }

    return (
      <div className={classes.logListWarpper} ref={(ref) => setTabDivRef(ref)}>
        {!app && (
          <>
            <div className={classes.detailHeaderDiv}>
              <DateRangeIcon className={classes.detailHeaderIcon} />
              <Typography category="Default" variant="Body">
                {dateText}
              </Typography>
            </div>
            <div className={classes.detailHeaderDiv}>
              <NavigationIcon className={classes.detailHeaderIcon} />
              <Typography category="Default" variant="Body">
                {t("Routes selected_", {
                  n: selectedDrives.length,
                  total: filteredDriveList.length,
                })}
              </Typography>
            </div>
          </>
        )}
        {app && (
          <div
            style={{
              height: 48,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // padding: 12,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // gap: 12,
              }}
            >
              <IconButton
                onClick={() => {
                  onClose?.();
                }}
              >
                <CloseIcon htmlColor="rgba(0, 0, 0, 0.87)" />
              </IconButton>

              <Typography category="Default" variant="BodyBold">
                {t("Routes")}
              </Typography>
            </div>
          </div>
        )}

        <div
          className={clsx(
            classes.scrollableDiv,
            app && classes.appScrollableDiv
          )}
        >
          <Tabs
            value={tabValue}
            className={clsx(classes.detailTabDiv, {
              [classes.appDetailTabDiv]: app,
            })}
            GPSTabs
          >
            <Tab
              className={clsx(classes.flex1, {
                [classes.noHover]: mobile,
                [classes.active]: tabValue === 0,
              })}
              label={
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ width: 128 }}
                >
                  {t("Tracking log")}
                </Typography>
              }
              onTouchEnd={
                ios
                  ? () => {
                      setTabValue(0);

                      detailListRef.current?.recomputeRowHeights();
                      detailListRef.current?.forceUpdateGrid();
                    }
                  : undefined
              }
              onClick={() => setTabValue(0)}
            />
            <Tab
              className={clsx(classes.flex1, {
                [classes.noHover]: mobile,
                [classes.active]: tabValue === 1,
              })}
              label={
                <Typography
                  category="Default"
                  variant="BodyBold"
                  style={{ width: 130 }}
                >
                  {t("Geofence alerts")}
                </Typography>
              }
              onTouchEnd={
                ios
                  ? () => {
                      setTabValue(1);
                    }
                  : undefined
              }
              onClick={() => {
                setTabValue(1);
              }}
            />
          </Tabs>
          <div className={classes.logDiv} ref={(ref) => setLogListDiv(ref)}>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: tabValue === 0 ? "flex" : "none",
              }}
            >
              {tab0Markup}
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: tabValue === 1 ? "flex" : "none",
              }}
            >
              {tab1Markup}
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    geofenceAlerts,
    selectedDrives,
    tabValue,
    showTracks,
    logListDiv,
    classes.logListWarpper,
    classes.detailHeaderDiv,
    classes.detailHeaderIcon,
    classes.scrollableDiv,
    classes.appScrollableDiv,
    classes.detailTabDiv,
    classes.appDetailTabDiv,
    classes.flex1,
    classes.noHover,
    classes.active,
    classes.logDiv,
    classes.emptyDiv,
    classes.trackList,
    app,
    t,
    filteredDriveList.length,
    mobile,
    showLoading,
    tracks,
    renderListHelper,
    rowCount,
    logRowRenderer,
    logRowHeight,
    selectedDrive,
    selectedTrack,
    needToUpdateScroll,
    openDrives,
    calculatedLogHeight,
    visibleRow,
    geofenceAlertRenderer,
    onClose,
    themeState?.color,
  ]);

  return detailPaneMarkup;
};
