import React, { useState } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CAMERA, loadNotificationEmailList } from "../../features/Camera/slice";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { deleteEmail } from "../../apis";
import { Webviewer, RESULT_CODE } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    color: (props: any) => (props.darkMode ? "#FFFFFF" : "#1E1E23"),
    backgroundColor: (props: any) => (props.darkMode ? "#1E1E23" : "#FFFFFF"),
    borderColor: (props: any) => (props.darkMode ? "#323236" : "#D4D4D5"),
  },
  content: {
    padding: "19px 16px 21px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "22px 32px 21px",
    },
    "& .MuiInputBase-root": {
      color: (props: any) => (props.darkMode ? "#FFFFFF" : "#1E1E23"),
      backgroundColor: (props: any) =>
        props.darkMode ? "#121216 " : "#FFFFFF",
    },
  },
  titleDiv: {
    padding: "24px 44px 0 24px",
    minHeight: 33,
    [theme.breakpoints.up(Webviewer.mobile)]: {},
  },
  LButton: {
    color: (props: any) => (props.darkMode ? "#FFFFFF" : "#1E1E23"),
    backgroundColor: (props: any) => (props.darkMode ? "#323236" : "#FFFFFF"),
    borderColor: (props: any) => (props.darkMode ? "#636367" : "#D4D4D5"),
  },
  RButton: {
    "&.Mui-disabled": {
      backgroundColor: (props: any) =>
        props.darkMode ? "rgba(208, 235, 255, 0.3)" : "rgba(0, 149, 224, 0.35)",
    },
  },
}));

interface PersonDeleteModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onClickNegative: () => void;
  onClickPositive: () => void;
  deleteRecipients: string;
  darkMode?: boolean;
}

export const PersonDeleteModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
  deleteRecipients,
  darkMode,
}: PersonDeleteModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ darkMode });
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      loading={loading}
      className={classes.modal}
      contentClassName={classes.content}
      titleClassName={classes.titleDiv}
      RButtonClassName={classes.RButton}
      LButtonClassName={classes.LButton}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      // 10629 - API 호출 완료까지 팝업 안닫히게 수정
      // API에 토큰 타입 추가
      onClickPositive={async () => {
        setLoading(true);
        try {
          if (camera && email && loginInfo) {
            const res = await deleteEmail(
              email,
              loginInfo.user_token,
              camera.psn,
              deleteRecipients,
              tokenType
            );

            const { resultcode } = res.data as {
              resultcode: RESULT_CODE;
              message: string;
            };
            if (resultcode === "BC_ERR_OK") {
              dispatch(loadNotificationEmailList(camera.psn));
              onClickPositive();
            }
          }
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }}
      heading={t("Delete")}
      close={true}
      content={
        <div style={{ padding: "-8px 24px 5px" }}>
          <Typography variant="Body" category="Default">
            {t("Are you sure_recipient")}
          </Typography>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Delete")}
      Secondary={true}
    />
  );
};
