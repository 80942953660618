"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = void 0;
var _styles = require("@material-ui/core/styles");
var theme = function theme(_ref) {
  var Colors = _ref.Colors,
    dir = _ref.dir,
    breakpoints = _ref.breakpoints,
    fontFamily = _ref.fontFamily;
  return (0, _styles.createMuiTheme)({
    direction: dir,
    breakpoints: breakpoints,
    typography: {
      fontFamily: fontFamily || "Roboto"
    },
    palette: {
      primary: {
        dark: Colors.primary["8"],
        main: Colors.primary["7"],
        light: Colors.primary["8"],
        contrastText: Colors.primary["0"]
      },
      secondary: {
        dark: Colors.secondary["12"],
        main: Colors.secondary["11"],
        light: Colors.secondary["12"]
      },
      background: {
        paper: Colors.primary["0"]
      }
    }
  });
};
exports.theme = theme;