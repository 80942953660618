import React, { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import {
  BatteryConnect,
  BatteryError,
  BatteryAdd,
  BatteryDisconnect,
  Typography,
  IconButton,
  DashcamEventHandler,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import { BatteryManualCommand, ICameraInfo } from "@thingsw/pitta-modules";
import RequireCloudConnectModal from "../modals/RequireCloudConnectModal";
import DownLoadAppAddBatteryModal from "../modals/DownLoadAppAddBatteryModal";
import {
  loadBatteryManual,
  updateBatteryInfo,
} from "../../features/Battery/slice";
import { RootState } from "../../features/store";
import { useDispatch, useSelector } from "react-redux";
import useCalculateBatteryPercent from "../../hooks/useCalculateBatteryPercent";
import { THEME } from "../../features/Theme/slice";

interface BatteryStatusProps {
  camera: ICameraInfo;
  setPanelState: Dispatch<SetStateAction<"battery" | "list" | "setting">>;
  onClick: DashcamEventHandler | undefined;
}

export const BatteryStatusButton = (props: BatteryStatusProps) => {
  const { camera, setPanelState, onClick } = props;
  const battery = camera?.battery;
  const dispatch = useDispatch();
  const { batteryInfo } = useSelector((state: RootState) => state.battery);
  const { colors } = useSelector((root: RootState) => root[THEME]);
  const { t } = useTranslation();

  const calculateBatteryPercent = useCalculateBatteryPercent({
    battery,
    batteryInfo,
  });

  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);

  const [openRequireCloudModal, setOpenRequireCloudModal] = useState<boolean>(
    false
  );

  const handleOpenDownloadModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenDownloadModal(true);
  };

  const handleOpenRequireCloudModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenRequireCloudModal(true);
  };

  const handleChangeBetteryPanel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    dispatch(
      updateBatteryInfo({
        percent: calculateBatteryPercent,
        temperature: camera.battery.celsius,
      })
    );

    dispatch(
      loadBatteryManual({
        camera: camera,
        command: BatteryManualCommand.START,
      })
    );
    onClick?.(camera);
    setPanelState("battery");
  };

  const batteryStatusMarkup = () => {
    // 배터리 등록 전 | 카메라 클라우드 연결 X, 카메라 연결 기록 없음, 정상 연결 카메라
    if (
      !battery ||
      camera.reg_category === "wifi" ||
      (!camera.login_date && !battery)
    ) {
      return (
        <IconButton onClick={handleOpenDownloadModal}>
          <BatteryAdd htmlColor="white" />
          <Typography
            category="Default"
            variant="Caption"
            style={{
              marginLeft: 4,
              height: 16,
            }}
            htmlColor={colors.primary["3"]}
          >
            Battery
          </Typography>
        </IconButton>
      );
    }

    // 배터리 연결 완료, 데이터 수신 내역 없음 (초기 연결)
    if (battery.state === "pending" && camera.active === "on") {
      return (
        <IconButton onClick={handleChangeBetteryPanel}>
          <BatteryDisconnect />
          <img
            style={{
              width: 34,
              height: 24,
              marginLeft: 4,
            }}
            src="/images/Loading-battery.gif"
            alt="lottie"
          />
        </IconButton>
      );
    }

    // 카메라 연결 해제
    if (camera.active === "off") {
      return (
        <IconButton onClick={handleOpenRequireCloudModal}>
          <BatteryDisconnect />
          <Typography
            category="Default"
            variant="Caption"
            style={{
              marginLeft: 4,
              height: 12,
            }}
            htmlColor={colors.primary["3"]}
          >
            {t("Check")}
          </Typography>
        </IconButton>
      );
    }

    // 배터리 정상 연결
    switch (battery.state) {
      case "latest":
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryConnect percent={calculateBatteryPercent} />
            <Typography
              category="Default"
              variant="Caption"
              style={{
                marginLeft: 4,
                height: 12,
              }}
              htmlColor={colors.primary["8"]}
            >
              {calculateBatteryPercent}%
            </Typography>
          </IconButton>
        );
      // 10분 이상 업데이트 없음
      case "lateup1":
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryError htmlColor="white" />
            <Typography
              category="Default"
              variant="Caption"
              style={{
                marginLeft: 4,
                height: 12,
              }}
              htmlColor={colors.primary["3"]}
            >
              {calculateBatteryPercent}%
            </Typography>
          </IconButton>
        );
      // 30분 이상 업데이트 없음
      case "lateup2":
        return (
          <IconButton onClick={handleChangeBetteryPanel}>
            <BatteryError htmlColor="white" />
            <Typography
              category="Default"
              variant="Caption"
              style={{
                marginLeft: 4,
                height: 12,
              }}
              htmlColor={colors.primary["3"]}
            >
              --%
            </Typography>
          </IconButton>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {batteryStatusMarkup()}
      <RequireCloudConnectModal
        openBatteryRequireModal={openRequireCloudModal}
        setOpenBatteryRequireModal={setOpenRequireCloudModal}
      />
      <DownLoadAppAddBatteryModal
        openDownloadModal={openDownloadModal}
        setOpenDownloadModal={setOpenDownloadModal}
      />
    </>
  );
};
