import { useQuery } from "@tanstack/react-query";
import { DashboardMapview } from "@thingsw/pitta-liveview-module";
import { IClusterZone, LightColors } from "@thingsw/pitta-modules";
import axios from "axios";
import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery as useQueryString } from "../hooks/useQuery";

export const DashboardMapviewScreen = () => {
  const query = useQueryString();

  const key = useMemo(() => {
    return query?.get("key");
  }, [query]);

  // type: all or live - default: all
  const type = useMemo(() => {
    return query?.get("type") as null | "all" | "live";
  }, [query]);

  const { data } = useQuery({
    queryKey: ["mapState"],
    queryFn: async () => {
      let statsType = "mapStats";
      if (type === "live") {
        statsType = "activeMapStats";
      }
      const resp = await axios.get(
        `https://gateway.blackvuecloud.com/stats/map?collection=display&statsType=${statsType}`,
        {
          headers: {
            "x-api-key": key,
          },
        }
      );
      return resp.data.response;
    },
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: "always",
    refetchOnMount: "always",
    refetchOnReconnect: "always",
    enabled: !!key,
  });

  const clusters = useMemo(() => {
    return _.map(
      data?.details,
      (d) =>
        ({
          lat: `${d.coordinates[0]}`,
          lng: `${d.coordinates[1]}`,
          count: `${d.count}`,
          share_video: "off",
        } as IClusterZone)
    );
  }, [data]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 99999,
          display: "flex",
          justifyContent: "space-between",
          filter:
            "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.05))",
        }}
      >
        <div>
          <div
            style={{
              borderRadius: 9,
              padding: 16,
              margin: 16,
              display: "flex",
              alignItems: "center",
              gap: 16,
              fontSize: 42,
              fontWeight: 800,
              color: type === "live" ? "red" : "blue",
            }}
          >
            {type === "live" ? "Concurrent Dashcams" : "Registered Dashcams"}
          </div>
        </div>

        <div
          style={{
            backgroundColor: LightColors.primary["0"],
            border: `1px solid ${LightColors.primary["6"]}`,
            borderRadius: 9,
            padding: "16px 32px",
            margin: "32px 16px",
            display: "flex",
            alignItems: "center",
            height: "fit-content",
            gap: 16,
            fontSize: 20,
          }}
        >
          <div>GPS - On : {data?.summary?.agreeGps ?? 0}</div> |{" "}
          <div>GPS - Off: {data?.summary?.disagreeGps ?? 0}</div>
        </div>
      </div>
      <DashboardMapview clusters={clusters} />
    </div>
  );
};
