import { Color, LightColors } from "@thingsw/pitta-modules";

export const DoughnutBackgroundImagePlugin = (img: string) => {
  const image = new Image();
  image.src = img;
  return {
    id: "DoughnutBackgroundImagePlugin",
    beforeDraw: (chart: any) => {
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const x = left + width / 2 - image.width / 2;
        const y = top + height / 2 - image.height / 2;
        ctx.drawImage(image, x, y);
      } else {
        image.onload = () => chart.draw();
      }
    },
  };
};

// 일 차트 날짜 변경 선을 그리는 플러그인
export const dayVerticalLinePlugin = {
  id: "dayChartVerticalLinePlugin",
  afterDraw: (chart: any) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x2; // 두 번째 X축 (날짜를 표시하는 축)

    ctx.save(); // 현재 상태 저장
    ctx.strokeStyle = LightColors.primary["6"]; // 선 색상
    ctx.lineWidth = 1.5; // 선 굵기

    xAxis.ticks.forEach((tick: any, index: any) => {
      if (index + 1 === xAxis.ticks.length) {
        const x = xAxis.getPixelForTick(index);
        // const y = xAxis.bottom;
        ctx.fillStyle = "#0095E0";
        ctx.font = "bold 12px";

        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(x + 6, chart.chartArea.top);
        ctx.lineTo(x + 6, chart.chartArea.bottom + 90);
        ctx.stroke();
      }

      if (tick.label) {
        // 라벨이 있는 경우에만 선을 그림

        const x = xAxis.getPixelForTick(index);
        ctx.beginPath();
        ctx.moveTo(x - 6, chart.chartArea.top);
        ctx.lineTo(x - 6, chart.chartArea.bottom + 90);
        ctx.stroke();
      }
    });
    ctx.restore(); // 상태 복원
  },
};

// 일 차트 눈금선을 그리는 플러그인
export const dayOffsetGridLinesPlugin = (colors: Color) => {
  return {
    id: "dayOffsetGridLinesPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x; // X축
  
      ctx.save();
      ctx.strokeStyle = xAxis.options.grid.color; // 기본 그리드 색상 사용
      ctx.lineWidth = xAxis.options.grid.lineWidth; // 기본 그리드 라인 너비 사용
  
      xAxis.ticks.forEach((tick: any, index: any) => {
        if (tick.label) {
          const x = xAxis.getPixelForTick(index);
          const y = xAxis.bottom;
          ctx.strokeStyle = "rgba(233, 233, 234, 0.4)";
          ctx.font = "12px";
          ctx.fillStyle = colors.primary["1"];
          ctx.fillText(tick.label, x - 6, y - 18);
          ctx.beginPath();
          ctx.moveTo(x - 6, chart.chartArea.top);
          ctx.lineTo(x - 6, chart.chartArea.bottom);
          ctx.stroke();
        }
      });
  
      ctx.restore();
    },
  };
}

// 주 차트 월 변경 선을 그리는 플러그인
export const weekVerticalLinePlugin = {
  id: "weekVerticalLinePlugin",
  afterDraw: (chart: any) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x2; // 두 번째 X축 (날짜를 표시하는 축)

    ctx.save(); // 현재 상태 저장
    ctx.strokeStyle = LightColors.primary["6"]; // 선 색상

    ctx.lineWidth = 1; // 선 굵기

    xAxis.ticks.forEach((tick: any, index: any) => {
      if (index + 1 === xAxis.ticks.length) {
        const x = xAxis.getPixelForTick(index);
        const y = xAxis.bottom;
        ctx.fillStyle = "#0095E0";
        ctx.font = "bold 12px";
        ctx.fillText("Now", x - 22, y - 8);

        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(x + 3, chart.chartArea.top);
        ctx.lineTo(x + 3, chart.chartArea.bottom + 90);
        ctx.stroke();
      }

      if (tick.label) {
        // 라벨이 있는 경우에만 선을 그림

        const x = xAxis.getPixelForTick(index);
        ctx.beginPath();
        ctx.moveTo(x - 3, chart.chartArea.top);
        ctx.lineTo(x - 3, chart.chartArea.bottom + 90);
        ctx.stroke();
      }
    });
    ctx.restore(); // 상태 복원
  },
};

// 주 차트 눈금선을 그리는 플러그인
export const weekOffsetGridLinesPlugin = (colors: Color) => {
  return {
    id: "weekOffsetGridLinesPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x; // X축
  
      ctx.save();
      ctx.strokeStyle = xAxis.options.grid.color; // 기본 그리드 색상 사용
      ctx.lineWidth = xAxis.options.grid.lineWidth; // 기본 그리드 라인 너비 사용
  
      xAxis.ticks.forEach((tick: any, index: any) => {
        if (tick.label) {
          const x = xAxis.getPixelForTick(index);
          const y = xAxis.bottom;
          ctx.strokeStyle = "rgba(233, 233, 234, 0.4)";
          ctx.font = "10px";
          ctx.fillStyle = colors.primary["1"];
          ctx.fillText(tick.label, x - 3, y - 18);
          ctx.beginPath();
          ctx.moveTo(x - 3, chart.chartArea.top); // 왼쪽으로 이동
          ctx.lineTo(x - 3, chart.chartArea.bottom); // 왼쪽으로 이동
          ctx.stroke();
        }
      });
  
      ctx.restore();
    },
  };
}

//섭씨 온도 경고 라벨을 표시하는 플러그인
export const celsiusWarningLabelPlugin = {
  id: "celsiusWarningLabelPlugin",
  afterDraw: (chart: any) => {
    const ctx = chart.ctx;
    const yAxis = chart.scales.y; // Y축
    const xAxis = chart.scales.x; // X축

    ctx.save();
    ctx.textAlign = "right";
    ctx.textBaseline = "middle";
    ctx.fillStyle = LightColors.secondary["17"]; // 라벨 색상

    const y = yAxis.getPixelForValue(70); //  70에 대한 픽셀 위치
    ctx.fillText("70℃", yAxis.right, y);

    ctx.beginPath();
    ctx.moveTo(xAxis.left, y);
    ctx.lineTo(xAxis.right, y);
    ctx.strokeStyle = LightColors.secondary["17"]; // 선 색상
    ctx.lineWidth = 1; // 선 두께
    ctx.stroke();

    ctx.restore();
  },
};

//화씨 온도 경고 라벨을 표시하는 플러그인
export const fahrenheitWarningLabelPlugin = {
  id: "fahrenheitWarningLabelPlugin",
  afterDraw: (chart: any) => {
    const ctx = chart.ctx;
    const yAxis = chart.scales.y; // Y축
    const xAxis = chart.scales.x; // X축

    ctx.save();
    ctx.textAlign = "right";
    ctx.textBaseline = "middle";
    ctx.fillStyle = LightColors.secondary["17"]; // 라벨 색상

    const y = yAxis.getPixelForValue(158); //  70에 대한 픽셀 위치
    ctx.fillText("158℉", yAxis.right, y);

    ctx.beginPath();
    ctx.moveTo(xAxis.left, y);
    ctx.lineTo(xAxis.right, y);
    ctx.strokeStyle = LightColors.secondary["17"]; // 선 색상
    ctx.lineWidth = 1; // 선 두께
    ctx.stroke();

    ctx.restore();
  },
};
