import React, { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
  dayVerticalLinePlugin,
  dayOffsetGridLinesPlugin,
} from "../../utils/ChartPlugin";

import { getDayData } from "../../utils/BatteryHistoryChartData";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { THEME } from "../../features/Theme/slice";

interface TempHistoryDayChartProps {
  isCelcius: boolean;
}

const TempHistoryDayChart = ({ isCelcius }: TempHistoryDayChartProps) => {
  const { t } = useTranslation();
  const { colors } = useSelector((root: RootState) => root[THEME]);

  const { batteryDayHistory } = useSelector(
    (state: RootState) => state.battery
  );
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (batteryDayHistory.items) setIsLoading(false);
  }, [batteryDayHistory]);

  const { dataset, labels, dateLabels } = useMemo(
    () =>
      getDayData(batteryDayHistory.items || [], (history) =>
        isCelcius
          ? history.celsiusAverage
          : (history.celsiusAverage * 9) / 5 + 32
      ),
    [batteryDayHistory.items, isCelcius]
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress size={48} thickness={6} color="primary" />
      </div>
    );
  }

  const data = {
    // X축 레이블을 4시간 단위로 설정
    labels: labels,
    datasets: [
      {
        label: "Temperature Level",
        data: isCelcius
          ? dataset
          : dataset.map((data) => {
              if (data === null) {
                return [-4, -4];
              }

              if (data > 32) {
                return [data, 32];
              }

              if (data < 32) {
                return [data, -4];
              }

              return [-4, -4];
            }),
        backgroundColor: "#BAE6FF",
        hoverBackgroundColor: "#BAE6FF",
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animations: false,

    scales: {
      y: {
        position: "right", // Y축을 오른쪽에 표시
        max: isCelcius ? 100 : (100 * 9) / 5 + 32,
        min: isCelcius ? -20 : (-20 * 9) / 5 + 32,
        grid: {
          drawBorder: false, // 축 경계에 그려지는 선 숨김
          color: "rgba(233, 233, 234, 0.4)",
        },
        ticks: {
          stepSize: isCelcius ? 20 : 36, // Y축 눈금 간격을 20으로 설정
          callback: function (value: any) {
            if (isCelcius) {
              // 표시할 눈금에 대한 포맷을 정의
              if (value === 20 || value === 40 || value === 60 || value === 80)
                return "";

              return value + "℃";
            } else {
              if (value === -4 || value === 32 || value === 212) {
                return value + "℉";
              }

              return "";
            }
          },
        },
      },
      x: {
        position: "bottom",

        grid: {
          drawOnChartArea: false, // X축의 그리드 라인 그림
          drawBorder: false, // 축 경계에 그려지는 선 숨김
          display: false, // 이 축의 그리드 라인을 숨김
        },
        ticks: {
          maxTicksLimit: 24, // X축에 표시할 최대 눈금 수
          maxRotation: 0, // 레이블의 최대 기울기 각도
          minRotation: 0, // 레이블의 최소 기울기 각도
          color: colors.primary["0"],
          callback: function (value: any) {
            return labels[value].toString();
          },
        },
      },
      x2: {
        type: "category",
        position: "bottom", // 날짜 라벨이 표시될 위치
        labels: dateLabels, // 여기에 날짜 라벨을 설정
        grid: {
          drawOnChartArea: false, // 이 축의 그리드 라인을 차트 영역에 그리지 않음
          display: false, // 이 축의 그리드 라인을 숨김
          drawBorder: false, // 축 경계에 그려지는 선 숨김
        },
        ticks: {
          autoSkip: false,
          align: "start",
          color: colors.primary["1"],
          maxRotation: 0, // 레이블의 최대 기울기 각도
          minRotation: 0, // 레이블의 최소 기울기 각도
        },
      },
    },
    plugins: {
      legend: {
        display: false, // 범례 숨김
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        position: "top",
        align: "start",
        text: t("Temperature Level"), // 차트 제목
        font: {
          size: 12,
          weight: "bold",
        },
        color: colors.primary["1"],
        padding: {
          bottom: 20,
        },
      },
    },
  };

  return (
    <Bar
      key={isCelcius ? "celsius" : "fahrenheit"}
      data={data}
      options={options}
      plugins={[dayVerticalLinePlugin, dayOffsetGridLinesPlugin(colors)]}
      type="bar"
    />
  );
};

export default TempHistoryDayChart;
