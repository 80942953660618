import { makeStyles, Theme, useTheme } from "@material-ui/core";
import {
  IconButton,
  Modal,
  SearchDate,
  SelectList,
  Typography,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GPSTrackingDatePanel } from "../../components/gps-tracking/GPSTrackingDatePanel";
import { GPSTrackingListPanel } from "../../components/gps-tracking/GPSTrackingListPanel";
import { MapboxGPSTrackingMap } from "../../components/maps/MapboxGPSTrackingMap";
import { CAMERA, loadCameras } from "../../features/Camera/slice";
import {
  // clearGPSTrackingData,
  GPS,
  loadGeofenceAlert,
  setCameraInfo,
  setCandiDrives,
  setSelectedDates,
  setSelectedDrive,
  setSelectedDrives,
  setShowedDates,
  setShowTracks,
} from "../../features/GPS/slice";
import { RootState } from "../../features/store";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { sendMessageBack } from "../../utils/App";
import { USER } from "../../features/User/slice";
import moment from "moment";
import { detect } from "detect-browser";
import { disableRotate, ITrackData } from "@thingsw/pitta-modules";
import React from "react";
import { VideoPlayerMapboxUXUIModal } from "../../components/uxui/VideoPlayerMapboxUXUIModal";
import { THEME } from "../../features/Theme/slice";

interface GPSTrackingScreenProps {
  psn: string;
  planName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    height: "calc(var(--vh, 1vh) * 100)",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  topDiv: {
    height: 48,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    "& svg path": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  panel: {
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    transform: "translateX(100%)",
    transition: "transform 0.3s ease-in-out",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    display: "flex",
    "& button svg path": {
      fill: (props: any) => props.colors.primary["1"],
    },
  },
  modalTitle: {
    minHeight: 16,
  },
  panelOpen: {
    transform: "translateX(0)",
    zIndex: 1000,
    // opacity: 1,
    // visibility: "visible",
    // animation: `$fadeIn 0.3s`,
    animation: `$transformAnimation 0.3s ease-in forwards`,
  },
  panelClose: {
    // transform: "translateX(0)",
    // zIndex: 0,
    // animation: `$delayedTransformAnimation 0.1s ease-out 0.3s forwards`,
  },
  "@keyframes transformAnimation": {
    to: {
      transform: "translateX(0)",
    },
  },
  "@keyframes delayedTransformAnimation": {
    to: {
      transform: "translateX(100%)",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      visibility: "hidden",
    },
    "1%": {
      opacity: 0,
      visibility: "visible",
    },
    "100%": {
      opacity: 1,
      visibility: "visible",
    },
  },
}));

export const GPSTrackingScreen = ({
  psn,
  planName,
}: GPSTrackingScreenProps) => {  
  const theme = useTheme();
  const themeState = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles(themeState);
  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const needToSetDrive = useRef(true);
  
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const { camera, driveList, tracks, selectedDrives, showTracks } = useSelector(
    (state: RootState) => state[GPS]
  );

  const { userProfile } = useSelector((state: RootState) => state[USER]);

  const [openDateModal, setOpenDateModal] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [openNotSupport, setOpenNotSupport] = useState(false);
  const [playTrack, setPlayTrack] = useState<ITrackData>();
  // 패널 애니메이션을 위해 패널 초기 상태를 확인하기 위한 변수
  // const [initOpenDateModal, setInitOpenDateModal] = useState(false);
  // const [initOpenListModal, setInitOpenListModal] = useState(false);
  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  useEffect(() => {
    if (showTracks && camera) {
      dispatch(
        loadGeofenceAlert({
          psn: camera?.psn,
          drive_no_list: _.map(selectedDrives, (t) => t.drive_no),
        })
      );
    }
  }, [dispatch, camera, selectedDrives, showTracks, userProfile?.userType]);

  useEffect(() => {
    const cam = _.find(
      cameraList?.deviceListInfo,
      (dev) => dev.device.psn === psn
    )?.device;
    console.log("GPSTrackingScreen", "update cam", cam);
    dispatch(setCameraInfo(cam));
  }, [cameraList?.deviceListInfo, dispatch, psn]);

  useEffect(() => {
    if (camera && driveList.length > 0 && needToSetDrive.current) {
      const dr = _.first(driveList);
      if (dr) {
        dispatch(setShowTracks(true));
        dispatch(setSelectedDrives([dr]));
        const selectedTrack = _.find(
          tracks,
          (d) => d.drive_no === selectedDrives[0].drive_no
        );
        dispatch(setSelectedDrive(selectedTrack));
        dispatch(setCandiDrives([dr]));
        const sdate = moment(dr.sdate.format("YYYYMMDD"), "YYYYMMDD").set(
          "hour",
          12
        );
        const edate = moment(dr.edate.format("YYYYMMDD"), "YYYYMMDD").set(
          "hour",
          12
        );

        let currentDate = moment(sdate); // 시작 날짜로 초기화
        let dates: moment.Moment[] = [];
        while (currentDate.isSameOrBefore(edate)) {
          dates.push(moment(currentDate));
          currentDate.add(1, "day"); // 다음 날짜로 이동
        }

        dispatch(setSelectedDates(dates));
        dispatch(setShowedDates(dates));
      }

      needToSetDrive.current = false;
    }
  }, [camera, dispatch, driveList, driveList.length, selectedDrives, tracks]);

  // useEffect(() => {
  //   if (showTracks && needToSetDrive.current) {
  //     const selectedTrack = _.find(
  //       tracks,
  //       (d) => d.drive_no === selectedDrives[0].drive_no
  //     );
  //     dispatch(setSelectedDrive(selectedTrack));
  //     needToSetDrive.current = false;
  //   }
  // }, [dispatch, selectedDrives, showTracks, tracks]);

  const handleChangePlayTrack = useCallback(
    (data: ITrackData) => {
      if (planName === "Fleet plan") {
        setOpenPlayerModal(true);
        setPlayTrack(data);
      } else {
        setOpenNotSupport(true);
      }
    },
    [planName]
  );
  const handleDownload = useCallback(async (data: ITrackData) => {
    const browser = detect();
    const appData = {
      ...data,
      vdate: moment.unix((data.vdate as unknown) as number).utc(false),
    };
    console.log("onDownloadApp", data, appData, browser?.name);
    if (browser?.name === "ios-webview") {
      //@ts-ignore
      return webkit.messageHandlers.downloadGPSTracking.postMessage(
        JSON.parse(JSON.stringify(appData))
      );
    } else if (browser?.name === "chromium-webview") {
      //@ts-ignore
      return window.Webviewer?.downloadGPSTracking(JSON.stringify(appData));
    }
  }, []);

  const titleMarkup = useMemo(() => {
    // if (openDateModal) {
    //   return "Dates";
    // }
    // if (openListModal) {
    //   return "Tracking log";
    // }
    return camera?.dev_name ?? "";
  }, [camera?.dev_name]);

  const handleBack = useCallback(() => {
    if (openPlayerModal) {
      return setOpenPlayerModal(false);
    }
    if (openDateModal) {
      // dispatch(setCandiDrives(selectedDrives));
      // setInitOpenDateModal(false);
      return setOpenDateModal(false);
    }
    if (openListModal) {
      // setInitOpenListModal(false);
      return setOpenListModal(false);
    }

    sendMessageBack();
  }, [openDateModal, openListModal, openPlayerModal]);

  //@ts-ignore
  window.backPressed = handleBack;

  return (
    <div className={classes.root} dir={theme.direction}>
      <div className={classes.topDiv}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
          }}
        >
          <IconButton onClick={handleBack}>
            <ArrowBackIosIcon htmlColor="rgba(0, 0, 0, 0.87)" />
          </IconButton>

          <Typography category="Default" variant="BodyBold">
            {t(titleMarkup)}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 24,
          }}
        >
          {!openListModal && !openDateModal && (
            <>
              <IconButton
                onClick={() => {
                  // setInitOpenDateModal(true);
                  // dispatch(setCandiDrives([]));
                  dispatch(setCandiDrives(selectedDrives));
                  setOpenDateModal(true);
                  setOpenListModal(false);
                }}
              >
                <SearchDate />
              </IconButton>

              <IconButton
                onClick={() => {
                  // setInitOpenListModal(true);
                  setOpenListModal(true);
                  setOpenDateModal(false);
                }}
              >
                <SelectList />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <MapboxGPSTrackingMap
        psn={psn}
        app
        onDownload={handleDownload}
        onPlay={handleChangePlayTrack}
        disableUpdate={openPlayerModal}
      />
      <div
        className={clsx(classes.panel, {
          [classes.panelOpen]: openDateModal,
          [classes.panelClose]: !openDateModal,
        })}
      >
        <GPSTrackingDatePanel
          app
          mode="camera"
          open={openDateModal}
          onClose={() => {
            setOpenDateModal(false);
            // dispatch(clearGPSTrackingData());
            // dispatch(setCandiDrives([]));
          }}          
        />
      </div>

      <div
        className={clsx(classes.panel, {
          [classes.panelOpen]: openListModal,
          [classes.panelClose]: !openListModal,
        })}
      >
        <GPSTrackingListPanel
          app
          onChangePlayTrack={handleChangePlayTrack}
          onDownload={handleDownload}
          onClose={() => setOpenListModal(false)}          
        />
      </div>
      {camera && openPlayerModal && (
        <VideoPlayerMapboxUXUIModal
          gpsTracking
          app
          mode={3}
          open={openPlayerModal}
          camera={camera}
          data={playTrack}
          onClose={() => {
            setOpenPlayerModal(false);
            disableRotate();
          }}
        />
      )}
      {openNotSupport && (
        <Modal
          open={openNotSupport}
          close
          heading=" "
          titleClassName={classes.modalTitle}
          onClose={() => setOpenNotSupport(false)}
          RButton={t("OK")}
          onClickPositive={() => setOpenNotSupport(false)}
          content={
            <Typography
              category="Default"
              variant="Body"
              dangerouslySetInnerHTML={{
                __html: t("This feature is only_vom"),
              }}
            />
          }
        />
      )}
    </div>
  );
};
